export default {
    emissionsCatData(emission_cat){
        const HEADER_ROW = [
            {
              value: 'Section',
              fontWeight: 'bold',
              backgroundColor:"#9FC5E8",
              height:20,
              alignVertical:"center",
              borderColor: "#000000"
            },
            {
              value: 'Description',
              fontWeight: 'bold',
              backgroundColor:"#9FC5E8",
              height:20,
              alignVertical:"center",
              borderColor: "#000000"
            },
            {
              value: 'Details',
              fontWeight: 'bold',
              backgroundColor:"#9FC5E8",
              height:20,
              span:3,
              alignVertical:"center",
              borderColor: "#000000"
            },
            null,
            null,
            {
                value: 'Output',
                fontWeight: 'bold',
                backgroundColor:"#9FC5E8",
                height:20,
                alignVertical:"center",
                borderColor: "#000000"
              },
              {
                value: 'Output',
                fontWeight: 'bold',
                backgroundColor:"#9FC5E8",
                height:20,
                alignVertical:"center",
                borderColor: "#000000"
              },
              {
                value: 'Output',
                fontWeight: 'bold',
                backgroundColor:"#9FC5E8",
                height:20,
                alignVertical:"center",
                borderColor: "#000000"
              }
          ];

          const headers=["Scope","Category","TOTAL Emissions (mtCO2e)","Biogenic Emissions (mtCO2e)","Base Year TOTAL Emissions (mtCO2e)","Base Year Biogenic Emissions (mtCO2e)"];
          
          var SUB_HEADER = [
            {
              value: "",
              height: 40,
              borderColor:"#000000"
            },
            {
              value: "",
              height: 40,
              borderColor:"#000000"
            }
          ];
          headers.forEach((header_val, index)=>{
            SUB_HEADER[index+2] = {
                value: header_val,
                align: 'center',
                alignVertical:"center",
                backgroundColor:"#46BDC6",
                fontWeight: 'bold',
                fontSize: '12',
                height: 40,
                wrap:true,
                borderColor:"#000000"
              };
          });

          const columns = [
            { width: 15 },
            { width: 40 }, // in characters
            { width: 10 },
            { width: 50 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
          ];
          
          const data = [
            HEADER_ROW,
            SUB_HEADER,
          ];
        
        var data_len = data.length;
        var data_index = 0;
        emission_cat.forEach((currentValue)=>{
            var org_details = currentValue.details;
            var org_details_len = org_details.length;  

              var curr_row_data_index = data.length;
              data[curr_row_data_index]= [
                 // "Section"
                {
                  value: currentValue.section,
                  wrap: true,
                  fontWeight:"bold",
                  align: "center",
                  alignVertical:"center",
                  borderColor:"#000000",
                  rowSpan:org_details_len
                },
                // "Descriptions"
                {
                  value: currentValue.descriptions,
                  wrap: true,
                  width: 30,
                  align: 'center',
                  alignVertical:"center",
                  borderColor:"#000000",
                  rowSpan:org_details_len
                }
              ];
              data_index++;

              const scop_row_data = [];
              if(org_details_len > 0){
                var tot_categories = 0;
                for (let j = 0; j < org_details_len; j++) {
                  var cat_count = org_details[j].category.length;
                  tot_categories += ((cat_count == 0)?1:cat_count);
                  
                  if(j===0){
                    data[curr_row_data_index][2] = {
                        value: org_details[j].scope,
                        wrap: true,
                        align: 'left',
                        alignVertical:"center",
                        backgroundColor:"#46BDC6",
                        borderColor:"#000000",
                        rowSpan:cat_count
                    }
                  }

                  if(cat_count > 0){
                     for (let k = 0; k < cat_count; k++) {
                      if(j===0 && k===0){
                        data[curr_row_data_index][3] = {
                            value: org_details[j].category[k].name,
                            wrap: true,
                            align: 'left',
                            backgroundColor:"#46BDC6",
                            borderColor:"#000000",
                        }
                        data[curr_row_data_index][4] ={
                          value: this.roundNumber(org_details[j].category[k].total_emissions),
                          align: 'center',
                          borderColor:"#000000",
                          wrap: true,
                        }
                        data[curr_row_data_index][5] ={
                          value: this.roundNumber(org_details[j].category[k].biogenic_emissions),
                          align: 'center',
                          borderColor:"#000000",
                          wrap: true,
                        }
                        data[curr_row_data_index][6] ={
                          value: this.roundNumber(org_details[j].category[k].base_year_total_emissions),
                          align: 'center',
                          borderColor:"#000000",
                          wrap: true,
                        }
                        data[curr_row_data_index][7] ={
                          value: this.roundNumber(org_details[j].category[k].base_year_biogenic_emissions),
                          align: 'center',
                          borderColor:"#000000",
                          wrap: true,
                        }
                      }else{
                         var details_scope_cell = null;
                         if(j != 0 && k===0){
                          details_scope_cell = {
                            value: org_details[j].scope,
                            wrap: true,
                            align: 'left',
                            alignVertical:"center",
                            backgroundColor:"#46BDC6",
                            borderColor:"#000000",
                            rowSpan:cat_count
                           }
                         }
                         
                         data[data_index+data_len]= [
                          null,
                          null,
                          details_scope_cell,
                          {
                            value: org_details[j].category[k].name,
                            align: 'left',
                            backgroundColor:"#46BDC6",
                            borderColor:"#000000",
                            wrap: true,
                          },
                          {
                            value: this.roundNumber(org_details[j].category[k].total_emissions),
                            align: 'center',
                            borderColor:"#000000",
                            wrap: true,
                          },
                          {
                            value: this.roundNumber(org_details[j].category[k].biogenic_emissions),
                            align: 'center',
                            borderColor:"#000000",
                            wrap: true,
                          },
                          {
                            value: this.roundNumber(org_details[j].category[k].base_year_total_emissions),
                            align: 'center',
                            borderColor:"#000000",
                            wrap: true,
                          },
                          {
                            value: this.roundNumber(org_details[j].category[k].base_year_biogenic_emissions),
                            align: 'center',
                            borderColor:"#000000",
                            wrap: true,
                          }
                        ];
                        data_index++;
                      }
                     }
                  }else{
                    if(j===0){
                    data[curr_row_data_index][3] = {
                        value: '',
                        wrap: true,
                        align: 'left',
                        alignVertical:"center",
                        backgroundColor:"#46BDC6",
                        borderColor:"#000000",
                    }
                  } else{
                        data[data_index+data_len] = [
                          null,
                          null,
                          {
                          value: org_details[j].scope,
                          wrap: true,
                          align: 'left',
                          alignVertical:"center",
                          backgroundColor:"#46BDC6",
                          borderColor:"#000000",
                        },
                        {
                          value: '',
                          wrap: true,
                          align: 'left',
                          alignVertical:"center",
                          backgroundColor:"#46BDC6",
                          borderColor:"#000000",
                        }
                      ];
                    data_index++;
                    }
                  }
                  }

                  if(tot_categories > org_details_len){
                    data[curr_row_data_index][0].rowSpan = tot_categories;
                    data[curr_row_data_index][1].rowSpan = tot_categories;
                  }
              }
        });

        return {
            data,
            columns
        }
    },

    roundNumber(number)
    {
      if(typeof number === 'undefined'){
        return '';
      } else if (typeof number === 'string') {
        return number;
      } else if(Number(number) == 0){
        return number;
      } else if(Number(number) > 99999){
        return Number(number).toExponential();
      } else if(Number(number) < 0.0001){
        return Number(number).toExponential();
      } else{
        return Math.round(Number(number) * 100000) / 100000;
      }
    }
}