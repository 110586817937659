const chart_background_colors = [
    'rgba(255, 0, 0, 0.5)',     // Red
    'rgba(0, 255, 0, 0.5)',     // Green
    'rgba(0, 0, 255, 0.5)',     // Blue
    'rgba(255, 255, 0, 0.5)',   // Yellow
    'rgba(255, 0, 255, 0.5)',   // Magenta
    'rgba(0, 255, 255, 0.5)',   // Cyan
    'rgba(255, 128, 0, 0.5)',   // Orange
    'rgba(128, 0, 255, 0.5)',   // Purple
    'rgba(0, 128, 255, 0.5)',   // Sky Blue
    'rgba(255, 128, 128, 0.5)', // Light Red
    'rgba(128, 255, 128, 0.5)', // Light Green
    'rgba(128, 128, 255, 0.5)', // Light Blue
    'rgba(255, 255, 128, 0.5)', // Light Yellow
    'rgba(255, 128, 255, 0.5)', // Light Magenta
    'rgba(128, 255, 255, 0.5)', // Light Cyan
    'rgba(192, 192, 192, 0.5)', // Silver
    'rgba(255, 0, 128, 0.5)',   // Fuchsia
    'rgba(0, 255, 128, 0.5)',   // Lime
    'rgba(128, 0, 0, 0.5)',     // Maroon
    'rgba(0, 128, 0, 0.5)',     // Green
    'rgba(0, 0, 128, 0.5)',     // Navy
    'rgba(128, 128, 0, 0.5)',   // Olive
    'rgba(128, 0, 128, 0.5)',   // Purple
    'rgba(0, 128, 128, 0.5)',   // Teal
    'rgba(255, 165, 0, 0.5)',   // Orange
    'rgba(255, 69, 0, 0.5)'     // Dark Orange
];
export default chart_background_colors;
