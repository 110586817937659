<template>
  <unauthorized-role v-if="locked"></unauthorized-role>
  <!-- Begin page -->
  <div class="min-vh-100 min-vw-100 bg-light container-fluid" style="margin-top: -12px;" v-else>
    <div class="row">
      <left-sidebar></left-sidebar>
      <div class="col shadow p-0 mr-5 min-vh-100" style="width: 75%">
          <div class="pe-4">
            <router-view/>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftSidebar from './components/navigation/LeftSidebar';
import axios from 'axios';
import {ref, onMounted} from "vue";
import {useAuthStore} from "@/stores/auth";
import {useRestletStore} from "@/stores/restlets";
import {useLinkStore} from "@/stores/links";
import {storeToRefs} from 'pinia';
import UnauthorizedRole from "@/components/UnauthorizedRole";

export default {
  components: {
    LeftSidebar,
    UnauthorizedRole,
  },

  setup()
  {
    const auth_store = useAuthStore();
    const {user} = storeToRefs(auth_store);
    const locked = ref(false);

    const restlet_store = useRestletStore();
    const link_store = useLinkStore();

    const auth_endpoint = ref('');
    const link_endpoint = ref('');

    function parseRestletUrls()
    {
      	restlet_store.setRestletUrls();
    }

	parseRestletUrls();

    onMounted(() => {

		auth_endpoint.value = restlet_store.authentication_restlet_url;
		link_endpoint.value = restlet_store.link_restlet_url;

      /**
       * Set the Authentication state, lock the application if the role us not authorized.
       */
      axios.get(auth_endpoint.value)
          .then(response => {
				auth_store.setUser(response.data.user);
				auth_store.setGlobalUnit(response.data.global_unit);
				auth_store.setEnabledFeatures(response.data.enabled_features);

				if(response.data.error_codes)
				{
					auth_store.setErrorCodes(response.data.error_codes);
				}

				/*if(response.data.user.roleId !== 'customrole_csghg_controller')
				{
					locked.value = true;
				}*/

				if(!['customrole_csghg_controller', 'customrole_csghg_imp_consultant'].includes(response.data.user.roleId))
				{
					locked.value = true;
				}
          })

      /**
       * Get dynamic links
       */
      axios.get(link_endpoint.value)
          .then(response => {
            link_store.setLinks(response.data.links);
          })
    });

    return {
      user,
      locked,
    }
  }
}

</script>
