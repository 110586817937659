import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AccountingPeriodList from "@/views/AccountingPeriods/AccountingPeriodList";
import AccountingPeriodView from "@/views/AccountingPeriods/AccountingPeriodView";
import EmissionSources from "@/views/EmissionTransactions/EmissionSources";
import ImportSources from "@/views/EmissionTransactions/ImportSources";
import AllocateAssets from "@/views/EmissionTransactions/AllocateAssets";
import EmissionSource from "@/views/EmissionTransactions/EmissionSource";
import Workbench from '@/views/Workbench/Workbench.vue';
import JobStatusList from '@/views/Workbench/JobStatusList.vue';
import ComingSoon from "../views/ComingSoon.vue";

//Reductions Workbench
import ReductionsWorkbench from "@/views/Reduce/ReductionsWorkbench";
import ReductionsWorkbenchView from '@/views/Reduce/ReductionsWorkbenchView';
//End Reductions Workbench

/**
 * Reporting Engine
 */
import Reports from "@/views/Reports/Reports";

/** Temporary */
import EmissionReport from "@/views/Reports/EmissionReport";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/accounting-periods',
    name: 'accounting_period_list',
    component: AccountingPeriodList,
    children: [
      {
        path: ':parent_period',
        name: 'accounting_period_view_parent',
        component: AccountingPeriodView,
      },
      {
        path: ':parent_period/:child_period',
        name: 'accounting_period_view_child',
        component: AccountingPeriodView,
      }
    ]
  },
  {
    path: '/import-sources',
    name: 'import_sources',
    component: ImportSources,
  },
  {
    path: '/allocate-assets',
    name: 'allocate_assets',
    component: AllocateAssets,
  },
  {
    path: '/emission-sources',
    name: 'emission_sources',
    component: EmissionSources,
  },
  {
    path: '/emission-source/:source',
    name: 'emission_source',
    component: EmissionSource,
  },
  {
    path: '/coming-soon',
    name: 'coming_soon',
    component: ComingSoon,
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    children: [
      {
        path: 'emission-report',
        name: 'emission_report',
        component: EmissionReport,
      }
    ]
  },
  {
    path: '/reductions-workbench',
    name: 'reductions_workbench',
    component: ReductionsWorkbench,
  },
  {
    path: '/reductions_transaction/:transaction',
    name: 'reductions_transaction',
    component: ReductionsWorkbenchView
  },
  {
    path: '/workbench',
    name: 'workbench',
    component: Workbench,
  },
  {
    path: '/show-job-status',
    name: 'show_job_status',
    component: JobStatusList,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
