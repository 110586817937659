<template>
    <div>
      <input ref="parent" type="search" class="form-control form-control-sm" :placeholder="(disabled == false) ? placeholder : ''" :disabled="disabled" v-model="search" @input="dropdownSearch($event)" :style="disabledInputCss()">
      <div class="list-group shadow-lg" v-if="menu" style="position: absolute; z-index: 1000;">
        <button type="button" class="list-group-item list-group-item-action" v-for="(record, index) in records" @click="selectRecord(record)" :key="index">
          <span class="text-uppercase">{{ record.name }}</span>
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import {useRestletStore} from "@/stores/restlets";
  import {storeToRefs} from "pinia/dist/pinia.esm-browser";
  import {ref, watch, onMounted} from "vue";
  import axios from "axios";
  
  import LoadingIndicator from "@/components/global/LoadingIndicator";
  import DisabledLabelCss from "@/helpers/disabledLabelCss";

  export default {
  
    components: {
      LoadingIndicator,
    },
  
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: 'Type to search',
      },
      initial_value: {
        type: String,
        default: '',
      },
      record_type: {
        type: String,
        default: 'items', /** items,expense_categories,accounts */
      }
    },
  
    setup(props, {emit, expose})
    {
      const restlet_store = useRestletStore();
      const {list_record_search_restlet_url} = storeToRefs(restlet_store);
  
      const menu = ref(false);
      const loading = ref(false);
      const name =  ref('');
      const records = ref([]);
  
      const search = ref('');
  
      function dropdownSearch(event)
      {
        if(search.value.length === 0)
        {
          records.value = [];
          menu.value = false;
          emit('record-selected', null);
        }
  
        else {
          setTimeout(() => {
            searchRecords(false, true);
          }, 500);
        }
      }
  
      function searchRecords(run = false, open_menu = false)
      {
        if(search.value.length >= 3 || (run && !search.value))
        {
          let search_params = {search_term: search.value, record_type: props.record_type};
  
          let query = new URLSearchParams(search_params).toString();
          let endpoint = list_record_search_restlet_url.value + '&' + query;
  
  
          loading.value = true;
          axios.get(endpoint)
              .then(response => {
                records.value = response.data[props.record_type];
                loading.value = false;
              })
              .catch(error => {});
        }
  
        if(open_menu)
        {
          menu.value = true;
        }
      }
  
      function selectRecord(record)
      {
        if(record)
        {
          search.value = record.name;
          emit('record-selected', record);
          menu.value = false;
        }
      }
  
      function clear()
      {
        search.value = '';
        records.value = [];
      }
  
      function setExistingName(name)
      {
        search.value = name;
      }

    function disabledInputCss(){
      let css_val = DisabledLabelCss.disabledLabel(props.disabled);
      return css_val;
    }
  
      onMounted(() => {
        search.value = props.initial_value || '';
      });

      watch(() => props.initial_value, (new_value) => {
        search.value = props.initial_value || '';
      });
  
      expose({setExistingName});
  
      return {
        menu,
        loading,
        search,
        name,
        records,
        dropdownSearch,
        searchRecords,
        selectRecord,
        clear,
        setExistingName,
        disabledInputCss,
      }
    }
  }
  </script>
  