const countries = [
    {
        "name": "Afghanistan",
        "code": "AF",
        "id": "af"
    },
    {
        "name": "Åland Islands",
        "code": "AX",
        "id": "ax"
    },
    {
        "name": "Albania",
        "code": "AL",
        "id": "al"
    },
    {
        "name": "Algeria",
        "code": "DZ",
        "id": "dz"
    },
    {
        "name": "American Samoa",
        "code": "AS",
        "id": "as"
    },
    {
        "name": "AndorrA",
        "code": "AD",
        "id": "ad"
    },
    {
        "name": "Angola",
        "code": "AO",
        "id": "ao"
    },
    {
        "name": "Anguilla",
        "code": "AI",
        "id": "ai"
    },
    {
        "name": "Antarctica",
        "code": "AQ",
        "id": "aq"
    },
    {
        "name": "Antigua and Barbuda",
        "code": "AG",
        "id": "ag"
    },
    {
        "name": "Argentina",
        "code": "AR",
        "id": "ar"
    },
    {
        "name": "Armenia",
        "code": "AM",
        "id": "am"
    },
    {
        "name": "Aruba",
        "code": "AW",
        "id": "aw"
    },
    {
        "name": "Australia",
        "code": "AU",
        "id": "au"
    },
    {
        "name": "Austria",
        "code": "AT",
        "id": "at"
    },
    {
        "name": "Azerbaijan",
        "code": "AZ",
        "id": "az"
    },
    {
        "name": "Bahamas",
        "code": "BS",
        "id": "bs"
    },
    {
        "name": "Bahrain",
        "code": "BH",
        "id": "bh"
    },
    {
        "name": "Bangladesh",
        "code": "BD",
        "id": "bd"
    },
    {
        "name": "Barbados",
        "code": "BB",
        "id": "bb"
    },
    {
        "name": "Belarus",
        "code": "BY",
        "id": "by"
    },
    {
        "name": "Belgium",
        "code": "BE",
        "id": "be"
    },
    {
        "name": "Belize",
        "code": "BZ",
        "id": "bz"
    },
    {
        "name": "Benin",
        "code": "BJ",
        "id": "bj"
    },
    {
        "name": "Bermuda",
        "code": "BM",
        "id": "bm"
    },
    {
        "name": "Bhutan",
        "code": "BT",
        "id": "bt"
    },
    {
        "name": "Bolivia",
        "code": "BO",
        "id": "bo"
    },
    {
        "name": "Bosnia and Herzegovina",
        "code": "BA",
        "id": "ba"
    },
    {
        "name": "Botswana",
        "code": "BW",
        "id": "bw"
    },
    {
        "name": "Bouvet Island",
        "code": "BV",
        "id": "bv"
    },
    {
        "name": "Brazil",
        "code": "BR",
        "id": "br"
    },
    {
        "name": "British Indian Ocean Territory",
        "code": "IO",
        "id": "io"
    },
    {
        "name": "Brunei Darussalam",
        "code": "BN",
        "id": "bn"
    },
    {
        "name": "Bulgaria",
        "code": "BG",
        "id": "bg"
    },
    {
        "name": "Burkina Faso",
        "code": "BF",
        "id": "bf"
    },
    {
        "name": "Burundi",
        "code": "BI",
        "id": "bi"
    },
    {
        "name": "Cambodia",
        "code": "KH",
        "id": "kh"
    },
    {
        "name": "Cameroon",
        "code": "CM",
        "id": "cm"
    },
    {
        "name": "Canada",
        "code": "CA",
        "id": "ca"
    },
    {
        "name": "Cape Verde",
        "code": "CV",
        "id": "cv"
    },
    {
        "name": "Cayman Islands",
        "code": "KY",
        "id": "ky"
    },
    {
        "name": "Central African Republic",
        "code": "CF",
        "id": "cf"
    },
    {
        "name": "Chad",
        "code": "TD",
        "id": "td"
    },
    {
        "name": "Chile",
        "code": "CL",
        "id": "cl"
    },
    {
        "name": "China",
        "code": "CN",
        "id": "cn"
    },
    {
        "name": "Christmas Island",
        "code": "CX",
        "id": "cx"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "code": "CC",
        "id": "cc"
    },
    {
        "name": "Colombia",
        "code": "CO",
        "id": "co"
    },
    {
        "name": "Comoros",
        "code": "KM",
        "id": "km"
    },
    {
        "name": "Congo",
        "code": "CG",
        "id": "cg"
    },
    {
        "name": "Congo, The Democratic Republic of the",
        "code": "CD",
        "id": "cd"
    },
    {
        "name": "Cook Islands",
        "code": "CK",
        "id": "ck"
    },
    {
        "name": "Costa Rica",
        "code": "CR",
        "id": "cr"
    },
    {
        "name": "Cote D'Ivoire",
        "code": "CI",
        "id": "ci"
    },
    {
        "name": "Croatia",
        "code": "HR",
        "id": "hr"
    },
    {
        "name": "Cuba",
        "code": "CU",
        "id": "cu"
    },
    {
        "name": "Cyprus",
        "code": "CY",
        "id": "cy"
    },
    {
        "name": "Czech Republic",
        "code": "CZ",
        "id": "cz"
    },
    {
        "name": "Denmark",
        "code": "DK",
        "id": "dk"
    },
    {
        "name": "Djibouti",
        "code": "DJ",
        "id": "dj"
    },
    {
        "name": "Dominica",
        "code": "DM",
        "id": "dm"
    },
    {
        "name": "Dominican Republic",
        "code": "DO",
        "id": "do"
    },
    {
        "name": "Ecuador",
        "code": "EC",
        "id": "ec"
    },
    {
        "name": "Egypt",
        "code": "EG",
        "id": "eg"
    },
    {
        "name": "El Salvador",
        "code": "SV",
        "id": "sv"
    },
    {
        "name": "Equatorial Guinea",
        "code": "GQ",
        "id": "gq"
    },
    {
        "name": "Eritrea",
        "code": "ER",
        "id": "er"
    },
    {
        "name": "Estonia",
        "code": "EE",
        "id": "ee"
    },
    {
        "name": "Ethiopia",
        "code": "ET",
        "id": "et"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "code": "FK",
        "id": "fk"
    },
    {
        "name": "Faroe Islands",
        "code": "FO",
        "id": "fo"
    },
    {
        "name": "Fiji",
        "code": "FJ",
        "id": "fj"
    },
    {
        "name": "Finland",
        "code": "FI",
        "id": "fi"
    },
    {
        "name": "France",
        "code": "FR",
        "id": "fr"
    },
    {
        "name": "French Guiana",
        "code": "GF",
        "id": "gf"
    },
    {
        "name": "French Polynesia",
        "code": "PF",
        "id": "pf"
    },
    {
        "name": "French Southern Territories",
        "code": "TF",
        "id": "tf"
    },
    {
        "name": "Gabon",
        "code": "GA",
        "id": "ga"
    },
    {
        "name": "Gambia",
        "code": "GM",
        "id": "gm"
    },
    {
        "name": "Georgia",
        "code": "GE",
        "id": "ge"
    },
    {
        "name": "Germany",
        "code": "DE",
        "id": "de"
    },
    {
        "name": "Ghana",
        "code": "GH",
        "id": "gh"
    },
    {
        "name": "Gibraltar",
        "code": "GI",
        "id": "gi"
    },
    {
        "name": "Greece",
        "code": "GR",
        "id": "gr"
    },
    {
        "name": "Greenland",
        "code": "GL",
        "id": "gl"
    },
    {
        "name": "Grenada",
        "code": "GD",
        "id": "gd"
    },
    {
        "name": "Guadeloupe",
        "code": "GP",
        "id": "gp"
    },
    {
        "name": "Guam",
        "code": "GU",
        "id": "gu"
    },
    {
        "name": "Guatemala",
        "code": "GT",
        "id": "gt"
    },
    {
        "name": "Guernsey",
        "code": "GG",
        "id": "gg"
    },
    {
        "name": "Guinea",
        "code": "GN",
        "id": "gn"
    },
    {
        "name": "Guinea-Bissau",
        "code": "GW",
        "id": "gw"
    },
    {
        "name": "Guyana",
        "code": "GY",
        "id": "gy"
    },
    {
        "name": "Haiti",
        "code": "HT",
        "id": "ht"
    },
    {
        "name": "Heard Island and Mcdonald Islands",
        "code": "HM",
        "id": "hm"
    },
    {
        "name": "Holy See (Vatican City State)",
        "code": "VA",
        "id": "va"
    },
    {
        "name": "Honduras",
        "code": "HN",
        "id": "hn"
    },
    {
        "name": "Hong Kong",
        "code": "HK",
        "id": "hk"
    },
    {
        "name": "Hungary",
        "code": "HU",
        "id": "hu"
    },
    {
        "name": "Iceland",
        "code": "IS",
        "id": "is"
    },
    {
        "name": "India",
        "code": "IN",
        "id": "in"
    },
    {
        "name": "Indonesia",
        "code": "ID",
        "id": "id"
    },
    {
        "name": "Iran, Islamic Republic Of",
        "code": "IR",
        "id": "ir"
    },
    {
        "name": "Iraq",
        "code": "IQ",
        "id": "iq"
    },
    {
        "name": "Ireland",
        "code": "IE",
        "id": "ie"
    },
    {
        "name": "Isle of Man",
        "code": "IM",
        "id": "im"
    },
    {
        "name": "Israel",
        "code": "IL",
        "id": "il"
    },
    {
        "name": "Italy",
        "code": "IT",
        "id": "it"
    },
    {
        "name": "Jamaica",
        "code": "JM",
        "id": "jm"
    },
    {
        "name": "Japan",
        "code": "JP",
        "id": "jp"
    },
    {
        "name": "Jersey",
        "code": "JE",
        "id": "je"
    },
    {
        "name": "Jordan",
        "code": "JO",
        "id": "jo"
    },
    {
        "name": "Kazakhstan",
        "code": "KZ",
        "id": "kz"
    },
    {
        "name": "Kenya",
        "code": "KE",
        "id": "ke"
    },
    {
        "name": "Kiribati",
        "code": "KI",
        "id": "ki"
    },
    {
        "name": "Korea, Democratic People'S Republic of",
        "code": "KP",
        "id": "kp"
    },
    {
        "name": "Korea, Republic of",
        "code": "KR",
        "id": "kr"
    },
    {
        "name": "Kuwait",
        "code": "KW",
        "id": "kw"
    },
    {
        "name": "Kyrgyzstan",
        "code": "KG",
        "id": "kg"
    },
    {
        "name": "Lao People'S Democratic Republic",
        "code": "LA",
        "id": "la"
    },
    {
        "name": "Latvia",
        "code": "LV",
        "id": "lv"
    },
    {
        "name": "Lebanon",
        "code": "LB",
        "id": "lb"
    },
    {
        "name": "Lesotho",
        "code": "LS",
        "id": "ls"
    },
    {
        "name": "Liberia",
        "code": "LR",
        "id": "lr"
    },
    {
        "name": "Libyan Arab Jamahiriya",
        "code": "LY",
        "id": "ly"
    },
    {
        "name": "Liechtenstein",
        "code": "LI",
        "id": "li"
    },
    {
        "name": "Lithuania",
        "code": "LT",
        "id": "lt"
    },
    {
        "name": "Luxembourg",
        "code": "LU",
        "id": "lu"
    },
    {
        "name": "Macao",
        "code": "MO",
        "id": "mo"
    },
    {
        "name": "Macedonia, The Former Yugoslav Republic of",
        "code": "MK",
        "id": "mk"
    },
    {
        "name": "Madagascar",
        "code": "MG",
        "id": "mg"
    },
    {
        "name": "Malawi",
        "code": "MW",
        "id": "mw"
    },
    {
        "name": "Malaysia",
        "code": "MY",
        "id": "my"
    },
    {
        "name": "Maldives",
        "code": "MV",
        "id": "mv"
    },
    {
        "name": "Mali",
        "code": "ML",
        "id": "ml"
    },
    {
        "name": "Malta",
        "code": "MT",
        "id": "mt"
    },
    {
        "name": "Marshall Islands",
        "code": "MH",
        "id": "mh"
    },
    {
        "name": "Martinique",
        "code": "MQ",
        "id": "mq"
    },
    {
        "name": "Mauritania",
        "code": "MR",
        "id": "mr"
    },
    {
        "name": "Mauritius",
        "code": "MU",
        "id": "mu"
    },
    {
        "name": "Mayotte",
        "code": "YT",
        "id": "yt"
    },
    {
        "name": "Mexico",
        "code": "MX",
        "id": "mx"
    },
    {
        "name": "Micronesia, Federated States of",
        "code": "FM",
        "id": "fm"
    },
    {
        "name": "Moldova, Republic of",
        "code": "MD",
        "id": "md"
    },
    {
        "name": "Monaco",
        "code": "MC",
        "id": "mc"
    },
    {
        "name": "Mongolia",
        "code": "MN",
        "id": "mn"
    },
    {
        "name": "Montserrat",
        "code": "MS",
        "id": "ms"
    },
    {
        "name": "Morocco",
        "code": "MA",
        "id": "ma"
    },
    {
        "name": "Mozambique",
        "code": "MZ",
        "id": "mz"
    },
    {
        "name": "Myanmar",
        "code": "MM",
        "id": "mm"
    },
    {
        "name": "Namibia",
        "code": "NA",
        "id": "na"
    },
    {
        "name": "Nauru",
        "code": "NR",
        "id": "nr"
    },
    {
        "name": "Nepal",
        "code": "NP",
        "id": "np"
    },
    {
        "name": "Netherlands",
        "code": "NL",
        "id": "nl"
    },
    {
        "name": "Netherlands Antilles",
        "code": "AN",
        "id": "an"
    },
    {
        "name": "New Caledonia",
        "code": "NC",
        "id": "nc"
    },
    {
        "name": "New Zealand",
        "code": "NZ",
        "id": "nz"
    },
    {
        "name": "Nicaragua",
        "code": "NI",
        "id": "ni"
    },
    {
        "name": "Niger",
        "code": "NE",
        "id": "ne"
    },
    {
        "name": "Nigeria",
        "code": "NG",
        "id": "ng"
    },
    {
        "name": "Niue",
        "code": "NU",
        "id": "nu"
    },
    {
        "name": "Norfolk Island",
        "code": "NF",
        "id": "nf"
    },
    {
        "name": "Northern Mariana Islands",
        "code": "MP",
        "id": "mp"
    },
    {
        "name": "Norway",
        "code": "NO",
        "id": "no"
    },
    {
        "name": "Oman",
        "code": "OM",
        "id": "om"
    },
    {
        "name": "Pakistan",
        "code": "PK",
        "id": "pk"
    },
    {
        "name": "Palau",
        "code": "PW",
        "id": "pw"
    },
    {
        "name": "Palestinian Territory, Occupied",
        "code": "PS",
        "id": "ps"
    },
    {
        "name": "Panama",
        "code": "PA",
        "id": "pa"
    },
    {
        "name": "Papua New Guinea",
        "code": "PG",
        "id": "pg"
    },
    {
        "name": "Paraguay",
        "code": "PY",
        "id": "py"
    },
    {
        "name": "Peru",
        "code": "PE",
        "id": "pe"
    },
    {
        "name": "Philippines",
        "code": "PH",
        "id": "ph"
    },
    {
        "name": "Pitcairn",
        "code": "PN",
        "id": "pn"
    },
    {
        "name": "Poland",
        "code": "PL",
        "id": "pl"
    },
    {
        "name": "Portugal",
        "code": "PT",
        "id": "pt"
    },
    {
        "name": "Puerto Rico",
        "code": "PR",
        "id": "pr"
    },
    {
        "name": "Qatar",
        "code": "QA",
        "id": "qa"
    },
    {
        "name": "Reunion",
        "code": "RE",
        "id": "re"
    },
    {
        "name": "Romania",
        "code": "RO",
        "id": "ro"
    },
    {
        "name": "Russian Federation",
        "code": "RU",
        "id": "ru"
    },
    {
        "name": "RWANDA",
        "code": "RW",
        "id": "rw"
    },
    {
        "name": "Saint Helena",
        "code": "SH",
        "id": "sh"
    },
    {
        "name": "Saint Kitts and Nevis",
        "code": "KN",
        "id": "kn"
    },
    {
        "name": "Saint Lucia",
        "code": "LC",
        "id": "lc"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "code": "PM",
        "id": "pm"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "code": "VC",
        "id": "vc"
    },
    {
        "name": "Samoa",
        "code": "WS",
        "id": "ws"
    },
    {
        "name": "San Marino",
        "code": "SM",
        "id": "sm"
    },
    {
        "name": "Sao Tome and Principe",
        "code": "ST",
        "id": "st"
    },
    {
        "name": "Saudi Arabia",
        "code": "SA",
        "id": "sa"
    },
    {
        "name": "Senegal",
        "code": "SN",
        "id": "sn"
    },
    {
        "name": "Serbia and Montenegro",
        "code": "CS",
        "id": "cs"
    },
    {
        "name": "Seychelles",
        "code": "SC",
        "id": "sc"
    },
    {
        "name": "Sierra Leone",
        "code": "SL",
        "id": "sl"
    },
    {
        "name": "Singapore",
        "code": "SG",
        "id": "sg"
    },
    {
        "name": "Slovakia",
        "code": "SK",
        "id": "sk"
    },
    {
        "name": "Slovenia",
        "code": "SI",
        "id": "si"
    },
    {
        "name": "Solomon Islands",
        "code": "SB",
        "id": "sb"
    },
    {
        "name": "Somalia",
        "code": "SO",
        "id": "so"
    },
    {
        "name": "South Africa",
        "code": "ZA",
        "id": "za"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "code": "GS",
        "id": "gs"
    },
    {
        "name": "Spain",
        "code": "ES",
        "id": "es"
    },
    {
        "name": "Sri Lanka",
        "code": "LK",
        "id": "lk"
    },
    {
        "name": "Sudan",
        "code": "SD",
        "id": "sd"
    },
    {
        "name": "Suriname",
        "code": "SR",
        "id": "sr"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "code": "SJ",
        "id": "sj"
    },
    {
        "name": "Swaziland",
        "code": "SZ",
        "id": "sz"
    },
    {
        "name": "Sweden",
        "code": "SE",
        "id": "se"
    },
    {
        "name": "Switzerland",
        "code": "CH",
        "id": "ch"
    },
    {
        "name": "Syrian Arab Republic",
        "code": "SY",
        "id": "sy"
    },
    {
        "name": "Taiwan, Province of China",
        "code": "TW",
        "id": "tw"
    },
    {
        "name": "Tajikistan",
        "code": "TJ",
        "id": "tj"
    },
    {
        "name": "Tanzania, United Republic of",
        "code": "TZ",
        "id": "tz"
    },
    {
        "name": "Thailand",
        "code": "TH",
        "id": "th"
    },
    {
        "name": "Timor-Leste",
        "code": "TL",
        "id": "tl"
    },
    {
        "name": "Togo",
        "code": "TG",
        "id": "tg"
    },
    {
        "name": "Tokelau",
        "code": "TK",
        "id": "tk"
    },
    {
        "name": "Tonga",
        "code": "TO",
        "id": "to"
    },
    {
        "name": "Trinidad and Tobago",
        "code": "TT",
        "id": "tt"
    },
    {
        "name": "Tunisia",
        "code": "TN",
        "id": "tn"
    },
    {
        "name": "Turkey",
        "code": "TR",
        "id": "tr"
    },
    {
        "name": "Turkmenistan",
        "code": "TM",
        "id": "tm"
    },
    {
        "name": "Turks and Caicos Islands",
        "code": "TC",
        "id": "tc"
    },
    {
        "name": "Tuvalu",
        "code": "TV",
        "id": "tv"
    },
    {
        "name": "Uganda",
        "code": "UG",
        "id": "ug"
    },
    {
        "name": "Ukraine",
        "code": "UA",
        "id": "ua"
    },
    {
        "name": "United Arab Emirates",
        "code": "AE",
        "id": "ae"
    },
    {
        "name": "United Kingdom",
        "code": "GB",
        "id": "gb"
    },
    {
        "name": "United States",
        "code": "US",
        "id": "us"
    },
    {
        "name": "United States Minor Outlying Islands",
        "code": "UM",
        "id": "um"
    },
    {
        "name": "Uruguay",
        "code": "UY",
        "id": "uy"
    },
    {
        "name": "Uzbekistan",
        "code": "UZ",
        "id": "uz"
    },
    {
        "name": "Vanuatu",
        "code": "VU",
        "id": "vu"
    },
    {
        "name": "Venezuela",
        "code": "VE",
        "id": "ve"
    },
    {
        "name": "Viet Nam",
        "code": "VN",
        "id": "vn"
    },
    {
        "name": "Virgin Islands, British",
        "code": "VG",
        "id": "vg"
    },
    {
        "name": "Virgin Islands, U.S.",
        "code": "VI",
        "id": "vi"
    },
    {
        "name": "Wallis and Futuna",
        "code": "WF",
        "id": "wf"
    },
    {
        "name": "Western Sahara",
        "code": "EH",
        "id": "eh"
    },
    {
        "name": "Yemen",
        "code": "YE",
        "id": "ye"
    },
    {
        "name": "Zambia",
        "code": "ZM",
        "id": "zm"
    },
    {
        "name": "Zimbabwe",
        "code": "ZW",
        "id": "zw"
    }
];

export default countries;