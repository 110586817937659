// Reference - https://help.sap.com/docs/SAP_FIELDGLASS_INTEGRATION/d8609ff701d84cc1ac5f6fc2037c48d4/cda58416e58348fa9e256a4cd4c72622.html

const uk_states = [
    {
      "abbreviation": "DRS",
      "name": "Derry and Strabane"
    },
    {
      "abbreviation": "FMO",
      "name": "Fermanagh and Omagh"
    },
    {
      "abbreviation": "ABE",
      "name": "Aberdeen City"
    },
    {
      "abbreviation": "ABD",
      "name": "Aberdeenshire"
    },
    {
      "abbreviation": "ANS",
      "name": "Angus"
    },
    {
      "abbreviation": "ANN",
      "name": "Antrim and Newtownabbey"
    },
    {
      "abbreviation": "AND",
      "name": "Ards and North Down"
    },
    {
      "abbreviation": "AGB",
      "name": "Argyll and Bute"
    },
    {
      "abbreviation": "ARM",
      "name": "Armagh"
    },
    {
      "abbreviation": "ABC",
      "name": "Armagh, Banbridge and Craigavon"
    },
    {
      "abbreviation": "BDG",
      "name": "Barking and Dagenham"
    },
    {
      "abbreviation": "BNE",
      "name": "Barnet"
    },
    {
      "abbreviation": "BNS",
      "name": "Barnsley"
    },
    {
      "abbreviation": "BAS",
      "name": "Bath and North East Somerset"
    },
    {
      "abbreviation": "BDF",
      "name": "Bedford"
    },
    {
      "abbreviation": "BFS",
      "name": "Belfast"
    },
    {
      "abbreviation": "BEX",
      "name": "Bexley"
    },
    {
      "abbreviation": "BIR",
      "name": "Birmingham"
    },
    {
      "abbreviation": "BBD",
      "name": "Blackburn with Darwen"
    },
    {
      "abbreviation": "BPL",
      "name": "Blackpool"
    },
    {
      "abbreviation": "BGW",
      "name": "Blaenau Gwent"
    },
    {
      "abbreviation": "BOL",
      "name": "Bolton"
    },
    {
      "abbreviation": "BMH",
      "name": "Bournemouth"
    },
    {
      "abbreviation": "BRC",
      "name": "Bracknell Forest"
    },
    {
      "abbreviation": "BRD",
      "name": "Bradford"
    },
    {
      "abbreviation": "BEN",
      "name": "Brent"
    },
    {
      "abbreviation": "BGE",
      "name": "Bridgend [Pen-y-bont ar Ogwr GB-POG]"
    },
    {
      "abbreviation": "BNH",
      "name": "Brighton and Hove"
    },
    {
      "abbreviation": "BST",
      "name": "Bristol, City of"
    },
    {
      "abbreviation": "BST",
      "name": "Bristol, City of"
    },
    {
      "abbreviation": "BRY",
      "name": "Bromley"
    },
    {
      "abbreviation": "BKM",
      "name": "Buckinghamshire"
    },
    {
      "abbreviation": "BUR",
      "name": "Bury"
    },
    {
      "abbreviation": "CAY",
      "name": "Caerphilly [Caerffili GB-CAF]"
    },
    {
      "abbreviation": "CLD",
      "name": "Calderdale"
    },
    {
      "abbreviation": "CAM",
      "name": "Cambridgeshire"
    },
    {
      "abbreviation": "CMD",
      "name": "Camden"
    },
    {
      "abbreviation": "CRF",
      "name": "Cardiff [Caerdydd GB-CRD]"
    },
    {
      "abbreviation": "CMN",
      "name": "Carmarthenshire [Sir Gaerfyrddin GB-GFY]"
    },
    {
      "abbreviation": "CCG",
      "name": "Causeway Coast and Glens"
    },
    {
      "abbreviation": "CBF",
      "name": "Central Bedfordshire"
    },
    {
      "abbreviation": "CGN",
      "name": "Ceredigion [Sir Ceredigion]"
    },
    {
      "abbreviation": "CHE",
      "name": "Cheshire East"
    },
    {
      "abbreviation": "CHW",
      "name": "Cheshire West and Chester"
    },
    {
      "abbreviation": "CLK",
      "name": "Clackmannanshire"
    },
    {
      "abbreviation": "CWY",
      "name": "Conwy"
    },
    {
      "abbreviation": "CON",
      "name": "Cornwall"
    },
    {
      "abbreviation": "COV",
      "name": "Coventry"
    },
    {
      "abbreviation": "CRY",
      "name": "Croydon"
    },
    {
      "abbreviation": "CMA",
      "name": "Cumbria"
    },
    {
      "abbreviation": "DAL",
      "name": "Darlington"
    },
    {
      "abbreviation": "DEN",
      "name": "Denbighshire [Sir Ddinbych GB-DDB]"
    },
    {
      "abbreviation": "DER",
      "name": "Derby"
    },
    {
      "abbreviation": "DBY",
      "name": "Derbyshire"
    },
    {
      "abbreviation": "DEV",
      "name": "Devon"
    },
    {
      "abbreviation": "DNC",
      "name": "Doncaster"
    },
    {
      "abbreviation": "DOR",
      "name": "Dorset"
    },
    {
      "abbreviation": "DUD",
      "name": "Dudley"
    },
    {
      "abbreviation": "DGY",
      "name": "Dumfries and Galloway"
    },
    {
      "abbreviation": "DND",
      "name": "Dundee City"
    },
    {
      "abbreviation": "DUR",
      "name": "Durham"
    },
    {
      "abbreviation": "EAL",
      "name": "Ealing"
    },
    {
      "abbreviation": "EAY",
      "name": "East Ayrshire"
    },
    {
      "abbreviation": "EDU",
      "name": "East Dunbartonshire"
    },
    {
      "abbreviation": "ELN",
      "name": "East Lothian"
    },
    {
      "abbreviation": "ERW",
      "name": "East Renfrewshire"
    },
    {
      "abbreviation": "ERY",
      "name": "East Riding of Yorkshire"
    },
    {
      "abbreviation": "ESX",
      "name": "East Sussex"
    },
    {
      "abbreviation": "EDH",
      "name": "Edinburgh, City of"
    },
    {
      "abbreviation": "ELS",
      "name": "Eilean Siar"
    },
    {
      "abbreviation": "ENF",
      "name": "Enfield"
    },
    {
      "abbreviation": "ESS",
      "name": "Essex"
    },
    {
      "abbreviation": "FAL",
      "name": "Falkirk"
    },
    {
      "abbreviation": "FIF",
      "name": "Fife"
    },
    {
      "abbreviation": "FLN",
      "name": "Flintshire [Sir y Fflint GB-FFL]"
    },
    {
      "abbreviation": "GAT",
      "name": "Gateshead"
    },
    {
      "abbreviation": "GLG",
      "name": "Glasgow City"
    },
    {
      "abbreviation": "GLS",
      "name": "Gloucestershire"
    },
    {
      "abbreviation": "GRE",
      "name": "Greenwich"
    },
    {
      "abbreviation": "GWN",
      "name": "Gwynedd"
    },
    {
      "abbreviation": "HCK",
      "name": "Hackney"
    },
    {
      "abbreviation": "HAL",
      "name": "Halton"
    },
    {
      "abbreviation": "HMF",
      "name": "Hammersmith and Fulham"
    },
    {
      "abbreviation": "HAM",
      "name": "Hampshire"
    },
    {
      "abbreviation": "HRY",
      "name": "Haringey"
    },
    {
      "abbreviation": "HRW",
      "name": "Harrow"
    },
    {
      "abbreviation": "HPL",
      "name": "Hartlepool"
    },
    {
      "abbreviation": "HAV",
      "name": "Havering"
    },
    {
      "abbreviation": "HEF",
      "name": "Herefordshire"
    },
    {
      "abbreviation": "HRT",
      "name": "Hertfordshire"
    },
    {
      "abbreviation": "HLD",
      "name": "Highland"
    },
    {
      "abbreviation": "HIL",
      "name": "Hillingdon"
    },
    {
      "abbreviation": "HNS",
      "name": "Hounslow"
    },
    {
      "abbreviation": "IVC",
      "name": "Inverclyde"
    },
    {
      "abbreviation": "AGY",
      "name": "Isle of Anglesey [Sir Ynys Môn GB-YNM]"
    },
    {
      "abbreviation": "IOW",
      "name": "Isle of Wight"
    },
    {
      "abbreviation": "IOS",
      "name": "Isles of Scilly"
    },
    {
      "abbreviation": "ISL",
      "name": "Islington"
    },
    {
      "abbreviation": "KEC",
      "name": "Kensington and Chelsea"
    },
    {
      "abbreviation": "KEN",
      "name": "Kent"
    },
    {
      "abbreviation": "KHL",
      "name": "Kingston upon Hull"
    },
    {
      "abbreviation": "KTT",
      "name": "Kingston upon Thames"
    },
    {
      "abbreviation": "KIR",
      "name": "Kirklees"
    },
    {
      "abbreviation": "KWL",
      "name": "Knowsley"
    },
    {
      "abbreviation": "LBH",
      "name": "Lambeth"
    },
    {
      "abbreviation": "LAN",
      "name": "Lancashire"
    },
    {
      "abbreviation": "LDS",
      "name": "Leeds"
    },
    {
      "abbreviation": "LCE",
      "name": "Leicester"
    },
    {
      "abbreviation": "LEC",
      "name": "Leicestershire"
    },
    {
      "abbreviation": "LEW",
      "name": "Lewisham"
    },
    {
      "abbreviation": "LIN",
      "name": "Lincolnshire"
    },
    {
      "abbreviation": "LBC",
      "name": "Lisburn and Castlereagh"
    },
    {
      "abbreviation": "LIV",
      "name": "Liverpool"
    },
    {
      "abbreviation": "LND",
      "name": "London, City of"
    },
    {
      "abbreviation": "LUT",
      "name": "Luton"
    },
    {
      "abbreviation": "MAN",
      "name": "Manchester"
    },
    {
      "abbreviation": "MDW",
      "name": "Medway"
    },
    {
      "abbreviation": "MTY",
      "name": "Merthyr Tydfil [Merthyr Tudful GB-MTU]"
    },
    {
      "abbreviation": "MRT",
      "name": "Merton"
    },
    {
      "abbreviation": "MEA",
      "name": "Mid and East Antrim"
    },
    {
      "abbreviation": "MUL",
      "name": "Mid Ulster"
    },
    {
      "abbreviation": "MDB",
      "name": "Middlesbrough"
    },
    {
      "abbreviation": "MLN",
      "name": "Midlothian"
    },
    {
      "abbreviation": "MIK",
      "name": "Milton Keynes"
    },
    {
      "abbreviation": "MON",
      "name": "Monmouthshire [Sir Fynwy GB-FYN]"
    },
    {
      "abbreviation": "MRY",
      "name": "Moray"
    },
    {
      "abbreviation": "NTL",
      "name": "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]"
    },
    {
      "abbreviation": "NET",
      "name": "Newcastle upon Tyne"
    },
    {
      "abbreviation": "NWM",
      "name": "Newham"
    },
    {
      "abbreviation": "NWP",
      "name": "Newport [Casnewydd GB-CNW]"
    },
    {
      "abbreviation": "NMD",
      "name": "Newry, Mourne and Down"
    },
    {
      "abbreviation": "NFK",
      "name": "Norfolk"
    },
    {
      "abbreviation": "NAY",
      "name": "North Ayrshire"
    },
    {
      "abbreviation": "NEL",
      "name": "North East Lincolnshire"
    },
    {
      "abbreviation": "NLK",
      "name": "North Lanarkshire"
    },
    {
      "abbreviation": "NLN",
      "name": "North Lincolnshire"
    },
    {
      "abbreviation": "NSM",
      "name": "North Somerset"
    },
    {
      "abbreviation": "NTY",
      "name": "North Tyneside"
    },
    {
      "abbreviation": "NYK",
      "name": "North Yorkshire"
    },
    {
      "abbreviation": "NTH",
      "name": "Northamptonshire"
    },
    {
      "abbreviation": "NBL",
      "name": "Northumberland"
    },
    {
      "abbreviation": "NGM",
      "name": "Nottingham"
    },
    {
      "abbreviation": "NTT",
      "name": "Nottinghamshire"
    },
    {
      "abbreviation": "OLD",
      "name": "Oldham"
    },
    {
      "abbreviation": "ORK",
      "name": "Orkney Islands"
    },
    {
      "abbreviation": "OXF",
      "name": "Oxfordshire"
    },
    {
      "abbreviation": "PEM",
      "name": "Pembrokeshire [Sir Benfro GB-BNF]"
    },
    {
      "abbreviation": "PKN",
      "name": "Perth and Kinross"
    },
    {
      "abbreviation": "PTE",
      "name": "Peterborough"
    },
    {
      "abbreviation": "PLY",
      "name": "Plymouth"
    },
    {
      "abbreviation": "POL",
      "name": "Poole"
    },
    {
      "abbreviation": "POR",
      "name": "Portsmouth"
    },
    {
      "abbreviation": "POW",
      "name": "Powys"
    },
    {
      "abbreviation": "RDG",
      "name": "Reading"
    },
    {
      "abbreviation": "RDB",
      "name": "Redbridge"
    },
    {
      "abbreviation": "RCC",
      "name": "Redcar and Cleveland"
    },
    {
      "abbreviation": "RFW",
      "name": "Renfrewshire"
    },
    {
      "abbreviation": "RCT",
      "name": "Rhondda, Cynon, Taff [Rhondda, Cynon,Taf]"
    },
    {
      "abbreviation": "RIC",
      "name": "Richmond upon Thames"
    },
    {
      "abbreviation": "RCH",
      "name": "Rochdale"
    },
    {
      "abbreviation": "ROT",
      "name": "Rotherham"
    },
    {
      "abbreviation": "RUT",
      "name": "Rutland"
    },
    {
      "abbreviation": "SLF",
      "name": "Salford"
    },
    {
      "abbreviation": "SAW",
      "name": "Sandwell"
    },
    {
      "abbreviation": "SCB",
      "name": "Scottish Borders, The"
    },
    {
      "abbreviation": "SFT",
      "name": "Sefton"
    },
    {
      "abbreviation": "SHF",
      "name": "Sheffield"
    },
    {
      "abbreviation": "ZET",
      "name": "Shetland Islands"
    },
    {
      "abbreviation": "SHR",
      "name": "Shropshire"
    },
    {
      "abbreviation": "SLG",
      "name": "Slough"
    },
    {
      "abbreviation": "SOL",
      "name": "Solihull"
    },
    {
      "abbreviation": "SOM",
      "name": "Somerset"
    },
    {
      "abbreviation": "SAY",
      "name": "South Ayrshire"
    },
    {
      "abbreviation": "SGC",
      "name": "South Gloucestershire"
    },
    {
      "abbreviation": "SLK",
      "name": "South Lanarkshire"
    },
    {
      "abbreviation": "STY",
      "name": "South Tyneside"
    },
    {
      "abbreviation": "STH",
      "name": "Southampton"
    },
    {
      "abbreviation": "SOS",
      "name": "Southend-on-Sea"
    },
    {
      "abbreviation": "SWK",
      "name": "Southwark"
    },
    {
      "abbreviation": "SHN",
      "name": "St. Helens"
    },
    {
      "abbreviation": "STS",
      "name": "Staffordshire"
    },
    {
      "abbreviation": "STG",
      "name": "Stirling"
    },
    {
      "abbreviation": "SKP",
      "name": "Stockport"
    },
    {
      "abbreviation": "STT",
      "name": "Stockton-on-Tees"
    },
    {
      "abbreviation": "STE",
      "name": "Stoke-on-Trent"
    },
    {
      "abbreviation": "SFK",
      "name": "Suffolk"
    },
    {
      "abbreviation": "SND",
      "name": "Sunderland"
    },
    {
      "abbreviation": "SRY",
      "name": "Surrey"
    },
    {
      "abbreviation": "STN",
      "name": "Sutton"
    },
    {
      "abbreviation": "SWA",
      "name": "Swansea [Abertawe GB-ATA]"
    },
    {
      "abbreviation": "SWD",
      "name": "Swindon"
    },
    {
      "abbreviation": "TAM",
      "name": "Tameside"
    },
    {
      "abbreviation": "TFW",
      "name": "Telford and Wrekin"
    },
    {
      "abbreviation": "THR",
      "name": "Thurrock"
    },
    {
      "abbreviation": "TOB",
      "name": "Torbay"
    },
    {
      "abbreviation": "TOF",
      "name": "Torfaen [Tor-faen]"
    },
    {
      "abbreviation": "TWH",
      "name": "Tower Hamlets"
    },
    {
      "abbreviation": "TRF",
      "name": "Trafford"
    },
    {
      "abbreviation": "VGL",
      "name": "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]"
    },
    {
      "abbreviation": "WKF",
      "name": "Wakefield"
    },
    {
      "abbreviation": "WLL",
      "name": "Walsall"
    },
    {
      "abbreviation": "WFT",
      "name": "Waltham Forest"
    },
    {
      "abbreviation": "WND",
      "name": "Wandsworth"
    },
    {
      "abbreviation": "WRT",
      "name": "Warrington"
    },
    {
      "abbreviation": "WAR",
      "name": "Warwickshire"
    },
    {
      "abbreviation": "WBK",
      "name": "West Berkshire"
    },
    {
      "abbreviation": "WDU",
      "name": "West Dunbartonshire"
    },
    {
      "abbreviation": "WLN",
      "name": "West Lothian"
    },
    {
      "abbreviation": "WSX",
      "name": "West Sussex"
    },
    {
      "abbreviation": "WSM",
      "name": "Westminster"
    },
    {
      "abbreviation": "WGN",
      "name": "Wigan"
    },
    {
      "abbreviation": "WIL",
      "name": "Wiltshire"
    },
    {
      "abbreviation": "WNM",
      "name": "Windsor and Maidenhead"
    },
    {
      "abbreviation": "WRL",
      "name": "Wirral"
    },
    {
      "abbreviation": "WOK",
      "name": "Wokingham"
    },
    {
      "abbreviation": "WLV",
      "name": "Wolverhampton"
    },
    {
      "abbreviation": "WOR",
      "name": "Worcestershire"
    },
    {
      "abbreviation": "WRX",
      "name": "Wrexham [Wrecsam GB-WRC]"
    },
    {
      "abbreviation": "YOR",
      "name": "York"
    }
];

export default uk_states;
