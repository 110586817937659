<template>
    <div class="modal fade" tabindex="-1" aria-labelledby="editSourceModal" id="edit_modal" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fs-5 my-0" id="editSourceModal">Edit Source: {{ editForm?.number }}</h5>
            <button type="button" class="btn-close" @click="toggleEditModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h5 class="mt-2 mb-2 fs-5">Main</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Name</label>
                  <input class="form-control form-control-sm" aria-label="Name" :class="{'is-invalid': editForm.errors.name}" type="text" name="name" v-model="editForm.name" />
                  <div class="invalid-feedback" v-if="editForm.errors.name">
                    {{ editForm.errors.name.toString() }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Emission Category</label>
                  <select class="form-select form-select-sm" aria-label="Emission Category" v-model="editForm.category_id" :class="{'is-invalid': editForm.errors.category_id}" @change="selectScope('edit')">
                    <option value="" selected>--</option>
                    <option v-for="(category, cat_index) in emission_categories" :value="category.id" :key="cat_index">{{ category.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.category_id">
                    {{ editForm.errors.category_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Scope</label>
                  <select class="form-select form-select-sm" aria-label="Scope" v-model="editForm.scope_id" :class="{'is-invalid': editForm.errors.scope_id}" disabled :style="disabledInputCss(true)">
                    <option value="" selected>--</option>
                    <option v-for="(scope, index) in scopes" :value="scope.id" :key="index">{{ scope.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.scope_id">
                    {{ editForm.errors.scope_id.toString() }}
                  </div>
                </div>
              </div>
            </div>

            <h5 class="mt-4 mb-2 fs-5">Transaction Search Criteria</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2" v-if="enabled_features.subsidiaries">
                  <label class="form-label">Subsidiary</label>
                  <select class="form-select form-select-sm" aria-label="Subsidiary" v-model="editForm.subsidiary_id">
                    <option value="" selected>--</option>
                    <option v-for="(subsidiary, sub_index) in subsidiaries" :value="subsidiary.id" :key="sub_index">{{ subsidiary.name }}</option>
                  </select>
                </div>
                <div class="mt-2" v-if="editing_source.id">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Item</span></label>
                  <list-record-selection record_type="items" @record-selected="selectListRecord('edit', 'item_id', $event)" ref="item_edit_selector" :initial_value="editing_source.item_name"></list-record-selection>
                  <div class="invalid-feedback" v-if="editForm.errors.item_id">
                    {{ editForm.errors.item_id.toString() }}
                  </div>
                </div>
                <div class="mt-2" v-if="editing_source.id">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Account</span></label>
                  <list-record-selection record_type="accounts" @record-selected="selectListRecord('edit', 'account_id', $event)" ref="account_edit_selector" :initial_value="editing_source.account_name"></list-record-selection>
                  <div class="invalid-feedback" v-if="editForm.errors.account_id">
                      {{ editForm.errors.account_id.toString() }}
                  </div>
                </div>
                
                <!-- <div class="mt-2" v-if="editing_source.id">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Customer</span></label>
                  <entity-selection entity_type="customers" @entity-selected="selectEntity('edit', 'customer_id', $event)" ref="customer_edit_selector" :initial_value="editing_source.customer_name"></entity-selection>
                  <div class="invalid-feedback" v-if="editForm.errors.customer_id">
                    {{ editForm.errors.customer_id.toString() }}
                  </div>
                </div> -->
                <!-- <div class="mt-2" v-if="editing_source.id">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Employee</span></label>
                  <entity-selection entity_type="employees" @entity-selected="selectEntity('edit', 'employee_id', $event)" ref="employee_edit_selector" :initial_value="editing_source.employee_name"></entity-selection>
                  <div class="invalid-feedback" v-if="editForm.errors.employee_id">
                    {{ editForm.errors.employee_id.toString() }}
                  </div>
                </div> -->
              </div>
              <div class="col">
                <div class="mt-2" v-if="enabled_features.locations">
                  <label class="form-label">Location</label>
                  <select class="form-select form-select-sm" aria-label="Location" v-model="editForm.location_id" :class="{'is-invalid': editForm.errors.location_id}" @change="updateLocationParams('edit')">
                    <option value="" selected>--</option>
                    <option v-for="(location, index) in locations" :value="location.id" :key="index">{{ location.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.location_id">
                    {{ editForm.errors.location_id.toString() }}
                  </div>
                </div>
                <div class="mt-2" v-if="editing_source.id">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Expense Category</span></label>
                  <list-record-selection record_type="expense_categories" @record-selected="selectListRecord('edit', 'expense_category_id', $event)" ref="expense_category_edit_selector" :initial_value="editing_source.expense_category_name"></list-record-selection>
                  <div class="invalid-feedback" v-if="editForm.errors.expense_category_id">
                    {{ editForm.errors.expense_category_id.toString() }}
                  </div>
                </div>
                <div class="mt-2" v-if="editing_source.id">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Vendor</span></label>
                  <entity-selection entity_type="vendors" @entity-selected="selectEntity('edit', 'vendor_id', $event)" ref="vendor_edit_selector" :initial_value="editing_source.vendor_name"></entity-selection>
                  <div class="invalid-feedback" v-if="editForm.errors.vendor_id">
                    {{ editForm.errors.vendor_id.toString() }}
                  </div>
                </div>

              </div>
            </div>

            <h5 class="mt-4 mb-2 fs-5">Activity Data</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2">
                  <label class="form-label">Calculation Approach</label>
                  <select class="form-select form-select-sm" aria-label="Calculation Approach" v-model="editForm.calculation_approach_id" :class="{'is-invalid': editForm.errors.calculation_approach_id}">
                    <option value="" selected>--</option>
                    <option v-for="(approach, index) in calculation_approaches" :value="approach.id" :key="index">{{ approach.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.calculation_approach_id">
                    {{ editForm.errors.calculation_approach_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Emission Factor Database</label>
                  <select class="form-select form-select-sm" aria-label="Emission Factor Database" v-model="editForm.emission_database_id" :class="{'is-invalid': editForm.errors.emission_database_id}" @change="resetSourchFuelValue('edit')">
                    <option value="" selected>--</option>
                    <option v-for="(emission_database, index) in emission_databases" :value="emission_database.id" :key="index">{{ emission_database.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.emission_database_id">
                    {{ editForm.errors.emission_database_id.toString() }}
                  </div>
                </div>

                <div class="mt-2">
                  <label class="form-label">Activity Type</label>
                  <select class="form-select form-select-sm" aria-label="Activity Type" v-model="editForm.activity_type_id" :class="{'is-invalid': editForm.errors.activity_type_id}" @change="resetSourchFuelValue('edit')">
                    <option value="" selected>--</option>
                    <option v-for="(activity_type, index) in filteredActivityTypes('edit')" :value="activity_type.id" :key="index">{{ activity_type.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.activity_type_id">
                    {{ editForm.errors.activity_type_id.toString() }}
                  </div>
                </div>
                
                <div class="mt-2">
                  <label class="form-label">Source Type</label>
                  <select class="form-select form-select-sm" aria-label="Source Type" v-model="editForm.source_type_id" :class="{'is-invalid': editForm.errors.source_type_id}">
                    <option value="" selected>--</option>
                    <option v-for="(source_type, index) in filteredSourceTypes('edit')" :value="source_type.id" :key="index">{{ source_type.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.source_type_id">
                    {{ editForm.errors.source_type_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Fuel Type</label>
                  <select class="form-select form-select-sm" aria-label="Fuel Type" v-model="editForm.fuel_id" :class="{'is-invalid': editForm.errors.fuel_id}">
                    <option value="" selected>--</option>
                    <option v-for="(fuel_type, index) in filteredFuelTypes('edit')" :value="fuel_type.id" :key="index">{{ fuel_type.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.fuel_id">
                    {{ editForm.errors.fuel_id.toString() }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="mt-2" v-if="requiresCustomEmissionFactor()">
                  <label class="form-label"><span class="text-danger me-1">*</span>Custom Emission Factor</label>
                  <select class="form-select form-select-sm" aria-label="Custom Emission Factor" v-model="editForm.custom_emission_factor_id" :class="{'is-invalid': editForm.errors.custom_emission_factor_id}">
                    <option value="" selected>--</option>
                    <option v-for="(custom_emission_factor, index) in custom_emission_factors" :value="custom_emission_factor.id" :key="index">{{ custom_emission_factor.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.custom_emission_factor_id">
                    {{ editForm.errors.custom_emission_factor_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Gas</label>
                  <select class="form-select form-select-sm" aria-label="Gas" v-model="editForm.gas_id" :class="{'is-invalid': editForm.errors.gas_id}">
                    <option value="" selected>--</option>
                    <option v-for="(gas, index) in gases" :value="gas.id" :key="index">{{ gas.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.gas_id">
                    {{ editForm.errors.gas_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Unit</label>
                  <select class="form-select form-select-sm" aria-label="Unit" v-model="editForm.unit_id" :class="{'is-invalid': editForm.errors.unit_id}">
                    <option value="" selected>--</option>
                    <option v-for="(unit, index) in units" :value="unit.id" :key="index">{{ unit.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.unit_id">
                    {{ editForm.errors.unit_id.toString() }}
                  </div>
                </div>
                <div class="mt-2" v-if="requiresYearOfManufacture('edit')">
                  <label class="form-label"><span class="text-danger me-1">*</span>Year of Manufacture</label>
                  <input class="form-control form-control-sm" aria-label="Year" :class="{'is-invalid': editForm.errors.year}" type="number" name="year" step="1" v-model="editForm.year" />
                  <div class="invalid-feedback" v-if="editForm.errors.year">
                    {{ editForm.errors.year.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Fuel Efficiency</label>
                  <input class="form-control form-control-sm" aria-label="Fuel Efficiency" :class="{'is-invalid': editForm.errors.fuel_efficiency}" type="number" name="fuel_efficiency" step="1" v-model="editForm.fuel_efficiency" />
                  <div class="invalid-feedback" v-if="editForm.errors.fuel_eficiency">
                    {{ editForm.errors.fuel_eficiency.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Fuel Efficiency Unit</label>
                  <select class="form-select form-select-sm" aria-label="Fuel Efficiency Unit" v-model="editForm.fuel_efficiency_unit_id" :class="{'is-invalid': editForm.errors.fuel_efficiency_unit_id}">
                    <option value="" selected>--</option>
                    <option v-for="(unit, index) in units" :value="unit.id" :key="index">{{ unit.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="editForm.errors.fuel_efficiency_unit_id">
                    {{ editForm.errors.fuel_efficiency_unit_id.toString() }}
                  </div>
                </div>
                <!-- 
                  <div class="mt-2">
                    <label class="form-label">Department</label>
                    <select class="form-select form-select-sm" aria-label="Department" v-model="editForm.department_id" :class="{'is-invalid': editForm.errors.department_id}">
                      <option value="" selected>--</option>
                      <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.department_id">
                      {{ editForm.errors.department_id.toString() }}
                    </div>
                  </div>
                  <div class="mt-2">
                    <label class="form-label">Class</label>
                    <select class="form-select form-select-sm" aria-label="Class" v-model="editForm.class_id" :class="{'is-invalid': editForm.errors.class_id}">
                      <option value="" selected>--</option>
                      <option v-for="(classification, index) in classes" :value="classification.id" :key="index">{{ classification.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.class_id">
                      {{ editForm.errors.class_id.toString() }}
                    </div>
                  </div>
                  <div class="mt-2" v-if="editing_source.id">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Asset</span></label>
                    <segment-selection segment_type="fixed_assets" @segment-selected="selectSegment('edit', 'fixed_asset_id', $event)" ref="fixed_asset_edit_selector" :initial_value="editing_source.fixed_asset_name"></segment-selection>
                    <div class="invalid-feedback" v-if="editForm.errors.fixed_asset_id">
                      {{ editForm.errors.fixed_asset_id.toString() }}
                    </div>
                  </div>
                  <div class="mt-2" v-if="editing_source.id">
                    <label class="form-label">Assignee</label>
                    <entity-selection entity_type="employees" @entity-selected="selectEntity('edit', 'assignee_id', $event)" ref="assignee_edit_selector" :initial_value="editing_source.assignee_name"></entity-selection>
                    <div class="invalid-feedback" v-if="editForm.errors.assignee_id">
                      {{ editForm.errors.assignee_id.toString() }}
                    </div>
                  </div>
                  <div class="mt-2" v-if="editing_source.id">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Partner</span></label>
                    <entity-selection entity_type="partners" @entity-selected="selectEntity('edit', 'partner_id', $event)" ref="partner_edit_selector" :initial_value="editing_source.partner_name"></entity-selection>
                    <div class="invalid-feedback" v-if="editForm.errors.partner_id">
                      {{ editForm.errors.partner_id.toString() }}
                    </div>
                  </div>
                  <div class="mt-2" v-if="editing_source.id">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Project</span></label>
                    <entity-selection entity_type="projects" @entity-selected="selectEntity('edit', 'project_id', $event)" ref="project_edit_selector" :initial_value="editing_source.project_name"></entity-selection>
                    <div class="invalid-feedback" v-if="editForm.errors.project_id">
                      {{ editForm.errors.project_id.toString() }}
                    </div>
                  </div>
                 -->
              </div>
            </div>
            
            <h5 class="mt-4 mb-2 fs-5">Region</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span><i class="fa-light fa-search"></i><span class="ms-2">Country</span></label>
                  <country-selection @country-selected="selectCountry('edit', 'actual_country', $event)" ref="actual_country_edit_selector" :initial_value="editForm.actual_country"></country-selection>
                  <div class="text-danger" v-if="editForm.errors.actual_country">
                    {{ editForm.errors.actual_country.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(editForm.category_id)">*</span><i class="fa-light fa-search"></i><span class="ms-2">State / Region / Province</span></label>
                  <region-selection :disabled="!editForm.actual_country" :country="editForm.actual_country" @region-selected="selectRegion('edit', 'actual_state', $event)" ref="actual_region_edit_selector" :initial_value="editForm.actual_state"></region-selection>
                  <div class="text-danger" v-if="editForm.errors.actual_state">
                    {{ editForm.errors.actual_state.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(editForm.category_id)">*</span>Zip / Postal Code</label>
                  <input class="form-control form-control-sm" aria-label="Name" :class="{'is-invalid': editForm.errors.zip_code}" type="text" name="zip_code" v-model="editForm.zip_code" />
                  <div class="text-danger" v-if="editForm.errors.zip_code">
                    {{ editForm.errors.zip_code.toString() }}
                  </div>
                </div>

              </div>
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span><i class="fa-light fa-search"></i><span class="ms-2">Emission Factor Country</span></label>
                  <country-selection @country-selected="selectCountry('edit', 'country', $event)" ref="country_edit_selector" :initial_value="editForm.country"></country-selection>
                  <div class="text-danger" v-if="editForm.errors.country">
                    {{ editForm.errors.country.toString() }}
                  </div>
                </div>
                
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(editForm.category_id)">*</span><i class="fa-light fa-search"></i><span class="ms-2">Emission Factor State / Region / Province</span></label>
                  <region-selection :disabled="!editForm.country" :country="editForm.country" @region-selected="selectRegion('edit', 'region', $event)" ref="region_edit_selector" :initial_value="editForm.region"></region-selection>
                  <div class="text-danger" v-if="editForm.errors.region">
                    {{ editForm.errors.region.toString() }}
                  </div>
                </div>

              </div>
            </div>
            
          </div>
          <div class="modal-footer d-flex justify-content-between">
            <div class="d-flex justify-content-start">
              <button type="button" class="btn btn-warning" :disabled="modal_loading" @click="inactivateSource">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-exclamation-square"></i></span>
                <span>Inactivate</span>
              </button>
            </div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-light" @click="toggleEditModal" :disabled="modal_loading">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-arrow-circle-left"></i></span>
                <span>Cancel</span>
              </button>
              <button type="button" class="btn btn-primary ms-2" :disabled="modal_loading" @click="updateSource">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-save"></i></span>
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { nextTick } from 'vue'
import {useRestletStore} from "@/stores/restlets";
import {useAuthStore} from "@/stores/auth";
import {storeToRefs} from "pinia/dist/pinia";
import axios from "axios";
import {useRouter} from "vue-router";

import LoadingIndicator from "@/components/global/LoadingIndicator";
import CountrySelection from "@/components/forms/CountrySelection";
import RegionSelection from "@/components/forms/RegionSelection";
import ListRecordSelection from "@/components/forms/ListRecordSelection";
import EntitySelection from "@/components/forms/EntitySelection";
import SegmentSelection from "@/components/forms/SegmentSelection";
import DisabledLabelCss from "@/helpers/disabledLabelCss";

export default {
name: "EditEmissionSourceModel",

components: {
    LoadingIndicator,
    CountrySelection,
    RegionSelection,
    ListRecordSelection,
    EntitySelection,
    SegmentSelection,
},

props: {
 toggleEditModal: Function,
 subsidiaries:Array,
 emission_categories:Array,
 scopes:Array,
 locations:Array,
 calculation_approaches:Array,
 emission_databases:Array,
 activity_types:Array,
 source_types:Array,
 fuel_types:Array,
 gases:Array,
 units:Array,
 departments:Array,
 classes:Array,
 custom_emission_factors:Array,
 editForm: Object,
 editing_source: Object,
},

data() {
 const restlet_store = useRestletStore();
 const auth_store = useAuthStore();
 const {enabled_features} = storeToRefs(auth_store);
 const router = useRouter();

 const {emission_sources_restlet_url} = storeToRefs(restlet_store);
 const {global_unit} = storeToRefs(auth_store);

 return {
   emission_sources_restlet_url: emission_sources_restlet_url,
   enabled_features:enabled_features,
   router:router,
   global_unit: global_unit,
   modal_loading: false,
   updated_source_id: '',
 }
},

methods: {
    disabledInputCss(value=false){
     return DisabledLabelCss.disabledLabel(value);
    },

    categoryRequiresRegion(category_id)
    {
      let categories_that_require_region = ['VAL_60170_T2641309_514'];

      let category = this.emission_categories.find(c => {
        return c.id === category_id;
      });

      if(category)
      {
        return categories_that_require_region.includes(category['scriptid']);
      }

      else {
        return false;
      }
    },
    
    filteredActivityTypes(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
      let category_id = form.category_id;

      return this.activity_types.filter(at => {
        let categories = at.category_ids?.length > 0 && category_id ? at.category_ids?.split(',') : [];
        return categories.includes(category_id);
      });
    },

    filteredSourceTypes(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
      let activity_type_id = form.activity_type_id;
      let emission_database_id = form.emission_database_id;

      return this.source_types.filter(st => {
        let activity_type_ids = st.activity_type_id?.length > 0 && activity_type_id ? st.activity_type_id?.split(',') : [];
        let emission_database_ids = st.emission_database_id?.length > 0 && emission_database_id ? st.emission_database_id?.split(',') : [];
        
        return emission_database_ids.includes(emission_database_id) && activity_type_ids.includes(activity_type_id);
      });
    },

    requiresCustomEmissionFactor()
    {
      let wanted_calculation_approach_scriptid = 'VAL_61496_T2641309_243';

      let calculation_approach = this.calculation_approaches.find(ca => {
        return ca.id === this.editForm.calculation_approach_id;
      });
  
      if(calculation_approach)
      {
        return wanted_calculation_approach_scriptid === calculation_approach['scriptid'];
      } else {
        return false;
      }
    },

    filteredFuelTypes(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
      let activity_type_id = form.activity_type_id;
      let emission_database_id = form.emission_database_id;

      return this.fuel_types.filter(ft => {
        let activity_type_ids = ft.activity_type_id?.length > 0 && activity_type_id ? ft.activity_type_id?.split(',') : [];
        let emission_database_ids = ft.emission_database_id?.length > 0 && emission_database_id ? ft.emission_database_id?.split(',') : [];
        
        return emission_database_ids.includes(emission_database_id) && activity_type_ids.includes(activity_type_id);
      });
    },

    requiresYearOfManufacture(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;

      let enabled_activity_types = [
        'VAL_60366_T2641309_196',
      ];

      let activity_type = this.activity_types.find(at => {
        return at.id === form.activity_type_id;
      });

      if(activity_type)
      {
        return enabled_activity_types.includes(activity_type['scriptid']);
      }

      else {
        return false;
      }
    },

    resetSourchFuelValue(mode){
      let form = mode === 'create' ? this.createForm : this.editForm;
      form.source_type_id= '';
      form.fuel_id= '';

      let emission_database_id = form.emission_database_id;
      let emission_factor_db = this.emission_databases.find(efd => {
        return efd.id === emission_database_id;
      });

      if(emission_factor_db){
        form.country = emission_factor_db.country;
        form.region = emission_factor_db.region;
      }
    },

    selectScope(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
      form.activity_type_id = '';
      form.source_type_id = '';
      form.fuel_id = '';
      let category_id = form.category_id;

      if(category_id)
      {
        let category = this.emission_categories.find(emcat => {
          return emcat.id == category_id;
        });

        if(category)
        {
          form.scope_id = category.scope_id;
        }
      } else {
        form.scope_id = '';
      }
    },

    updateLocationParams(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;

      let location = this.locations.find(l => {
        return l.id === form.location_id;
      });

      if(location)
      {
        form.actual_country = location.country;
        form.actual_state = location.region;
        form.zip_code = location.zip;
        // form.country = location.country;
        // form.region = location.region;

        //location_country.value = location.country;
        //location_region.value = location.region;
      }
    },

    selectEntity(mode, field, entity)
    {
      let entity_form = mode === 'create' ? this.createForm : this.editForm;
      entity_form[field] = !entity ? null : entity.id;
    },

    selectListRecord(mode, field, record)
    {
      let record_form = mode === 'create' ? this.createForm : this.editForm;
      record_form[field] = !record ? null : record.id;
    },

    selectCountry(mode, field, country)
    {
      let country_form = mode === 'create' ? this.createForm : this.editForm;
      
      if(country){
        country_form[field] = country.code;
      } else {
        country_form[field] = '';
      }
      
      if(field === 'actual_country'){
        country_form['actual_state'] = '';
        country_form['zip_code'] = '';
      } else{
        country_form['region'] = '';
      }
      
    },

    selectRegion(mode, field, region)
    {
      let region_form = mode === 'create' ? this.createForm : this.editForm;
      region_form[field] = !region ? null : region.abbreviation;
    },

    inactivateSource()
    {
      let confirmed = confirm('Do you want to inactivate this emission source?');

      if(confirmed)
      {
        let source_params = {};
        let form = {action: 'inactivate', id: this.editing_source.id};

        let query = new URLSearchParams(source_params).toString();
        let endpoint = this.emission_sources_restlet_url + '&' + query;

        axios.put(endpoint, form)
            .then(response => {
              this.$emit('getSources', 'sources_with_filters');
              this.toggleEditModal();
            });
      }
    },

    updateSource()
    {
      this.editForm.errors = [];
      let errors = this.validateForm('edit');

      if(errors === 0)
      {
        this.modal_loading = true;
        let source_params = {};
        let form = this.editForm;
        form['action'] = 'update';

        let query = new URLSearchParams(source_params).toString();
        let endpoint = this.emission_sources_restlet_url + '&' + query;

        axios.put(endpoint, form)
            .then(response => {

              if(response.data['status'] === 'success')
              {
                this.$emit('getSources', 'sources');
                this.toggleEditModal();
                this.modal_loading = false;
                this.updated_source_id = response.data['emission_source_id'] || null;
                this.$emit('updatedSourceId', this.updated_source_id);
                if(this.updated_source_id)
                {
                    this.router.push({name: 'emission_source', params: {source: this.updated_source_id}});
                }
              }
            });
      }
    },

    validateForm(mode)
    {
      let errors = 0;
      let form = mode === 'create' ? this.createForm : this.editForm;
       
      //Making subsidiary optional - GHGAC-1217
      /*if(!form.subsidiary_id && this.enabled_features.subsidiaries)
      {
        form.errors['subsidiary_id'] = 'The subsidiary is mandatory';
        errors++;
      }*/

      //Making source type optional - GHGAC-690
      /*if(!form.source_type_id)
      {
        form.errors['source_type_id'] = 'The source type is mandatory';
        errors++;
      }*/

      if(!form.name)
      {
        form.errors['name'] = 'The name is mandatory';
        errors++;
      }

      if(!form.scope_id)
      {
        form.errors['scope_id'] = 'The scope is mandatory';
        errors++;
      }

      if((this.requiresYearOfManufacture(mode) && !form.year) || form.year?.length > 0 && (Number(form.year) < 1950 || Number(form.year) > 2050))
      {
        form.errors['year'] = 'Invalid year';
        errors++;
      }

      if(!form.country)
      {
        form.errors['country'] = 'The emission factor country is required';
        errors++;
      }

      if(this.categoryRequiresRegion(form.category_id) && !form.region)
      {
        form.errors['region'] = 'The emission factor state / region / province is required';
        errors++;
      }

      if(!form.actual_country)
      {
        form.errors['actual_country'] = 'The country is required';
        errors++;
      }

      if(this.categoryRequiresRegion(form.category_id) && !form.actual_state)
      {
        form.errors['actual_state'] = 'The state / region / province is required';
        errors++;
      }

      if(this.categoryRequiresRegion(form.category_id) && !form.zip_code)
      {
        form.errors['zip_code'] = 'The zip code is required';
        errors++;
      }

      if(this.requiresCustomEmissionFactor() && !form.custom_emission_factor_id)
      {
        form.errors['custom_emission_factor_id'] = 'The custom emission factor is required with this calculation approach';
        errors++;
      }

      return errors;
    },

},

}

</script>