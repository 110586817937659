<template>
   <div class="container-fluid">
       <div class="d-flex w-75 mt-4">
        <router-link :to="{name: 'workbench'}" class="btn btn-link ps-0">
        <span><i class="fa-light fa-chevron-double-left"></i></span>
        <span class="ms-2">Back to Emission Results</span>
        </router-link>
       </div>
       <h4 class="page-title">Job Status</h4>

      <div class="card">
          <div class="card-body">
          <div class="toast align-items-center bg-primary border-0 mb-2" :class="is_job_failed ? 'show' : 'hide'" role="alert" aria-live="assertive" aria-atomic="true">
           <div class="d-flex">
             <div class="toast-body">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> A job is currently running. You can refresh the job &nbsp; <a class="text-white" href="javascript:void(0);" @click="refreshJob">here</a>
             </div>

             <button type="button" class="btn-close btn-close-white me-2 m-auto" @click="hideToast()"></button>
           </div>
         </div>

          <div class="row" v-if="enabled_features.subsidiaries">
              <div class="col-4">
                <div class="select-box mb-2">
                  <label class="form-label label-black">Subsidiary</label>
                  <vue-multiselect v-model="selectedsubsidiaries" placeholder="Search and select Subsidiary" label="name" :options="subsidiaries" :multiple="true" :taggable="true" :close-on-select="true" track-by="id" @select="checkRunJob" :disabled="is_job_running"></vue-multiselect>
                </div>
              </div>
          </div>
          <div class="row">
              <div class="col-2">
                  <div class="form-check mt-3">
                    <input type="checkbox" class="form-check-input" name="vendor_bills" id="vendor_bills" value="vendor_bills" v-model="type" @change="checkRunJob" :disabled="is_job_running">
                    <label class="form-label label-black" for="vendor_bills">Vendor Bills</label>
                  </div>
              </div>
              <div class="col-2">
                  <div class="form-check mt-3">
                    <input type="checkbox" class="form-check-input" name="expense_reports" id="expense_reports" value="expense_reports" v-model="type" @change="checkRunJob" :disabled="is_job_running">
                    <label class="form-label label-black" for="expense_reports">Expense Reports</label>
                  </div>
              </div>
              <div class="col-2">
                  <div class="form-check mt-3">
                    <input type="checkbox" class="form-check-input" name="credit_card_transactions" id="credit_card_transactions" value="credit_card_transactions" v-model="type" @change="checkRunJob" :disabled="is_job_running">
                    <label class="form-label label-black" for="credit_card_transactions">Credit Card Transactions</label>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-4">
              <div class="mt-3 input-group">
                  <label class="mt-1 me-3 form-label label-black">Transaction Start Date</label>
                  <span class="input-group-text" id="transaction_start_date1"><i class="fa-solid fa-calendar-days"></i></span>
                  <input type="date" class="date-input form-control form-control-sm" name="transaction_start_date" aria-label="Transaction Start Date" aria-describedby="transaction_start_date1" v-model="transaction_start_date" @change="checkRunJob" :disabled="is_job_running" />
                </div>
              </div>
              <div class="col-4">
                  <div class="input-group mt-3">
                  <label class="mt-1 me-3 form-label label-black">Transaction End Date</label>
                  <span class="input-group-text" id="transaction_end_date1"><i class="fa-solid fa-calendar-days"></i></span>
                  <input type="date" class="date-input form-control form-control-sm" name="transaction_end_date" aria-label="Transaction End Date" aria-describedby="transaction_end_date1" v-model="transaction_end_date" @change="checkRunJob" :disabled="is_job_running" />
                  </div>
              </div>
              <div class="col-4 text-end mt-3">
                <button type="button" class="btn " :class="job_button?'btn-primary':'btn--secondary'" @click="saveJobStatus" :disabled="!job_button">Run Job</button>
              </div>
          </div>

        <hr />

        <loading-indicator v-if="loading"></loading-indicator>
        <div v-else>
        <div v-if="(job_status_list.length) > 0">
        <div class="table-actions float-end">
            <div class="arrow-pagination">{{ ((this.currentPage * this.perPage) -this.perPage) + 1 }}-{{ (this.currentPage * this.perPage) < this.totalItems?(this.currentPage * this.perPage):this.totalItems}} of {{ totalItems }} <i class="fa-solid fa-chevron-left cursor-pointer" @click="onClickPaginations(currentPage - 1)" disabled></i> <i class="fa-solid fa-chevron-right cursor-pointer" @click="onClickPaginations(currentPage + 1)"></i>
            </div>
        </div>

        <div class="responsive-table">
        <table class="table mt-2">
            <thead>
                <tr>
                <th scope="col">Date Created</th>
                <th scope="col" v-if="enabled_features.subsidiaries">Subsidiaries</th>
                <th scope="col">Transaction Type</th>
                <th scope="col">User</th>
                <th scope="col">Transaction Start Date</th>
                <th scope="col">Transaction End Date</th>
                <th scope="col">Error</th>
                <th scope="col">Success</th>
                <th scope="col">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="job_status in job_status_list">
                <td>{{ (job_status.dateCreated).toUpperCase() }}</td>
                <td v-if="enabled_features.subsidiaries">{{ job_status.subsidiaryText }}</td>
                <td class="capitalize">{{ job_status.tranType?.replace(/_/g, ' ') }}</td>
                <td>{{ job_status.userName }}</td>
                <td>{{ job_status.startDate }}</td>
                <td>{{ job_status.endDate }}</td>
                <td>{{ Number(job_status.failedRecords || 0) + Number(job_status.incompleteRecords || 0) }}</td> <!-- Precaution as these values are changing in the backend -->
                <td>{{ job_status.successRecords }}</td>
                <td class="capitalize">{{ job_status.status }}</td>
                </tr>
            </tbody>
        </table>
        </div>
             
            <vue-awesome-paginate class="d-flex justify-content-center" v-if="totalPages > 1" :total-items="totalItems" :items-per-page="perPage" :max-pages-shown="3" :on-click="onClickPaginations" v-model="currentPage" />
        </div>
        <div class="not-found-box flex-with-column" v-else>
          <p class="record-not-found">This page shows the list of Job Runs. When executed, a Job Run triggers the script which calculates Emissions from your NetSuite Transactions. There are not currently any Completed or In Progress Jobs to display, please use the filters above to execute a new Job.</p>
        </div>
      </div>
     </div>
    </div>
  </div>
</template>
  
  <script>
  import {useAuthStore} from "@/stores/auth";
  import {useRestletStore} from "@/stores/restlets";
  import {storeToRefs} from "pinia/dist/pinia";
  import { nextTick } from 'vue'
  import LoadingIndicator from "@/components/global/LoadingIndicator";
  import axios from "axios";
  import VueMultiselect from 'vue-multiselect'
  import Filter from "@/helpers/filters";
  import * as dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  
  export default {
    name: "JobStatusList",
    
   components: {
     LoadingIndicator,
     VueMultiselect,
   },
  
   data()
   { 
    const auth_store = useAuthStore();
    const {enabled_features} = storeToRefs(auth_store);
    const restlet_store = useRestletStore();
    const {emission_sources_restlet_url, workbench_restlet_url} = storeToRefs(restlet_store);
     
     return {
       emission_sources_restlet_url: emission_sources_restlet_url,
       workbench_restlet_url: workbench_restlet_url,
       enabled_features: enabled_features,
       loading: false,
       subsidiaries: [],
       selectedsubsidiaries: [],
       type:[],
       transaction_start_date: "",
       transaction_end_date: "",
       job_status_list: [],
       currentPage: 1,
       totalPages: 0,
       perPage: 10,
       totalItems: 0,
       job_button: false,
       is_job_running: true,
       is_job_failed: false,
       page_timer: '',
     }
   },
  
   mounted(){
        dayjs.extend(utc);
        nextTick(() => {
          this.getSources();
        });
   },

   methods: {
    getSources()
    {
      this.loading = true;

      let source_params = {};
      source_params['query_type'] = 'sources_with_filters';

      let query = new URLSearchParams(source_params).toString();
      let endpoint = this.emission_sources_restlet_url + '&' + query;

      axios.get(endpoint)
          .then(response => { 
              this.getJobStatus();

              if(response.data.subsidiaries)
              {
                this.subsidiaries = response.data.subsidiaries;
              }
          })
          .catch(err => {
            console.log(err);
          });
    },
    
    getJobStatus()
    {
      this.loading = true;

      let source_params = {};
      source_params['type'] = 'ghg_logs';
      source_params['page'] = this.currentPage;

      let query = new URLSearchParams(source_params).toString();
      let endpoint = this.workbench_restlet_url + '&' + query;

      axios.get(endpoint)
          .then(response => {       
            this.job_status_list = response.data.results;
            this.currentPage = Number(response.data.current_page);
            this.totalPages  = response.data.total_pages;
            this.totalItems  = response.data.total_items;
            this.loading = false;
            this.is_job_running=false;
            this.reloadPage();
          })
          .catch(err => {
            console.log(err);
          });
    },

    checkRunJob(){
      if(this.selectedsubsidiaries.length && this.type.length && this.transaction_start_date && this.transaction_end_date){
        this.job_button = true;
      } else {
        this.job_button = false;
      }
    },

    saveJobStatus(){
      this.job_button = false;
      this.is_job_running = true;

      let data = {
        "start_date": dayjs.utc(this.transaction_start_date).format('MM-DD-YYYY'),
        "end_date": dayjs.utc(this.transaction_end_date).format('MM-DD-YYYY'),
        "type": this.type.toString(),
        "subsidiary": Filter.fetchAllIdValues(this.selectedsubsidiaries)
      };

      let endpoint = this.workbench_restlet_url;

      axios.post(endpoint, data)
        .then(response => {
          if(response.data['status'] === 'success')
          {
            this.getJobStatus();            
          } else if(response.data['status'] === 'failed'){
            this.is_job_failed = true;
          }

          this.is_job_running = false;
          this.checkRunJob();
        })
        .catch(e => {
          this.is_job_running = false;
          this.checkRunJob();
          if(e.response.data['message'])
          {
            alert(e.response.data['message']);
          } else {
            alert('Error');
          }
        });
    },

    refreshJob(){
      this.getJobStatus();
      this.is_job_running = false;
      this.is_job_failed = false;
      this.checkRunJob();
    },

    hideToast(){
      this.is_job_failed = !this.is_job_failed;
    },

    onClickPaginations(page){
      if(page < 1){
        return false;
      }else if(page > this.totalPages){
        return false;
      }
      this.currentPage = page;
      this.getJobStatus();
    },

    reloadPage(){
      var incompleteJob = this.job_status_list.find(job => {
        return job.status != 'complete';
      });
      
      if(incompleteJob){
        if(!this.page_timer){
          this.page_timer = setInterval(this.getJobStatus, 30000);
        }
      } else {
        this.cancelReloadPage();
      }
    },

    cancelReloadPage () {
      clearInterval(this.page_timer);
    },

   },

   beforeUnmount () {
      this.cancelReloadPage();
   }

  }
  </script>
  
  <style scoped>
  .page-title{
   font-size: 1.32rem;
   padding-top: 1rem;
   padding-bottom: 1.4rem;
   font-weight: 700;
   color: #2D3648;
   margin:0;
  }
  a.nav-link.custom-nav {
   border-radius: 6px;
   border: 0;
   font-size: 1rem;
   font-weight: 700;
   color:#24385B;
  }
  a.nav-link.custom-nav.active{
  color:#2D3648;
  }
  .input-group-text{
  background-color: #607799;
  color:#fff;
  }
  
  .date-input{
  margin: 0 1rem;
  max-width: 150px;
  }
  
  .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.2rem; 
    font-size: 1rem;
  }
  .table-actions h4 {
    font-size: 1.2rem !important;
  }
  .table-actions .fa-chevron-left{
   margin-left: 0.5rem;
   margin-right: 0.8rem;
  }
  table{
  table-layout: fixed;
  border-right:1px solid #d9e9e8;
  border-top:1px solid #d9e9e8;
  }
  thead{
  background: #EEEEEE;
  }
  tr > th,
  tr > td{
  padding:1.5rem 0.5rem;
  width: 117px;
  box-shadow: inset 1px 0px rgb(217 233 232);
  font-size: 14px !important;
  }
  
  tr > th{
    font-weight: bold !important;
  }
  th:first-child, td:first-child{
    width: 145px;
  }
  th:nth-child(2){
    width: 200px;
  }
  th:nth-child(3){
    width: 165px;
  }
  th:nth-child(4){
    width: 200px;
  }
  th:last-child,
  td:last-child{
    position: sticky;
    right: 0;
  }
  th:last-child{
    background: #eee;
    top: 0;
    z-index: 1;
  }
  td:last-child{
    background: #fff;
  }
  
  td > input{
  margin-left: 0.5rem;
  }
  .clear-btn{
    color: #24385B;
    font-weight: 700;
    text-decoration: none;
    font-size: 1rem;
  
  }
  .responsive-table {
      overflow: auto;
      width: calc(100vw - 363px);
  }
  .mr-10 {
      margin-right: 10px;
    }
  .pagination-container {
    padding-top: 20px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .not-found-box {
    margin-top: 65px;
    margin-bottom: 50px;
  }
  .font-size-14 {
    font-size: 14px !important;
  }
  
  tr > td, tr > th {
    padding: 0.5rem 0.5rem;
    vertical-align: middle;
  }
  .arrow-pagination{
    margin-right: 10px;
  }
  .results-heading{
    margin-top: 5px;
    margin-bottom: 8px;
  }

  .toast{
  min-width: 570px;
  border-radius: 0;
  font-size: 1rem;
  padding: 0.5rem;
  padding-left: 0.8rem;
}
.toast-body{
padding:0;
display: flex;
align-items: center;
}
.toast-body i {
font-size: 2rem;
margin-right:10px;
}
.btn-close {
 width: 0.2em;
 height: 0.2em;
 opacity: 1;
}

.toast.bg-primary {
 background: #607799 !important;
 color: #fff !important;
}
.toast.bg-success {
 background: #5BB240 !important;
 color: #fff !important;
} 
.toast.bg-danger {
background-color: #B24040 !important;
color:#fff !important;
}
.toast-body button.btn.btn-link {
 color: #fff;
 font-weight: bold;
 padding: 0;
 margin: 0;
 padding-left: 4px;
 padding-right: 4px;
 text-transform: lowercase;
 font-size: 1.2rem;
}

  .btn--secondary{
    color: #fff !important;
    background-color: #babcbf !important;
    border-color: #babcbf !important;
  }
  .btn--secondary:hover {
    color: #fff !important;
    background-color: #babcbf !important;
    border-color: #babcbf !important;
  }
  </style>

 <style>
  .multiselect__option--highlight {
    background-color: #607799 !important;
  }
  .multiselect__tag {
    background: #607799 !important;
  }

  .multiselect__tag-icon::after {
    color: white !important;
  }
  .multiselect__content{
    padding-left: 0 !important;
    margin: 0 !important;
  }
  .form-label {
    font-size: 0.8rem !important;
  }
  .multiselect__tags {
    border: 1px solid #999 !important;
  }
  .multiselect__tags input.multiselect__input {
    border: none !important;
  }
  .capitalize {
    text-transform: capitalize !important;
  }
  .label-black {
    color: #2d3648 !important;
  }
  .form-check-input {
    border: 1px solid rgba(0,0,0,0.8) !important;
  }
  .record-not-found {
    text-align: center;
    font-size: 16px !important;
  }
</style>  