// Reference - https://www.drupal.org/node/2122715

const au_states = [
    {
        "name": "New South Wales",
        "abbreviation": "NSW"
    },
    {
        "name": "Queensland",
        "abbreviation": "QLD"
    },
    {
        "name": "South Australia",
        "abbreviation": "SA"
    },
    {
        "name": "Tasmania",
        "abbreviation": "TAS"
    },
    {
        "name": "Victoria",
        "abbreviation": "VIC"
    },
    {
        "name": "Western Australia",
        "abbreviation": "WA"
    },
    {
        "name": "Australian Capital Territory",
        "abbreviation": "ACT"
    },
    {
        "name": "Northern Territory",
        "abbreviation": "NT"
    }
];

export default au_states;
