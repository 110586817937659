<template>
  <div>
    <template v-if="indexed_countries.includes(country)">
      <input ref="parent" type="search" class="form-control form-control-sm" :placeholder="(disabled == false) ? placeholder : ''" :disabled="disabled" v-model="search" @input="dropdownSearch($event)" :style="disabledInputCss()">
      <div class="list-group shadow-lg" v-if="menu" style="position: absolute; z-index: 1000;">
        <button type="button" class="list-group-item list-group-item-action" v-for="(region, index) in visible_regions" @click="selectRegion(region)" :key="index">
          <span class="text-uppercase">{{ region.name }}</span>
        </button>
      </div>
    </template>
    <template v-else>
      <input ref="parent" class="form-control form-control-sm" :placeholder="(disabled == false) ? placeholder : ''" :disabled="disabled" v-model="name" @input="emitRegion()" :style="disabledInputCss()">
    </template>
  </div>
</template>

<script>
import {ref, watch, onMounted} from "vue";
import us_states from "@/utils/us_states";
import ca_provinces from "@/utils/ca_provinces";
import uk_states from "@/utils/uk_states";
import au_states from "@/utils/au_states";
import Fuse from "fuse.js";

import LoadingIndicator from "@/components/global/LoadingIndicator";
import DisabledLabelCss from "@/helpers/disabledLabelCss";

export default {

  components: {
    LoadingIndicator,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Type to search',
    },
    initial_value: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      default: '',
    }
  },

  setup(props, {emit, expose})
  {
    const publicUrl = ref(process.env.BASE_URL);
    const menu = ref(false);
    const loading = ref(false);
    const name =  ref('');
    const visible_regions = ref([]);
    const indexed_countries = ref(['US', 'CA', 'AU', 'GB']);

    const search = ref('');

    function dropdownSearch(event)
    {
      if(search.value.length === 0)
      {
        visible_regions.value = [];
        menu.value = false;
        emit('region-selected', null);
      }

      else {
        setTimeout(() => {
          searchRegions(false, true);
        }, 500);
      }
    }

    function searchRegions(run = false, open_menu = false)
    {
      if(search.value.length >= 2 || (run && !search.value))
      {

        loading.value = true;
        let options = {
          keys: ['name', 'id', 'code'],
        }

        let regions_array = [];
        if(props.country === 'GB'){
          regions_array = uk_states;
        } else if(props.country === 'AU'){
          regions_array = au_states;
        } else if(props.country === 'US'){
          regions_array = us_states;
        } else {
          regions_array = ca_provinces;
        }

        let fuse = new Fuse(regions_array, options);

        let results = fuse.search(search.value);

        visible_regions.value = results.map(result => {
          return result.item;
        }).slice(0, 5);
      }

      if(open_menu)
      {
        menu.value = true;
      }
    }

    function selectRegion(region)
    {
      if(region)
      {
        search.value = region.name;
        emit('region-selected', region);
        menu.value = false;
      }
    }

    function emitRegion()
    {
      emit('region-selected', {
        name: name.value,
        abbreviation: name.value,
      });
      menu.value = false;
    }

    function clear()
    {
      search.value = '';
      visible_regions.value = [];
    }

    function setExistingName(name)
    {
      search.value = name;
    }

    function resetSearchValue(new_value)
    {
      let region_code = new_value || null;

      if(indexed_countries.value.includes(props.country))
      {
        let regions_array = [];
        search.value = "";
        
        if(props.country === 'GB'){
          regions_array = uk_states;
        } else if(props.country === 'AU'){
          regions_array = au_states;
        } else if(props.country === 'US'){
          regions_array = us_states;
        } else {
          regions_array = ca_provinces;
        }
      
        if(region_code)
        {
          let region = regions_array.find(c => {
            return c.abbreviation === region_code;
          });
          
          if(region){
            search.value = region.name;
          }
        }

      }

      else {
        name.value = new_value || '';
      }
    }

    function disabledInputCss(){
      let css_val = DisabledLabelCss.disabledLabel(props.disabled);
      return css_val;
    }

    onMounted(() => {
      resetSearchValue(props.initial_value);
    });

    expose({setExistingName});

    watch(() => props.initial_value, (new_value) => {
      resetSearchValue(new_value);
    });

    watch(() => props.country, (new_value) => {
      search.value = '';
    });

    return {
      publicUrl,
      menu,
      loading,
      search,
      name,
      visible_regions,
      indexed_countries,
      dropdownSearch,
      searchRegions,
      selectRegion,
      emitRegion,
      clear,
      setExistingName,
      disabledInputCss,
    }
  }
}
</script>
