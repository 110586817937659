<template>
  <div>
    <input ref="parent" type="search" class="form-control form-control-sm" :placeholder="(disabled == false) ? placeholder : ''" :disabled="disabled" v-model="search" @input="dropdownSearch($event)" :style="disabledInputCss()">
    <div class="list-group shadow-lg" v-if="menu" style="position: absolute; z-index: 1000;">
      <button type="button" class="list-group-item list-group-item-action" v-for="(entity, index) in entities" @click="selectEntity(entity)" :key="index">
        <span class="text-uppercase">{{ entity.name }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import {useRestletStore} from "@/stores/restlets";
import {storeToRefs} from "pinia/dist/pinia.esm-browser";
import {ref, onMounted, watch} from "vue";
import axios from "axios";

import LoadingIndicator from "@/components/global/LoadingIndicator";
import DisabledLabelCss from "@/helpers/disabledLabelCss";

export default {

  components: {
    LoadingIndicator,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Type to search',
    },
    initial_value: {
      type: String,
      default: '',
    },
    entity_type: {
      type: String,
      default: 'customers', /** customers,vendors,employees,projects,partners */
    }
  },

  setup(props, {emit, expose})
  {
    const restlet_store = useRestletStore();
    const {entity_search_restlet_url} = storeToRefs(restlet_store);

    const menu = ref(false);
    const loading = ref(false);
    const name =  ref('');
    const entities = ref([]);

    const search = ref('');

    function dropdownSearch(event)
    {
      if(search.value.length === 0)
      {
        entities.value = [];
        menu.value = false;
        emit('entity-selected', null);
      }

      else {
        setTimeout(() => {
          searchEntities(false, true);
        }, 500);
      }
    }

    function searchEntities(run = false, open_menu = false)
    {
      if(search.value.length >= 3 || (run && !search.value))
      {
        let search_params = {search_term: search.value, entity_type: props.entity_type};

        let query = new URLSearchParams(search_params).toString();
        let endpoint = entity_search_restlet_url.value + '&' + query;


        loading.value = true;
        axios.get(endpoint)
            .then(response => {
              entities.value = response.data[props.entity_type];
              loading.value = false;
            })
            .catch(error => {});
      }

      if(open_menu)
      {
        menu.value = true;
      }
    }

    function selectEntity(entity)
    {
      if(entity)
      {
        search.value = entity.name;
        emit('entity-selected', entity);
        menu.value = false;
      }
    }

    function clear()
    {
      search.value = '';
      entities.value = [];
    }

    function setExistingName(name)
    {
      search.value = name;
    }

    function disabledInputCss(){
      let css_val = DisabledLabelCss.disabledLabel(props.disabled);
      return css_val;
    }

    onMounted(() => {
      search.value = props.initial_value || '';
    });

    watch(() => props.initial_value, ( newValue) => {
      search.value = newValue;
    });

    expose({setExistingName});

    return {
      menu,
      loading,
      search,
      name,
      entities,
      dropdownSearch,
      searchEntities,
      selectEntity,
      clear,
      setExistingName,
      disabledInputCss
    }
  }
}
</script>
