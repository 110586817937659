<template>
<div class="container-fluid pt-4">
<h4 class="navbar-brand fs-4">Reductions Workbench View</h4>
<div class="card">
    <div class="card-body">

    <h5 class="mb-2 fs-5">Main</h5>
    <div class="row">
    <div class="col">
        <div class="mt-2">
        <label class="form-label">Emission Source</label>
        <input class="form-control form-control-sm" aria-label="Emission Source" type="text" name="emission_source" v-model="(createForm.emission_source)" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">Date</label>
        <input class="form-control form-control-sm" aria-label="Date" type="text" name="date" v-model="createForm.date" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">GHG Period</label>
        <input class="form-control form-control-sm" aria-label="GHG Period" type="text" name="ghg_period" v-model="createForm.ghg_period" disabled />
        </div> 

    </div>

    <div class="col">
        <div class="mt-2">
        <label class="form-label">Description</label><br />
        <input class="form-control form-control-sm" aria-label="Description" type="text" name="description" v-model="createForm.description" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">Type</label>
        <input class="form-control form-control-sm" aria-label="Type" type="text" name="type" v-model="createForm.type" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">Transaction Type</label>
        <input class="form-control form-control-sm" aria-label="Transaction Type" type="text" name="transaction_type" v-model="createForm.transaction_type" disabled />
        </div>

    </div>
    </div>
    <h5 class="mt-4 mb-2 fs-5">Classification</h5>
    <div class="row">
    <div class="col">
        <div class="mt-2">
        <label class="form-label">Emission Category</label>
        <select class="form-select form-select-sm" aria-label="Emission Category" v-model="createForm.category_id" disabled>
        <option value="" selected>--</option>
        <option v-for="(category, cat_index) in emission_categories" :value="category.id" :key="cat_index">{{ category.name }}</option>
        </select>
        </div>

        <div class="mt-2">
        <label class="form-label">Scope</label>
        <select class="form-select form-select-sm" aria-label="Scope" v-model="createForm.scope_id" disabled>
            <option value="" selected>--</option>
            <option v-for="(scope, index) in scopes" :value="scope.id" :key="index">{{ scope.name }}</option>
        </select>
        </div>

        <div class="mt-2">
        <label class="form-label">Source Type</label>
        <select class="form-select form-select-sm" aria-label="Source Type" v-model="createForm.source_type_id" disabled>
            <option value="" selected>--</option>
            <option v-for="(source_type, index) in filteredSourceTypes('create')" :value="source_type.id" :key="index">{{ source_type.name }}</option>
        </select>
        </div>

        <div class="mt-2">
        <label class="form-label"><span class="text-danger me-1">*</span>Subsidiary</label>
        <select class="form-select form-select-sm" aria-label="Subsidiary" v-model="createForm.subsidiary_id" disabled>
            <option value="" selected>--</option>
            <option v-for="(subsidiary, sub_index) in subsidiaries" :value="subsidiary.id" :key="sub_index">{{ subsidiary.name }}</option>
        </select>
        </div>

        <div class="mt-2">
        <label class="form-label">Department</label>
        <select class="form-select form-select-sm" aria-label="Department" v-model="createForm.department_id" disabled>
            <option value="" selected>--</option>
            <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
        </select>
        </div>

        <div class="mt-2">
        <label class="form-label">Class</label>
        <select class="form-select form-select-sm" aria-label="Class" v-model="createForm.class_id" disabled>
            <option value="" selected>--</option>
            <option v-for="(classification, index) in classes" :value="classification.id" :key="index">{{ classification.name }}</option>
        </select>
        </div>  
        
    </div>
    <div class="col">
        <div class="mt-2">
        <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Fixed Asset</span></label>
        <segment-selection segment_type="fixed_assets" @segment-selected="selectSegment('create', 'fixed_asset_id', $event)" ref="fixed_asset_create_selector" :disabled="true"></segment-selection>
        <div class="invalid-feedback" v-if="createForm.errors.fixed_asset_id">
            {{ createForm.errors.fixed_asset_id.toString() }}
        </div>
        </div>

        <div class="mt-2">
        <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Customer</span></label>
        <entity-selection entity_type="customers" @entity-selected="selectEntity('create', 'customer_id', $event)" ref="customer_create_selector" :disabled="true"></entity-selection>
        </div>

        <div class="mt-2">
        <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Vendor</span></label>
        <entity-selection entity_type="vendors" @entity-selected="selectEntity('create', 'vendor_id', $event)" ref="vendor_create_selector" :disabled="true"></entity-selection>
        </div>

        <div class="mt-2">
        <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Employee</span></label>
        <entity-selection entity_type="employees" @entity-selected="selectEntity('create', 'employee_id', $event)" ref="employee_create_selector" :disabled="true"></entity-selection>
        </div>

        <div class="mt-2">
        <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Partner</span></label>
        <entity-selection entity_type="partners" @entity-selected="selectEntity('create', 'partner_id', $event)" ref="partner_create_selector" :disabled="true"></entity-selection>
        </div>

        <div class="mt-2">
        <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Project</span></label>
        <entity-selection entity_type="projects" @entity-selected="selectEntity('create', 'project_id', $event)" ref="project_create_selector" :disabled="true"></entity-selection>
        </div>
    </div>
    </div>

    <h5 class="mt-4 mb-2 fs-5">Amount</h5>
    <div class="row">
    <div class="col">
        <div class="mt-2">
        <label class="form-label">Amount</label>
        <input class="form-control form-control-sm" aria-label="Amount" type="text" name="amount" v-model="createForm.amount" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">Unit</label>
        <input class="form-control form-control-sm" aria-label="Unit" type="text" name="unit" v-model="createForm.unit" disabled />
        </div>

    </div>
    <div class="col">
        <div class="mt-2">
        <label class="form-label">Gas</label>
        <input class="form-control form-control-sm" aria-label="Gas" type="text" name="gas" v-model="createForm.gas" disabled />
        </div>
    </div>
    </div>

    <div class="row" v-if="form_type == 'Offset'">
    <h5 class="mt-4 mb-2 fs-5">Offsets</h5>
    <div class="col">
        <div class="mt-2">
        <label class="form-label">Offset Source</label>
        <input class="form-control form-control-sm" aria-label="Offset Source" type="text" name="offset_source" v-model="createForm.offset_source" disabled />
        </div>

        <div class="mt-2">
        <div class="mt-2">
            <label class="form-label">Offset Category</label>
            <input class="form-control form-control-sm" aria-label="Offset Category" type="text" name="offset_category" v-model="createForm.offset_category" disabled />
        </div>
        </div>

        <div class="mt-2">
        <label class="form-label">Offset Type</label>
        <input class="form-control form-control-sm" aria-label="Offset Type" type="text" name="offset_type" v-model="createForm.offset_type" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">Project ID</label>
        <input class="form-control form-control-sm" aria-label="Project ID" type="text" name="project_id" v-model="createForm.project_id" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">Project Description</label>
        <textarea class="form-control form-control-sm" aria-label="Project Description" type="text" name="project_description" v-model="createForm.project_description" rows="4" cols="69" disabled></textarea>
        </div>
        
    </div>
    <div class="col">
        <div class="mt-2">
        <label class="form-label">Project Type</label>
        <input class="form-control form-control-sm" aria-label="Project Type" type="text" name="project_type" v-model="createForm.project_type" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">Project Location</label>
        <input class="form-control form-control-sm" aria-label="Project Location" type="text" name="project_location" v-model="createForm.project_location" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">Project Provider</label>
        <input class="form-control form-control-sm" aria-label="Project Provider" type="text" name="project_provider" v-model="createForm.project_provider" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">Vintage Year</label>
        <input class="form-control form-control-sm" aria-label="Vintage Year" type="text" name="vintage_year" v-model="createForm.vintage_year" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">Registry</label>
        <input class="form-control form-control-sm" aria-label="Registry" type="text" name="registry" v-model="createForm.registry" disabled />
        </div>

        <div class="mt-2">
        <label class="form-label">Registration Number</label>
        <input class="form-control form-control-sm" aria-label="Registration Number" type="text" name="registration_number" v-model="createForm.registration_number" disabled />
        </div>
    </div>
    </div>

    <h5 class="mt-4 mb-2 fs-5">Region</h5>
    <div class="row">
    <div class="col">
        <div class="mt-2">
        <label class="form-label">Location</label>
        <select class="form-select form-select-sm" aria-label="Location" v-model="createForm.location_id" disabled>
            <option value="" selected>--</option>
            <option v-for="(location, index) in locations" :value="location.id" :key="index">{{ location.name }}</option>
        </select>
        </div>

        <div class="mt-2">
        <label class="form-label">Country</label>
        <country-selection @country-selected="selectCountry('create', 'country', $event)" :disabled="!createForm.country" ref="country_create_selector"></country-selection>
        </div>

    </div>
    <div class="col">
        <div class="mt-2">
        <label class="form-label">State/Region/Province</label>
        <region-selection :disabled="!createForm.country" :country="createForm.country" @region-selected="selectRegion('create', 'region', $event)" ref="region_create_selector"></region-selection>
        </div>

        <div class="mt-2">
        <label class="form-label">Zip / Postal Code</label>
        <input class="form-control form-control-sm" aria-label="Name" type="text" name="zip_code" v-model="createForm.zip_code" disabled />
        </div>
    </div>
    </div>
    <div class="text-center mt-3">
        <a class="btn btn-primary" @click="$router.go(-1)">back</a>
    </div>
    </div>
    </div>
    </div>
</template>
<script>
import {useRestletStore} from "@/stores/restlets";
import LoadingIndicator from "@/components/global/LoadingIndicator";
import {ref, computed, onMounted} from "vue";
import {storeToRefs} from "pinia/dist/pinia";
import axios from "axios";
import scopes from "@/utils/scopes";
import Fuse from 'fuse.js';

import EntitySelection from "@/components/forms/EntitySelection";
import SegmentSelection from "@/components/forms/SegmentSelection";
import CountrySelection from "@/components/forms/CountrySelection";
import RegionSelection from "@/components/forms/RegionSelection";

export default {
 name: "ReductionsWorkbenchView",
 components: {
      LoadingIndicator,
      EntitySelection,
      SegmentSelection,
      CountrySelection,
      RegionSelection,
    },
 data(){
    return {
        form_type:'Offset',
        createForm: {
            //Main
        emission_source:'',
        date:'',
        ghg_period:'',
        description:'',
        type:'',
        transaction_type:'',

        //Classification
        category_id:'',
        scope_id:'',
        source_type_id:'',
        subsidiary_id:'',
        department_id:'',
        class_id:'',
        fixed_asset_id:'',
        customer_id:'',
        vendor_id:'',
        employee_id:'',
        partner_id:'',
        project_id:'',

        //Amount
        amount:'',
        unit:'',
        gas:'',

        //Offset
        offset_source:'',
        offset_category:'',
        offset_type:'',
        project_id:'',
        project_description:'',
        project_type:'',
        project_location:'',
        project_provider:'',
        vintage_year:'',
        registry:'',
        registration_number:'',
        
        //Region
        location_id:'',
        country:false,
        region:'',
        zip_code:''
        }
    }
 },
 setup(){
      const restlet_store = useRestletStore();
      const {emission_sources_restlet_url, scope_restlet_url} = storeToRefs(restlet_store);

      const subsidiaries = ref([]);
      const locations = ref([]);
      const classes = ref([]);
      const departments = ref([]);
      const source_types = ref([]);
      const customers = ref([]);
      const vendors = ref([]);
      const employees = ref([]);
      const projects = ref([]);
      const partners = ref([]);
      const fixed_assets = ref([]);
      const emission_categories = ref([]);
      const createForm = ref({
        country: false,
        errors: [],
      });
      
      function getSources(query_type = 'sources')
      {
        let source_params = {};
  
        if(query_type === 'sources_with_filters')
        {
          source_params['query_type'] = 'sources_with_filters';
        }else {
          source_params['query_type'] = 'sources';
        }
  
        let query = new URLSearchParams(source_params).toString();
        let endpoint = emission_sources_restlet_url.value + '&' + query;
  
        axios.get(endpoint)
            .then(response => {
                emission_sources.value = response.data.emission_sources;
  
                if(response.data.emission_categories)
                {
                  emission_categories.value = [...new Set(response.data.emission_categories)];
                }
  
                if(response.data.subsidiaries)
                {
                  subsidiaries.value = response.data.subsidiaries;
                }
  
                if(response.data.locations)
                {
                  locations.value = response.data.locations;
                }
  
                if(response.data['classes'])
                {
                  classes.value = response.data['classes'];
                }
  
                if(response.data.departments)
                {
                  departments.value = response.data.departments;
                }
  
                if(response.data.source_types)
                {
                  source_types.value = response.data.source_types;
                }
  
                if(response.data.fixed_assets)
                {
                  fixed_assets.value = response.data.fixed_assets;
                }
  
                loading.value = false;
            })
            .catch(err => {
              console.log(err);
            });
      }

      function filteredSourceTypes(mode)
      {
        let form = mode === createForm.value;
        let category_id = form.category_id;
  
        return source_types.value.filter(st => {
          return st['category_id'] === category_id
        });
      }

      onMounted(() => {
        getSources('sources_with_filters');
      });

      return {
        emission_categories,
        subsidiaries,
        classes,
        departments,
        source_types,
        scopes,
        customers,
        vendors,
        employees,
        projects,
        partners,
        fixed_assets,
        getSources,
        filteredSourceTypes,
        createForm,
      }
 }
}
</script>