<template>
  <table class="table-fixed-layout">
    <tr class="table-header">
      <th :style="{ 'width': '10%' }">Section</th>
      <th :style="{ 'width': '30%' }">Description</th>
      <th :style="{ 'width': '60%' }">Details</th>
    </tr>

    <template v-for="(data, efIndex) in EmissionFacility" :key="efIndex">
      <tr>
        <th>{{ data.section }}</th>
        <td>{{ data.descriptions }}</td>
        <td class="padd-0">
          <table class="no-border">
          <template
            v-for="(value, detailIndex) in data.details" :key="detailIndex">
              <tr v-if="detailIndex === 0">
                <th className="bg-color">{{ 'Emissions' }}</th>
                <th className="bg-color" v-for="(location_object, location_index) in value['locations']" :key="location_index">
                  {{ location_object['location_name'] }}
                </th>
              </tr>
              <tr>
                <td  class="bg-color">{{ value['scope'] }}</td>
                <td v-for="(location_object, location_index) in value['locations']" :key="location_index">{{ roundNumber(location_object['location_amount']) }}</td>
              </tr>
          </template>
        </table>
        </td>
      </tr>
    </template>
  </table>
</template>
<script>
export default {
  props: {
    EmissionFacility: Object
  },

  setup()
  {
    function roundNumber(number)
    {
      if (typeof number === 'string') {
        return number;
      } else if(Number(number) == 0){
        return number;
      } else if(Number(number) > 99999){
        return Number(number).toExponential();
      } else if(Number(number) < 0.0001){
        return Number(number).toExponential();
      } else{
        return Math.round(Number(number) * 100000) / 100000;
      }
    }

    return {
      roundNumber,
    }
  }
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #000;
  font-family: "docs-Tahoma", Arial;
  font-size: 10pt;
}
td,
th {
  padding: 6px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}
td:first-child,
th:first-child {
  border-left: 1px solid #000;
}
.bg-color {
  background-color: #46bdc6;
}
.no-border {
  border: 0;
}
.padd-0{
    padding:0;
    overflow-x: scroll;
}
td > .no-border > tr:first-child td,
 td > .no-border > tr:first-child th{
    border-top:0;
    border-left: 0;
 }

 td > .no-border > tr >  td{
    border-left: 0;
 }

 td > .no-border > tr >  td:last-child,
 td > .no-border > tr >  th:last-child{
    border-right: 0;
 }
 .table-header{
  background-color: #9fc5e8;
 }

 .table-header  > th{
  font-size: 14px;
 }

th {
  font-weight: bold;
}

td {
  word-wrap: break-word;
}

.table-fixed-layout {
  table-layout: fixed;
}

</style>
