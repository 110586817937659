import {defineStore} from "pinia";

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            user: {},
            global_unit: 'kg',
            enabled_features: {},
			error_codes: [],
        };
    },
    getters: {

    },
    actions: {
        setUser(user)
        {
            this.user = user;
        },
        setGlobalUnit(unit)
        {
            this.global_unit = unit;
        },
        setEnabledFeatures(features)
        {
            this.enabled_features = features;
        },
		setErrorCodes(error_codes)
		{
			this.error_codes = error_codes;
		}
    }
});