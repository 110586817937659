<template>
  <div class="container-fluid">
    <loading-indicator v-if="loading"></loading-indicator>
    <div v-else>
      <nav class="navbar bg-light">
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex w-75">
            <router-link :to="{name: 'emission_sources'}" class="btn btn-link">
              <span><i class="fa-light fa-chevron-double-left"></i></span>
              <span class="ms-2">Back to Sources</span>
            </router-link>
          </div>
          <!-- <div class="d-flex justify-content-end w-100">
            <router-link class="btn bg-white me-2" v-if="!loading" :to="{name: 'coming_soon'}" :disabled="loading_transactions">
              <span class="me-2"><i class="fa-light fa-shuffle"></i></span>
              <span>Allocate Bill</span>
            </router-link>
          </div> -->
        </div>
      </nav>
      <div class="card border-0 shadow light-widget rounded-3 mt-2">
        <div>
          <h5 class="card-title mb-0 mt-3 fs-5 ps-3">
          <b>{{ emission_source.name }}</b>
          <button type="button" class="btn btn-primary ms-4" v-if="!loading" @click="editEmissionSource" :disabled="loading_transactions">Edit</button>
          </h5>
        </div>
        <hr />
        <div class="card-body">
          <!-- <h5 class="card-title mb-0 mt-2 fs-5"></h5> -->
          <div class="row">
              <div class="col-3 mt-2" v-if="emission_source.number">
                <label class="form-label">ID</label>
                <p>{{ emission_source.number }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.name">
                <label class="form-label">Name</label>
                <p>{{ emission_source.name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.subsidiary_name && enabled_features.subsidiaries">
                <label class="form-label">Subsidiary</label>
                <p>{{ emission_source.subsidiary_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.category_name">
                <label class="form-label">Emission Category</label>
                <p>{{ emission_source.category_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.source_type_name">
                <label class="form-label">Source Type</label>
                <p>{{ emission_source.source_type_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.scope_name">
                <label class="form-label">Scope</label>
                <p>{{ emission_source.scope_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.country">
                <label class="form-label">Emission Factor Country</label>
                <p>{{ emission_source.country }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.region">
                <label class="form-label">Emission Factor State / Region / Province</label>
                <p>{{ emission_source.region }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.actual_country">
                <label class="form-label">Country</label>
                <p>{{ emission_source.country }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.actual_state">
                <label class="form-label">State / Region / Province</label>
                <p>{{ emission_source.region }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.gas_name">
                <label class="form-label">Gas</label>
                <p>{{ emission_source.gas_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.calculation_approach_name">
                <label class="form-label">Calculation Approach</label>
                <p>{{ emission_source.calculation_approach_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.custom_emission_factor_name">
                <label class="form-label">Custom Emission Factor</label>
                <p>{{ emission_source.custom_emission_factor_name }}</p>
              </div>
              
              <div class="col-3 mt-2" v-if="emission_source.year">
                <label class="form-label">Year</label>
                <p>{{ emission_source.year }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.department_name && enabled_features.departments">
                <label class="form-label">Department</label>
                <p>{{ emission_source.department_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.class_name && enabled_features.classes">
                <label class="form-label">Class</label>
                <p>{{ emission_source.class_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.location_name && enabled_features.locations">
                <label class="form-label">Location</label>
                <p>{{ emission_source.location_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.fixed_asset_name">
                <label class="form-label">Asset</label>
                <p>{{ emission_source.fixed_asset_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.zip_code">
                <label class="form-label">Zip / Postal Code</label>
                <p>{{ emission_source.zip_code }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.account_name">
                  <label class="form-label">Account</label>
                  <p>{{ emission_source.account_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.activity_type_name">
                <label class="form-label">Activity Type</label>
                <p>{{ emission_source.activity_type_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.fuel_name">
                <label class="form-label">Fuel Type</label>
                <p>{{ emission_source.fuel_name }}</p>
              </div>
              <!-- <div class="col-3 mt-2" v-if="emission_source.customer_name">
                <label class="form-label">Customer</label>
                <p>{{ emission_source.customer_name }}</p>
              </div> -->

              <div class="col-3 mt-2" v-if="emission_source.vendor_name">
                <label class="form-label">Vendor</label>
                <p>{{ emission_source.vendor_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.employee_name">
                <label class="form-label">Employee</label>
                <p>{{ emission_source.employee_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.partner_name && enabled_features.partners">
                <label class="form-label">Partner</label>
                <p>{{ emission_source.partner_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.project_name && enabled_features.projects">
                <label class="form-label">Project</label>
                <p>{{ emission_source.project_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.assignee_name">
                <label class="form-label">Assignee</label>
                <p>{{ emission_source.assignee_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.unit_name">
                <label class="form-label">Unit</label>
                <p>{{ emission_source.unit_name }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.fuel_efficiency">
                <label class="form-label">Fuel Efficiency</label>
                <p>{{ emission_source.fuel_efficiency }}</p>
              </div>

              <div class="col-3 mt-2" v-if="emission_source.fuel_efficiency_unit_name">
                <label class="form-label">Fuel Efficiency Unit</label>
                <p>{{ emission_source.fuel_efficiency_unit_name }}</p>
              </div>
            </div>
          <hr /> 
          <div class="row mt-0">
            <loading-indicator v-if="loading_transactions"></loading-indicator>
            <div class="card-body background-blur pt-0" v-else>
              <h5 class="card-title mb-3 fs-5 mt-0">
                <b>Emissions</b>
                <button type="button" class="btn btn-primary ms-4" v-if="!loading" @click="toggleCreateModal" :disabled="loading_transactions">
                <span class="me-2"><i class="fa-light fa-plus-circle"></i></span>
                <span>Add</span>
                </button>
              </h5>
              <table class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Description</th>
                  <th scope="col">Date</th>
                  <th scope="col">Amount t CO2e</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(transaction, tran_index) in emission_transactions" :key="tran_index" :class="{'bg-success': updated_transaction_id == transaction.id, 'bg-danger': transaction.posting_status_name === 'Error'}">
                  <td>{{ transaction.name }}</td>
                  <td>{{ transaction.altname }}</td>
                  <td>{{ transaction.date }}</td>
                  <td>{{ getTransactionEmissionValue(transaction) }}</td>
                  <td>{{ transaction.posting_status_name }}</td>
                  <td>
                    <div class="btn-group" role="group" aria-label="Actions">
                      <button type="button" class="btn btn-sm btn-link"><i class="fa-light fa-edit" @click="editTransaction(transaction, 'edit')"></i></button>
                      <button type="button" class="btn btn-sm btn-link"><i class="fa-light fa-eye" @click="editTransaction(transaction, 'view')"></i></button>
                      <button type="button" class="btn btn-sm btn-link" v-if="!transaction.period_locked && !transaction.period_closed"><i class="fa-light fa-trash-alt" @click="deleteTransaction(transaction)"></i></button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>

              <vue-awesome-paginate class="d-flex justify-content-center" v-if="totalPages > 1" :total-items="totalItems" :items-per-page="perPage" :max-pages-shown="3" :on-click="onClickPaginations" v-model="currentPage" />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Create Modal-->
   <teleport to="#modal-area">
    <create-emission-model :toggleCreateModal="toggleCreateModal" :createForm="createForm" :emission_source="emission_source" :periods="periods" :scopes="scopes" :emission_categories="emission_categories" :subsidiaries="subsidiaries" :source_types="source_types" :departments="departments" :classes="classes" :calculation_approaches="calculation_approaches" :activity_types="activity_types" :fuel_types="fuel_types" :units="units" :custom_emission_factors="custom_emission_factors" :refrigerant_sources="refrigerant_sources" :emission_databases="emission_databases" :locations="locations" :getTransactions="getTransactions" @updatedTransactionId="updateTransactionId($event)" />
  </teleport>

  <!--Edit Modal-->
  <teleport to="#modal-area">
    <edit-emission-model :toggleEditEmissionModel="toggleEditEmissionModel" :editForm="editForm" :enable_edit="enable_edit" :emission_sources="emission_sources" :emission_source="emission_source" :periods="periods" :scopes="scopes" :emission_categories="emission_categories" :subsidiaries="subsidiaries" :source_types="source_types" :departments="departments" :classes="classes" :calculation_approaches="calculation_approaches" :activity_types="activity_types" :fuel_types="fuel_types" :units="units" :custom_emission_factors="custom_emission_factors" :refrigerant_sources="refrigerant_sources" :emission_databases="emission_databases" :locations="locations" :getWorkbenches="getTransactions" @updatedTransactionId="updateTransactionId($event)" />
  </teleport>

  <!--Edit Emission Source Modal-->
  <teleport to="#modal-area">
    <edit-emission-source-model :toggleEditModal="toggleEditModal" @getSources="getSources" :editing_source="emission_source" :editForm="editEmissionSourceForm" :subsidiaries="subsidiaries" :emission_categories="emission_categories" :scopes="scopes" :departments="departments" :classes="classes" :calculation_approaches="all_calculation_approaches" :activity_types="all_activity_types" :source_types="source_types" :fuel_types="fuel_types" :units="units" :gases="refrigerant_sources" :emission_databases="emission_databases" :custom_emission_factors="custom_emission_factors" :locations="locations"></edit-emission-source-model>
  </teleport>
  
</template>

<script>
import {useAuthStore} from "@/stores/auth";
import {useRestletStore} from "@/stores/restlets";
import {useRoute, useRouter} from "vue-router";
import LoadingIndicator from "@/components/global/LoadingIndicator";
import {ref, onMounted} from "vue";
import {storeToRefs} from "pinia/dist/pinia";
import axios from "axios";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import CountrySelection from "@/components/forms/CountrySelection";
import RegionSelection from "@/components/forms/RegionSelection";
import ListRecordSelection from "@/components/forms/ListRecordSelection";
import EntitySelection from "@/components/forms/EntitySelection";
import SegmentSelection from "@/components/forms/SegmentSelection";

import DisabledLabelCss from "@/helpers/disabledLabelCss";
import EditEmissionModel from "@/components/models/EditEmissionModel";
import CreateEmissionModel from "@/components/models/CreateEmissionModel";
import EditEmissionSourceModel from "@/components/models/EditEmissionSourceModel";

export default {
  components: {
    LoadingIndicator, 
    CountrySelection,
    RegionSelection,
    ListRecordSelection,
    EntitySelection,
    SegmentSelection,
    EditEmissionModel,
    CreateEmissionModel,
    EditEmissionSourceModel
  },
  data(){
    return {
      updated_transaction_id:"",
    }
  },
  methods: {
    updateTransactionId(updated_transaction_id){
      this.updated_transaction_id = updated_transaction_id;
    },
  },

  setup()
  {
    const region_create_selector = ref(null);
    const region_edit_selector = ref(null);

    const auth_store = useAuthStore();
    const {enabled_features} = storeToRefs(auth_store);
    const restlet_store = useRestletStore();
    const {emission_sources_restlet_url, ghg_transactions_restlet_url, custom_emission_factor_restlet_url, scope_restlet_url} = storeToRefs(restlet_store);
    const route = useRoute();
    const router = useRouter();

    const scopes = ref([]);
    const source_types = ref([]);
    const emission_source = ref({});
    const emission_transactions = ref([]);
    const classes = ref([]);
    const departments = ref([]);
    const emission_categories = ref([]);
    const calculation_approaches = ref([]);
    const all_calculation_approaches = ref([]);
    const activity_types = ref([]);
    const all_activity_types = ref([]);
    const fuel_types = ref([]);
    const refrigerant_sources = ref([]);
    const units = ref([]);
    const emission_databases = ref([]);
    const custom_emission_factors = ref([]);
    const periods = ref([]);
    const locations = ref([]);
    const emission_sources = ref([]);
    const subsidiaries = ref([]);

    const createForm = ref({});
    const editForm = ref({});

    const editing_transaction = ref({});
    const loading = ref(true);
    const loading_transactions = ref(true);
    const modal_loading = ref(false);
    const create_modal = ref(null);
    const edit_modal = ref(null);
    const enable_edit = ref(false);
    const loading_summary = ref(false);

    const currentPage = ref(1);
    const totalPages = ref(0);
    const perPage = ref(10);
    const totalItems = ref(0);

    const editEmissionModel = ref('');

    const editEmissionSourceForm = ref({
      errors: [],
    });
    const edit_enission_source_modal = ref(null);

    function getScopes()
    {
      let source_params = {};

      let query = new URLSearchParams(source_params).toString();
      let endpoint = scope_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            scopes.value = response.data.scopes;
            loading.value = false;
          })
          .catch(err => {
            console.log(err);
          });
    }

     function getCustomEmissionFactors()
    {
      let params = {};

      let query = new URLSearchParams(params).toString();
      let endpoint = custom_emission_factor_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            custom_emission_factors.value = response.data.custom_emission_factors;
            loading.value = false;
          })
          .catch(err => {
            console.log(err);
          });
    }

    function getSource()
    {
      let source_id = route.params.source;
      let source_params = {query_type: 'single_source', source_id: source_id};

      let query = new URLSearchParams(source_params).toString();
      let endpoint = emission_sources_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            emission_source.value = response.data.emission_source;

            getSourceTypes();

            loading.value = false;
          })
          .catch(err => {
            console.log(err);
          });
    }

    function getSourceTypes()
    {
        let source_params = {query_type: 'source_types_only'};

        let query = new URLSearchParams(source_params).toString();
        let endpoint = emission_sources_restlet_url.value + '&' + query;

        axios.get(endpoint)
            .then(response => {
              source_types.value = response.data.source_types;
              // let category_id = emission_source.value.category_id;

              // let request_source_types = response.data.source_types;

              // source_types.value = request_source_types.filter(st => {
              //   let categories = st.category_ids.length > 0 && category_id ? st.category_ids.split(',') : [];
              //   return categories.includes(category_id);
              // });
            })
            .catch(err => {
              console.log(err);
            });
    }

    function getEmissionSources()
    {
        let source_params = {query_type: 'sources'};

        let query = new URLSearchParams(source_params).toString();
        let endpoint = emission_sources_restlet_url.value + '&' + query;

        axios.get(endpoint)
            .then(response => {
              emission_sources.value = response.data.emission_sources;
            })
            .catch(err => {
              console.log(err);
            });
    }
    
    function getTransactionDropdowns()
    {
      loading_transactions.value = true;
      let source_id = route.params.source;
      let source_params = {query_type: 'dropdowns', source_id: source_id};

      let query = new URLSearchParams(source_params).toString();
      let endpoint = ghg_transactions_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {

            if(response.data.classes)
            {
              classes.value = response.data.classes;
            }

            if(response.data.departments)
            {
              departments.value = response.data.departments;
            }

            if(response.data.emission_categories)
            {
              emission_categories.value = response.data.emission_categories;

              let source_category = emission_categories.value.find(emcat => {
                return emcat.id === emission_source.value.category_id;
              });

              if(source_category)
              {
                emission_source.value.category_name = source_category.name;
              }
            }

            if(response.data.calculation_approaches)
            {
              all_calculation_approaches.value = response.data.calculation_approaches;
              calculation_approaches.value = response.data.calculation_approaches.filter(a => {
                let categories = a.category_ids.length > 0 ? a.category_ids.split(',') : [];
                return categories.includes(emission_source.value.category_id);
              });
            }

            if(response.data.activity_types)
            {
              all_activity_types.value = response.data.activity_types;

              activity_types.value = response.data.activity_types.filter(t => {
                let categories = t.category_ids.length > 0 ? t.category_ids.split(',') : [];
                return categories.includes(emission_source.value.category_id);
              });
            }

            if(response.data.fuel_types)
            {
              fuel_types.value = response.data.fuel_types;
              // fuel_types.value = response.data.fuel_types.filter(ft => {
              //   let categories = ft.category_ids.length > 0 ? ft.category_ids.split(',') : [];
              //   //console.log(categories);
              //   return categories.includes(emission_source.value.category_id);
              // });
            }

            if(response.data.refrigerant_sources)
            {
              refrigerant_sources.value = response.data.refrigerant_sources;
            }

            if(response.data.units)
            {
              units.value = response.data.units;
            }

            if(response.data.periods)
            {
              periods.value = response.data.periods;
            }

            if(response.data.emission_databases)
            {
              emission_databases.value = response.data.emission_databases;
            }

            if(response.data.locations)
            {
              locations.value = response.data.locations;
            }

            if(response.data.subsidiaries)
            {
              subsidiaries.value = response.data.subsidiaries;
            }

            loading_transactions.value = false;
          })
          .catch(err => {
            console.log(err);
          });
    }

    function getTransactions()
    {
      loading_transactions.value = true;
      let source_id = route.params.source;
      let source_params = {query_type: 'transactions', source_id: source_id, page: currentPage.value, sort_order: 'desc', sort_by: 'id'};

      let query = new URLSearchParams(source_params).toString();
      let endpoint = ghg_transactions_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            emission_transactions.value = response.data.emission_transactions;
            currentPage.value = Number(response.data.current_page);
            totalPages.value  = response.data.total_pages;
            totalItems.value  = response.data.total_items;

            loading_transactions.value = false;
            if(route.query.transactionId) {
              editTransaction(response.data.emission_transactions.find(et => et.id == route.query.transactionId), route.query.mode)
            }
          })
          .catch(err => {
            console.log(err);
          });
    }

    function toggleCreateModal()
    {
      createForm.value = {};
      createForm.value.source_id = emission_source.value['id'];
      createForm.value.category_id = emission_source.value['category_id'];
      createForm.value.scope_id = emission_source.value['scope_id'];
      createForm.value.year = emission_source.value['year'];
      createForm.value.class_id = emission_source.value['class_id'];
      createForm.value.department_id = emission_source.value['department_id'];
      createForm.value.country = emission_source.value['country'];
      createForm.value.region = emission_source.value['region'];
      createForm.value.actual_country = emission_source.value['actual_country'];
      createForm.value.actual_state = emission_source.value['actual_state'];
      createForm.value.zip_code = emission_source.value['zip_code'];
      createForm.value.source_type_id = emission_source.value.source_type_id;
      createForm.value.subsidiary_id = emission_source.value.subsidiary_id;
      createForm.value.gas_id = emission_source.value.gas_id || '';
      createForm.value.custom_emission_factor_id = emission_source.value.custom_emission_factor_id || '';
      createForm.value.calculation_approach_id = emission_source.value.calculation_approach_id || '';
      createForm.value.activity_type_id = emission_source.value.activity_type_id || '';
      createForm.value.fuel_type_id = emission_source.value.fuel_id || '';
      createForm.value.unit_id = emission_source.value.unit_id || '';
      createForm.value.fuel_efficiency = emission_source.value.fuel_efficiency || '';
      createForm.value.fuel_efficiency_unit_id = emission_source.value.fuel_efficiency_unit_id || '';
      createForm.value.emission_database_id = emission_source.value.emission_database_id || '';
      createForm.value.item_id = emission_source.value.item_id || '';
      createForm.value.expense_category_id = emission_source.value.expense_category_id || '';
      createForm.value.account_id = emission_source.value.account_id || '';
      createForm.value.location_id = emission_source.value.location_id || '';
      createForm.value.vendor_id = emission_source.value.vendor_id || '';
      createForm.value.vendor_name = emission_source.value.vendor_name || '';
      createForm.value.customer_id = emission_source.value.customer_id || '';
      createForm.value.customer_name = emission_source.value.customer_name || '';
      // createForm.value.partner_id = emission_source.value.partner_id || '';
      // createForm.value.partner_name = emission_source.value.partner_name || '';
      // createForm.value.project_id = emission_source.value.project_id || '';
      // createForm.value.project_name = emission_source.value.project_name || '';
      createForm.value.passengers = 1;

      if(!emission_source.value.calculation_approach_id)
      {
        defaultCalculationApproach();
      }

      create_modal.value.toggle();
    }
    
    function toggleEditEmissionModel(){
      editEmissionModel.value.toggle();

      if(enable_edit.value == false && editEmissionModel.value._isShown == false){
        router.replace({ path: route.path });
      }
    }

    function editTransaction(emission_transaction, mode)
    {
      if (mode == 'view') {
        router.replace({ path: route.path, query: { transactionId: emission_transaction.id, mode: mode } })
      }
      editing_transaction.value = {};
      editing_transaction.value = emission_transaction;

      editForm.value = {};
      editForm.value.id = emission_transaction.id;
      editForm.value.name = emission_transaction.name;
      editForm.value.altname = emission_transaction['altname'];
      editForm.value.category_id = emission_source.value['category_id'];
      editForm.value.scope_id = emission_source.value['scope_id'];
      editForm.value.source_type_id = emission_transaction['source_type_id'] || emission_source.value.source_type_id;
      editForm.value.subsidiary_id = emission_transaction['subsidiary_id'] || emission_source.value.subsidiary_id;
      editForm.value.activity_type_id = emission_transaction['activity_type_id'];
      editForm.value.amount = emission_transaction['amount'];
      editForm.value.calculation_approach_id = emission_transaction['calculation_approach_id'];
      editForm.value.custom_emission_factor_id = emission_transaction['custom_emission_factor_id'];
      editForm.value.data_source = emission_transaction['data_source'];
      editForm.value.netsuite_transaction = emission_transaction['netsuite_transaction']??'';
      editForm.value.class_id = emission_transaction['class_id'] || emission_source.value['class_id'];
      editForm.value.date = dayjs.utc(emission_transaction['date']).format('YYYY-MM-DD'); //2022-09-30
      editForm.value.department_id = emission_transaction['department_id'] || emission_source.value['department_id'];
      editForm.value.fuel_efficiency = emission_transaction['fuel_efficiency'];
      editForm.value.fuel_efficiency_unit_id = emission_transaction['fuel_efficiency_unit_id'];
      editForm.value.fuel_type_id = emission_transaction['fuel_type_id'];
      editForm.value.period_id = emission_transaction['period_id'];
      editForm.value.gas_id = emission_transaction['gas_id'];
      editForm.value.source_id = emission_transaction['source_id'];
      editForm.value.unit_id = emission_transaction['unit_id'];
      editForm.value.posting_status_id = emission_transaction['posting_status_id'];
      editForm.value.posting_status_name = emission_transaction['posting_status_name'];
      editForm.value.posting_message = emission_transaction['posting_message'] || '';
      editForm.value.summary = emission_transaction['summary'];
      editForm.value.year = emission_transaction['year'] || emission_source.value['year'];
      editForm.value.country = emission_transaction['country'];
      editForm.value.region = emission_transaction['region'];
      editForm.value.actual_country = emission_transaction['actual_country'];
      editForm.value.actual_state = emission_transaction['actual_state'];
      editForm.value.zip_code = emission_transaction['zip_code'];
      editForm.value.period_locked = emission_transaction['period_locked'];
      editForm.value.period_closed = emission_transaction['period_closed'];
      editForm.value.passengers = emission_transaction['passengers'];
      editForm.value.emission_database_id = emission_transaction['emission_database_id'];
      editForm.value.item_id = emission_transaction['item_id'];
      editForm.value.item_name = emission_transaction['item_name'];
      editForm.value.expense_category_id = emission_transaction['expense_category_id'];
      editForm.value.expense_category_name = emission_transaction['expense_category_name'];
      editForm.value.account_id = emission_transaction['account_id'];
      editForm.value.account_name = emission_transaction['account_name'];
      editForm.value.shipping_weight = emission_transaction['shipping_weight'];
      editForm.value.shipping_weight_unit_id = emission_transaction['shipping_weight_unit_id'];
      editForm.value.location_id = emission_transaction['location_id'];
      editForm.value.vendor_id = emission_transaction['vendor_id'];
      editForm.value.vendor_name = emission_transaction['vendor_name'];
      editForm.value.customer_id = emission_transaction['customer_id'];
      editForm.value.customer_name = emission_transaction['customer_name'];
      editForm.value.partner_id = emission_transaction['partner_id'];
      editForm.value.partner_name = emission_transaction['partner_name'];
      editForm.value.project_id = emission_transaction['project_id'];
      editForm.value.project_name = emission_transaction['project_name'];
      editForm.value.fixed_asset_id = emission_transaction['fixed_asset_id'];
      editForm.value.fixed_asset_name = emission_transaction['fixed_asset_name'];
      editForm.value.employee_id = emission_transaction['employee_id'];
      editForm.value.employee_name = emission_transaction['employee_name'];
      editForm.value.notes = emission_transaction['notes'];

      if(mode === 'edit' && !editForm.value.period_locked && !editForm.value.period_closed)
      {
        enable_edit.value = true;
      }

      else {
        enable_edit.value = false;
      }

      toggleEditEmissionModel();
    }

    function getSummary(transaction_id)
    {
      loading_summary.value = true;
      let source_params = {query_type: 'transaction_summary', transaction_id: transaction_id};

      let query = new URLSearchParams(source_params).toString();
      let endpoint = ghg_transactions_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            loading_summary.value = false;
            editForm.value.summary = response.data['transaction_summary'];
          })
          .catch(err => {
            loading_summary.value = false;
            editForm.value.summary = [];
          });
    }
    
    function deleteTransaction(emission_transaction, toggle_modal = false)
    {
      let confirmed = confirm('Do you want to delete this GHG transaction?');

      if(confirmed)
      {
        let source_params = {};
        let form = {id: emission_transaction.id, action: 'delete'};

        let query = new URLSearchParams(source_params).toString();
        let endpoint = ghg_transactions_restlet_url.value + '&'  + query;

        axios.put(endpoint, form)
            .then(response => {
              getTransactions();
              if(toggle_modal)
              {
                toggleEditEmissionModel();
              }
            })
            .catch(e => {
              modal_loading.value = false;
              if(e.response.data['message'])
              {
                alert(e.response.data['message']);
              }

              else {
                alert('Error');
              }
            });
      }
    }

    function defaultCalculationApproach()
    {
      let category_script_id = emission_source.value.category_scriptid.toUpperCase();

      const categories_for_emission_factor_db = [
        'VAL_60166_T2641309_304',
        'VAL_60168_T2641309_491',
        'VAL_60170_T2641309_514',
        'VAL_60177_T2641309_112',
        'VAL_60176_T2641309_650',
        'VAL_60171_T2641309_172', // purchased goods and services
        'VAL_60172_T2641309_405', // capital goods
      ];

      const categories_for_direct_gas_emissions = [
        'VAL_60167_T2641309_944',
        'VAL_60169_T2641309_340',
        'VAL_60173_T2641309_333',
        'VAL_60174_T2641309_674',
        'VAL_60175_T2641309_267',
        'VAL_60178_T2641309_818',
        'VAL_60179_T2641309_405',
        'VAL_60180_T2641309_651',
        'VAL_60181_T2641309_845',
        'VAL_60182_T2641309_341',
        'VAL_60183_T2641309_855',
        'VAL_60184_T2641309_948',
        'VAL_60185_T2641309_143',
        'VAL_61964_T2641309_836',
      ];

      if(categories_for_emission_factor_db.includes(category_script_id))
      {
        let calculation_approach = calculation_approaches.value.find(ca => {
          return ca.scriptid.toUpperCase() === 'VAL_60243_T2641309_921';
        });

        if(calculation_approach)
        {
          createForm.value.calculation_approach_id = calculation_approach['id'];
        }
      }

      else if(categories_for_direct_gas_emissions.includes(category_script_id))
      {
        let calculation_approach = calculation_approaches.value.find(ca => {
          return ca.scriptid.toUpperCase() === 'VAL_60247_T2641309_476';
        });

        if(calculation_approach)
        {
          createForm.value.calculation_approach_id = calculation_approach['id'];
        }
      }
    }
    
    /** Used on the summary table. Lists the emission amount if it exists or an error message. */
    function getTransactionEmissionValue(transaction)
    {
      if(transaction.posting_status_name === 'Error')
      {
        return parsePostingMessage(transaction.posting_message);
      }

      else {
        return transaction.summary.length > 0 ? transaction.summary[0]['amount_co2e'] : '-';
      }
    }

    function parsePostingMessage(message)
    {
      let parsed_message = message;

      try {
        parsed_message = JSON.parse(message);
      }

      catch(e) {
        //console.log(e);
      }
      
      if(parsed_message['message'])
      {
        return 'Error: ' + parsed_message['message'];
      }

      else {
        return parsed_message;
      }
    }

    function disabledInputCss(value=false){
      let is_show = value ? value : (!enable_edit.value);
      return DisabledLabelCss.disabledLabel(is_show);
    }

    function updateLocationParams(mode)
    {
      let form = mode === 'create' ? createForm.value : editForm.value;

      let location = locations.value.find(l => {
        return l.id === form.location_id;
      });

      if(location)
      {
        form.country = location.country;
        form.region = location.region;
        form.zip_code = location.zip_code;
      }
    }
    
    function toggleEditModal()
    {
      edit_enission_source_modal.value.toggle();
    }


    function editEmissionSource(){
      let source = emission_source.value;
  
      editEmissionSourceForm.value.id = source.id;
      editEmissionSourceForm.value.number = source.number;
      editEmissionSourceForm.value.name = source.name;
      editEmissionSourceForm.value.subsidiary_id = source.subsidiary_id;
      editEmissionSourceForm.value.source_type_id = source.source_type_id;
      editEmissionSourceForm.value.scope_id = source.scope_id;
      editEmissionSourceForm.value.year = source.year;
      editEmissionSourceForm.value.department_id = source.department_id;
      editEmissionSourceForm.value.class_id = source.class_id;
      editEmissionSourceForm.value.location_id = source.location_id;
      editEmissionSourceForm.value.fixed_asset_id = source.fixed_asset_id;
      editEmissionSourceForm.value.customer_id = source.customer_id;
      editEmissionSourceForm.value.vendor_id = source.vendor_id;
      editEmissionSourceForm.value.employee_id = source.employee_id;
      editEmissionSourceForm.value.assignee_id = source.assignee_id;
      editEmissionSourceForm.value.partner_id = source.partner_id;
      editEmissionSourceForm.value.project_id = source.project_id;
      editEmissionSourceForm.value.category_id = source.category_id;
      editEmissionSourceForm.value.country = source.country;
      editEmissionSourceForm.value.region = source.region;
      editEmissionSourceForm.value.actual_country = source.actual_country;
      editEmissionSourceForm.value.actual_state = source.actual_state;
      editEmissionSourceForm.value.zip_code = source.zip_code;
      editEmissionSourceForm.value.gas_id = source.gas_id;
      editEmissionSourceForm.value.custom_emission_factor_id = source.custom_emission_factor_id;
      editEmissionSourceForm.value.account_id = source.account_id;
      editEmissionSourceForm.value.calculation_approach_id = source.calculation_approach_id;
      editEmissionSourceForm.value.activity_type_id = source.activity_type_id;
      editEmissionSourceForm.value.fuel_id = source.fuel_id;
      editEmissionSourceForm.value.unit_id = source.unit_id;
      editEmissionSourceForm.value.fuel_efficiency = source.fuel_efficiency;
      editEmissionSourceForm.value.fuel_efficiency_unit_id = source.fuel_efficiency_unit_id;
      editEmissionSourceForm.value.emission_database_id = source.emission_database_id;
      editEmissionSourceForm.value.item_id = source.item_id;
      editEmissionSourceForm.value.expense_category_id = source.expense_category_id;
      editEmissionSourceForm.value.errors = [];

      toggleEditModal();
    }

    function getSources(query_type=''){
      getSource();
    }

    function onClickPaginations(page){
      if(page < 1){
        return false;
      }else if(page > totalPages.value){
        return false;
      }
      currentPage.value = page;
      getTransactions();
    }

    dayjs.extend(utc);

    onMounted(() => {

      try {
        getSource();
        getScopes();
        getEmissionSources();
        getCustomEmissionFactors();
        getTransactionDropdowns();
        getTransactions();
        create_modal.value = new bootstrap.Modal(document.getElementById('create_modal'));
        editEmissionModel.value = new bootstrap.Modal(document.getElementById('edit_emission_modal'));
        edit_enission_source_modal.value = new bootstrap.Modal(document.getElementById('edit_modal'));
      }

      catch(e){
        console.log(e);
      }

      // setTimeout(() => {
      //   loading_transactions.value = false;
      // }, 3000);
    });

    return {
      enabled_features,
      route,
      emission_source,
      emission_transactions,
      classes,
      departments,
      emission_categories,
      all_calculation_approaches,
      calculation_approaches,
      activity_types,
      all_activity_types,
      fuel_types,
      refrigerant_sources,
      units,
      emission_databases,
      custom_emission_factors,
      periods,
      locations,
      scopes,
      source_types,
      createForm,
      editForm,
      editing_transaction,
      loading,
      loading_transactions,
      modal_loading,
      create_modal,
      edit_modal,
      enable_edit,
      loading_summary,
      toggleCreateModal,
      editTransaction,
      getSummary,
      getSourceTypes,
      getTransactionEmissionValue,
      parsePostingMessage,
      disabledInputCss,
      updateLocationParams,
      
      editEmissionModel,
      deleteTransaction,
      toggleEditEmissionModel,
      getTransactions,
      getTransactionDropdowns,
      emission_sources,
      editEmissionSource,
      editEmissionSourceForm,
      edit_enission_source_modal,
      toggleEditModal,
      subsidiaries,
      getSources,
      onClickPaginations,
      currentPage,
      totalPages,
      totalItems,
      perPage,
    }
  }
}
</script>

<style>
.collapse-heading{
  text-decoration: none;
  color:black;
}
</style>