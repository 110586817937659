import {defineStore} from "pinia";

export const useLinkStore = defineStore('links', {
    state: () => {
        return {
            links: [],
        };
    },
    getters: {

    },
    actions: {
        setLinks(links)
        {
            this.links = links;
        },
    }
});