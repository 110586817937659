<template>
  <div class="modal fade" tabindex="-1" aria-labelledby="createSourceModal" id="edit_emission_modal" aria-hidden="true">
   <div class="modal-dialog modal-lg">
     <div class="modal-content">
       <div class="modal-header">
         <h5 class="modal-title fs-5 my-0" id="createSourceModal">{{ enable_edit ? `Edit Emission: ${editForm.name}` : `View Emission: ${editForm.name}` }}</h5>
         <button type="button" class="btn-close" aria-label="Close" @click="toggleEditEmissionModel"></button>
       </div>

       <loading-indicator v-if="loading_transactions"></loading-indicator>
       <div class="modal-body" v-else>
         <div class="alert alert-warning d-flex align-items-center py-2 px-4" role="alert" v-if="editForm.period_locked || editForm.period_closed">
             <span class="me-2"><i class="fa-light fa-lock-alt"></i></span> This transaction belongs to a closed or locked period and cannot be edited.
         </div>
         <h5 class="mt-0 mb-2 fs-5">Main</h5>
         <div class="row">
           <div class="col">
             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span>Date</label>
               <input class="form-control form-control-sm" aria-label="Date" :class="{'is-invalid': errors.date}" type="date" name="date" v-model="editForm.date" @change="selectPeriod('edit')" :disabled="!enable_edit" :style="disabledInputCss()" />
               <div class="invalid-feedback" v-if="errors.date">
                 {{ errors.date.toString() }}
               </div>
             </div>

             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span>Description</label>
               <input class="form-control form-control-sm" aria-label="Description" :class="{'is-invalid': errors.altname}" type="text" name="altname" v-model="editForm.altname" :disabled="!enable_edit" :style="disabledInputCss()" />
               <div class="invalid-feedback" v-if="errors.altname">
                 {{ errors.altname.toString() }}
               </div>
             </div>

             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span>Emission Category</label>
               <select class="form-select form-select-sm" aria-label="Emission Category" v-model="editForm.category_id" :class="{'is-invalid': errors.category_id}" disabled :style="disabledInputCss(true)">
                 <option value="" selected>--</option>
                 <option v-for="(category, index) in emission_categories" :value="category.id" :key="index">{{ category.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.category_id">
                 {{ errors.category_id.toString() }}
               </div>
             </div>

             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span>Scope</label>
               <select class="form-select form-select-sm" aria-label="Scope" v-model="editForm.scope_id" :class="{'is-invalid': errors.scope_id}" disabled :style="disabledInputCss(true)">
                 <option value="" selected>--</option>
                 <option v-for="(scope, index) in scopes" :value="scope.id" :key="index">{{ scope.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.scope_id">
                 {{ errors.scope_id.toString() }}
               </div>
             </div>

             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span>Emission Source</label>
               <select class="form-select form-select-sm" aria-label="Scope" v-model="editForm.source_id" :class="{'is-invalid': errors.source_id}" @change="changeDependentFields($event)" v-if="editForm.status_id == 1">
                 <option value="" selected>--</option>
                 <option v-for="(e_source, index) in emission_sources" :value="e_source.id" :key="index">{{ e_source.name }}</option>
               </select>
               <router-link :to="{name: 'emission_source', params: {source: emission_source.id}}" class="emission-source-link" target="_blank" v-else-if="emission_source.id">
                <span>{{ emission_source.name }}</span>
                <span class="new-tab-icon"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
              </router-link>
               <!-- <input class="form-control form-control-sm" aria-label="Source" :class="{'is-invalid': errors.source_id}" type="text" name="source_name" :value="emission_source.name" disabled :style="disabledInputCss(true)" v-else /> -->
               <div class="invalid-feedback" v-if="errors.source_id">
                 {{ errors.source_id.toString() }}
               </div>
             </div>

             <div class="mt-2">
               <label class="form-label">Data Source</label>
               <input class="form-control form-control-sm" aria-label="Data Source" :class="{'is-invalid': errors.data_source}" type="text" name="data_source" v-model="editForm.data_source" :disabled="!enable_edit" :style="disabledInputCss()" />
               <div class="invalid-feedback" v-if="errors.data_source">
                 {{ errors.data_source.toString() }}
               </div>
             </div> 
           </div>
           <div class="col">
             <div class="mt-4">
               <loading-indicator v-if="loading_summary"></loading-indicator>
               <div v-else>
                 <table class="table table-striped table-bordered table-sm" v-if="editForm.posting_status_name === 'Error'">
                   <thead>
                   <tr class="table-active">
                     <th scope="col" colspan="2"><span class="fw-bold">Status</span></th>
                   </tr>
                   </thead>
                   <tbody>
                   <tr>
                     <th>{{ parsePostingMessage(editForm.posting_message) }}</th>
                   </tr>
                   </tbody>
                 </table>
                 <table class="table table-striped table-bordered table-sm" v-else-if="editForm.posting_status_name === 'Incomplete'">
                   <thead>
                   <tr class="table-active">
                     <th scope="col" colspan="2"><span class="fw-bold">Status</span></th>
                   </tr>
                   </thead>
                   <tbody>
                   <tr>
                     <th>Error: Please populate Emission Source on the record.</th>
                   </tr>
                   </tbody>
                 </table>
                 <table class="table table-striped table-bordered table-sm" v-else-if="editForm.summary && editForm.summary.length > 0">
                   <thead>
                   <tr class="table-active">
                     <th scope="col" colspan="2"><span class="fw-bold">Summary View</span></th>
                     <th scope="col" colspan="1"><span class="fw-bold">Unit</span></th>
                   </tr>
                   </thead>
                   <tbody>
                   <tr v-for="(line, index) in editForm.summary">
                     <th><span :class="{'fw-bold': index === 0}">{{ line['gas'] }}</span></th>
                     <td>{{ line['amount_gas'] }}</td>
                     <td>{{ global_unit  }}</td>
                   </tr>
                   </tbody>
                 </table>
               </div>
             </div>

             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span>GHG Period</label>
               <select class="form-select form-select-sm" aria-label="GHG Period" v-model="editForm.period_id" :class="{'is-invalid': errors.period_id}" disabled :style="disabledInputCss(true)">
                 <option value="" selected>--</option>
                 <option v-for="(period, index) in periods" :value="period.id" :key="index">{{ period.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.period_id">
                 {{ errors.period_id.toString() }}
               </div>
             </div>

             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span>Type</label>
               <input class="form-control form-control-sm" aria-label="Description" value="Emission" type="text" name="altname" disabled :style="disabledInputCss(true)" />
             </div>

              <div class="mt-2" v-if="enabled_features.subsidiaries">
                <label class="form-label"><span class="text-danger me-1">*</span>Subsidiary</label>
                <select class="form-select form-select-sm" aria-label="Subsidiary" v-model="editForm.subsidiary_id" :class="{'is-invalid': errors.subsidiary_id}" :disabled="!enable_edit" :style="disabledInputCss()">
                  <option value="" selected>--</option>
                  <option v-for="(subsidiary, sub_index) in subsidiaries" :value="subsidiary.id" :key="sub_index">{{ subsidiary.name }}</option>
                </select>
                <div class="invalid-feedback" v-if="errors.subsidiary_id">
                  {{ errors.subsidiary_id.toString() }}
                </div>
              </div>
             
             <div class="mt-2">
               <label class="form-label">NetSuite Transaction</label>
               <input class="form-control form-control-sm" aria-label="NetSuite Transaction" v-model="editForm.netsuite_transaction" type="text" name="netsuite_transaction" disabled :style="disabledInputCss(true)" />
             </div>

           </div>
         </div>

         <h5 class="mt-4 mb-2 fs-5">Activity Data</h5>
         <div class="row">
           <div class="col">
             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span>Calculation Approach</label>
               <select
                 class="form-select form-select-sm"
                 aria-label="Calculation Approach"
                 v-model="editForm.calculation_approach_id"
                 :class="{'is-invalid': errors.calculation_approach_id}"
                 :disabled="!enable_edit || calculationApproachIsLocked()" :style="disabledInputCss()">
                 <option value="" selected>--</option>
                 <option v-for="(calculation_approach, index) in calculation_approaches" :value="calculation_approach.id" :key="index">{{ calculation_approach.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.calculation_approach_id">
                 {{ errors.calculation_approach_id.toString() }}
               </div>
             </div>

             <div class="mt-2" v-if="activityTypeFieldIsVisible('edit')">
               <label class="form-label">
                 <span class="text-danger me-1" v-if="activity_types.length > 0">*</span>Activity Type
               </label>
               <select class="form-select form-select-sm" aria-label="Activity Type" v-model="editForm.activity_type_id" :class="{'is-invalid': errors.activity_type_id}" :disabled="!enable_edit || activity_types.length < 1" :style="disabledInputCss()" @change="resetSourchFuelValue('edit')">
                 <option value="" selected>--</option>
                 <option v-for="(activity_type, index) in filteredActivityTypes('edit')" :value="activity_type.id" :key="index">{{ activity_type.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.activity_type_id">
                 {{ errors.activity_type_id.toString() }}
               </div>
             </div>

             <div class="mt-2" v-if="isStandardEmissionFactors('edit')">
               <label class="form-label">Emission Factor Database</label>
               <select class="form-select form-select-sm" aria-label="Emission Factor Database" v-model="editForm.emission_database_id" :class="{'is-invalid': errors.emission_database_id}" :disabled="!enable_edit" :style="disabledInputCss()" @change="resetSourchFuelValue('edit')">
                 <option value="" selected>--</option>
                 <option v-for="(emission_database, index) in emission_databases" :value="emission_database.id" :key="index">{{ emission_database.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.emission_database_id">
                 {{ errors.emission_database_id.toString() }}
               </div>
             </div>

             <div class="mt-2" v-if="isStandardEmissionFactors('edit')">
               <label class="form-label"><span class="text-danger me-1">*</span>Source Type</label>
               <select class="form-select form-select-sm" aria-label="Source Type" v-model="editForm.source_type_id" :class="{'is-invalid': errors.source_type_id}" :disabled="!enable_edit" :style="disabledInputCss()">
                 <option value="" selected>--</option>
                 <option v-for="(source_type, index) in filteredSourceTypes('edit')" :value="source_type.id" :key="index">{{ source_type.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.source_type_id">
                 {{ errors.source_type_id.toString() }}
               </div>
             </div>

             <div class="mt-2" v-if="fuelRelatedFieldIsVisible('edit', emission_source.category_id)">
               <label class="form-label"><span class="text-danger me-1">*</span>Fuel Type</label>
               <select class="form-select form-select-sm" aria-label="Fuel Type" v-model="editForm.fuel_type_id" :class="{'is-invalid': errors.fuel_type_id}" :disabled="!enable_edit" :style="disabledInputCss()">
                 <option value="" selected>--</option>
                 <option v-for="(fuel_type, index) in filteredFuelTypes('edit')" :value="fuel_type.id" :key="index">{{ fuel_type.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.fuel_type_id">
                 {{ errors.fuel_type_id.toString() }}
               </div>
             </div>
           </div>
           <div class="col">
             <div class="mt-2" v-if="requiresCustomEmissionFactor(editForm.calculation_approach_id)">
               <label class="form-label"><span class="text-danger me-1">*</span>Custom Emission Factor</label>
               <select class="form-select form-select-sm" aria-label="Custom Emission Factor" v-model="editForm.custom_emission_factor_id" :class="{'is-invalid': errors.custom_emission_factor_id}" :disabled="!enable_edit" :style="disabledInputCss()">
                 <option value="" selected>--</option>
                 <option v-for="(custom_emission_factor, index) in custom_emission_factors" :value="custom_emission_factor.id" :key="index">{{ custom_emission_factor.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.custom_emission_factor_id">
                 {{ errors.custom_emission_factor_id.toString() }}
               </div>
             </div>

             <div class="mt-2" v-if="fuelRelatedFieldIsVisible('edit', emission_source.category_id)">
               <label class="form-label"><span v-if="fuelEfficiencyFieldIsRequired('edit')" class="text-danger me-1">*</span>Fuel Efficiency</label>
               <input
                   class="form-control form-control-sm"
                   aria-label="Fuel Efficiency"
                   :class="{'is-invalid': errors.fuel_efficiency}"
                   type="number"
                   name="fuel_efficiency"
                   v-model="editForm.fuel_efficiency"
                   :disabled="!enable_edit || fuelEfficiencyFieldIsDisabled('edit')" :style="disabledInputCss()" />
               <div class="invalid-feedback" v-if="errors.fuel_efficiency">
                 {{ errors.fuel_efficiency.toString() }}
               </div>
             </div>

             <div class="mt-2" v-if="fuelRelatedFieldIsVisible('edit', emission_source.category_id)">
               <label class="form-label"><span v-if="fuelEfficiencyFieldIsRequired('edit')" class="text-danger me-1">*</span>Fuel Efficiency Unit</label>
               <select
                   class="form-select form-select-sm"
                   aria-label="Fuel Efficiency Unit"
                   v-model="editForm.fuel_efficiency_unit_id"
                   :class="{'is-invalid': errors.fuel_efficiency_unit_id}"
                   :disabled="!enable_edit || fuelEfficiencyFieldIsDisabled('edit')" :style="disabledInputCss()">
                 <option value="" selected>--</option>
                 <option v-for="(unit, index) in getVisibleEfficiencyUnits('edit')" :value="unit.id" :key="index">{{ unit.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.fuel_efficiency_unit_id">
                 {{ errors.fuel_efficiency_unit_id.toString() }}
               </div>
             </div>

             <div class="mt-2" v-if="requiresPassengers('edit')">
               <label class="form-label"><span class="text-danger me-1">*</span>Number of Passengers</label>
               <input class="form-control form-control-sm" aria-label="Number of Passengers" :class="{'is-invalid': errors.passengers}" type="number" step="1" name="passengers" v-model="editForm.passengers" :disabled="!enable_edit" :style="disabledInputCss()" />
               <div class="invalid-feedback" v-if="errors.passengers">
                 {{ errors.passengers.toString() }}
               </div>
             </div>

             <div class="mt-2" v-if="shippingUnitFieldIsRequired('edit')">
               <label class="form-label"><span class="text-danger me-1">*</span>Shipping Weight</label>
               <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.shipping_weight}" aria-label="Shipping Weight" name="shipping_weight" v-model="editForm.shipping_weight" :disabled="!enable_edit" :style="disabledInputCss()" />
               <div class="invalid-feedback" v-if="errors.shipping_weight">
                 {{ errors.shipping_weight.toString() }}
               </div>
             </div>

             <div class="mt-2" v-if="shippingUnitFieldIsRequired('edit')">
               <label class="form-label"><span class="text-danger me-1">*</span>Shipping Weight Unit</label>
               <select class="form-select form-select-sm" :class="{'is-invalid': errors.shipping_weight_unit_id}" name="shipping_weight_unit_id" aria-label="Shipping Weight Unit" v-model="editForm.shipping_weight_unit_id" :disabled="!enable_edit" :style="disabledInputCss()">
                 <option value="" selected>--</option>
                 <option v-for="(unit, index) in filterShippingWeightUnits('edit')" :value="unit.id" :key="index">{{ unit.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.shipping_weight_unit_id">
                 {{ errors.shipping_weight_unit_id.toString() }}
               </div>
             </div>
             
           </div>
         </div>
         
         <h5 class="mt-4 mb-2 fs-5">Amount</h5>
         <div class="row">
           <div class="col">
             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span>Amount</label>
               <input class="form-control form-control-sm" aria-label="Amount" :class="{'is-invalid': errors.amount}" type="number" name="amount" v-model="editForm.amount" :disabled="!enable_edit" :style="disabledInputCss()" />
               <div class="invalid-feedback" v-if="errors.amount">
                 {{ errors.amount.toString() }}
               </div>
             </div>

             <div class="mt-2" v-if="requiresRefrigerantSource('edit') || requiresGas(editForm.calculation_approach_id || '')">
               <label class="form-label"><span class="text-danger me-1">*</span>{{ getGasFieldName('edit') }}</label>
               <select class="form-select form-select-sm" aria-label="Refrigerant Source" v-model="editForm.gas_id" :class="{'is-invalid': errors.gas_id}" :disabled="!enable_edit" :style="disabledInputCss()">
                 <option value="" selected>--</option>
                 <option v-for="(refrigerant_source, index) in refrigerant_sources" :value="refrigerant_source.id" :key="index">{{ refrigerant_source.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.gas_id">
                 {{ errors.gas_id.toString() }}
               </div>
             </div>

           </div>

           <div class="col">
             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span>Unit</label>
               <select class="form-select form-select-sm" aria-label="Unit" v-model="editForm.unit_id" :class="{'is-invalid': errors.unit_id}" :disabled="!enable_edit" :style="disabledInputCss()">
                 <option value="" selected>--</option>
                 <option v-for="(unit, index) in getVisibleUnits('edit')" :value="unit.id" :key="index">{{ unit.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.unit_id">
                 {{ errors.unit_id.toString() }}
               </div>
             </div>
             
           </div>
         </div>
         
         <h5 class="mt-4 mb-2 fs-5">Region</h5>
         <div class="row mb-4">
           <div class="col">
             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span><i class="fa-light fa-search"></i><span class="ms-2">Country</span></label>
               <country-selection @country-selected="selectCountry('edit', 'actual_country', $event)" ref="actual_country_edit_selector" :initial_value="editForm.actual_country" :disabled="!enable_edit"></country-selection>
               <div class="text-danger" v-if="errors.actual_country">
                 {{ errors.actual_country.toString() }}
               </div>
             </div>
             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(editForm.category_id)">*</span><i class="fa-light fa-search"></i><span class="ms-2">State / Region / Province</span></label>
               <region-selection :disabled="!enable_edit || !editForm.actual_country" :country="editForm.actual_country" @region-selected="selectRegion('edit', 'actual_state', $event)" ref="actual_region_edit_selector" :initial_value="editForm.actual_state"></region-selection>
               <div class="text-danger" v-if="errors.actual_state">
                 {{ errors.actual_state.toString() }}
               </div>
             </div>
             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(editForm.category_id)">*</span>Zip / Postal Code</label>
               <input class="form-control form-control-sm" aria-label="Name" :class="{'is-invalid': errors.zip_code}" type="text" name="zip_code" v-model="editForm.zip_code" :disabled="!enable_edit" :style="disabledInputCss()" />
               <div class="text-danger" v-if="errors.zip_code">
                 {{ errors.zip_code.toString() }}
               </div>
             </div>

           </div>
           <div class="col">
            <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1">*</span><i class="fa-light fa-search"></i><span class="ms-2">Emission Factor Country</span></label>
               <country-selection @country-selected="selectCountry('edit', 'country', $event)" ref="country_edit_selector" :initial_value="editForm.country" :disabled="!enable_edit"></country-selection>
               <div class="text-danger" v-if="errors.country">
                 {{ errors.country.toString() }}
               </div>
             </div>
             
             <div class="mt-2">
               <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(editForm.category_id)">*</span><i class="fa-light fa-search"></i><span class="ms-2">Emission Factor State / Region / Province</span></label>
               <region-selection :disabled="!enable_edit || !editForm.country" :country="editForm.country" @region-selected="selectRegion('edit', 'region', $event)" ref="region_edit_selector" :initial_value="editForm.region"></region-selection>
               <div class="text-danger" v-if="errors.region">
                 {{ errors.region.toString() }}
               </div>
             </div>

           </div>
         </div>

         <a class="mb-2 fs-5 collapse-heading" data-bs-toggle="collapse" href="#edit_classification" role="button" aria-expanded="false" aria-controls="edit_classification" @click="toggleCollapse('edit', 'classification')">
          <h5 class="mt-1 mb-2 fs-5"><span class="fs-5 fw-bold me-2"><i class="fa " :class="edit_classification_toggle?'fa-plus':'fa-minus'" aria-hidden="true"></i></span>
           Classification</h5> 
         </a>
         <div class="collapse" id="edit_classification">
           <div class="row">
             <div class="col">
              <div class="mt-2" v-if="enabled_features.locations">
                 <label class="form-label">Location</label>
                 <select class="form-select form-select-sm" aria-label="Location" v-model="editForm.location_id" :class="{'is-invalid': errors.location_id}" :disabled="!enable_edit" :style="disabledInputCss()" @change="updateLocationParams('edit')">
                   <option value="" selected>--</option>
                   <option v-for="(location, index) in locations" :value="location.id" :key="index">{{ location.name }}</option>
                 </select>
                 <div class="invalid-feedback" v-if="errors.location_id">
                   {{ errors.location_id.toString() }}
                 </div>
               </div>
               <div class="mt-2" v-if="editForm.id">
                 <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Expense Category</span></label>
                 <list-record-selection
                   record_type="expense_categories"
                   @record-selected="selectListRecord('edit', 'expense_category_id', $event)"
                   ref="expense_category_edit_selector"
                   :initial_value="editForm.expense_category_name"
                   :disabled="!enable_edit">
                 </list-record-selection>
                 <div class="invalid-feedback" v-if="errors.expense_category_id">
                   {{ errors.expense_category_id.toString() }}
                 </div>
               </div>
               <div class="mt-2" v-if="editForm.id">
                 <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Vendor</span></label>
                 <entity-selection entity_type="vendors" @entity-selected="selectEntity('edit', 'vendor_id', $event)" ref="vendor_edit_selector" :initial_value="editForm.vendor_name" :disabled="!enable_edit"></entity-selection>
                 <div class="invalid-feedback" v-if="errors.vendor_id">
                   {{ errors.vendor_id.toString() }}
                 </div>
               </div>
               <div class="mt-2" v-if="editForm.id && enabled_features.partners">
                 <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Partner</span></label>
                 <entity-selection entity_type="partners" @entity-selected="selectEntity('edit', 'partner_id', $event)" ref="partner_edit_selector" :initial_value="editForm.partner_name" :disabled="!enable_edit"></entity-selection>
                 <div class="invalid-feedback" v-if="errors.partner_id">
                   {{ errors.partner_id.toString() }}
                 </div>
               </div>
               <div class="mt-2" v-if="editForm.id && enabled_features.projects">
                 <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Project</span></label>
                 <entity-selection entity_type="projects" @entity-selected="selectEntity('edit', 'project_id', $event)" ref="project_edit_selector" :initial_value="editForm.project_name" :disabled="!enable_edit"></entity-selection>
                 <div class="invalid-feedback" v-if="errors.project_id">
                   {{ errors.project_id.toString() }}
                 </div>
               </div>

               <div class="mt-2" v-if="editForm.id">
                 <label class="form-label"><span class="ms-2">Asset</span></label>

                 <input type="search" class="form-control form-control-sm" placeholder="" v-model="editForm.fixed_asset_name" :style="disabledInputCss()">

                 <!-- <segment-selection segment_type="fixed_assets" @segment-selected="selectSegment('edit', 'fixed_asset_id', $event)" ref="fixed_asset_edit_selector" :initial_value="editForm.fixed_asset_name" :disabled="!enable_edit"></segment-selection>
                 <div class="invalid-feedback" v-if="errors.fixed_asset_id">
                   {{ errors.fixed_asset_id.toString() }}
                 </div> -->
               </div>

             </div>

             <div class="col">
              <div class="mt-2" v-if="editForm.id">
                 <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Item</span></label>
                 <list-record-selection
                   record_type="items"
                   @record-selected="selectListRecord('edit', 'item_id', $event)"
                   ref="item_edit_selector"
                   :initial_value="editForm.item_name"
                   :disabled="!enable_edit">
                 </list-record-selection>
                 <div class="invalid-feedback" v-if="errors.item_id">
                   {{ errors.item_id.toString() }}
                 </div>
               </div>
               <div class="mt-2" v-if="editForm.id">
                   <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Account</span></label>
                   <list-record-selection
                           record_type="accounts"
                           @record-selected="selectListRecord('edit', 'account_id', $event)"
                           ref="account_edit_selector"
                           :initial_value="editForm.account_name"
                           :disabled="!enable_edit">
                   </list-record-selection>
                   <div class="invalid-feedback" v-if="errors.account_id">
                       {{ errors.account_id.toString() }}
                   </div>
               </div>
              <div class="mt-2" v-if="editForm.id">
                  <label class="form-label">
                    <i class="fa-light fa-search"></i>
                    <span class="ms-2">Employee</span>
                  </label>

                  <entity-selection entity_type="employees" @entity-selected="selectEntity('edit', 'employee_id', $event)" ref="employee_edit_selector" :initial_value="editForm.employee_name" :disabled="!enable_edit"></entity-selection>
                  <div class="invalid-feedback" v-if="errors.employee_id">
                    {{ errors.employee_id.toString() }}
                  </div>
               </div>
               <div class="mt-2" v-if="enabled_features.departments">
               <label class="form-label">Department</label>
               <select class="form-select form-select-sm" aria-label="Department" v-model="editForm.department_id" :class="{'is-invalid': errors.department_id}" :disabled="!enable_edit" :style="disabledInputCss()">
                 <option value="" selected>--</option>
                 <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.department_id">
                 {{ errors.department_id.toString() }}
               </div>
             </div>

             <div class="mt-2" v-if="enabled_features.classes">
               <label class="form-label">Class</label>
               <select class="form-select form-select-sm" aria-label="Class" v-model="editForm.class_id" :class="{'is-invalid': errors.class_id}" :disabled="!enable_edit" :style="disabledInputCss()">
                 <option value="" selected>--</option>
                 <option v-for="(classification, index) in classes" :value="classification.id" :key="index">{{ classification.name }}</option>
               </select>
               <div class="invalid-feedback" v-if="errors.class_id">
                 {{ errors.class_id.toString() }}
               </div>
             </div>

             </div>
           </div>
         </div>

         <h5 class="mt-4 mb-2 fs-5">Notes</h5>
         <div class="row">
           <div class="col">
             <div class="mt-2">
               <textarea class="form-control form-control-sm" aria-label="Notes" name="notes" v-model="editForm.notes" :disabled="!enable_edit" :style="disabledInputCss()">{{editForm.notes}}</textarea>
             </div>
           </div>
         </div>
         
       </div>

       <div class="modal-footer d-flex justify-content-between" v-if="enable_edit">
         <div class="d-flex justify-content-start">
           <button type="button" class="btn btn-danger" :disabled="modal_loading" @click="deleteTransaction(true)">
             <span v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
             <span v-else><i class="fa-light fa-trash-alt"></i></span>
           </button>
         </div>

         <div class="d-flex justify-content-end">
           <button type="button" class="btn btn-light" :disabled="modal_loading" @click="toggleEditEmissionModel">
             <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
             <span class="me-2" v-else><i class="fa-light fa-arrow-circle-left"></i></span>
             <span>Cancel</span>
           </button>
           <button type="button" class="btn btn-primary ms-2" :disabled="modal_loading" @click="updateTransaction">
             <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
             <span class="me-2" v-else><i class="fa-light fa-save"></i></span>
             <span>Save</span>
           </button>
         </div>
       </div>
     </div>
   </div>
 </div>
</template>

<script>
import { nextTick } from 'vue'
import {useRestletStore} from "@/stores/restlets";
import {useAuthStore} from "@/stores/auth";
import {storeToRefs} from "pinia/dist/pinia";
import axios from "axios";

import LoadingIndicator from "@/components/global/LoadingIndicator";
import CountrySelection from "@/components/forms/CountrySelection";
import RegionSelection from "@/components/forms/RegionSelection";
import ListRecordSelection from "@/components/forms/ListRecordSelection";
import EntitySelection from "@/components/forms/EntitySelection";
import SegmentSelection from "@/components/forms/SegmentSelection";
import DisabledLabelCss from "@/helpers/disabledLabelCss";
import EmissionMixin from "@/mixins/emission_transaction_model"

export default {
name: "EditEmissionModel",

components: {
LoadingIndicator,
CountrySelection,
RegionSelection,
ListRecordSelection,
EntitySelection,
SegmentSelection,
},

props: {
 toggleEditEmissionModel: Function,
 getWorkbenches: Function,
 emission_sources:Array,
 enable_edit:Boolean,
},

data() {
 const restlet_store = useRestletStore();
 const auth_store = useAuthStore();
 const {enabled_features} = storeToRefs(auth_store);
 const {ghg_transactions_restlet_url} = storeToRefs(restlet_store);
 const {global_unit} = storeToRefs(auth_store);

 return {
   ghg_transactions_restlet_url: ghg_transactions_restlet_url,
   global_unit: global_unit,
   enabled_features: enabled_features,
   isEdit: this.enable_edit
 }
},

mounted(){
 nextTick(() => {
    if(!this.editForm.period_locked && !this.editForm.period_closed)
     {
       this.isEdit = true;
     }else {
       this.isEdit = false;
     }
     
     this.errors = [];
 });
 
},

methods: {
disabledInputCss(value=false){
     let is_show = value ? value : (!this.enable_edit);
     return DisabledLabelCss.disabledLabel(is_show);
},

deleteTransaction(toggle_modal = false)
 {
   let confirmed = confirm('Do you want to delete this GHG transaction?');

   if(confirmed)
   {
     let source_params = {};
     let form = {id: this.editForm.id, action: 'delete'};

     let query = new URLSearchParams(source_params).toString();
     let endpoint = this.ghg_transactions_restlet_url + '&'  + query;

     axios.put(endpoint, form)
       .then(response => {
         this.getWorkbenches();
         if(toggle_modal)
         {
           this.toggleEditEmissionModel();
         }
       })
       .catch(e => {
         this.modal_loading = false;
         if(e.response.data['message'])
         {
           alert(e.response.data['message']);
         } else {
           alert('Error');
         }
       });
   }
 },

updateTransaction()
 {
   this.errors = [];
   let errors = this.validateForm('edit');

   if(errors === 0)
   {
     this.modal_loading = true;
     let source_params = {};
     let form = this.editForm;
     form['action'] = 'update';

     let query = new URLSearchParams(source_params).toString();
     let endpoint = this.ghg_transactions_restlet_url + '&' + query;

     axios.put(endpoint, form)
       .then(response => {
         if(response.data['status'] === 'success')
         {
           this.errors = [];
           this.getWorkbenches();
           this.toggleEditEmissionModel();
           this.modal_loading = false;
           this.edit_classification_toggle = true;
           this.$emit('updatedTransactionId', response.data['emission_transaction_id']);
         }
       })
       .catch(e => {
         this.modal_loading = false;
         if(e.response.data['message'])
         {
           alert(e.response.data['message']);
         } else {
           alert('Error');
           this.toggleEditEmissionModel();
         }
       });
   }
 },

},

mixins: [EmissionMixin],

}

</script>
<style>
  .emission-source-link {
    display: block;
    padding-left: 0.5rem;
    font-size: 0.75rem;
  }
  .new-tab-icon {
    margin-left: 10px;
  }
</style>
