<template>
  <div>
    <input ref="parent" type="search" class="form-control form-control-sm" :placeholder="(disabled == false) ? placeholder : ''" :disabled="disabled" v-model="search" @input="dropdownSearch($event)" :style="disabledInputCss()">
    <div class="list-group shadow-lg" v-if="menu" style="position: absolute; z-index: 1000;">
      <button type="button" class="list-group-item list-group-item-action" v-for="(country, index) in visible_countries" @click="selectCountry(country)" :key="index">
        <span class="text-uppercase">{{ country.name }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import {ref, watch, onMounted} from "vue";
import countries from "@/utils/countries";
import Fuse from "fuse.js";

import LoadingIndicator from "@/components/global/LoadingIndicator";
import DisabledLabelCss from "@/helpers/disabledLabelCss";

export default {

  components: {
    LoadingIndicator,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Type to search',
    },
    initial_value: {
      type: String,
      default: '',
    },
  },

  setup(props, {emit, expose})
  {
    const publicUrl = ref(process.env.BASE_URL);
    const menu = ref(false);
    const loading = ref(false);
    const name =  ref('');
    const visible_countries = ref([]);

    const search = ref('');

    function dropdownSearch(event)
    {
      if(search.value.length === 0)
      {
        visible_countries.value = [];
        menu.value = false;
        emit('country-selected', null);
      }

      else {
        setTimeout(() => {
          searchCountries(false, true);
        }, 500);
      }
    }

    function searchCountries(run = false, open_menu = false)
    {
      if(search.value.length >= 2 || (run && !search.value))
      {

        loading.value = true;
        let options = {
          keys: ['name', 'id', 'code'],
        }

        let fuse = new Fuse(countries, options);

        let results = fuse.search(search.value);


        visible_countries.value = results.map(result => {
          return result.item;
        }).slice(0, 5);
      }

      if(open_menu)
      {
        menu.value = true;
      }
    }

    function selectCountry(country)
    {
      if(country)
      {
        search.value = country.name;
        emit('country-selected', country);
        menu.value = false;
      }

      else {
        emit('country-selected', null);
      }
    }

    function clear()
    {
      search.value = '';
      visible_countries.value = [];
    }

    function setExistingName(name)
    {
      search.value = name;
    }

    function resetSearchValue(new_value)
    {
      let country_code = new_value || null;

      if(country_code)
      {
        let country = countries.find(c => {
          return c.code === country_code;
        });

        search.value = country.name || '';
      }

      else {
        search.value = '';
      }
    }

    function disabledInputCss(){
      let css_val = DisabledLabelCss.disabledLabel(props.disabled);
      return css_val;
    }

    onMounted(() => {
      resetSearchValue(props.initial_value);
    });

    expose({setExistingName});

    watch(() => props.initial_value, (new_value) => {
      resetSearchValue(new_value);
    });

    return {
      publicUrl,
      menu,
      loading,
      search,
      name,
      visible_countries,
      dropdownSearch,
      searchCountries,
      selectCountry,
      clear,
      setExistingName,
      disabledInputCss,
    }
  }
}
</script>
