<template>
  <div class="card custom-height" v-if="accounting_period?.id">
    <loading-indicator v-if="loading_period"></loading-indicator>
    <div class="card-body" v-else>
      <div class="row align-items-center fs-5">
        <div class="col" v-if="route.params.child_period">
          <span v-if="accounting_period.closed" class="me-2 text-success"><i class="fa-duotone fa-circle-check me-2"></i> <span class="text-black">Closed</span></span>
          <span v-else-if="accounting_period.locked" class="me-2 text-danger"><i class="fa-duotone fa-lock me-2"></i> <span class="text-black">Locked</span></span>
          <span v-else class="me-2 text-warning"><i class="fa-duotone fa-lock-open me-2"></i><span class="text-black">Open</span></span>
        </div>
        <div class="col" v-else-if="accounting_period.base_year">
          <span class="me-2 text-success"><i class="fa-duotone fa-circle-check"></i></span> <span>Base Year</span>
        </div>
        <div class="col d-flex justify-content-end cursor-pointer" @click="editGhgYear"><i class="fa-regular fa-pen-to-square"></i></div>
      </div>
      <hr class="mb-2" />

      <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-between">
          <h4 class="py-2">{{ accounting_period.name }}</h4>
          <template v-if="route.params.child_period">
            <button type="button" class="btn btn-warning text-white" v-if="accounting_period.closed" @click="reopenPeriod">Reopen</button>
            <template v-else-if="accounting_period.locked">
              <div>
                <button type="button" class="btn btn-success text-white" @click="closePeriod">Close</button>
                <button type="button" class="btn btn-warning text-white" @click="reopenPeriod">Open</button>
            </div>
            </template>
            <button type="button" class="btn btn-danger text-white" @click="lockPeriod" v-else >Lock</button>
          </template>
        </div>

        <div class="col-6">
          <p class="mt-2 mb-1 text-muted fs-5">Start Date</p>
          <div class="d-flex">
            <div>
              <h5 class="mt-1 fs-6">
                {{ accounting_period.start_date }}
              </h5>
            </div>
          </div>
        </div>

        <div class="col-6 d-flex align-items-end flex-column">
          <p class="mt-2 mb-1 text-muted fs-5">End Date</p>
          <div class="d-flex">
            <i class='uil uil-schedule font-18 text-success me-1'></i>
            <div>
              <h5 class="mt-1 fs-6">
                {{ accounting_period.end_date }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="route.params.child_period">
        <div class="col">
          <div class="list-wrapper">
            <h5 class="fs-4 my-0">Checklist</h5>
           <ul>
            <li>
              <span class="me-2 d-flex align-items-center"><i class="fa-thin fa-circle bullet-size-10"></i></span> <span>Lock GHG Period</span>
            </li>
            <li class="anchor-link" v-for="(link, link_index) in ghg_checklist_links">
              <a :href="link.link" target="_blank"> <span class="me-2 d-flex align-items-center"><i class="fa-thin fa-circle bullet-size-10"></i></span>{{ link.label }}</a>
            </li>
            <li>
              <span class="me-2 d-flex align-items-center"><i class="fa-thin fa-circle bullet-size-10"></i></span> <span>Close GHG Period</span>
            </li>
           </ul>
        </div>
          </div>
      </div>

    </div>
  </div>

  <!-- Edit GHG Year Model -->
  <teleport to="#modal-area">
    <div class="modal fade" tabindex="-1" aria-labelledby="editSourceModal" id="edit_year_modal" aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title my-0" id="editSourceModal">Update Year</h5>
            <button type="button" class="btn-close" @click="cancel('year')" aria-label="Close"></button>
          </div>

          <div class="modal-body">

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Name</label>
              <input class="form-control form-control-sm" :class="{'is-invalid': formGhgYearErrors.name}" aria-label="Name" type="text" name="name" v-model="editYearForm.name"/>
              <div class="invalid-feedback" v-if="formGhgYearErrors.name">
                {{ formGhgYearErrors.name }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Start Date</label>
              <input class="form-control form-control-sm" aria-label="Start Date" :class="{'is-invalid': formGhgYearErrors.start_date}" type="date" name="start_date" v-model="editYearForm.start_date" />
              <div class="invalid-feedback" v-if="formGhgYearErrors.start_date">
                {{ formGhgYearErrors.start_date.toString() }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>End Date</label>
              <input class="form-control form-control-sm" aria-label="End Date" :class="{'is-invalid': formGhgYearErrors.end_date}" type="date" name="end_date" v-model="editYearForm.end_date" />
              <div class="invalid-feedback" v-if="formGhgYearErrors.end_date">
                {{ formGhgYearErrors.end_date.toString() }}
              </div>
            </div>

            <div class="form-check mt-2">
              <input class="form-check-input" type="checkbox" value="" id="create_base_year" v-model="editYearForm.base_year">
              <label class="form-check-label" for="create_base_year">
                Is base year?
              </label>
              <div class="text-danger" v-if="formGhgYearErrors.base_year">
                {{ formGhgYearErrors.base_year }}
              </div>
            </div>

          </div>
          <div class="modal-footer d-flex justify-content-between">
            <div class="d-flex justify-content-start">

            </div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-light me-2" @click="cancel('year')" :disabled="modal_loading">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-arrow-circle-left"></i></span>
                <span>Cancel</span>
              </button>
              <button type="button" class="btn btn-primary" :disabled="modal_loading" @click="updateYear">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-save"></i></span>
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <!-- Edit GHG Month Model -->
  <teleport to="#modal-area">
    <div class="modal fade" tabindex="-1" aria-labelledby="editSourceModal" id="edit_month_modal" aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title my-0" id="editSourceModal">Update Month</h5>
            <button type="button" class="btn-close" @click="cancel('month')" aria-label="Close"></button>
          </div>

          <div class="modal-body">

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Netsuite Accounting Period</label>
              <select class="form-select form-select-sm" aria-label="Netsuite Accounting Period" v-model="editMonthForm.ns_period" :class="{'is-invalid': formGhgMonthErrors.ns_period}" @change="preselectMonthDates">
                <option value="" selected>--</option>
                <option v-for="(ns_period, index) in ns_periods" :value="ns_period.id" :key="index">{{ ns_period.name }}</option>
              </select>
              <div class="invalid-feedback" v-if="formGhgMonthErrors.ns_period">
                {{ formGhgMonthErrors.ns_period.toString() }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Parent Period</label>
              <select class="form-select form-select-sm" aria-label="Parent Period" v-model="editMonthForm.parent" :class="{'is-invalid': formGhgMonthErrors.parent}">
                <option value="" selected>--</option>
                <option v-for="(accounting_p, index) in accounting_periods" :value="accounting_p.id" :key="index">{{ accounting_p.name }}</option>
              </select>
              <div class="invalid-feedback" v-if="formGhgMonthErrors.parent">
                {{ formGhgMonthErrors.parent.toString() }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Name</label>
              <input class="form-control form-control-sm" aria-label="Name" :class="{'is-invalid': formGhgMonthErrors.name}" type="text" name="name" v-model="editMonthForm.name" />
              <div class="invalid-feedback" v-if="formGhgMonthErrors.name">
                {{ formGhgMonthErrors.name.toString() }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Start Date</label>
              <input class="form-control form-control-sm" aria-label="Start Date" :class="{'is-invalid': formGhgMonthErrors.start_date}" type="date" name="start_date" v-model="editMonthForm.start_date" />
              <div class="invalid-feedback" v-if="formGhgMonthErrors.start_date">
                {{ formGhgMonthErrors.start_date.toString() }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>End Date</label>
              <input class="form-control form-control-sm" aria-label="End Date" :class="{'is-invalid': formGhgMonthErrors.end_date}" type="date" name="end_date" v-model="editMonthForm.end_date" />
              <div class="invalid-feedback" v-if="formGhgMonthErrors.end_date">
                {{ formGhgMonthErrors.end_date.toString() }}
              </div>
            </div>


          </div>
          <div class="modal-footer d-flex justify-content-between">
            <div class="d-flex justify-content-start">

            </div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-light me-2" @click="cancel('month')" :disabled="modal_loading">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-arrow-circle-left"></i></span>
                <span>Cancel</span>
              </button>
              <button type="button" class="btn btn-primary" :disabled="modal_loading" @click="updateMonth">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-save"></i></span>
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {onMounted, ref, computed} from "vue";
import {useRouter, useRoute} from "vue-router";
import {useRestletStore} from "@/stores/restlets";
import {useLinkStore} from "@/stores/links";
import {storeToRefs} from "pinia";
import LoadingIndicator from "@/components/global/LoadingIndicator.vue";
import axios from "axios";

export default {

  components: {
    LoadingIndicator,
  },

  props: {
    ns_periods: Object,
    accounting_periods: Object
  },
  emits: ["periodsUpdated"],

  setup(props, {emit})
  {
    const router = useRouter();
    const route = useRoute();
    const restlet_store = useRestletStore();
    const {period_restlet_url} = storeToRefs(restlet_store);
    const link_store = useLinkStore();
    const {links} = storeToRefs(link_store);

    const loading_period = ref(false);
    const accounting_period = ref({});
    
    // Edit GHG Year
    const editYearForm = ref({});
    const formGhgYearErrors = ref({});
    const edit_year_modal = ref(null);
    const modal_loading = ref(false);

    // Edit GHG Month(Child)
    const editMonthForm = ref({});
    const formGhgMonthErrors = ref({});
    const edit_month_modal = ref(null);

    const ghg_checklist_links = computed(() => {
      return links.value.filter(l => {
        return l.location === 'ghg_period_checklist';
      });
    });

    function lockPeriod()
    {
      let message = 'Are you sure you want to lock this period?';
      let confirmed = confirm(message);

      if(confirmed)
      {
        let params = {};
        let form = {id: accounting_period.value.id, action: 'lock'};

        let query = new URLSearchParams(params).toString();
        let endpoint = period_restlet_url.value + '&' + query;

        axios.put(endpoint, form)
            .then(response => {

              if(response.data['status'] === 'success')
              {
                getPeriod();
                emit('periods-updated');
              }
            });
      }
    }

    function closePeriod()
    {
      let message = 'Are you sure you want to close this period?';
      let confirmed = confirm(message);

      if(confirmed)
      {
        let params = {};
        let form = {id: accounting_period.value.id, action: 'close'};

        let query = new URLSearchParams(params).toString();
        let endpoint = period_restlet_url.value + '&' + query;

        axios.put(endpoint, form)
            .then(response => {

              if(response.data['status'] === 'success')
              {
                getPeriod();
                emit('periods-updated');
              }
            });
      }
    }

    function reopenPeriod()
    {
      let message = 'Are you sure you want to re-open this period?';
      let confirmed = confirm(message);

      if(confirmed)
      {
        let params = {};
        let form = {id: accounting_period.value.id, action: 'reopen'};

        let query = new URLSearchParams(params).toString();
        let endpoint = period_restlet_url.value + '&' + query;

        axios.put(endpoint, form)
            .then(response => {

              if(response.data['status'] === 'success')
              {
                getPeriod();
                emit('periods-updated');
              }
            });
      }
    }

    function refreshPeriods()
    {
      emit('periods-updated');
    }

    function getPeriod()
    {
      loading_period.value = true;
      const ghg_period_id_param = route.params.child_period || route.params.parent_period;
      let params = {query_type: 'single_period', ghg_period_id: ghg_period_id_param};

      let query = new URLSearchParams(params).toString();
      let endpoint = period_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            loading_period.value = false;
            accounting_period.value = response.data['ghg_period'];
            accounting_period.value.start_date = new Date(accounting_period.value.start_date).toDateString();
            accounting_period.value.end_date = new Date(accounting_period.value.end_date).toDateString();
          })
          .catch(err => {
            loading_period.value = false;
          });
    }
    
    //Edit GHG YEAR
    function dateFormat(date_val){
      if(!date_val){
        return "";
      }
      var date = new Date(date_val); // Or your date here
      var day = ('0' + date.getDate()).slice(-2);
      var month = ('0' + (date.getMonth() + 1)).slice(-2);
      var year = date.getFullYear();

      return year + '-' + month + '-' + day;
    }

    function editGhgYear(){

      if (accounting_period.value.parentid){
          //  GHG Month
          editMonthForm.value = accounting_period.value;
          editMonthForm.value.parent = editMonthForm.value.parentid;
          editMonthForm.value.ns_period = editMonthForm.value.ns_acc_period;
          editMonthForm.value.start_date = dateFormat(editMonthForm.value.start_date);
          editMonthForm.value.end_date = dateFormat(editMonthForm.value.end_date);
          toggleEditMonthModal();
      } else {
        //GHG Year
         editYearForm.value = accounting_period.value;
         editYearForm.value.start_date = dateFormat(editYearForm.value.start_date);
         editYearForm.value.end_date = dateFormat(editYearForm.value.end_date);
         toggleEditYearModal();
      }

    }

    function toggleEditYearModal()
    {
      edit_year_modal.value.toggle();
    }

    function toggleEditMonthModal()
    {
      edit_month_modal.value.toggle();
    }

    function cancel(mode)
    {
      if(mode === 'month')
      {
        toggleEditMonthModal();
        formGhgMonthErrors.value = {};
      }

      else if(mode === 'year')
      {
        toggleEditYearModal();
        formGhgYearErrors.value = {};
      }
    }

    function updateYear(){
      let errors = 0;

      if (!editYearForm.value.name) {
        formGhgYearErrors.value.name = 'The name is required';
        errors++;
      }

      if (!editYearForm.value.start_date) {
        formGhgYearErrors.value.start_date = 'The start date is required';
        errors++;
      }

      if (!editYearForm.value.end_date) {
        formGhgYearErrors.value.end_date = 'The end date is required';
        errors++;
      }

      if(errors == 0){
        modal_loading.value = true;
        let form = editYearForm.value;
        console.log(form);

        //call api for update ghg year
      }
    }
    //End Edit GHG YEAR

    //Edit GHG month
    function preselectMonthDates()
    {
      let ns_period = props.ns_periods.find(nsp => {
        return nsp.id === editMonthForm.value.ns_acc_period;
      });

      if(ns_period)
      {
        editMonthForm.value.name = ns_period.name;
        editMonthForm.value.start_date = dateFormat(ns_period.start_date);
        editMonthForm.value.end_date = dateFormat(ns_period.end_date);
      }
    }

    function updateMonth(){
      let errors = 0;

      if (!editMonthForm.value.ns_period) { 
        formGhgMonthErrors.value.ns_period = 'The Netsuite period is required';
        errors++;
      }

      if (!editMonthForm.value.parent) {
        formGhgMonthErrors.value.parent = 'The parent period is required';
        errors++;
      }

      if (!editMonthForm.value.name) {
        formGhgMonthErrors.value.name = 'The name is required';
        errors++;
      }

      if (!editMonthForm.value.start_date) {
        formGhgMonthErrors.value.start_date = 'The start date is required';
        errors++;
      }

      if (!editMonthForm.value.end_date) {
        formGhgMonthErrors.value.end_date = 'The end date is required';
        errors++;
      }

      if(errors == 0){
        modal_loading.value = true;
        let form = editMonthForm.value;
        console.log(form);

        //call api for update ghg month
      }
    }
    //End Edit GHG Month

    onMounted(() => {

      getPeriod();
      edit_year_modal.value = new bootstrap.Modal(document.getElementById('edit_year_modal'));
      edit_month_modal.value = new bootstrap.Modal(document.getElementById('edit_month_modal'));
    });

    return {
      router,
      route,
      loading_period,
      accounting_period,
      lockPeriod,
      closePeriod,
      reopenPeriod,
      refreshPeriods,
      ghg_checklist_links,
      editGhgYear,
      editYearForm,
      formGhgYearErrors,
      edit_year_modal,
      updateYear,
      toggleEditYearModal,
      cancel,
      modal_loading,
      editMonthForm,
      formGhgMonthErrors,
      edit_month_modal,
      preselectMonthDates,
      updateMonth,
    }
  }
}
</script>

<style scoped>
.badge-lg{
  font-size: 1.125rem;
}
.list-wrapper {
    border: 1px solid #ccc;
    margin-top: 1rem;
    padding: 0.6rem;
    min-height: 300px;
}
.list-wrapper > ul{
  margin:0 !important;
  padding:0 !important;
  margin-top:1rem !important;
}
.list-wrapper > ul > li{
  padding-bottom: 10px;
  list-style: none;
  display: flex;
  padding-left: 5px;
}
.list-wrapper > ul > .anchor-link > a{
  text-decoration: none;
  color:blue;
  display: flex;
  font-size: 0.9rem !important;
}
.bullet-size-10{
  font-size: 10px !important;
  color: #000 !important;
}
.custom-height{
  height: calc(100vh - 40px);
}
</style>