<template>
  <table v-if="company_information !== undefined">
    <tr>
      <th class="text-center" colspan="3">
        Greenhouse Gas Emissions Inventory Report
      </th>
    </tr>
    <tr>
      <td v-if="filterData?.inventoryYear !== undefined" class="text-center" colspan="3">
        {{ filterData.inventoryYear}}
      </td>
      <td v-else class="text-center" colspan="3" >
       {{company_information.inventory_year }}
      </td>
    </tr>
    <tr>
      <td></td>
      <td>{{ company_information.company_name }}</td>
      <td class="padd-0" rowspan="2">
        <table class="no-border year-range">
          <tr>
           <td>&nbsp;</td>
           <td>&nbsp;</td>
           <td>&nbsp;</td>
            <td rowspan="2">
              <img :src="company_information.company_logo" alt="companyLogo" style="height: 40px" />
            </td>
          </tr>
          <tr>
              <td>{{filterData?.fromDate}}</td>
              <td class="text-center">To</td>
              <td>{{filterData?.toDate}}</td>
          </tr>
        </table>
       </td>
    </tr>
    <tr>
      <td></td>
      <td>Reporting Period</td>
    </tr>
    <template v-for="(values, index) in company_information.data" :key="index">
      <tr v-if="index === 0" class="table-header">
        <th v-for="(headerName,hIndex) in Object.keys(values)" :key="hIndex" :style="{ 'width': headerWidth(hIndex) + '%' }">
          {{ headerName }}
        </th>
      </tr>
      <tr>
        <th>{{ values.section }}</th>
        <td class="padd-0 descp " v-if="typeof values.descriptions === 'object'">
          <table class="no-border">
            <tr v-for="(name, descIndex) in values.descriptions" :key="descIndex">
              <td>{{ name }}</td>
            </tr>
          </table>
        </td>
        <td v-else class="descp">{{ values.descriptions }}</td>
        <td class="padd-0" v-if="typeof values.details === 'object'">
          <table class="no-border with-header">
            <template v-for="(value, detailIndex) in values.details" :key="detailIndex">
              <tr v-if="detailIndex === 0">
                <th className="bg-color">{{ value.name }}</th>
                <th className="bg-color">{{ value.parent }}</th>
                <th className="bg-color">{{ value.organizational_boundary }}</th>
                <th className="bg-color">{{ value.equity_share }}</th>
                <th className="bg-color">{{ value.reporting_company }}</th>
                <th className="bg-color">{{ value.operational_control }}</th>
              </tr>
              <tr v-else>
                <td>{{ value.name }}</td>
                <td>{{ value.parent }}</td>
                <td>{{ value.organizational_boundary }}</td>
                <td>{{ value.equity_share }}</td>
                <td>{{ value.reporting_company }}</td>
                <td>{{ value.operational_control }}</td>
              </tr>
            </template>
          </table>
        </td>
        <td v-else>{{ values.details }}</td>
      </tr>
    </template>
  </table>
</template>

<script>
import headerWidth from "@/helpers/report";
export default {
  props: {
    filterData:Object,
    company_information: Object
  },
  methods: {
    headerWidth
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #000;
  font-family: "docs-Tahoma", Arial;
  font-size: 10pt;
  word-break: break-word;
  height:100%;
}
td,
th {
  padding: 6px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}
th:first-letter{
  text-transform: capitalize;
}
td:first-child,
th:first-child {
  border-left: 1px solid #000;
}
.bg-color {
  background-color: #46bdc6;
}

td.descp {
  width: 25%;
}
.no-border {
  border: 0;
}
.padd-0{
    padding:0;
}
 td > .no-border:not(.with-header) td{
    border-left: 0;
    border-right: 0;
 }
 td > .no-border > tr:first-child td,
 td > .no-border > tr:first-child th{
    border-top:0;
    border-left: 0;
 }

 td > .with-header td{
    border-left: 0;
 }
 td > .with-header tr >  td:last-child,
 td > .with-header tr >  th:last-child{
    border-right: 0;
 }
 .table-header {
  background-color: #9fc5e8;
}
.table-header > th {
  font-size: 14px;
}

th {
  font-weight: bold;
}
table.no-border.year-range > tr >  td:first-child {
    border-left:0;
}
table.no-border.year-range > tr >  td:nth-child(2) {
  width: 10%;
}
table.no-border.year-range > tr > td {
    width: 30%;
    border-left: 1px solid #000;
}

td {
  word-wrap: break-word;
}
</style>
