<template>
 <div class="modal fade" tabindex="-1" aria-labelledby="createSourceModal" id="create_modal" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title my-0 fs-5" id="createSourceModal">New Emission</h5>
            <button type="button" class="btn-close" aria-label="Close" @click="toggleCreateModal()"></button>
          </div>

          <loading-indicator v-if="loading_transactions"></loading-indicator>
          <div class="modal-body" v-else>
            <h5 class="mt-0 mb-2 fs-5">Main</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Date</label>
                  <input class="form-control form-control-sm" aria-label="Date" :class="{'is-invalid': errors.date}" type="date" name="date" v-model="createForm.date" @change="selectPeriod('create')" />
                  <div class="invalid-feedback" v-if="errors.date">
                    {{ errors.date.toString() }}
                  </div>
                </div>

                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Description</label>
                  <input class="form-control form-control-sm" aria-label="Description" :class="{'is-invalid': errors.altname}" type="text" name="altname" v-model="createForm.altname" />
                  <div class="invalid-feedback" v-if="errors.altname">
                    {{ errors.altname.toString() }}
                  </div>
                </div>
                
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Emission Category</label>
                  <select class="form-select form-select-sm" aria-label="Emission Category" v-model="createForm.category_id" :class="{'is-invalid': errors.category_id}" disabled :style="disabledInputCss(true)">
                    <option value="" selected>--</option>
                    <option v-for="(category, index) in emission_categories" :value="category.id" :key="index">{{ category.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.category_id">
                    {{ errors.category_id.toString() }}
                  </div>
                </div>

                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Scope</label>
                  <select class="form-select form-select-sm" aria-label="Scope" v-model="createForm.scope_id" :class="{'is-invalid': errors.scope_id}" disabled :style="disabledInputCss(true)" >
                    <option value="" selected>--</option>
                    <option v-for="(scope, index) in scopes" :value="scope.id" :key="index">{{ scope.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.scope_id">
                    {{ errors.scope_id.toString() }}
                  </div>
                </div>

                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Emission Source</label>
                  <input class="form-control form-control-sm" aria-label="Source" :class="{'is-invalid': errors.source_id}" type="text" name="source_name" :value="emission_source.name" disabled :style="disabledInputCss(true)" />
                  <div class="invalid-feedback" v-if="errors.source_id">
                    {{ errors.source_id.toString() }}
                  </div>
                </div>

                <div class="mt-2">
                  <label class="form-label">Data Source</label>
                  <input class="form-control form-control-sm" aria-label="Data Source" :class="{'is-invalid': errors.data_source}" type="text" name="data_source" v-model="createForm.data_source" />
                  <div class="invalid-feedback" v-if="errors.data_source">
                    {{ errors.data_source.toString() }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>GHG Period</label>
                  <select class="form-select form-select-sm" aria-label="GHG Period" v-model="createForm.period_id" :class="{'is-invalid': errors.period_id}" disabled :style="disabledInputCss(true)">
                    <option value="" selected>--</option>
                    <option v-for="(period, index) in periods" :value="period.id" :key="index">{{ period.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.period_id">
                    {{ errors.period_id.toString() }}
                  </div>
                </div>

                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Type</label>
                  <input class="form-control form-control-sm" aria-label="Description" value="Emission" type="text" name="altname" disabled :style="disabledInputCss(true)" />
                </div>

                <div class="mt-2" v-if="enabled_features.subsidiaries">
                  <label class="form-label"><span class="text-danger me-1">*</span>Subsidiary</label>
                  <select class="form-select form-select-sm" aria-label="Subsidiary" v-model="createForm.subsidiary_id" :class="{'is-invalid': errors.subsidiary_id}">
                    <option value="" selected>--</option>
                    <option v-for="(subsidiary, sub_index) in subsidiaries" :value="subsidiary.id" :key="sub_index">{{ subsidiary.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.subsidiary_id">
                   {{ errors.subsidiary_id.toString() }}
                  </div>
                </div>
                
                <div class="mt-2">
                  <label class="form-label">NetSuite Transaction</label>
                  <input class="form-control form-control-sm" aria-label="NetSuite Transaction" v-model="createForm.netsuite_transaction" type="text" name="netsuite_transaction" disabled :style="disabledInputCss(true)" />
                </div>

              </div>
            </div>
            <h5 class="mt-4 mb-2 fs-5">Activity Data</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Calculation Approach</label>
                  <select
                    class="form-select form-select-sm"
                    aria-label="Calculation Approach"
                    v-model="createForm.calculation_approach_id"
                    :class="{'is-invalid': errors.calculation_approach_id}"
                    :disabled="calculationApproachIsLocked()">
                    <option value="" selected>--</option>
                    <option v-for="(calculation_approach, index) in calculation_approaches" :value="calculation_approach.id" :key="index">{{ calculation_approach.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.calculation_approach_id">
                    {{ errors.calculation_approach_id.toString() }}
                  </div>
                </div>

                <div class="mt-2" v-if="activityTypeFieldIsVisible('create')">
                  <label class="form-label">
                    <span class="text-danger me-1" v-if="activity_types.length > 0">*</span>Activity Type
                  </label>
                  <select class="form-select form-select-sm" aria-label="Amount" v-model="createForm.activity_type_id" :class="{'is-invalid': errors.activity_type_id}" :disabled="activity_types.length < 1" @change="resetSourchFuelValue('create')">
                    <option value="" selected>--</option>
                    <option v-for="(activity_type, index) in activity_types" :value="activity_type.id" :key="index">{{ activity_type.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.activity_type_id">
                    {{ errors.activity_type_id.toString() }}
                  </div>
                </div>

                <div class="mt-2" v-if="isStandardEmissionFactors('create')">
                  <label class="form-label">Emission Factor Database</label>
                  <select class="form-select form-select-sm" aria-label="Emission Factor Database" v-model="createForm.emission_database_id" :class="{'is-invalid': errors.emission_database_id}" @change="resetSourchFuelValue('create')">
                    <option value="" selected>--</option>
                    <option v-for="(emission_database, index) in emission_databases" :value="emission_database.id" :key="index">{{ emission_database.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.emission_database_id">
                    {{ errors.emission_database_id.toString() }}
                  </div>
                </div>

                <div class="mt-2" v-if="isStandardEmissionFactors('create')">
                  <label class="form-label"><span class="text-danger me-1">*</span>Source Type</label>
                  <select class="form-select form-select-sm" aria-label="Source Type" v-model="createForm.source_type_id" :class="{'is-invalid': errors.source_type_id}">
                    <option value="" selected>--</option>
                    <option v-for="(source_type, index) in filteredSourceTypes('create')" :value="source_type.id" :key="index">{{ source_type.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.source_type_id">
                    {{ errors.source_type_id.toString() }}
                  </div>
                </div>

                <div class="mt-2" v-if="fuelRelatedFieldIsVisible('create', emission_source.category_id)">
                  <label class="form-label"><span class="text-danger me-1">*</span>Fuel Type</label>
                  <select class="form-select form-select-sm" aria-label="Fuel Type" v-model="createForm.fuel_type_id" :class="{'is-invalid': errors.fuel_type_id}">
                    <option value="" selected>--</option>
                    <option v-for="(fuel_type, index) in filteredFuelTypes('create')" :value="fuel_type.id" :key="index">{{ fuel_type.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.fuel_type_id">
                    {{ errors.fuel_type_id.toString() }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="mt-2" v-if="requiresCustomEmissionFactor(createForm.calculation_approach_id)">
                  <label class="form-label"><span class="text-danger me-1">*</span>Custom Emission Factor</label>
                  <select class="form-select form-select-sm" aria-label="Custom Emission Factor" v-model="createForm.custom_emission_factor_id" :class="{'is-invalid': errors.custom_emission_factor_id}">
                    <option value="" selected>--</option>
                    <option v-for="(custom_emission_factor, index) in custom_emission_factors" :value="custom_emission_factor.id" :key="index">{{ custom_emission_factor.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.custom_emission_factor_id">
                    {{ errors.custom_emission_factor_id.toString() }}
                  </div>
                </div>

                <div class="mt-2" v-if="fuelRelatedFieldIsVisible('create', emission_source.category_id)">
                  <label class="form-label"><span v-if="fuelEfficiencyFieldIsRequired('create')" class="text-danger me-1">*</span>Fuel Efficiency</label>
                  <input
                      class="form-control form-control-sm"
                      aria-label="Fuel Efficiency"
                      :class="{'is-invalid': errors.fuel_efficiency}"
                      type="number"
                      name="fuel_efficiency"
                      v-model="createForm.fuel_efficiency"
                      :disabled="fuelEfficiencyFieldIsDisabled('create')"/>
                  <div class="invalid-feedback" v-if="errors.fuel_efficiency">
                    {{ errors.fuel_efficiency.toString() }}
                  </div>
                </div>

                <div class="mt-2" v-if="fuelRelatedFieldIsVisible('create', emission_source.category_id)">
                  <label class="form-label"><span v-if="fuelEfficiencyFieldIsRequired('create')" class="text-danger me-1">*</span>Fuel Efficiency Unit</label>
                  <select
                      class="form-select form-select-sm"
                      aria-label="Fuel Efficiency Unit"
                      v-model="createForm.fuel_efficiency_unit_id"
                      :class="{'is-invalid': errors.fuel_efficiency_unit_id}"
                      :disabled="fuelEfficiencyFieldIsDisabled('create')">
                    <option value="" selected>--</option>
                    <option v-for="(unit, index) in getVisibleEfficiencyUnits('create')" :value="unit.id" :key="index">{{ unit.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.fuel_efficiency_unit_id">
                    {{ errors.fuel_efficiency_unit_id.toString() }}
                  </div>
                </div>

                <div class="mt-2" v-if="requiresPassengers('create')">
                  <label class="form-label"><span class="text-danger me-1">*</span>Number of Passengers</label>
                  <input class="form-control form-control-sm" aria-label="Number of Passengers" :class="{'is-invalid': errors.passengers}" type="number" step="1" name="passengers" v-model="createForm.passengers" />
                  <div class="invalid-feedback" v-if="errors.passengers">
                    {{ errors.passengers.toString() }}
                  </div>
                </div>

                <div class="mt-2" v-if="shippingUnitFieldIsRequired('create')">
                  <label class="form-label"><span class="text-danger me-1">*</span>Shipping Weight</label>
                  <input type="number" class="form-control form-control-sm" :class="{'is-invalid': errors.shipping_weight}" aria-label="Shipping Weight" name="shipping_weight" v-model="createForm.shipping_weight" />
                  <div class="invalid-feedback" v-if="errors.shipping_weight">
                    {{ errors.shipping_weight.toString() }}
                  </div>
                </div>

                <div class="mt-2" v-if="shippingUnitFieldIsRequired('create')">
                  <label class="form-label"><span class="text-danger me-1">*</span>Shipping Weight Unit</label>
                  <select class="form-select form-select-sm" :class="{'is-invalid': errors.shipping_weight_unit_id}"    name="shipping_weight_unit_id" aria-label="Shipping Weight Unit" v-model="createForm.shipping_weight_unit_id">
                    <option value="" selected>--</option>
                    <option v-for="(unit, index) in filterShippingWeightUnits('create')" :value="unit.id" :key="index">{{ unit.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.shipping_weight_unit_id">
                    {{ errors.shipping_weight_unit_id.toString() }}
                  </div>
                </div>

              </div>
            </div>

            <h5 class="mt-4 mb-2 fs-5">Amount</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Amount</label>
                  <input class="form-control form-control-sm" aria-label="Amount" :class="{'is-invalid': errors.amount}" type="number" name="amount" v-model="createForm.amount" />
                  <div class="invalid-feedback" v-if="errors.amount">
                    {{ errors.amount.toString() }}
                  </div>
                </div>

                <div class="mt-2" v-if="requiresRefrigerantSource('create') || requiresGas(createForm.calculation_approach_id || '')">
                  <label class="form-label"><span class="text-danger me-1">*</span>{{ getGasFieldName('create') }}</label>
                  <select class="form-select form-select-sm" aria-label="Refrigerant Source" v-model="createForm.gas_id" :class="{'is-invalid': errors.gas_id}">
                    <option value="" selected>--</option>
                    <option v-for="(refrigerant_source, index) in refrigerant_sources" :value="refrigerant_source.id" :key="index">{{ refrigerant_source.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.gas_id">
                    {{ errors.gas_id.toString() }}
                  </div>
                </div>

              </div>

              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Unit</label>
                  <select class="form-select form-select-sm" aria-label="Unit" v-model="createForm.unit_id" :class="{'is-invalid': errors.unit_id}">
                    <option value="" selected>--</option>
                    <option v-for="(unit, index) in getVisibleUnits('create')" :value="unit.id" :key="index">{{ unit.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="errors.unit_id">
                    {{ errors.unit_id.toString() }}
                  </div>
                </div>

              </div>
            </div>

            <h5 class="mt-4 mb-2 fs-5">Region</h5>
            <div class="row mb-4">
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span><i class="fa-light fa-search"></i><span class="ms-2">Country</span></label>
                  <country-selection @country-selected="selectCountry('create', 'actual_country', $event)" ref="actual_country_create_selector" :initial_value="createForm.actual_country"></country-selection>
                  <div class="text-danger" v-if="errors.actual_country">
                    {{ errors.actual_country.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(createForm.category_id)">*</span><i class="fa-light fa-search"></i><span class="ms-2">State / Region / Province</span></label>
                  <region-selection :disabled="!createForm.actual_country" :country="createForm.actual_country" @region-selected="selectRegion('create', 'actual_state', $event)" ref="actual_region_create_selector" :initial_value="createForm.actual_state"></region-selection>
                  <div class="text-danger" v-if="errors.actual_state">
                    {{ errors.actual_state.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(createForm.category_id)">*</span>Zip / Postal Code</label>
                  <input class="form-control form-control-sm" aria-label="Name" :class="{'is-invalid': errors.zip_code}" type="text" name="zip_code" v-model="createForm.zip_code" />
                  <div class="text-danger" v-if="errors.zip_code">
                    {{ errors.zip_code.toString() }}
                  </div>
                </div>

              </div>
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span><i class="fa-light fa-search"></i><span class="ms-2">Emission Factor Country</span></label>
                  <country-selection @country-selected="selectCountry('create', 'country', $event)" ref="country_create_selector" :initial_value="createForm.country"></country-selection>
                  <div class="text-danger" v-if="errors.country">
                    {{ errors.country.toString() }}
                  </div>
                </div>
              
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(createForm.category_id)">*</span><i class="fa-light fa-search"></i><span class="ms-2">Emission Factor State / Region / Province</span></label>
                  <region-selection :disabled="!createForm.country" :country="createForm.country" @region-selected="selectRegion('create', 'region', $event)" ref="region_create_selector" :initial_value="createForm.region"></region-selection>
                  <div class="text-danger" v-if="errors.region">
                    {{ errors.region.toString() }}
                  </div>
                </div>
                
              </div>
            </div>
      
            <a class="mb-2 fs-5 collapse-heading" data-bs-toggle="collapse" href="#classification" role="button" aria-expanded="false" aria-controls="classification" @click="toggleCollapse('create', 'classification')">
             <h5 class="mt-1 mb-2 fs-5"><span class="fs-5 fw-bold me-2"><i class="fa " :class="classification_toggle?'fa-plus':'fa-minus'" aria-hidden="true"></i></span>
              Classification</h5> 
            </a>
            <div class="collapse" id="classification">
              <div class="row">
                <div class="col">
                  <div class="mt-2" v-if="enabled_features.locations">
                    <label class="form-label">Location</label>
                    <select class="form-select form-select-sm" aria-label="Location" v-model="createForm.location_id" :class="{'is-invalid': errors.location_id}" @change="updateLocationParams('create')">
                      <option value="" selected>--</option>
                      <option v-for="(location, index) in locations" :value="location.id" :key="index">{{ location.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="errors.location_id">
                      {{ errors.location_id.toString() }}
                    </div>
                  </div>
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Expense Category</span></label>
                    <list-record-selection record_type="expense_categories" @record-selected="selectListRecord('create', 'expense_category_id', $event)" ref="expense_category_create_selector" :initial_value="emission_source.expense_category_name"></list-record-selection>
                    <div class="text-danger" v-if="errors.expense_category_id">
                      {{ errors.expense_category_id.toString() }}
                    </div>
                  </div>
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Vendor</span></label>
                    <entity-selection entity_type="vendors" @entity-selected="selectEntity('create', 'vendor_id', $event)" ref="vendor_create_selector" :initial_value="createForm.vendor_name"></entity-selection>
                    <div class="text-danger" v-if="errors.vendor_id">
                      {{ errors.vendor_id.toString() }}
                    </div>
                  </div>
                  <div class="mt-2" v-if="enabled_features.partners">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Partner</span></label>
                    <entity-selection entity_type="partners" @entity-selected="selectEntity('create', 'partner_id', $event)" ref="partner_create_selector" :initial_value="createForm.partner_name"></entity-selection>
                    <div class="text-danger" v-if="errors.partner_id">
                      {{ errors.partner_id.toString() }}
                    </div>
                  </div>
                  
                  <div class="mt-2" v-if="enabled_features.projects">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Project</span></label>
                    <entity-selection entity_type="projects" @entity-selected="selectEntity('create', 'project_id', $event)" ref="project_create_selector" :initial_value="createForm.project_name"></entity-selection>
                    <div class="text-danger" v-if="errors.project_id">
                      {{ errors.project_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label"><span class="ms-2">Asset</span></label>

                    <input type="search" class="form-control form-control-sm" v-model="createForm.fixed_asset_name">

                    <!-- <segment-selection segment_type="fixed_assets" @segment-selected="selectSegment('create', 'fixed_asset_id', $event)" ref="fixed_asset_create_selector"></segment-selection>
                    <div class="text-danger" v-if="errors.fixed_asset_id">
                      {{ errors.fixed_asset_id.toString() }}
                    </div> -->
                  </div>

                </div>

                <div class="col">
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Item</span></label>
                    <list-record-selection record_type="items" @record-selected="selectListRecord('create', 'item_id', $event)" ref="item_create_selector" :initial_value="emission_source.item_name"></list-record-selection>
                    <div class="text-danger" v-if="errors.item_id">
                      {{ errors.item_id.toString() }}
                    </div>
                  </div>
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Account</span></label>
                    <list-record-selection record_type="accounts" @record-selected="selectListRecord('create', 'account_id', $event)" ref="account_create_selector" :initial_value="emission_source.account_name"></list-record-selection>
                    <div class="text-danger" v-if="errors.account_id">
                        {{ errors.account_id.toString() }}
                    </div>
                  </div>
                  <div class="mt-2">
                    <label class="form-label">
                      <i class="fa-light fa-search"></i>
                      <span class="ms-2">Employee</span>
                    </label>
                    <entity-selection entity_type="employees" @entity-selected="selectEntity('create', 'employee_id', $event)" ref="employee_create_selector" :initial_value="createForm.employee_name"></entity-selection>
                    <div class="invalid-feedback" v-if="errors.employee_id">
                      {{ errors.employee_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2" v-if="enabled_features.departments">
                    <label class="form-label">Department</label>
                    <select class="form-select form-select-sm" aria-label="Department" v-model="createForm.department_id" :class="{'is-invalid': errors.department_id}">
                      <option value="" selected>--</option>
                      <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="errors.department_id">
                      {{ errors.department_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2" v-if="enabled_features.classes">
                    <label class="form-label">Class</label>
                    <select class="form-select form-select-sm" aria-label="Class" v-model="createForm.class_id" :class="{'is-invalid': errors.class_id}">
                      <option value="" selected>--</option>
                      <option v-for="(classification, index) in classes" :value="classification.id" :key="index">{{ classification.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="errors.class_id">
                      {{ errors.class_id.toString() }}
                    </div>
                  </div>                  

                </div>
              </div>
            </div>
          
            <h5 class="mt-4 mb-2 fs-5">Notes</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2">
                  <textarea class="form-control form-control-sm" aria-label="Notes" name="notes" v-model="createForm.notes" ></textarea>
                </div>
              </div>
            </div>
            
          </div>

          <div class="modal-footer d-flex justify-content-between">
            <div class="d-flex justify-content-start"></div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-light" :disabled="modal_loading" @click="toggleCreateModal()">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-arrow-circle-left"></i></span>
                <span>Cancel</span>
              </button>
              <button type="button" class="btn btn-primary ms-2" :disabled="modal_loading" @click="saveTransaction">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-save"></i></span>
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>   
</template>
<script>
import { nextTick } from 'vue'
import {useRestletStore} from "@/stores/restlets";
import {useAuthStore} from "@/stores/auth";
import {storeToRefs} from "pinia/dist/pinia";
import axios from "axios";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import LoadingIndicator from "@/components/global/LoadingIndicator";
import CountrySelection from "@/components/forms/CountrySelection";
import RegionSelection from "@/components/forms/RegionSelection";
import ListRecordSelection from "@/components/forms/ListRecordSelection";
import EntitySelection from "@/components/forms/EntitySelection";
import SegmentSelection from "@/components/forms/SegmentSelection";
import DisabledLabelCss from "@/helpers/disabledLabelCss";
import EmissionMixin from "@/mixins/emission_transaction_model"

export default {
name: "CreateEmissionModel",

components: {
   LoadingIndicator,
   CountrySelection,
   RegionSelection,
   ListRecordSelection,
   EntitySelection,
   SegmentSelection,
 },
 props: {
    toggleCreateModal: Function,
    getTransactions: Function,
},

 data() {
    const restlet_store = useRestletStore();
    const auth_store = useAuthStore();
    const {enabled_features} = storeToRefs(auth_store);
    const {ghg_transactions_restlet_url} = storeToRefs(restlet_store);
    const {global_unit} = storeToRefs(auth_store);
  
    return {
      ghg_transactions_restlet_url: ghg_transactions_restlet_url,
      global_unit: global_unit,
      enabled_features: enabled_features,
    }
},
mounted(){
    nextTick(() => {        
        this.errors = [];
    });
    
},

methods: {
  disabledInputCss(value=false){
        let is_show = value ? value : false;
        return DisabledLabelCss.disabledLabel(is_show);
  },

  saveTransaction()
    {
      this.errors = [];
      let errors = this.validateForm('create');

      if(errors === 0)
      {
        this.modal_loading = true;
        let source_params = {};
        let form = this.createForm;
        
        let query = new URLSearchParams(source_params).toString();
        let endpoint = this.ghg_transactions_restlet_url + '&' + query;

        axios.post(endpoint, form)
            .then(response => {

              if(response.data['status'] === 'success')
              {
                this.errors = [];
                this.getTransactions();
                this.toggleCreateModal();
                this.modal_loading = false;
                this.classification_toggle = true;
                this.$emit('updatedTransactionId', response.data['emission_transaction_id']);
              }
            })
            .catch(e => {
              this.modal_loading = false;
              if(e.response.data['message'])
              {
                alert(e.response.data['message']);
              }

              else {
                alert('Error');
                toggleCreateModal();
              }
            });
      }
    },
},

mixins: [EmissionMixin],

}

dayjs.extend(utc);
</script>