/** Import boostrap's JS and other core components */
import './utils/bootstrap';

/** Import Core Vue Objects */
import { createApp } from 'vue'
import App from './App.vue'

/** Import Route and State support files **/
import router from './router'

/** Import sass sources */
import './assets/sass/app.scss'

/** Import Pinia for Store Management **/
import {createPinia} from 'pinia';

// import the package
import VueAwesomePaginate from "vue-awesome-paginate";
import './assets/css/pagination.css'

createApp(App).use(router).use(createPinia()).use(VueAwesomePaginate).mount('#app')
