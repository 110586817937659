<template>
  <div class="container-fluid">
    <nav class="navbar bg-light">
      <div class="d-flex justify-content-between w-100">
        <div class="d-flex w-75">
          <router-link :to="{name: 'home'}" class="btn btn-link">
            <span><i class="fa-light fa-chevron-double-left"></i></span>
            <span class="ms-2">Back to Reports</span>
          </router-link>
        </div>
        <div class="d-flex justify-content-end w-100">
          <button class="btn bg-white me-2" type="button" @click="exportTableToExcel">
            <span class="me-2"><i class="fa-light fa-file-excel"></i></span>
            <span>Export to Excel</span>
          </button>
          <button class="btn btn-primary" type="button" @click="toggleReportModal">
              <span>Add Filter</span>
          </button>
        </div>
      </div>
    </nav>
    <loading-indicator v-if="loading"></loading-indicator>
    <div class="row mt-4">
      <div id="root" class="container">
       <ul class="nav nav-tabs report-tab d-md-flex justify-content-start my-0 px-0" style="list-style-type: none;">
        <!-- <li class ="nav-item"> <a @click="handleTabClick($event)" class="nav-link" :class="activetab === 1 ? 'active' : ''" aria-current="page">Company Information</a></li> -->
        <li class ="nav-item"> <a @click="handleTabClick($event)" class="nav-link" :class="activetab === 1 ? 'active' : ''" aria-current="page">Emissions By Gas</a></li>
        <li class ="nav-item"> <a @click="handleTabClick($event)" class="nav-link" :class="activetab === 2 ? 'active' : ''" aria-current="page">Emissions By Category</a></li>
        <li class ="nav-item"> <a @click="handleTabClick($event)" class="nav-link" :class="activetab === 3 ? 'active' : ''" aria-current="page">Emissions By Facility</a></li>
        <!-- <li class ="nav-item"> <a @click="handleTabClick($event)" class="nav-link" :class="activetab === 5 ? 'active' : ''" aria-current="page">Emissions By Country</a></li> -->
        <!-- <li class ="nav-item"> <a @click="handleTabClick($event)" class="nav-link" :class="activetab === 6 ? 'active' : ''" aria-current="page">GHG Inventory Information</a></li> -->
      </ul>
       <div class="report-tab-content">
        <!-- <div class="tab-content" v-if="activetab === 1"><CompanyInformation :filterData="filterData" :company_information="company_information"/></div> -->
        <div class="tab-content" v-if="activetab === 1"><EmissionsByGas :EmissionGas="EmissionGas" /></div>
        <div class="tab-content" v-if="activetab === 2"><EmissionsByCategory :EmissionCategory="EmissionCategory" /></div>
        <div class="tab-content" v-if="activetab === 3"><EmissionsByFacility :EmissionFacility="EmissionFacility" /></div>
        <!-- <div class="tab-content" v-if="activetab === 5"><EmissionsByCountry :EmissionCountry="EmissionCountry" /></div> -->
        <!-- <div class="tab-content" v-if="activetab === 6"><GHGInventoryInformation :InventoryInformation="InventoryInformation" /></div> -->
       </div>

</div>

    </div>
  </div>
  <!--Filter Model-->
  <teleport to="#modal-area">
      <div class="modal fade" tabindex="-1" aria-labelledby="filterReportModal" id="report-modal" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="createSourceModal">Add Filter</h5>
              <button type="button" class="btn-close" aria-label="Close" @click="toggleReportModal"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Operational Boundary</label>
                  <select class="form-select form-select-sm" aria-label="Operational Boundary" multiple="true"
                    v-model="reportForm.report_scopes"
                    :class="{ 'is-invalid': reportForm.errors.report_scopes }">
                    <option v-for="(scope, index) in scopes" :value="scope.id" :key="index">{{ scope.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="reportForm.errors.report_scopes">
                    {{ reportForm.errors.report_scopes.toString() }}
                  </div>
                </div>
                <div class="col mt-2">
                  <label class="form-label">GHG Inventory Year</label>
                  <input class="form-control form-control-sm" aria-label="GHG Inventory Year"
                    :class="{ 'is-invalid': reportForm.errors.chg_inventory_year }" type="number"
                    @change="inventoryChange($event)" v-model="reportForm.chg_inventory_year" />
                  <div class="invalid-feedback" v-if="reportForm.errors.chg_inventory_year">
                    {{ reportForm.errors.chg_inventory_year.toString() }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>GHG Period From</label>
                  <select class="form-select form-select-sm" aria-label="GHG Period From" @change="fromPeriodChangeHandler($event)"
                    v-model="reportForm.chg_period_from" :class="{ 'is-invalid': reportForm.errors.chg_period_from }">
                    <option value="" selected>--</option>
                    <option v-for="(fromPeriods, index) in fromPeriods" :value="fromPeriods.id" :key="index">{{ fromPeriods.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="reportForm.errors.chg_period_from">
                    {{ reportForm.errors.chg_period_from.toString() }}
                  </div>
                </div>
                <div class="col mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>GHG Period To</label>
                  <select class="form-select form-select-sm" aria-label="GHG Period TO" v-model="reportForm.chg_period_to"
                    :class="{ 'is-invalid': reportForm.errors.chg_period_to }">
                    <option value="" selected>--</option>
                    <option v-for="(toPeriods, index) in toPeriods" :value="toPeriods.id" :key="index">{{ toPeriods.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="reportForm.errors.chg_period_to">
                    {{ reportForm.errors.chg_period_to.toString() }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-end">
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-light mr-10" @click="toggleReportModal">
                  <span class="me-2"><i class="fa-light fa-arrow-circle-left"></i></span>
                  <span>Cancel</span>
                </button>
                <button type="button" class="btn btn-primary" @click="submitReportFilter">
                  <span class="me-2"><i class="fa-light fa-save"></i></span>
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
</template>

<script>
import { ref, onMounted, provide } from "vue";
import * as dayjs from "dayjs";
import {useRestletStore} from "@/stores/restlets";
import {storeToRefs} from "pinia";
import writeXlsxFile from 'write-excel-file';
import CompanyInformation from "./CompanyInformation.vue"
import EmissionsByGas from "./EmissionsByGas.vue"
import EmissionsByCategory from "./EmissionsByCategory.vue"
import EmissionsByFacility from "./EmissionsByFacility.vue"
import EmissionsByCountry from "./EmissionsByCountry.vue"
import ExportCompanyInfo from "@/utils/excels/company_informations";
import ExportEmissionGas from "@/utils/excels/emissions_by_gas";
import ExportEmissionCategory from "@/utils/excels/emissions_by_category";
import ExportEmissionFacility from "@/utils/excels/emissions_by_facility";
import ExportEmissionCountry from "@/utils/excels/emissions_by_country";
import ExportEmissionInventory from "@/utils/excels/emissions_by_inventory";
import GHGInventoryInformation from "./GHGInventoryInformation.vue"
import axios from "axios";
import LoadingIndicator from "@/components/global/LoadingIndicator";

export default {
  components: {
    LoadingIndicator,
    CompanyInformation,
    EmissionsByGas,
    EmissionsByCategory,
    EmissionsByFacility,
    EmissionsByCountry,
    GHGInventoryInformation
  },
  setup()
  {
    const restlet_store = useRestletStore();
    const {period_restlet_url, scope_restlet_url, report_restlet_url} = storeToRefs(restlet_store);

    const reportModal = ref(null);
    const reportForm = ref({ errors: [], report_scopes: [] });
    const accountingPeriods = ref([])
    const fromPeriods = ref([])
    const toPeriods = ref([])
    const activetab = ref()
    const filterData = ref({
      inventoryYear:'',
      fromPeriod: '',
      toPeriod: '',
    });
    const company_information = ref([]);
    const scopes = ref([]);
    const EmissionGas = ref([]);
    const EmissionCategory = ref([]);
    const EmissionFacility = ref([]);
    const EmissionCountry = ref([]);
    const InventoryInformation = ref([]);
    var selected_scopes_name = '';
    const loading = ref(false);

    function getScopes()
    {
      let source_params = {};

      let query = new URLSearchParams(source_params).toString();
      let endpoint = scope_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            scopes.value = response.data.scopes;
            loading.value = false;
          })
          .catch(err => {
            console.log(err);
          });
    }

    function toggleReportModal() {
        reportModal.value.toggle();
    }

    function inventoryChange(event) {
        const value = event.target.value;
    }

    function scopeChanged(){
      var selected_scopes = [];
      (reportForm.value.report_scopes).forEach((scope_id, index) => {
        selected_scopes[index]=scopes.value.find(scop=>scop.id==scope_id).name;
      });

      if(selected_scopes.length){
        selected_scopes_name=selected_scopes.join(', ');
        (company_information.value).data[2].details = selected_scopes_name;
      }
    }

    function fromPeriodChangeHandler(event) {
      const selectedFromPeriod = accountingPeriods.value.find(period => period.id == event.target.value)
      toPeriods.value = accountingPeriods.value.filter(period => (dayjs(selectedFromPeriod.end_date) < dayjs(period.start_date) || selectedFromPeriod.id == period.id) )
      delete reportForm.value.chg_period_to;
    }

    function getAccountingPeriods() {

      let params = {query_type: 'child_periods'};

      let query = new URLSearchParams(params).toString();
      let endpoint = period_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            accountingPeriods.value = response.data['ghg_periods'];
            fromPeriods.value = accountingPeriods.value;
            toPeriods.value = accountingPeriods.value;
          })
          .catch(err => {

          });
    }

    function submitReportFilter() {
      let errors = 0;
      reportForm.value.errors = [];
      const form = reportForm.value;
      if (form.report_scopes.length === 0) {
        form.errors['report_scopes'] = 'The operational boundary is mandatory';
        errors++;
      }
      if (!form.chg_inventory_year) {
        form.errors['chg_inventory_year'] = 'The ghg inventory year is mandatory';
        errors++;
      }
      if (!form.chg_period_from) {
        form.errors['chg_period_from'] = 'The ghg period from is mandatory';
        errors++;
      }
      if (!form.chg_period_to) {
        form.errors['chg_period_to'] = 'The ghg period to is mandatory';
        errors++;
      }
      if (form.chg_period_from && form.chg_period_to) {
        const fromEndDate = dayjs(accountingPeriods.value.find(period => period.id == form.chg_period_from).end_date);
        const toEndDate = dayjs(accountingPeriods.value.find(period => period.id == form.chg_period_to).end_date);
        const noOfDays = toEndDate.diff(fromEndDate, 'day');
        if (noOfDays > 365) {
          form.errors['chg_period_to'] = 'GHG Period should not be more than 1 year';
          errors++;
        }
      }
      // console.log(form)
      if (errors === 0) {
        const fromPeriod = accountingPeriods.value.find(fromPeriod =>  fromPeriod.id === form.chg_period_from).name;
        const toPeriod = accountingPeriods.value.find(toPeriod =>  toPeriod.id === form.chg_period_to).name;
        filterData.value = {
          inventoryYear:form?.chg_inventory_year,
          fromPeriod,
          toPeriod,
        }
        toggleReportModal();
        fetchExcelData();
      }
    }

    function handleTabClick(event){
      const currentElm = event.target.parentElement;
      const getIndex = Array.from(currentElm.parentElement.children).indexOf(currentElm) + 1
      activetab.value = getIndex;
    }

    onMounted(() => {
      getScopes();
      getAccountingPeriods();
      reportModal.value = new bootstrap.Modal(document.getElementById('report-modal'));
      toggleReportModal();
      activetab.value = 1;
    })

    function fetchExcelData(){
      loading.value = true;
      let query_data = reportForm.value;
      
      // let company_info_params = {
      //   report_scopes: query_data.report_scopes??'',
      //   inventoryYear:query_data.chg_inventory_year??'',
      //   fromPeriod:query_data.chg_period_from??'',
      //   toPeriod:query_data.chg_period_to??'',
      //   section: 'company_information',
      // };
      // let company_info_query = new URLSearchParams(company_info_params).toString();

      // let companyInfo_endpoint = report_restlet_url.value + '&' + company_info_query;
      // axios.get(companyInfo_endpoint)
      //     .then(response => {
      //       company_information.value = response.data;
      //       scopeChanged();
      //     })
      //     .catch(err => {
      //       console.log('company_info', err);
      //     });  
      

      let em_by_gas_params = {
        report_scopes: query_data.report_scopes??'',
        inventoryYear:query_data.chg_inventory_year??'',
        fromPeriod:query_data.chg_period_from??'',
        toPeriod:query_data.chg_period_to??'',
        section: 'emissions_by_gas',
      };
      let em_by_gas_query = new URLSearchParams(em_by_gas_params).toString();

      let gas_endpoint = report_restlet_url.value + '&' + em_by_gas_query;
      axios.get(gas_endpoint)
          .then(response => {
            EmissionGas.value = response.data;
            loading.value = false;
          })
          .catch(err => {
            console.log(err);
          });  

      
      let em_by_cat_params = {
        report_scopes: query_data.report_scopes??'',
        inventoryYear:query_data.chg_inventory_year??'',
        fromPeriod:query_data.chg_period_from??'',
        toPeriod:query_data.chg_period_to??'',
        section: 'emissions_by_category',
      };
      let em_by_cat_query = new URLSearchParams(em_by_cat_params).toString();
          
      let cat_endpoint = report_restlet_url.value + '&' + em_by_cat_query;
      axios.get(cat_endpoint)
          .then(response => {
            EmissionCategory.value = response.data;
          })
          .catch(err => {
            console.log(err);
          });
      


      let em_by_fac_params = {
        report_scopes: query_data.report_scopes??'',
        inventoryYear:query_data.chg_inventory_year??'',
        fromPeriod:query_data.chg_period_from??'',
        toPeriod:query_data.chg_period_to??'',
        section: 'emissions_by_facility',
      };
      let em_by_fac_query = new URLSearchParams(em_by_fac_params).toString();

      let facility_endpoint = report_restlet_url.value + '&' + em_by_fac_query;
      axios.get(facility_endpoint)
          .then(response => {
            EmissionFacility.value = response.data;
          })
          .catch(err => {
            console.log('Emission by country', err);
          });  


      // let em_by_country_params = {
      //   report_scopes: query_data.report_scopes??'',
      //   inventoryYear:query_data.chg_inventory_year??'',
      //   fromPeriod:query_data.chg_period_from??'',
      //   toPeriod:query_data.chg_period_to??'',
      //   section: 'emissions_by_country',
      // };
      // let em_by_country_query = new URLSearchParams(em_by_country_params).toString();

      // let country_endpoint = report_restlet_url.value + '&' + em_by_country_query;
      // axios.get(country_endpoint)
      //   .then(response => {
      //     EmissionCountry.value = response.data;
      //   })
      //   .catch(err => {
      //     console.log('Emission by country', err);
      //   });
        
        
      // let ghg_inv_params = {
      //   report_scopes: query_data.report_scopes??'',
      //   inventoryYear:query_data.chg_inventory_year??'',
      //   fromPeriod:query_data.chg_period_from??'',
      //   toPeriod:query_data.chg_period_to??'',
      //   section: 'ghg_inventory_information',
      // };
      // let ghg_inv_query = new URLSearchParams(ghg_inv_params).toString();

      // let inventory_endpoint = report_restlet_url.value + '&' + ghg_inv_query;
      // axios.get(inventory_endpoint)
      //   .then(response => {
      //     InventoryInformation.value = response.data;
      //   })
      //   .catch(err => {
      //     console.log('Inventory Information', err);
      //   });  
    }

    function exportTableToExcel() {
      // (company_information.value).inventory_year = reportForm.value.chg_inventory_year;
      // if(filterData.value){
      //   (company_information.value).reporting_period_from = filterData.value.fromPeriod;
      //   (company_information.value).reporting_period_to = filterData.value.toPeriod;
      // }
      // // Excel - Company Information
      // const company_info_sheet = ExportCompanyInfo.companyInfoExcelData(company_information.value);
      // const company_info_sheet_data = company_info_sheet.data;
      // const columns1 = company_info_sheet.columns;
      // Excel - Emission by Gas
      const emissions_by_gas_sheet = ExportEmissionGas.emissionsGasExcelData(EmissionGas.value);
      const emmision_by_gas_data_sheet_data = emissions_by_gas_sheet.data;
      const columns2 = emissions_by_gas_sheet.columns;
      // Excel - Emission by Category
      const emissions_by_cat_sheet = ExportEmissionCategory.emissionsCatData(EmissionCategory.value);
      const emissions_cat_sheet_data = emissions_by_cat_sheet.data;
      const columns3 = emissions_by_cat_sheet.columns;
      // Excel - Emission by Facility
      const emissions_by_facility_sheet = ExportEmissionFacility.emissionsFacilityData(EmissionFacility.value);
      const emissions_facility_sheet_data = emissions_by_facility_sheet.data;
      const columns4 = emissions_by_facility_sheet.columns;
      // Excel - Emission by Country
      // const emissions_by_country_sheet = ExportEmissionCountry.emissionsCountryData(EmissionCountry.value);
      // const emissions_country_sheet_data = emissions_by_country_sheet.data;
      // const columns5 = emissions_by_country_sheet.columns;
      // // Excel - Emission by Category
      // const emissions_ghg_inventory_sheet = ExportEmissionInventory.emissionsInventoryData(InventoryInformation.value);
      // const emissions_inventory_sheet_data = emissions_ghg_inventory_sheet.data;
      // const columns6 = emissions_ghg_inventory_sheet.columns;

      writeXlsxFile([emmision_by_gas_data_sheet_data, emissions_cat_sheet_data, emissions_facility_sheet_data], {
        columns: [columns2, columns3, columns4],
        sheets: ['Emissions By Gas', 'Emissions By Category', 'Emissions By Facility'],
        // columns: [columns1, columns2, columns3, columns4, columns5, columns6],
        // sheets: ['Company Information', 'Emissions By Gas', 'Emissions By Category', 'Emissions By Facility', 'Emissions By Country', 'GHG Inventory Information'],
        fileName: 'report.xlsx'
      });
    }
// console.log(emmision_by_gas_data);
    return {
      exportTableToExcel,
      reportModal,
      toggleReportModal,
      reportForm,
      submitReportFilter,
      scopes,
      inventoryChange,
      fromPeriods,
      toPeriods,
      fromPeriodChangeHandler,
      handleTabClick,
      activetab,
      company_information,
      EmissionGas,
      EmissionCategory,
      EmissionFacility,
      EmissionCountry,
      InventoryInformation,
      filterData,
      loading,
    }
  }
}
</script>

<style scoped>
.report-tab-content{
  padding:15px;
  background:#fff;
  border: 1px solid #CBD5E1;
  border-top:0;
}
.mr-10 {
    margin-right: 10px;
  }
.report-tab > li > a{
  cursor: pointer;
  font-size: 1rem;
}

</style>
