<template>
    <div class="container-fluid">
      <div class="pt-2">
        <nav class="navbar bg-light">
          <div class="d-flex flex-column mb-2">
            <h4 class="navbar-brand fs-4">Reductions Workbench</h4>
          </div>
          <div class="d-flex justify-content-between w-100">
            <div class="d-flex w-75">
              <input class="form-control" type="search" placeholder="Search" aria-label="Search" v-model="search_filter" style="width: 120px;">
              <select class="form-select form-select-sm ms-2" aria-label="Category Filter" v-model="category_filter">
                <option value="" selected>--Emission Category--</option>
                <option v-for="(category, cat_index) in emission_categories" :value="category.id" :key="cat_index">{{ category.name }}</option>
              </select>
              <select class="form-select form-select-sm ms-2" aria-label="Subsidiary Filter" v-model="subsidiary_filter">
                <option value="" selected>--Subsidiary--</option>
                <option v-for="(subsidiary, sub_index) in subsidiaries" :value="subsidiary.id" :key="sub_index">{{ subsidiary.name }}</option>
              </select>
              <select class="form-select form-select-sm ms-2" aria-label="Location filter" v-model="location_filter">
                <option value="" selected>--Location--</option>
                <option v-for="(location, loc_index) in locations" :value="location.id" :key="loc_index">{{ location.name }}</option>
              </select>
              <select class="form-select form-select-sm ms-2" aria-label="Class Filter" v-model="class_filter">
                <option value="" selected>--Class--</option>
                <option v-for="(ns_class, class_index) in classes" :value="ns_class.id" :key="class_index">{{ ns_class.name }}</option>
              </select>
              <select class="form-select form-select-sm ms-2" aria-label="Department filter" v-model="department_filter">
                <option value="" selected>--Department--</option>
                <option v-for="(department, dep_index) in departments" :value="department.id" :key="dep_index">{{ department.name }}</option>
              </select>
            </div>
            <div class="d-flex">
              <div class="dropdown">
                <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="me-2"><i class="fa-light fa-plus-circle"></i></span>
                  <span class="pe-2">Add</span>
                </button>
                <div class="dropdown-menu dropdown-menu-dark dropdown-menu-end">
                  <button type="button" class="dropdown-item" @click="toggleCreateModal('VAL_62174_T2641309_667')">New Reduction</button>
                  <button type="button" class="dropdown-item" @click="toggleCreateModal('VAL_62173_T2641309_236')">New Offset</button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
  
      <loading-indicator v-if="loading"></loading-indicator>
      <div v-else>
        <table class="table reduction-table rounded">
            <thead class="fs-6">
                <tr>
                <th scope="col"><b>Name</b></th>
                <th scope="col"><b>Type</b></th>
                <th scope="col"><b>Category</b></th>
                <th scope="col"><b>Action</b></th>
                </tr>
            </thead>
            <tbody class="fs-6">
                <tr v-for="(reduction, reduction_index) in visible_reductions" :key="reduction_index">
                    <td>{{ reduction.name }}</td>
                    <td>{{ reduction.type_name }}</td>
                    <td>{{ reduction.category_name }}</td>
                    <td>
                        <div class="btn-group" role="group" aria-label="Actions">
                            <button type="button" class="btn btn-sm btn-link" @click="editReduction(reduction, 'edit')"><i class="fa-light fa-edit"></i></button>
                            <button type="button" class="btn btn-sm btn-link" @click="editReduction(reduction, 'view')"><i class="fa-light fa-eye"></i></button>
                            <a href="#" class="btn btn-sm btn-link"><i class="fa-light fa-trash-alt"></i></a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
  
    </div>
  
    <!--Create Modal-->
    <teleport to="#modal-area">
      <div class="modal fade" tabindex="-1" aria-labelledby="createReductionModal" id="create_modal" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title fs-5" id="createReductionModal">{{ createForm.transaction_type_script_id === 'VAL_62174_T2641309_667' ? 'New Reduction' : 'New Offset'  }}</h5>
              <button type="button" class="btn-close" @click="cancel('create')" aria-label="Close"></button>
            </div>
  
            <div class="modal-body">
              <h5 class="mb-2 fs-5">Main</h5>
              <div class="row">
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Emission Source</label>
                    <select class="form-select form-select-sm" aria-label="Emission Source" v-model="createForm.source_id" :class="{'is-invalid': createForm.errors.source_id}">
                      <option value="" selected>--</option>
                      <option v-for="(source, source_index) in emission_sources" :value="source.id" :key="source_index">{{ source.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="createForm.errors.source_id">
                      {{ createForm.errors.source_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label"><span class="text-danger me-1">*</span>Date</label>
                    <input class="form-control form-control-sm" aria-label="Date" :class="{'is-invalid': createForm.errors.date}" type="date" name="date" v-model="createForm.date" @change="selectPeriod('create')" />
                    <div class="invalid-feedback" v-if="createForm.errors.date">
                      {{ createForm.errors.date.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label"><span class="text-danger me-1">*</span>GHG Period</label>
                    <select class="form-select form-select-sm" aria-label="GHG Period" v-model="createForm.period_id" :class="{'is-invalid': createForm.errors.period_id}" disabled>
                      <option value="" selected>--</option>
                      <option v-for="(period, index) in periods" :value="period.id" :key="index">{{ period.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="createForm.errors.period_id">
                      {{ createForm.errors.period_id.toString() }}
                    </div>
                  </div> 

                </div>

                <div class="col">
                  <div class="mt-2">
                    <label class="form-label"><span class="text-danger me-1">*</span>Description</label>
                    <input class="form-control form-control-sm" aria-label="Description" :class="{'is-invalid': createForm.errors.altname}" type="text" name="altname" v-model="createForm.altname" />
                    <div class="invalid-feedback" v-if="createForm.errors.altname">
                      {{ createForm.errors.altname.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label"><span class="text-danger me-1">*</span>Transaction Type</label>
                    <select class="form-select form-select-sm" aria-label="Reduction Type" v-model="createForm.transaction_type_script_id" :class="{'is-invalid': createForm.errors.transaction_type_script_id}" disabled>
                      <option value="" selected>--</option>
                      <option v-for="(rtype, type_index) in reduction_types" :value="rtype.scriptid" :key="type_index">{{ rtype.name }}</option>
                    </select>
                    <div class="text-danger" v-if="createForm.errors.transaction_type_script_id">
                      {{ createForm.errors.transaction_type_script_id.toString() }}
                    </div>
                  </div>
  
                </div>
              </div>
              <h5 class="mt-4 mb-2 fs-5">Classification</h5>
              <div class="row">
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Emission Category</label>
                    <select class="form-select form-select-sm" aria-label="Emission Category" v-model="createForm.category_id" :class="{'is-invalid': createForm.errors.category_id}" @change="selectScope('create')">
                      <option value="" selected>--</option>
                      <option v-for="(category, cat_index) in emission_categories" :value="category.id" :key="cat_index">{{ category.name }}</option>
                   </select>
                   <div class="invalid-feedback" v-if="createForm.errors.category_id">
                      {{ createForm.errors.category_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Scope</label>
                    <select class="form-select form-select-sm" aria-label="Scope" v-model="createForm.scope_id" :class="{'is-invalid': createForm.errors.scope_id}">
                      <option value="" selected>--</option>
                      <option v-for="(scope, index) in scopes" :value="scope.id" :key="index">{{ scope.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="createForm.errors.scope_id">
                      {{ createForm.errors.scope_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Source Type</label>
                    <select class="form-select form-select-sm" aria-label="Source Type" v-model="createForm.source_type_id" :class="{'is-invalid': createForm.errors.source_type_id}">
                      <option value="" selected>--</option>
                      <option v-for="(source_type, index) in source_types" :value="source_type.id" :key="index">{{ source_type.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="createForm.errors.source_type_id">
                      {{ createForm.errors.source_type_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label"><span class="text-danger me-1">*</span>Subsidiary</label>
                    <select class="form-select form-select-sm" aria-label="Subsidiary" v-model="createForm.subsidiary_id" :class="{'is-invalid': createForm.errors.subsidiary_id}">
                      <option value="" selected>--</option>
                      <option v-for="(subsidiary, sub_index) in subsidiaries" :value="subsidiary.id" :key="sub_index">{{ subsidiary.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="createForm.errors.subsidiary_id">
                      {{ createForm.errors.subsidiary_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Department</label>
                    <select class="form-select form-select-sm" aria-label="Department" v-model="createForm.department_id" :class="{'is-invalid': createForm.errors.department_id}">
                      <option value="" selected>--</option>
                      <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="createForm.errors.department_id">
                      {{ createForm.errors.department_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label">Class</label>
                    <select class="form-select form-select-sm" aria-label="Class" v-model="createForm.class_id" :class="{'is-invalid': createForm.errors.class_id}">
                      <option value="" selected>--</option>
                      <option v-for="(classification, index) in classes" :value="classification.id" :key="index">{{ classification.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="createForm.errors.class_id">
                      {{ createForm.errors.class_id.toString() }}
                    </div>
                  </div>  
                  
                </div>
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Fixed Asset</span></label>
                    <segment-selection segment_type="fixed_assets" @segment-selected="selectSegment('create', 'fixed_asset_id', $event)" ref="fixed_asset_create_selector"></segment-selection>
                    <div class="text-danger" v-if="createForm.errors.fixed_asset_id">
                      {{ createForm.errors.fixed_asset_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Customer</span></label>
                    <entity-selection entity_type="customers" @entity-selected="selectEntity('create', 'customer_id', $event)" ref="customer_create_selector"></entity-selection>
                    <div class="text-danger" v-if="createForm.errors.customer_id">
                      {{ createForm.errors.customer_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Vendor</span></label>
                    <entity-selection entity_type="vendors" @entity-selected="selectEntity('create', 'vendor_id', $event)" ref="vendor_create_selector"></entity-selection>
                    <div class="text-danger" v-if="createForm.errors.vendor_id">
                      {{ createForm.errors.vendor_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Employee</span></label>
                    <entity-selection entity_type="employees" @entity-selected="selectEntity('create', 'employee_id', $event)" ref="employee_create_selector"></entity-selection>
                    <div class="text-danger" v-if="createForm.errors.employee_id">
                      {{ createForm.errors.employee_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Partner</span></label>
                    <entity-selection entity_type="partners" @entity-selected="selectEntity('create', 'partner_id', $event)" ref="partner_create_selector"></entity-selection>
                    <div class="text-danger" v-if="createForm.errors.partner_id">
                      {{ createForm.errors.partner_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Project</span></label>
                    <entity-selection entity_type="projects" @entity-selected="selectEntity('create', 'project_id', $event)" ref="project_create_selector"></entity-selection>
                    <div class="text-danger" v-if="createForm.errors.project_id">
                      {{ createForm.errors.project_id.toString() }}
                    </div>
                  </div>
                </div>
              </div>

              <h5 class="mt-4 mb-2 fs-5">Amount</h5>
              <div class="row">
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Amount</label>
                    <input class="form-control form-control-sm" aria-label="Amount" type="number" name="amount" v-model="createForm.amount" />
                    <div class="text-danger" v-if="createForm.errors.amount">
                      {{ createForm.errors.amount.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Unit</label>
                    <select class="form-select form-select-sm" aria-label="Unit" v-model="createForm.unit_id" :class="{'is-invalid': createForm.errors.unit_id}">
                      <option value="" selected>--</option>
                      <option v-for="(unit, index) in units" :value="unit.id" :key="index">{{ unit.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="createForm.errors.unit_id">
                      {{ createForm.errors.unit_id.toString() }}
                    </div>
                  </div>

                </div>
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Gas</label>
                    <select class="form-select form-select-sm" aria-label="Gas" v-model="createForm.gas_id" :class="{'is-invalid': createForm.errors.gas_id}">
                      <option value="" selected>--</option>
                      <option v-for="(gas, index) in classes" :value="gas.id" :key="index">{{ gas.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="createForm.errors.gas_id">
                      {{ createForm.errors.gas_id.toString() }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="createForm.transaction_type_script_id == 'VAL_62173_T2641309_236'">
                <h5 class="mt-4 mb-2 fs-5">Offsets</h5>
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Offset Source</label>
                    <input class="form-control form-control-sm" aria-label="Offset Source" type="text" name="offset_source" v-model="createForm.offset_source" :class="{'is-invalid': createForm.errors.offset_source}" />
                    <div class="text-danger" v-if="createForm.errors.offset_source">
                      {{ createForm.errors.offset_source.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <div class="mt-2">
                      <label class="form-label">Offset Category</label>
                      <select class="form-select form-select-sm" aria-label="Class" v-model="createForm.offset_category_id" :class="{'is-invalid': createForm.errors.offset_category_id}">
                        <option value="" selected>--</option>
                        <option v-for="(category, index) in offset_categories" :value="category.id" :key="index">{{ category.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="createForm.errors.offset_category_id">
                        {{ createForm.errors.offset_category_id.toString() }}
                      </div>
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Offset Type</label>
                    <select class="form-select form-select-sm" aria-label="Class" v-model="createForm.offset_type_id" :class="{'is-invalid': createForm.errors.offset_type_id}">
                        <option value="" selected>--</option>
                        <option v-for="(offset_type, index) in offset_types" :value="offset_type.id" :key="index">{{ offset_type.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="createForm.errors.offset_type_id">
                        {{ createForm.errors.offset_type_id.toString() }}
                      </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Project ID</label>
                    <input class="form-control form-control-sm" aria-label="Project ID" type="text" name="offset_project_id" v-model="createForm.offset_project_id" :class="{'is-invalid': createForm.errors.offset_project_id}" />
                    <div class="text-danger" v-if="createForm.errors.offset_project_id">
                      {{ createForm.errors.offset_project_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Project Description</label>
                    <textarea class="form-control form-control-sm" aria-label="Project Description" type="text" name="offset_project_description" v-model="createForm.offset_project_description" rows="4" cols="69" :class="{'is-invalid': createForm.errors.offset_project_description}">
                    </textarea>
                    <div class="text-danger" v-if="createForm.errors.offset_project_description">
                      {{ createForm.errors.offset_project_description.toString() }}
                    </div>
                  </div>
                  
                </div>
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Project Type</label>
                    <input class="form-control form-control-sm" aria-label="Project Type" type="text" name="offset_project_type" v-model="createForm.offset_project_type" :class="{'is-invalid': createForm.errors.offset_project_type}" />
                    <div class="text-danger" v-if="createForm.errors.offset_project_type">
                      {{ createForm.errors.offset_project_type.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Project Location</label>
                    <input class="form-control form-control-sm" aria-label="Project Location" type="text" name="offset_project_location" v-model="createForm.offset_project_location" :class="{'is-invalid': createForm.errors.offset_project_location}" />
                    <div class="text-danger" v-if="createForm.errors.offset_project_location">
                      {{ createForm.errors.offset_project_location.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Project Provider</label>
                    <input class="form-control form-control-sm" aria-label="Project Provider" type="text" name="offset_project_provider" v-model="createForm.offset_project_provider" :class="{'is-invalid': createForm.errors.offset_project_provider}" />
                    <div class="text-danger" v-if="createForm.errors.offset_project_provider">
                      {{ createForm.errors.offset_project_provider.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label">Vintage Year</label>
                    <input class="form-control form-control-sm" aria-label="Vintage Year" type="text" name="offset_vintage_year" v-model="createForm.offset_vintage_year" :class="{'is-invalid': createForm.errors.offset_vintage_year}" />
                    <div class="text-danger" v-if="createForm.errors.offset_vintage_year">
                      {{ createForm.errors.offset_vintage_year.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label">Registry</label>
                    <input class="form-control form-control-sm" aria-label="Registry" type="text" name="offset_registry" v-model="createForm.offset_registry" :class="{'is-invalid': createForm.errors.offset_registry}" />
                    <div class="text-danger" v-if="createForm.errors.offset_registry">
                      {{ createForm.errors.offset_registry.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label">Registration Number</label>
                    <input class="form-control form-control-sm" aria-label="Registration Number" type="text" name="offset_registration_number" v-model="createForm.offset_registration_number" :class="{'is-invalid': createForm.errors.offset_registration_number}" />
                    <div class="text-danger" v-if="createForm.errors.offset_registration_number">
                      {{ createForm.errors.offset_registration_number.toString() }}
                    </div>
                  </div>
                </div>
              </div>

              <h5 class="mt-4 mb-2 fs-5">Region</h5>
              <div class="row">
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Location</label>
                    <select class="form-select form-select-sm" aria-label="Location" v-model="createForm.location_id" @change="updateLocationParams('create')" :class="{'is-invalid': createForm.errors.location_id}">
                      <option value="" selected>--</option>
                      <option v-for="(location, index) in locations" :value="location.id" :key="index">{{ location.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="createForm.errors.location_id">
                      {{ createForm.errors.location_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Country</label>
                    <country-selection @country-selected="selectCountry('create', 'country', $event)" ref="country_create_selector"></country-selection>
                    <div class="text-danger" v-if="createForm.errors.country">
                      {{ createForm.errors.country.toString() }}
                    </div>
                  </div>

                </div>
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">State/Region/Province</label>
                    <region-selection :disabled="!createForm.country" :country="createForm.country || ''" @region-selected="selectRegion('create', 'region', $event)" ref="region_create_selector"></region-selection>
                    <div class="text-danger" v-if="createForm.errors.region">
                      {{ createForm.errors.region.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Zip / Postal Code</label>
                    <input class="form-control form-control-sm" aria-label="Name" :class="{'is-invalid': createForm.errors.zip_code}" type="text" name="zip_code" v-model="createForm.zip_code" />
                    <div class="text-danger" v-if="createForm.errors.zip_code">
                      {{ createForm.errors.zip_code.toString() }}
                    </div>
                  </div>
                </div>
              </div>
  
            </div>
            <div class="modal-footer d-flex justify-content-between">
              <div class="d-flex justify-content-start">
                
              </div>
  
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-light" @click="cancel('create')" :disabled="modal_loading">
                  <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                  <span class="me-2" v-else><i class="fa-light fa-arrow-circle-left"></i></span>
                  <span>Cancel</span>
                </button>
                <button type="button" class="btn btn-primary ml-3" :disabled="modal_loading" @click="saveReduction" style="margin-left: 10px;">
                  <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                  <span class="me-2" v-else><i class="fa-light fa-save"></i></span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  
    <!--Edit Modal-->
    <teleport to="#modal-area">
      <div class="modal fade" tabindex="-1" aria-labelledby="editReductionModal" id="edit_modal" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title fs-5" id="editReductionModal">{{ editForm.transaction_type_script_id === 'VAL_62174_T2641309_667' ? 'Edit Reduction' : 'Edit Offset'  }}</h5>
              <button type="button" class="btn-close" @click="cancel('edit')" aria-label="Close"></button>
            </div>
  
            <div class="modal-body">
              <h5 class="mb-2 fs-5">Main</h5>
              <div class="row">
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Emission Source</label>
                    <select class="form-select form-select-sm" aria-label="Emission Source" v-model="editForm.source_id" :class="{'is-invalid': editForm.errors.source_id}" :disabled="!enable_edit">
                      <option value="" selected>--</option>
                      <option v-for="(source, source_index) in emission_sources" :value="source.id" :key="source_index">{{ source.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.source_id">
                      {{ editForm.errors.source_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label"><span class="text-danger me-1">*</span>Date</label>
                    <input class="form-control form-control-sm" aria-label="Date" :class="{'is-invalid': editForm.errors.date}" type="date" name="date" v-model="editForm.date" @change="selectPeriod('create')" :disabled="!enable_edit" />
                    <div class="invalid-feedback" v-if="editForm.errors.date">
                      {{ editForm.errors.date.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label"><span class="text-danger me-1">*</span>GHG Period</label>
                    <select class="form-select form-select-sm" aria-label="GHG Period" v-model="editForm.period_id" :class="{'is-invalid': editForm.errors.period_id}" disabled>
                      <option value="" selected>--</option>
                      <option v-for="(period, index) in periods" :value="period.id" :key="index">{{ period.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.period_id">
                      {{ editForm.errors.period_id.toString() }}
                    </div>
                  </div> 

                </div>

                <div class="col">
                  <div class="mt-2">
                    <label class="form-label"><span class="text-danger me-1">*</span>Description</label>
                    <input class="form-control form-control-sm" aria-label="Description" :class="{'is-invalid': editForm.errors.altname}" type="text" name="altname" v-model="editForm.altname" :disabled="!enable_edit" />
                    <div class="invalid-feedback" v-if="editForm.errors.altname">
                      {{ editForm.errors.altname.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label"><span class="text-danger me-1">*</span>Transaction Type</label>
                    <select class="form-select form-select-sm" aria-label="Reduction Type" v-model="editForm.transaction_type_script_id" :class="{'is-invalid': editForm.errors.transaction_type_script_id}" disabled>
                      <option value="" selected>--</option>
                      <option v-for="(rtype, type_index) in reduction_types" :value="rtype.scriptid" :key="type_index">{{ rtype.name }}</option>
                    </select>
                    <div class="text-danger" v-if="editForm.errors.transaction_type_script_id">
                      {{ editForm.errors.transaction_type_script_id.toString() }}
                    </div>
                  </div>
  
                </div>
              </div>
              <h5 class="mt-4 mb-2 fs-5">Classification</h5>
              <div class="row">
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Emission Category</label>
                    <select class="form-select form-select-sm" aria-label="Emission Category" v-model="editForm.category_id" :class="{'is-invalid': editForm.errors.category_id}" @change="selectScope('create')" :disabled="!enable_edit">
                      <option value="" selected>--</option>
                      <option v-for="(category, cat_index) in emission_categories" :value="category.id" :key="cat_index">{{ category.name }}</option>
                   </select>
                   <div class="invalid-feedback" v-if="editForm.errors.category_id">
                      {{ editForm.errors.category_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Scope</label>
                    <select class="form-select form-select-sm" aria-label="Scope" v-model="editForm.scope_id" :class="{'is-invalid': editForm.errors.scope_id}" :disabled="!enable_edit">
                      <option value="" selected>--</option>
                      <option v-for="(scope, index) in scopes" :value="scope.id" :key="index">{{ scope.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.scope_id">
                      {{ editForm.errors.scope_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Source Type</label>
                    <select class="form-select form-select-sm" aria-label="Source Type" v-model="editForm.source_type_id" :class="{'is-invalid': editForm.errors.source_type_id}" :disabled="!enable_edit">
                      <option value="" selected>--</option>
                      <option v-for="(source_type, index) in source_types" :value="source_type.id" :key="index">{{ source_type.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.source_type_id">
                      {{ editForm.errors.source_type_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label"><span class="text-danger me-1">*</span>Subsidiary</label>
                    <select class="form-select form-select-sm" aria-label="Subsidiary" v-model="editForm.subsidiary_id" :class="{'is-invalid': editForm.errors.subsidiary_id}" :disabled="!enable_edit">
                      <option value="" selected>--</option>
                      <option v-for="(subsidiary, sub_index) in subsidiaries" :value="subsidiary.id" :key="sub_index">{{ subsidiary.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.subsidiary_id">
                      {{ editForm.errors.subsidiary_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Department</label>
                    <select class="form-select form-select-sm" aria-label="Department" v-model="editForm.department_id" :class="{'is-invalid': editForm.errors.department_id}" :disabled="!enable_edit">
                      <option value="" selected>--</option>
                      <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.department_id">
                      {{ editForm.errors.department_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label">Class</label>
                    <select class="form-select form-select-sm" aria-label="Class" v-model="editForm.class_id" :class="{'is-invalid': editForm.errors.class_id}" :disabled="!enable_edit">
                      <option value="" selected>--</option>
                      <option v-for="(classification, index) in classes" :value="classification.id" :key="index">{{ classification.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.class_id">
                      {{ editForm.errors.class_id.toString() }}
                    </div>
                  </div>  
                  
                </div>
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Fixed Asset</span></label>
                    <segment-selection segment_type="fixed_assets" @segment-selected="selectSegment('edit', 'fixed_asset_id', $event)" ref="fixed_asset_create_selector" :disabled="!enable_edit"></segment-selection>
                    <div class="text-danger" v-if="editForm.errors.fixed_asset_id">
                      {{ editForm.errors.fixed_asset_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Customer</span></label>
                    <entity-selection entity_type="customers" @entity-selected="selectEntity('edit', 'customer_id', $event)" ref="customer_create_selector" :disabled="!enable_edit"></entity-selection>
                    <div class="text-danger" v-if="editForm.errors.customer_id">
                      {{ editForm.errors.customer_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Vendor</span></label>
                    <entity-selection entity_type="vendors" @entity-selected="selectEntity('edit', 'vendor_id', $event)" ref="vendor_create_selector" :disabled="!enable_edit"></entity-selection>
                    <div class="text-danger" v-if="editForm.errors.vendor_id">
                      {{ editForm.errors.vendor_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Employee</span></label>
                    <entity-selection entity_type="employees" @entity-selected="selectEntity('edit', 'employee_id', $event)" ref="employee_create_selector" :disabled="!enable_edit"></entity-selection>
                    <div class="text-danger" v-if="editForm.errors.employee_id">
                      {{ editForm.errors.employee_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Partner</span></label>
                    <entity-selection entity_type="partners" @entity-selected="selectEntity('edit', 'partner_id', $event)" ref="partner_create_selector" :disabled="!enable_edit"></entity-selection>
                    <div class="text-danger" v-if="editForm.errors.partner_id">
                      {{ editForm.errors.partner_id.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Project</span></label>
                    <entity-selection entity_type="projects" @entity-selected="selectEntity('edit', 'project_id', $event)" ref="project_create_selector" :disabled="!enable_edit"></entity-selection>
                    <div class="text-danger" v-if="editForm.errors.project_id">
                      {{ editForm.errors.project_id.toString() }}
                    </div>
                  </div>
                </div>
              </div>

              <h5 class="mt-4 mb-2 fs-5">Amount</h5>
              <div class="row">
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Amount</label>
                    <input class="form-control form-control-sm" aria-label="Amount" type="number" name="amount" v-model="editForm.amount" :disabled="!enable_edit" />
                    <div class="text-danger" v-if="editForm.errors.amount">
                      {{ editForm.errors.amount.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Unit</label>
                    <select class="form-select form-select-sm" aria-label="Unit" v-model="editForm.unit_id" :class="{'is-invalid': editForm.errors.unit_id}" :disabled="!enable_edit">
                      <option value="" selected>--</option>
                      <option v-for="(unit, index) in units" :value="unit.id" :key="index">{{ unit.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.unit_id">
                      {{ editForm.errors.unit_id.toString() }}
                    </div>
                  </div>

                </div>
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Gas</label>
                    <select class="form-select form-select-sm" aria-label="Gas" v-model="editForm.gas_id" :class="{'is-invalid': editForm.errors.gas_id}" :disabled="!enable_edit">
                      <option value="" selected>--</option>
                      <option v-for="(gas, index) in classes" :value="gas.id" :key="index">{{ gas.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.gas_id">
                      {{ editForm.errors.gas_id.toString() }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="editForm.transaction_type_script_id == 'VAL_62173_T2641309_236'">
                <h5 class="mt-4 mb-2 fs-5">Offsets</h5>
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Offset Source</label>
                    <input class="form-control form-control-sm" aria-label="Offset Source" type="text" name="offset_source" v-model="editForm.offset_source" :class="{'is-invalid': editForm.errors.offset_source}" :disabled="!enable_edit" />
                    <div class="text-danger" v-if="editForm.errors.offset_source">
                      {{ editForm.errors.offset_source.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <div class="mt-2">
                      <label class="form-label">Offset Category</label>
                      <select class="form-select form-select-sm" aria-label="Class" v-model="editForm.offset_category_id" :class="{'is-invalid': editForm.errors.offset_category_id}" :disabled="!enable_edit">
                        <option value="" selected>--</option>
                        <option v-for="(category, index) in offset_categories" :value="category.id" :key="index">{{ category.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="editForm.errors.offset_category_id">
                        {{ editForm.errors.offset_category_id.toString() }}
                      </div>
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Offset Type</label>
                    <select class="form-select form-select-sm" aria-label="Class" v-model="editForm.offset_type_id" :class="{'is-invalid': editForm.errors.offset_type_id}" :disabled="!enable_edit">
                        <option value="" selected>--</option>
                        <option v-for="(offset_type, index) in offset_types" :value="offset_type.id" :key="index">{{ offset_type.name }}</option>
                      </select>
                      <div class="invalid-feedback" v-if="editForm.errors.offset_type_id">
                        {{ editForm.errors.offset_type_id.toString() }}
                      </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Project ID</label>
                    <input class="form-control form-control-sm" aria-label="Project ID" type="text" name="offset_project_id" v-model="editForm.offset_project_id" :class="{'is-invalid': editForm.errors.offset_project_id}" :disabled="!enable_edit" />
                    <div class="text-danger" v-if="editForm.errors.offset_project_id">
                      {{ editForm.errors.offset_project_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Project Description</label>
                    <textarea class="form-control form-control-sm" aria-label="Project Description" type="text" name="offset_project_description" v-model="editForm.offset_project_description" rows="4" cols="69" :class="{'is-invalid': editForm.errors.offset_project_description}" :disabled="!enable_edit">
                    </textarea>
                    <div class="text-danger" v-if="editForm.errors.offset_project_description">
                      {{ editForm.errors.offset_project_description.toString() }}
                    </div>
                  </div>
                  
                </div>
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Project Type</label>
                    <input class="form-control form-control-sm" aria-label="Project Type" type="text" name="offset_project_type" v-model="editForm.offset_project_type" :class="{'is-invalid': editForm.errors.offset_project_type}" :disabled="!enable_edit" />
                    <div class="text-danger" v-if="editForm.errors.offset_project_type">
                      {{ editForm.errors.offset_project_type.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Project Location</label>
                    <input class="form-control form-control-sm" aria-label="Project Location" type="text" name="offset_project_location" v-model="editForm.offset_project_location" :class="{'is-invalid': editForm.errors.offset_project_location}" :disabled="!enable_edit" />
                    <div class="text-danger" v-if="editForm.errors.offset_project_location">
                      {{ editForm.errors.offset_project_location.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Project Provider</label>
                    <input class="form-control form-control-sm" aria-label="Project Provider" type="text" name="offset_project_provider" v-model="editForm.offset_project_provider" :class="{'is-invalid': editForm.errors.offset_project_provider}" :disabled="!enable_edit" />
                    <div class="text-danger" v-if="editForm.errors.offset_project_provider">
                      {{ editForm.errors.offset_project_provider.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label">Vintage Year</label>
                    <input class="form-control form-control-sm" aria-label="Vintage Year" type="text" name="offset_vintage_year" v-model="editForm.offset_vintage_year" :class="{'is-invalid': editForm.errors.offset_vintage_year}" :disabled="!enable_edit" />
                    <div class="text-danger" v-if="editForm.errors.offset_vintage_year">
                      {{ editForm.errors.offset_vintage_year.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label">Registry</label>
                    <input class="form-control form-control-sm" aria-label="Registry" type="text" name="offset_registry" v-model="editForm.offset_registry" :class="{'is-invalid': editForm.errors.offset_registry}" :disabled="!enable_edit" />
                    <div class="text-danger" v-if="editForm.errors.offset_registry">
                      {{ editForm.errors.offset_registry.toString() }}
                    </div>
                  </div>
  
                  <div class="mt-2">
                    <label class="form-label">Registration Number</label>
                    <input class="form-control form-control-sm" aria-label="Registration Number" type="text" name="offset_registration_number" v-model="editForm.offset_registration_number" :class="{'is-invalid': editForm.errors.offset_registration_number}" :disabled="!enable_edit" />
                    <div class="text-danger" v-if="editForm.errors.offset_registration_number">
                      {{ editForm.errors.offset_registration_number.toString() }}
                    </div>
                  </div>
                </div>
              </div>

              <h5 class="mt-4 mb-2 fs-5">Region</h5>
              <div class="row">
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">Location</label>
                    <select class="form-select form-select-sm" aria-label="Location" v-model="editForm.location_id" @change="updateLocationParams('edit')" :class="{'is-invalid': editForm.errors.location_id}" :disabled="!enable_edit">
                      <option value="" selected>--</option>
                      <option v-for="(location, index) in locations" :value="location.id" :key="index">{{ location.name }}</option>
                    </select>
                    <div class="invalid-feedback" v-if="editForm.errors.location_id">
                      {{ editForm.errors.location_id.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Country</label>
                    <country-selection @country-selected="selectCountry('edit', 'country', $event)" ref="country_create_selector" :disabled="!enable_edit"></country-selection>
                    <div class="text-danger" v-if="editForm.errors.country">
                      {{ editForm.errors.country.toString() }}
                    </div>
                  </div>

                </div>
                <div class="col">
                  <div class="mt-2">
                    <label class="form-label">State/Region/Province</label>
                    <region-selection :disabled="!editForm.country || !enable_edit" :country="editForm.country || ''" @region-selected="selectRegion('edit', 'region', $event)" ref="region_create_selector"></region-selection>
                    <div class="text-danger" v-if="editForm.errors.region">
                      {{ editForm.errors.region.toString() }}
                    </div>
                  </div>

                  <div class="mt-2">
                    <label class="form-label">Zip / Postal Code</label>
                    <input class="form-control form-control-sm" aria-label="Name" :class="{'is-invalid': editForm.errors.zip_code}" type="text" name="zip_code" v-model="editForm.zip_code" :disabled="!enable_edit" />
                    <div class="text-danger" v-if="editForm.errors.zip_code">
                      {{ editForm.errors.zip_code.toString() }}
                    </div>
                  </div>
                </div>
              </div>
  
            </div>

            <div class="modal-footer d-flex justify-content-between">
              <div class="d-flex justify-content-start">
                
              </div>
  
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-light" @click="cancel('edit')" :disabled="modal_loading">
                  <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                  <span class="me-2" v-else><i class="fa-light fa-arrow-circle-left"></i></span>
                  <span>Cancel</span>
                </button>
                <button type="button" class="btn btn-primary" :disabled="modal_loading" @click="updateReduction">
                  <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                  <span class="me-2" v-else><i class="fa-light fa-save"></i></span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </template>
  
  <script>
  import {useRestletStore} from "@/stores/restlets";
  import LoadingIndicator from "@/components/global/LoadingIndicator";
  import {ref, computed, onMounted} from "vue";
  import {storeToRefs} from "pinia/dist/pinia";
  import axios from "axios";
  import * as dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  import Fuse from 'fuse.js';
  
  import EntitySelection from "@/components/forms/EntitySelection";
  import SegmentSelection from "@/components/forms/SegmentSelection";
  import CountrySelection from "@/components/forms/CountrySelection";
  import RegionSelection from "@/components/forms/RegionSelection";
  
  export default {
    components: {
      LoadingIndicator,
      EntitySelection,
      SegmentSelection,
      CountrySelection,
      RegionSelection,
    },
  
    setup()
    {
      const restlet_store = useRestletStore();
      const {ghg_reductions_restlet_url, scope_restlet_url} = storeToRefs(restlet_store);
      const reduction_transactions = ref([]);
      const emission_sources = ref([]);
      const periods = ref([]);
      const reduction_types = ref([]);
      const emission_categories = ref([]);
      const scopes = ref([]);
      const source_types = ref([]);
      const subsidiaries = ref([]);
      const locations = ref([]);
      const departments = ref([]);
      const classes = ref([]);
      const units = ref([]);
      const gases = ref([]);
      const offset_categories = ref([]);
      const offset_types = ref([]);
      

      const createForm = ref({
        errors: [],
      });

      const editForm = ref({
        errors: [],
      });

      const editing_reduction = ref({});
      const updated_reduction_id = ref(null);
  
      const loading = ref(true);
      const modal_loading = ref(false);
      const create_modal = ref(null);
      const edit_modal = ref(null);
  
      const search_filter = ref('');
      const category_filter = ref('');
      const subsidiary_filter = ref('');
      const location_filter = ref('');
      const class_filter = ref('');
      const department_filter = ref('');
      const enable_edit = ref(false);
  
      const visible_reductions = computed(() => {
  
        let filtered_reductions = reduction_transactions.value;
  
        if(search_filter.value.length)
        {
          let options = {
            keys: ['name', 'id'],
          }
  
          let fuse = new Fuse(filtered_reductions, options);
  
          let results = fuse.search(search_filter.value);
  
          filtered_reductions = results.map(result => {
            return result.item;
          });
        }
  
        return filtered_reductions
            .filter((fr) => {
              if(category_filter.value.length > 0)
              {
                return fr['category_id'] === category_filter.value;
              }
  
              else {
                return true;
              }
            })
            .filter((fr) => {
              if(subsidiary_filter.value.length > 0)
              {
                return fr['subsidiary_id'] === subsidiary_filter.value;
              }
  
              else {
                return true;
              }
            })
            .filter((fr) => {
              if(location_filter.value.length > 0)
              {
                return fr['location_id'] === location_filter.value;
              }
  
              else {
                return true;
              }
            })
            .filter((fr) => {
              if(class_filter.value.length > 0)
              {
                return fr['class_id'] === class_filter.value;
              }
  
              else {
                return true;
              }
            })
            .filter((fr) => {
              if(department_filter.value.length > 0)
              {
                return fr['department_id'] === department_filter.value;
              }
  
              else {
                return true;
              }
            });
      });

      function getScopes()
      {
        let source_params = {};

        let query = new URLSearchParams(source_params).toString();
        let endpoint = scope_restlet_url.value + '&' + query;

        axios.get(endpoint)
            .then(response => {
              scopes.value = response.data.scopes;
            })
            .catch(err => {
              console.log(err);
            });
      }
  
      function getReductions(query_type = 'transactions_with_dropdowns')
      {
        loading.value = true;
        let source_params = {};
        source_params['query_type'] = query_type;
  
        let query = new URLSearchParams(source_params).toString();
        let endpoint = ghg_reductions_restlet_url.value + '&' + query;
  
        axios.get(endpoint)
            .then(response => {
                reduction_transactions.value = response.data.reduction_transactions;

                if(response.data.emission_sources)
                {
                  emission_sources.value = response.data.emission_sources;
                }

                if(response.data.periods)
                {
                  periods.value = response.data.periods;
                }

                if(response.data.reduction_types)
                {
                  reduction_types.value = response.data.reduction_types;
                }
  
                if(response.data.emission_categories)
                {
                  emission_categories.value = [...new Set(response.data.emission_categories)];
                }

                if(response.data.scopes)
                {
                  scopes.value = response.data.scopes;
                }

                if(response.data.source_types)
                {
                  source_types.value = response.data.source_types;
                }
  
                if(response.data.subsidiaries)
                {
                  subsidiaries.value = response.data.subsidiaries;
                }
  
                if(response.data.locations)
                {
                  locations.value = response.data.locations;
                }

                if(response.data.departments)
                {
                  departments.value = response.data.departments;
                }
  
                if(response.data['classes'])
                {
                  classes.value = response.data['classes'];
                }

                if(response.data.units)
                {
                  units.value = response.data.units;
                }

                if(response.data.gases)
                {
                  gases.value = response.data.gases;
                }

                if(response.data.offset_categories)
                {
                  offset_categories.value = response.data.offset_categories;
                }

                if(response.data.offset_types)
                {
                  offset_types.value = response.data.offset_types;
                }
  
                loading.value = false;
            })
            .catch(err => {
              console.log(err);
            });
      }
  
      function toggleCreateModal(type="")
      {
        createForm.value = {
            errors: [],
          };
          createForm.value.transaction_type_script_id = type;
          create_modal.value.toggle();
      }

      function toggleEditModal()
      {
        edit_modal.value.toggle();
      }
  
      function editReduction(reduction, mode)
      {
        editing_reduction.value = reduction;
        editForm.value.id = reduction.id;
        //Main
        editForm.value.emission_source = reduction.emission_source;
        editForm.value.date = reduction.date;
        editForm.value.ghg_period = reduction.ghg_period;
        editForm.value.period_locked = reduction['period_locked'];
        editForm.value.period_closed = reduction['period_closed'];
        editForm.value.description = reduction.description;
        editForm.value.transaction_type_script_id = reduction.transaction_type_script_id;

        //Classification
        editForm.value.category_id = reduction.category_id;
        editForm.value.scope_id = reduction.scope_id;
        editForm.value.source_type_id = reduction.source_type_id;
        editForm.value.subsidiary_id = reduction.subsidiary_id;
        editForm.value.department_id = reduction.department_id;
        editForm.value.class_id = reduction.class_id;
        editForm.value.fixed_asset_id = reduction.fixed_asset_id;
        editForm.value.customer_id = reduction.customer_id;
        editForm.value.vendor_id = reduction.vendor_id;
        editForm.value.employee_id = reduction.employee_id;
        editForm.value.partner_id = reduction.partner_id;
        editForm.value.project_id = reduction.project_id;

        //Amount
        editForm.value.amount = reduction.amount;
        editForm.value.unit = reduction.unit;
        editForm.value.gas = reduction.gas;

        //Offset
        editForm.value.offset_source = reduction.offset_source;
        editForm.value.offset_category = reduction.offset_category_id;
        editForm.value.offset_type = reduction.offset_type_id;
        editForm.value.project_id = reduction.offset_project_id;
        editForm.value.project_description = reduction.offset_project_description;
        editForm.value.project_type = reduction.offset_project_type;
        editForm.value.project_location = reduction.offset_project_location;
        editForm.value.project_provider = reduction.offset_project_provider;
        editForm.value.vintage_year = reduction.offset_vintage_year;
        editForm.value.registry = reduction.offset_registry;
        editForm.value.registration_number = reduction.offset_registration_number;
        
        //Region
        editForm.value.location_id = reduction.location_id;
        editForm.value.country = reduction.country;
        editForm.value.region = reduction.region;
        editForm.value.zip_code = reduction.zip_code;

        if(mode === 'edit' && !editForm.value.period_locked && !editForm.value.period_closed)
        {
          enable_edit.value = true;
        }

        else {
          enable_edit.value = false;
        }
  
        toggleEditModal();
      }
  
      function selectEntity(mode, field, entity)
      {
        let entity_form = mode === 'create' ? createForm.value : editForm.value;
        entity_form[field] = !entity ? null : entity.id;
      }
  
      function selectCountry(mode, field, country)
      {
        let country_form = mode === 'create' ? createForm.value : editForm.value;

        if(country)
        {  
          country_form[field] = country.code;
          country_form['region'] = '';
          country_form['zip_code'] = ''; 
        }

        else {
          country_form[field] = '';
          country_form['region'] = '';
          country_form['zip_code'] = '';
        }
      }
  
      function selectRegion(mode, field, region)
      {
        let region_form = mode === 'create' ? createForm.value : editForm.value;
        region_form[field] = !region ? null : region.abbreviation;
      }
  
      function selectSegment(mode, field, segment)
      {
        let segment_form = mode === 'create' ? createForm.value : editForm.value;
        segment_form[field] = !segment ? null : segment.id;
      }
  
      function validateForm(mode)
      {
        let errors = 0;
        let form = mode === 'create' ? createForm.value : editForm.value;
        
        if(!form.date)
        {
          form.errors['date'] = 'The Date is mandatory';
          errors++;
        }

        if(!form.subsidiary_id)
        {
          form.errors['subsidiary_id'] = 'The subsidiary is mandatory';
          errors++;
        }
  
        return errors;
      }
  
      function saveReduction()
      {
        createForm.value.errors = [];
        let errors = validateForm('create');
  
        if(errors === 0)
        {
          modal_loading.value = true;
          let source_params = {};
          let form = createForm.value;
  
          let query = new URLSearchParams(source_params).toString();
          let endpoint = ghg_reductions_restlet_url.value + '&' + query;
  
          axios.post(endpoint, form)
              .then(response => {
  
                if(response.data['status'] === 'success')
                {
                  createForm.value = {errors: []};
                  getReductions('transactions');
                  toggleCreateModal();
                  modal_loading.value = false;
                  updated_reduction_id.value = response.data['reduction_transaction_id'] || null;
                }
              });
        }
      }
  
      function updateReduction()
      {
        editForm.value.errors = [];
        let errors = validateForm('edit');
  
        if(errors === 0)
        {
          modal_loading.value = true;
          let source_params = {};
          let form = editForm.value;
          form['action'] = 'update';
  
          let query = new URLSearchParams(source_params).toString();
          let endpoint = ghg_reductions_restlet_url.value + '&' + query;
  
          axios.put(endpoint, form)
              .then(response => {
  
                if(response.data['status'] === 'success')
                {
                  editForm.value = {errors: []};
                  getReductions('transactions');
                  toggleCreateModal("", 'edit');
                  modal_loading.value = false;
                  updated_reduction_id.value = response.data['reduction_transaction_id'] || null;
                }
              });
        }
      }
  
      function selectScope(mode)
      {
        let form = mode === 'create' ? createForm.value : editForm.value;
        let category_id = form.category_id;
  
        if(category_id)
        {
          let category = emission_categories.value.find(emcat => {
            return emcat.id == category_id;
          });
  
          if(category)
          {
            form.scope_id = category.scope_id;
          }
        }
  
        else {
          form.scope_id = '';
        }
      }
  
      function cancel(mode)
      {
        toggleCreateModal("", mode);
      }

      function selectPeriod(mode)
      {
        let form = mode === 'create' ? createForm.value : editForm.value;
        let date_source = dayjs.utc(form.date).valueOf();
        let period = periods.value.find(p => {
          let start = dayjs.utc(p['start_date']).startOf('day').valueOf();
          let end = dayjs.utc(p['end_date']).endOf('day').valueOf();

          return date_source >= start && date_source <= end;
        });

        if(period)
        {
          form.period_id = period.id;
          form.errors.period_id = null;
          form.errors.date = null;
        }

        else {
          form.period_id = '';
          form.errors.period_id = 'No valid period was found for this date';
          form.errors.date = 'Invalid date';
        }
      }

      function updateLocationParams(mode)
      {
        let form = mode === 'create' ? createForm.value : editForm.value;

        let location = locations.value.find(l => {
          return l.id === form.location_id;
        });

        if(location)
        {
          form.country = location.country;
          form.region = location.region;
          form.zip_code = location.zip_code;
        }
      }

      dayjs.extend(utc);
  
      onMounted(() => {
        getScopes();
        getReductions('transactions_with_dropdowns');
        create_modal.value = new bootstrap.Modal(document.getElementById('create_modal'));
        edit_modal.value = new bootstrap.Modal(document.getElementById('edit_modal'));
      });
  
      return {
        loading,
        modal_loading,
        create_modal,
        edit_modal,
        search_filter,
        category_filter,
        subsidiary_filter,
        location_filter,
        class_filter,
        department_filter,
        enable_edit,
        reduction_transactions,
        visible_reductions,
        emission_sources,
        periods,
        reduction_types,
        emission_categories,
        scopes,
        source_types,
        subsidiaries,
        locations,
        departments,
        classes,
        units,
        gases,
        offset_categories,
        offset_types,
        createForm,
        editForm,
        editing_reduction,
        updated_reduction_id,
        getReductions,
        toggleCreateModal,
        editReduction,
        selectEntity,
        selectCountry,
        selectRegion,
        selectSegment,
        validateForm,
        saveReduction,
        updateReduction,
        selectScope,
        cancel,
        selectPeriod,
        updateLocationParams,
      }
    }
  }
  </script>

<style scoped>
.reduction-table {
  background-color: #ffffff;
  margin-top: 10px;
}
</style>