export default {
  emissionsGasExcelData(emission_gas) {
    const HEADER_ROW = [
      {
        value: 'Section',
        fontWeight: 'bold',
        backgroundColor: "#9FC5E8",
        height: 20,
        alignVertical: "center",
        borderColor: "#000000",
      },
      {
        value: 'Description',
        fontWeight: 'bold',
        backgroundColor: "#9FC5E8",
        height: 20,
        alignVertical: "center",
        borderColor: "#000000",
      },
      {
        value: 'Details',
        fontWeight: 'bold',
        backgroundColor: "#9FC5E8",
        height: 20,
        span: 8,
        alignVertical: "center",
        borderColor: "#000000",
      }
    ];

    const columns = [
      { width: 15 },
      { width: 40 }, // in characters
      { width: 10 },
      { width: 20 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
    ];

    const data = [
      HEADER_ROW,
    ];

    var data_len = data.length;
    var data_index = 0;
    emission_gas.forEach((currentValue) => {
      var org_details = currentValue.details;
      var headers = currentValue.header;
      var optional_header = currentValue.optional_header;
      var org_details_emission_len = org_details.emissions.length;
      var org_details_emission_optional_len = org_details.emissions_optional.length;
      var org_details_len = ((org_details.emissions.length > 0) ? org_details.emissions.length + 1 : 0) + ((org_details.emissions_optional.length > 0) ? org_details.emissions_optional.length + 1 : 0);

      var header_details = [
        // "Section"
        {
          value: currentValue.section,
          wrap: true,
          fontWeight: "bold",
          align: 'center',
          alignVertical: "center",
          borderColor: "#000000",
          rowSpan: org_details_len
        },
        // "Descriptions"
        {
          value: currentValue.descriptions,
          wrap: true,
          width: 30,
          align: 'center',
          alignVertical: "center",
          borderColor: "#000000",
          rowSpan: org_details_emission_len+1
        }
      ];
      headers.forEach((header_val, index) => {
        header_details[index + 2] = {
          value: header_val,
          align: 'center',
          alignVertical: "center",
          backgroundColor: "#46BDC6",
          borderColor: "#000000",
          fontWeight: (header_val == 'TOTAL (mtCO2e)') ? 'bold' : '',
          fontSize: (header_val == 'TOTAL (mtCO2e)') ? '10' : 12,
          height: 30,
        };
      });
      data[data_index + data_len] = header_details;
      data_index++;

      if (org_details_emission_len > 0) {
        var org_details_emissions = org_details.emissions;
        // console.log(org_details_emissions[0]);
        for (let i = 0; i < org_details_emission_len; i++) {
          data[data_index + data_len] = [
            null,
            null,
            {
              value: org_details_emissions[i].emissions,
              align: 'center',
              borderColor: "#000000",
              backgroundColor: "#46BDC6",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emissions[i]['total']),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emissions[i]['co2']),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emissions[i]['ch4']),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emissions[i]['n2o']),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emissions[i]['hfcs']),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emissions[i]['pfcs']),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emissions[i]['sf6']),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            }
          ]
          data_index++;
        }
      }

      if (optional_header.length > 0) {
        var op_desc = {
          value: currentValue.optional_descriptions??'',
          wrap: true,
          width: 30,
          align: 'center',
          alignVertical: "center",
          borderColor: "#000000",
          rowSpan: org_details_emission_optional_len+1
        }
        var optional_header_details = [
          null,
          op_desc
        ];
        optional_header.forEach((op_header_val, index) => {
          optional_header_details[index + 2] = {
            value: op_header_val,
            align: 'center',
            alignVertical: "center",
            backgroundColor: "#46BDC6",
            borderColor: "#000000",
            fontWeight: (op_header_val == 'TOTAL (mtCO2e)') ? 'bold' : '',
            fontSize: (op_header_val == 'TOTAL (mtCO2e)') ? '10' : 12,
            height: 30,
          };
        });
        data[data_index + data_len] = optional_header_details;
        data_index++;
      }

      if (org_details_emission_optional_len > 0) {
        var org_details_emission_optional = org_details.emissions_optional;
        for (let j = 0; j < org_details_emission_optional_len; j++) {
        
          data[data_index + data_len] = [
            null,
            null,
            {
              value: org_details_emission_optional[j].emissions,
              align: 'center',
              borderColor: "#000000",
              backgroundColor: "#46BDC6",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emission_optional[j].total),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emission_optional[j].cfcs),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emission_optional[j].nox),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emission_optional[j].hcfcs),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: this.roundNumber(org_details_emission_optional[j].nf3),
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: "",
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            },
            {
              value: "",
              align: 'center',
              borderColor: "#000000",
              wrap: true,
            }
          ]
          data_index++;
        }
      }
    });

    return {
      data,
      columns
    }
  },

  roundNumber(number)
    {
      if(typeof number === 'undefined'){
        return '';
      } else if (typeof number === 'string') {
        return number;
      } else if(Number(number) == 0){
        return number;
      } else if(Number(number) > 99999){
        return Number(number).toExponential();
      } else if(Number(number) < 0.0001){
        return Number(number).toExponential();
      } else{
        return Math.round(Number(number) * 100000) / 100000;
      }
    }
}
