export default {
     disabledLabel(is_show=false){
        if(is_show){
            return {
            'background': '#fff !important',
            'border-color': '#fff !important',
            'box-shadow': 'none !important',
            'color': '#000 !important'
            } 
        } else {
            return {}
        }
    }
}
