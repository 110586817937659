import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export default{
  props: {
    createForm: Object,
    editForm:Object,
    emission_source: Object,
    periods:Array,
    scopes:Array,
    emission_categories: Array,
    source_types: Array,
    subsidiaries: Array,
    departments: Array,
    classes: Array,
    calculation_approaches: Array,
    activity_types: Array,
    fuel_types: Array,
    units: Array,
    custom_emission_factors: Array,
    refrigerant_sources: Array,
    emission_databases: Array,
    locations: Array,
   },
  
  data() { 
    return {
      loading_transactions: false,
      loading_summary: false,
      modal_loading: false,
      errors: [],
      classification_toggle: true,
      edit_classification_toggle: true,
    }
  },
  
  methods: {
    selectPeriod(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
      let date_source = dayjs.utc(form.date).valueOf();

      let period = this.periods.find(p => {
        let start = dayjs.utc(p['start_date']).startOf('day').valueOf();
        let end = dayjs.utc(p['end_date']).endOf('day').valueOf();
        
        return date_source >= start && date_source <= end;
      });

      // TEMP FIND THE MISTAKE WITH DATE FORMATS OTHER THAN MMDDYYYY
      //console.log(date_source);
      //console.log(this.periods);
      //console.log(period);
  
      if(period){
        form.period_id = period.id;
        this.errors.period_id = null;
        this.errors.date = null;
      } else {
        form.period_id = '';
        this.errors.period_id = 'No valid period was found for this date';
        this.errors.date = 'Invalid date';
      }
    },
  
    parsePostingMessage(message){
      let parsed_message = message;
  
      try {
        parsed_message = JSON.parse(message);
      } catch(e) {
        //console.log(e);
      }
      
      if(parsed_message['message']){
        return 'Error: ' + parsed_message['message'];
      } else {
        return parsed_message;
      }
    },
  
    selectListRecord(mode, field, record)
    {
      let record_form = mode === 'create' ? this.createForm : this.editForm;
      record_form[field] = !record ? null : record.id;
    },
  
    activityTypeFieldIsVisible(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
    
      let enabled_calculation_approaches = [
        'VAL_61496_T2641309_243',
        'VAL_60243_T2641309_921',
      ];
  
      let calculation_approach = this.calculation_approaches.find(ca => {
        return ca.id === form.calculation_approach_id;
      });
  
      if(calculation_approach){
        return enabled_calculation_approaches.includes(calculation_approach['scriptid']);
      } else {
        return false;
      }
    },
    
   isStandardEmissionFactors(mode)
      {
        let form = mode === 'create' ? this.createForm : this.editForm;
  
        let enabled_calculation_approaches = [
          'VAL_60243_T2641309_921',
        ];
  
        let calculation_approach = this.calculation_approaches.find(ca => {
        return ca.id === form.calculation_approach_id;
      });

      let disabled_activity_types = [
        'VAL_64187_T2641309_541',//Fugitive Emissions
      ];

      let activity_type = this.activity_types.find(at => {
        return at.id === form.activity_type_id;
      });
  
      if(activity_type && calculation_approach){
        return enabled_calculation_approaches.includes(calculation_approach['scriptid']) && !disabled_activity_types.includes(activity_type['scriptid']);
      } else if(calculation_approach){
        return enabled_calculation_approaches.includes(calculation_approach['scriptid']);
      } else {
        return false;
      }
    },

    fuelRelatedFieldIsVisible(mode, category_id)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
  
      let activity_type_id = form.activity_type_id;
  
      let activity_type = this.activity_types.find(at => {
        return at.id === activity_type_id;
      });
  
      /**** BRANCH ONE, DETERMINE VISIBILITY BASED ON EMISSION CATEGORY */
      /** From ticket: GHGAC-406 */
      let enabled_categories = [
        'VAL_60166_T2641309_304',//"Stationary Combustion"
        'VAL_60168_T2641309_491',//"Mobile Combustion"
        'VAL_60176_T2641309_650',//"Business Travel"
        'VAL_60177_T2641309_112',//"Employee Commuting"
        'VAL_60173_T2641309_333',//"Fuel and Energy Related Activities"
        'VAL_60179_T2641309_405',//"Downstream Transportation and Distribution"
        'VAL_60174_T2641309_674',//"Upstream Transportation and Distribution"
        'VAL_60183_T2641309_855',//"Downstream Leased Assets"
        'VAL_60178_T2641309_818',//Upstream Leased Assets
        'VAL_60180_T2641309_651',//Processing of Sold Products
        'VAL_60181_T2641309_845',//Use of Sold Products and Services,
        'VAL_60184_T2641309_948',//Franchises,
        'VAL_60185_T2641309_143',//Investments
        'VAL_60171_T2641309_172',//Purchased Goods and Services
        'VAL_60172_T2641309_405',//Capital Goods
      ];
  
      let enabled_calculation_approaches = [
        'VAL_61496_T2641309_243',
        'VAL_60243_T2641309_921',
      ];
  
      let disabled_activity_types = [
        'VAL_63211_T2641309_964',//Passenger
        'VAL_63509_T2641309_458',//Accommodation
        'VAL_64179_T2641309_854',//Industrial Processes
        'VAL_62073_T2641309_122',//Grid Electricity - Location Based
        'VAL_62075_T2641309_142',//Grid Electricity - Market Based
        'VAL_62080_T2641309_423',//Steam and Heat
        'VAL_64184_T2641309_481',//Agriculture Emissions
        'VAL_64187_T2641309_541',//Fugitive Emissions
        'VAL_62081_T2641309_523',//Financial Values
        'VAL_65890_T2641309_797',//Well-to-Tank - Heat and Steam
        'VAL_65900_T2641309_185',//Well-to-Tank - Passenger,
        'VAL_65889_T2641309_410',//Well-to-Tank - Electricity,
        'VAL_65897_T2641309_194',//Closed-Loop Source Recycled Material
        'VAL_65894_T2641309_545',//Primary Material Production
        'VAL_65895_T2641309_375',//Re-used Material
        'VAL_385059_T2641309_749',//Per Unit Purchased
        // 'VAL_65893_T2641309_485',//Well-to-Tank - Shipping,
        // 'VAL_64104_T2641309_116',//Shipping
      ];
  
      let calculation_approach = this.calculation_approaches.find(ca => {
        return ca.id === form.calculation_approach_id;
      });
  
      let category = this.emission_categories.find(c => {
        return c.id === category_id;
      });
  
      if(activity_type_id && category && calculation_approach)
      {
        return enabled_categories.includes(category['scriptid']) && enabled_calculation_approaches.includes(calculation_approach['scriptid']) && !disabled_activity_types.includes(activity_type['scriptid']);
      } else {
        return false;
      }
    },

    fuelTypeFieldIsRequired(mode, is_visible)
    {
      if(!is_visible){
        return is_visible;
      }

      let form = mode === 'create' ? this.createForm : this.editForm;

      let disabled_activity_types = [
        'VAL_65893_T2641309_485',//Well-to-Tank - Shipping,
        'VAL_64104_T2641309_116',//Shipping
      ];
  
      let activity_type = this.activity_types.find(at => {
        return at.id === form.activity_type_id;
      });
  
      if(activity_type){
        return !disabled_activity_types.includes(activity_type['scriptid']);
      } else {
        return true;
      }
      
    },
  
   calculationApproachIsLocked()
   {
      let category_script_id = this.emission_source.category_scriptid?.toUpperCase();
  
      const categories_for_locking_calculation_approach = [];
  
      return categories_for_locking_calculation_approach.includes(category_script_id);
    },
  
    requiresCustomEmissionFactor(calculation_approach_id)
    {
      let wanted_calculation_approach_scriptid = 'VAL_61496_T2641309_243';
  
      let calculation_approach = this.calculation_approaches.find(ca => {
        return ca.id === calculation_approach_id;
      });
  
      if(calculation_approach)
      {
        return wanted_calculation_approach_scriptid === calculation_approach['scriptid'];
      } else {
        return false;
      }
    },
  
    requiresRefrigerantSource(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;

      let enabled_category = [
        'VAL_60169_T2641309_340',//Fugitive Emissions
      ];

      let enabled_activity_types = [
        'VAL_64187_T2641309_541',//Fugitive Emissions
      ];

      let activity_type = this.activity_types.find(at => {
        return at.id === form.activity_type_id;
      });

      if(activity_type){
        return enabled_activity_types.includes(activity_type['scriptid']);
      } else {
        return enabled_category.includes(this.emission_source.category_scriptid?.toUpperCase());
      }
    },
  
    requiresGas(calculation_approach_id)
    {
      if(calculation_approach_id.length > 0)
      {
        let wanted_calculation_approach_scriptid = 'VAL_60247_T2641309_476';
  
        let calculation_approach = this.calculation_approaches.find(ca => {
          return ca.id === calculation_approach_id;
        });
  
        if(calculation_approach)
        {
          return wanted_calculation_approach_scriptid === calculation_approach['scriptid'];
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  
    getGasFieldName(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
  
      if(this.requiresRefrigerantSource(mode))
      {
        return 'Leak Source';
      } else {
        return this.requiresGas(form.calculation_approach_id || '') ? 'Gas' : 'Refrigerant Source';
      }
    },
  
    requiresPassengers(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
     
      let enabled_activity_types = [
        'VAL_63211_T2641309_964', //Passenger
        'VAL_65900_T2641309_185', //Well-to-Tank - Passenger
      ];
  
      let activity_type = this.activity_types.find(at => {
        return at.id === form.activity_type_id;
      });
  
      if(activity_type)
      {
        return enabled_activity_types.includes(activity_type['scriptid']);
      } else {
        return false;
      }
    },
  
    getVisibleUnits(mode)
      {
        let form = mode === 'create' ? this.createForm : this.editForm;
  
        let activity_type = this.activity_types.find(at => {
          return at.id === form.activity_type_id;
        });
  
        let calculation_approach = this.calculation_approaches.find(ca => {
          return ca.id === form.calculation_approach_id;
        });
  
        if(activity_type || calculation_approach)
        {
          if(calculation_approach && calculation_approach['scriptid'] === 'VAL_60247_T2641309_476')
          {
            return this.units.filter(u => {
                let allowed_categories = ['VAL_59837_T2641309_502'];
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
          } else if(activity_type) {
            // Stationary
            if(activity_type['scriptid'] === 'VAL_62079_T2641309_426')
            {
              return this.units.filter(u => {
                let allowed_categories = ['VAL_59837_T2641309_502', 'VAL_59838_T2641309_714'];
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            }
            
            // Mobile, Well-to-Tank - Fuels
            else if(activity_type['scriptid'] === 'VAL_60367_T2641309_317' || activity_type['scriptid'] === 'VAL_64180_T2641309_838')
            {
              return this.units.filter(u => {
                let allowed_categories = ['VAL_59837_T2641309_502', 'VAL_59838_T2641309_714'];
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            }
  
            // Distance, Shipping, Well-to-Tank - Shipping, Well-to-Tank - Distance
            else if(['VAL_60366_T2641309_196','VAL_64104_T2641309_116','VAL_65893_T2641309_485','VAL_65899_T2641309_939'].includes(activity_type['scriptid']))
            {
              return this.units.filter(u => {
                let allowed_categories = ['VAL_59839_T2641309_362'];
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            }
  
            // Grid Electricity - Location Based, Well-to-Tank - Electricity
            else if(activity_type['scriptid'] === 'VAL_62073_T2641309_122' || activity_type['scriptid'] === 'VAL_65889_T2641309_410')
            {
              return this.units.filter(u => {
                let allowed_categories = ['VAL_59836_T2641309_578'];
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            }
  
            // Market Based
            else if(activity_type['scriptid'] === 'VAL_62075_T2641309_142')
            {
              return this.units.filter(u => {
                let allowed_categories = ['VAL_59836_T2641309_578'];
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            }
  
            // Steam and Heat, Well-to-Tank - Heat and Steam
            else if(activity_type['scriptid'] === 'VAL_62080_T2641309_423' || activity_type['scriptid'] === 'VAL_65890_T2641309_797')
            {
              return this.units.filter(u => {
                let allowed_categories = ['VAL_59836_T2641309_578'];
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            }
  
            // Passenger, Well-to-Tank - Heat and Steam
            else if(activity_type['scriptid'] === 'VAL_63211_T2641309_964' || activity_type['scriptid'] === 'VAL_65900_T2641309_185')
            {
              return this.units.filter(u => {
                let allowed_categories = ['VAL_59839_T2641309_362'];
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            } 
            //Water Managment
            else if(activity_type['scriptid'] === 'VAL_63515_T2641309_931'){
              return this.units.filter(u => {
                let allowed_categories = ['VAL_59838_T2641309_714','VAL_65774_T2641309_507']; //Volume,People
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            }

            // Financial Value
            else if(activity_type['scriptid'] === 'VAL_62081_T2641309_523')
            {
              return this.units.filter(u => {
                let allowed_categories = ['VAL_64134_T2641309_700'];
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            }
  
            //Accommondation
            else if(activity_type['scriptid'] === 'VAL_63509_T2641309_458'){
              return this.units.filter(u => {
                let allowed_categories = ['VAL_63516_T2641309_655'];//Accommondation
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            } 
            //Industrial Processes,Anaerobically Digested (Dry Digestate with Curing), Anaerobically Digested (Wet Digestate with Curing, Combusted, Composted, Landfilled, Recycled,Fugitive Emissions, Closed-Loop Source Recycled Material, Primary Material Production, Re-used Material
            else if(['VAL_64179_T2641309_854','VAL_62983_T2641309_654','VAL_62984_T2641309_699','VAL_62981_T2641309_361','VAL_62982_T2641309_217','VAL_62980_T2641309_575','VAL_62979_T2641309_248','VAL_64187_T2641309_541','VAL_65897_T2641309_194','VAL_65894_T2641309_545','VAL_65895_T2641309_375'].includes(activity_type['scriptid'])){
              return this.units.filter(u => {
                let allowed_categories = ['VAL_59837_T2641309_502']; //Weight
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            }
            // Transmission and Distribution
            else if(activity_type['scriptid'] === 'VAL_65891_T2641309_478')
            {
              return this.units.filter(u => {
                let allowed_categories = ['VAL_59839_T2641309_362','VAL_59836_T2641309_578']; //Distance, Energy
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            }
            // Per Unit Purchased
            else if(activity_type['scriptid'] === 'VAL_385059_T2641309_749')
            {
              return this.units.filter(u => {
                let allowed_categories = ['VAL_59838_T2641309_714','VAL_385060_T2641309_339','VAL_59837_T2641309_502','VAL_60918_T2641309_926']; //Volume, Unit, Weight, Area
                return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
              });
            }
            else {
              return this.units;
            }
          } else {
            return this.units;
          }
        } else {
          return this.units;
        }
    },
  
    fuelEfficiencyFieldIsRequired(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
  
      let enabled_activity_types = [
        'VAL_60366_T2641309_196',//Distance
      ];

      let enable_emission_database = [
        'VAL_63198_T2641309_866',//US EPA
      ];

      let emission_factor_db = this.emission_databases.find(efd => {
        return efd.id === form.emission_database_id;
      });
  
      let activity_type = this.activity_types.find(at => {
        return at.id === form.activity_type_id;
      });
  
      if(activity_type && emission_factor_db)
      {
        return enabled_activity_types.includes(activity_type['scriptid']) && enable_emission_database.includes(emission_factor_db['scriptid']);
      } else if(activity_type){
        return enabled_activity_types.includes(activity_type['scriptid']);
      } else {
        return false;
      }
    },
  
    /** check for side-effects */
    fuelEfficiencyFieldIsDisabled(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
  
      let wanted_activity_types = [
        'VAL_60367_T2641309_317',//Mobile Fuel Use
        'VAL_64180_T2641309_838',//Well-to-Tank - Fuels
        'VAL_65899_T2641309_939',//Well-to-Tank - Distance
        'VAL_65891_T2641309_478',//Transmission and Distribution
        'VAL_65893_T2641309_485',//Well-to-Tank - Shipping,
        'VAL_64104_T2641309_116',//Shipping
        // 'VAL_62079_T2641309_426',//Stationary Fuel Use
      ];

      let enable_emission_database = [
        'VAL_63198_T2641309_866',//US EPA
      ];
  
      let activity_type = this.activity_types.find(at => {
        return at.id === form.activity_type_id;
      });

      let emission_factor_db = this.emission_databases.find(efd => {
        return efd.id === form.emission_database_id;
      });
  
      if(activity_type)
      {
        // Distance
        if(activity_type['scriptid'] === 'VAL_60366_T2641309_196' && emission_factor_db)
        {
          return !enable_emission_database.includes(emission_factor_db.scriptid);
        } else{
          return wanted_activity_types.includes(activity_type.scriptid);
        }
      } else {
        return false;
      }
    },
  
    getVisibleEfficiencyUnits(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;
  
      // Removed activity type condition for fuel efficiency unit filter. Was only applying filter for activity type VAL_60366_T2641309_196;
  
      return this.units.filter(u => {
        return u.unit_category_scriptid.toUpperCase() === 'VAL_60896_T2641309_283';
      });
    },
    
   selectCountry(mode, field, country)
    {
      let country_form = mode === 'create' ? this.createForm : this.editForm;
  
      if(country)
      {  
        country_form[field] = country.code;
      } else {
        country_form[field] = '';
      }

      if(field === 'actual_country'){
        country_form['actual_state'] = '';
        country_form['zip_code'] = '';
      } else{
        country_form['region'] = '';
      }
      
    },

    updateLocationParams(mode)
    {
      let form = mode === 'create' ? this.createForm : this.editForm;

      let location = this.locations.find(l => {
        return l.id === form.location_id;
      });

      if(location)
      {
        form.actual_country = location.country;
        form.actual_state = location.region;
        form.zip_code = location.zip_code || location.zip;
        // form.country = location.country;
        // form.region = location.region;
      }
    },
  
    categoryRequiresRegion(category_id)
    {
      let categories_that_require_region = ['VAL_60170_T2641309_514'];
  
      let category = this.emission_categories.find(c => {
        return c.id === category_id;
      });
  
      if(category)
      {
        return categories_that_require_region.includes(category['scriptid']);
      } else {
        return false;
      }
    },
   
    selectRegion(mode, field, region)
    {
      let region_form = mode === 'create' ? this.createForm : this.editForm;
      region_form[field] = !region ? null : region.abbreviation;
    },
  
    validateForm(mode)
    {
      let error = 0;
      let form = mode === 'create' ? this.createForm : this.editForm;
  
      if(!form.altname)
      {
        this.errors['altname'] = 'The transaction description is required';
        error++;
      }
  
      if(!form['date'])
      {
        this.errors['date'] = 'The date is required';
        error++;
      }
  
      if(!form.period_id)
      {
        this.errors['period_id'] = 'The period is required';
        error++;
      }
  
      if(!form.altname)
      {
        this.errors['altname'] = 'The transaction description is required';
        error++;
      }
  
      if(!form.category_id)
      {
        this.errors['category_id'] = 'The Emission Category is required';
        error++;
      }
  
      if(!form.source_id)
      {
        this.errors['source_id'] = 'The Emission Source is required';
        error++;
      }

      if(!form.subsidiary_id && this.enabled_features.subsidiaries)
      {
        this.errors['subsidiary_id'] = 'The subsidiary is required';
        error++;
      }
  
      if(!form.scope_id)
      {
        this.errors['scope_id'] = 'The scope is required';
        error++;
      }
  
      if(!form.fuel_type_id && this.fuelRelatedFieldIsVisible(mode, this.emission_source.category_id))
      {
        this.errors['fuel_type_id'] = 'The fuel type is required';
        error++;
      }
  
      if(!form.shipping_weight && this.shippingUnitFieldIsRequired(mode))
      {
        this.errors['shipping_weight'] = 'The shipping weight is required';
        error++;
      }
  
      if(!form.shipping_weight_unit_id && this.shippingUnitFieldIsRequired(mode))
      {
        this.errors['shipping_weight_unit_id'] = 'The shipping weight unit is required';
        error++;
      }
  
      if(!form.calculation_approach_id)
      {
        this.errors['calculation_approach_id'] = 'The calculation approach is required';
        error++;
      }
  
      if(this.requiresCustomEmissionFactor(form.calculation_approach_id) && !form.custom_emission_factor_id)
      {
        this.errors['custom_emission_factor_id'] = 'The custom emission factor is required with this calculation approach';
        error++;
      }
  
      if(!form.unit_id)
      {
        this.errors['unit_id'] = 'The unit is required';
        error++;
      }
  
      if(!form.amount)
      {
        this.errors['amount'] = 'The amount is required';
        error++;
      }
  
      if(this.activityTypeFieldIsVisible(mode) && !form.activity_type_id && this.activity_types.length > 0)
      {
        this.errors['activity_type_id'] = 'The activity type is required';
        error++;
      }
  
      if(!form.gas_id && this.requiresRefrigerantSource(mode))
      {
        this.errors['gas_id'] = 'The '+this.getGasFieldName(mode)+' is required';
        error++;
      }
  
      if(!form.gas_id && this.requiresGas(form.calculation_approach_id || ''))
      {
        this.errors['gas_id'] = 'The gas is required';
        error++;
      }
  
      if(!form.country)
      {
        this.errors['country'] = 'The emission factor country is required';
        error++;
      }
  
      if(this.categoryRequiresRegion(form.category_id) && !form.region)
      {
        this.errors['region'] = 'The emission factor state / region / province is required';
        error++;
      }

      if(!form.actual_country)
      {
        this.errors['actual_country'] = 'The country is required';
        error++;
      }
  
      if(this.categoryRequiresRegion(form.category_id) && !form.actual_state)
      {
        this.errors['actual_state'] = 'The state / region / province is required';
        error++;
      }
  
      if(this.categoryRequiresRegion(form.category_id) && !form.zip_code)
      {
        this.errors['zip_code'] = 'The zip code is required';
        error++;
      }
  
      if(!form.source_type_id && this.isStandardEmissionFactors(mode))
      {
        this.errors['source_type_id'] = 'The source type is required';
        error++;
      }
  
      if(this.fuelEfficiencyFieldIsRequired(mode) && !form.fuel_efficiency)
      {
        this.errors['fuel_efficiency'] = 'The fuel efficiency is required';
        error++;
      }
  
      if(this.fuelEfficiencyFieldIsRequired(mode) && !form.fuel_efficiency_unit_id)
      {
        this.errors['fuel_efficiency_unit_id'] = 'The fuel efficiency unit is required';
        error++;
      }
  
      if(this.requiresPassengers(mode) && !form.passengers)
      {
        this.errors['passengers'] = 'The number of passengers is required';
        error++;
      }
  
      return error;
    },
    
    filteredActivityTypes(mode)
      {
        let form = mode === 'create' ? this.createForm : this.editForm;
        let category_id = form.category_id;
  
        return this.activity_types.filter(at => {
          let categories = at.category_ids?.length > 0 && category_id ? at.category_ids?.split(',') : [];
          return categories.includes(category_id);
        });
    },
  
    filteredSourceTypes(mode)
      {
        let form = mode === 'create' ? this.createForm : this.editForm;
        let activity_type_id = form.activity_type_id;
        let emission_database_id = form.emission_database_id;
  
        return this.source_types.filter(st => {
          let activity_type_ids = st.activity_type_id?.length > 0 && activity_type_id ? st.activity_type_id?.split(',') : [];
          let emission_database_ids = st.emission_database_id?.length > 0 && emission_database_id ? st.emission_database_id?.split(',') : [];
          
          return emission_database_ids.includes(emission_database_id) && activity_type_ids.includes(activity_type_id);
        });
      },
  
     filteredFuelTypes(mode)
      {
        let form = mode === 'create' ? this.createForm : this.editForm;
        let activity_type_id = form.activity_type_id;
        let emission_database_id = form.emission_database_id;
  
        return this.fuel_types.filter(ft => {
          let activity_type_ids = ft.activity_type_id?.length > 0 && activity_type_id ? ft.activity_type_id?.split(',') : [];
          let emission_database_ids = ft.emission_database_id?.length > 0 && emission_database_id ? ft.emission_database_id?.split(',') : [];
          
          return emission_database_ids.includes(emission_database_id) && activity_type_ids.includes(activity_type_id);
        });
      },
  
    resetSourchFuelValue(mode){
      let form = mode === 'create' ? this.createForm : this.editForm;
      form.source_type_id= '';
      form.fuel_type_id= '';

      let emission_database_id = form.emission_database_id;
      let emission_factor_db = this.emission_databases.find(efd => {
        return efd.id === emission_database_id;
      });

      if(emission_factor_db){
        form.country = emission_factor_db.country;
        form.region = emission_factor_db.region;
      }

    },
  
    toggleCollapse(mode, collapse_type){
        if(mode=='create' && collapse_type=="classification")
          this.classification_toggle = !this.classification_toggle;
        if(mode=='edit' && collapse_type=="classification")
          this.edit_classification_toggle = !this.edit_classification_toggle;
      },
  
     selectEntity(mode, field, entity)
      {
        let entity_form = mode === 'create' ? this.createForm : this.editForm;
        entity_form[field] = !entity ? null : entity.id;
      },
  
     selectSegment(mode, field, segment)
      {
        let segment_form = mode === 'create' ? this.createForm : this.editForm;
        segment_form[field] = !segment ? null : segment.id;
      },
  
     shippingUnitFieldIsRequired(mode){
        let form = mode === 'create' ? this.createForm : this.editForm;
  
        let enabled_activity_types = [
          'VAL_64104_T2641309_116', //Shipping
          'VAL_65893_T2641309_485', //Well-to-Tank - Shipping
        ];
  
        let activity_type = this.activity_types.find(at => {
          return at.id === form.activity_type_id;
        });
  
        if(activity_type)
        {
          return enabled_activity_types.includes(activity_type['scriptid']);
        }
  
        else {
          return false;
        }
      },
  
     filterShippingWeightUnits(mode){
        let form = mode === 'create' ? this.createForm : this.editForm;
  
        let activity_type = this.activity_types.find(at => {
          return at.id === form.activity_type_id;
        });
        
        //Shipping, Well-to-Tank - Shipping
        if(['VAL_64104_T2641309_116','VAL_65893_T2641309_485'].includes(activity_type['scriptid']))
        {
            //Shipping then filter units by weight
            return this.units.filter(u => {
              let allowed_categories = ['VAL_59837_T2641309_502'];
              return allowed_categories.includes(u.unit_category_scriptid.toUpperCase());
            });
        } else {
          return this.units;
        }
      },
      
      changeDependentFields(event){
        let selected_source_id = event.target.value;
        
        let emissionSource = this.emission_sources.find(es => {
          return es.id === selected_source_id;
        });

        if(emissionSource){
          let emissionCategory = this.emission_categories.find(ec => {
            return ec.id === emissionSource.category_id;
          });
          this.emission_source.category_id = emissionSource.category_id??'';
          this.emission_source.category_scriptid = emissionCategory.scriptid??'';
          this.emission_source.id = selected_source_id;
          this.emission_source.name = emissionCategory.name;

          this.editForm.category_id = emissionSource.category_id;
          this.editForm.category_name = emissionSource.category_name;
          this.editForm.scope_id = emissionSource.scope_id;
          this.editForm.scope_name = emissionSource.scope_name;
          this.editForm.calculation_approach_id = emissionSource.calculation_approach_id;
          this.editForm.calculation_approach_name = emissionSource.calculation_approach_name;
          this.editForm.activity_type_id = emissionSource.activity_type_id;
          this.editForm.activity_type_name = emissionSource.activity_type_name;
          this.editForm.emission_database_id = emissionSource.emission_database_id;
          this.editForm.emission_database_name = emissionSource.emission_database_name;
          this.editForm.source_type_id = emissionSource.source_type_id;
          this.editForm.source_type_name = emissionSource.source_type_name;
          this.editForm.fuel_type_id = emissionSource.fuel_id;
          this.editForm.fuel_type_name = emissionSource.fuel_name;
          this.editForm.fuel_efficiency = emissionSource.fuel_efficiency;
          this.editForm.fuel_efficiency_unit_id = emissionSource.fuel_efficiency_unit_id;
          this.editForm.fuel_efficiency_unit_name = emissionSource.fuel_efficiency_unit_name;
          this.editForm.unit_id = emissionSource.unit_id;
          this.editForm.unit_name = emissionSource.unit_name;
          this.editForm.vendor_id = emissionSource.vendor_id;
          this.editForm.vendor_name = emissionSource.vendor_name;
          this.editForm.customer_id = emissionSource.customer_id;
          this.editForm.customer_name = emissionSource.customer_name;
          this.editForm.item_id = emissionSource.item_id;
          this.editForm.item_name = emissionSource.item_name;
          this.editForm.expense_category_id = emissionSource.expense_category_id;
          this.editForm.expense_category_name = emissionSource.expense_category_name;
          this.editForm.account_id = emissionSource.account_id;
          this.editForm.account_name = emissionSource.account_name;
          this.editForm.country = emissionSource.country;
          this.editForm.zip_code = emissionSource.zip_code;
          this.editForm.region = emissionSource.region;
        }
      },

  },

  watch:{
    createForm: {
      handler(newValue){
          this.errors = [];
      },
      deep: true
    },
    editForm: {
      handler(newValue){
          this.errors = [];
      },
      deep: true
    },
  }

}
dayjs.extend(utc);