<template>
  <div class="min-vh-100" :style="background_styles">
    <div class="container">
      <div class="d-flex justify-content-start pt-4">
        <div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
          <button type="button" class="btn btn-light" :class="{'active': dashboard_timeframe == 'ytd'}" :disabled="is_disabled_overview_group"  @click="getYtd">YTD</button>
          <button type="button" class="btn btn-light" :class="{'active': dashboard_timeframe == 'last_3_months'}" :disabled="is_disabled_overview_group" @click="getLast3Months">Last 3 Months</button>
          <button type="button" class="btn btn-light" :class="{'active': dashboard_timeframe == 'last_month'}" :disabled="is_disabled_overview_group" @click="getLastMonth">Last Month</button>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col">
          <div class="card text-center widget-card border-0 shadow light-widget rounded-3">
            <div class="card-body background-blur">
              <span class="widget-icon">
                <i class="fa-light fa-heat"></i>
              </span>
              <loading-indicator v-if="loading"></loading-indicator>
              <div class="d-flex justify-content-center mt-1 mb-1" v-else>
                <h4 class="fs-4 my-auto" >{{ parseFloat(total_emissions).toFixed(3) }}</h4>
                <p class="text-muted my-auto"><span class="text-nowrap ms-2 align-middle">{{ global_unit + ' ' + 'CO2e' }}</span></p>
              </div>
              <span class="text-muted fw-bold mt-0 fs-6" title="Total Emissions">Total Emissions</span>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card text-center widget-card border-0 shadow light-widget rounded-3">
            <div class="card-body background-blur">
              <div class="widget-icon">
                <i class="fa-light fa-industry-windows"></i>
              </div>
              <loading-indicator v-if="loading"></loading-indicator>
              <div class="d-flex justify-content-center mt-1 mb-1" v-else>
                <h4 class="fs-4 my-auto">{{ parseFloat(total_scope_1).toFixed(3) }}</h4>
                <p class="text-muted my-auto"><span class="text-nowrap ms-2 align-middle">{{ global_unit + ' ' + 'CO2e' }}</span></p>
              </div>
              <span class="text-muted fw-normal mt-0 fs-6" title="Scope 1">Scope 1 Emissions</span>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card text-center widget-card border-0 shadow light-widget rounded-3">
            <div class="card-body background-blur">
              <div class="widget-icon">
                <i class="fa-light fa-plug-circle-bolt"></i>
              </div>
              <loading-indicator v-if="loading"></loading-indicator>
              <div class="d-flex justify-content-center mt-1 mb-1" v-else>
                <h4 class="fs-4 my-auto">{{ parseFloat(total_scope_2).toFixed(3) }}</h4>
                <p class="text-muted my-auto"><span class="text-nowrap ms-2 align-middle">{{ global_unit + ' ' + 'CO2e' }}</span></p>
              </div>
              <span class="text-muted fw-normal mt-0 fs-6" title="Scope 2">Scope 2 Emissions</span>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card text-center widget-card border-0 shadow light-widget rounded-3">
            <div class="card-body background-blur">
              <div class="widget-icon">
                <i class="fa-light fa-truck-plane"></i>
              </div>
              <loading-indicator v-if="loading"></loading-indicator>
              <div class="d-flex justify-content-center mt-1 mb-1" v-else>
                <h4 class="fs-4 my-auto">{{ parseFloat(total_scope_3).toFixed(3) }}</h4>
                <p class="text-muted my-auto"><span class="text-nowrap ms-2 align-middle">{{ global_unit + ' ' + 'CO2e' }}</span></p>
              </div>
              <span class="text-muted fw-normal mt-0 fs-6" title="Scope 3">Scope 3 Emissions</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-5 pb-4">
          <div class="card border-0 shadow light-widget rounded-3">
            <div class="card-body background-blur">
              <h5 class="text-muted fw-normal mt-0 mb-0 fs-5" title="Emissions Overview">Emissions Overview</h5>
              <div class="d-flex justify-content-center pt-4">
                <div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
                  <button type="button" class="btn btn-light" :class="{'active': overview_group === 'category'}" :disabled="is_disabled_overview_group" @click="getGroup('category')">Emission Category</button>
                  <button type="button" class="btn btn-light" :class="{'active': overview_group === 'subsidiary'}" :disabled="is_disabled_overview_group" @click="getGroup('subsidiary')" v-if="enabled_features.subsidiaries">Subsidiary</button>
                  <button type="button" class="btn btn-light" :class="{'active': overview_group === 'location'}" :disabled="is_disabled_overview_group" @click="getGroup('location')" v-if="enabled_features.locations">Location</button>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="mt-2 chart-container" style="position: relative;">
                  <canvas :id="chart_id"></canvas>
                </div>
              </div>
            </div>
          </div>

          <div class="card border-0 shadow light-widget rounded-3 mt-4">
            <div class="card-body background-blur">
              <div class="float-end mb-2">
                <a href="https://carbon-suite.com/blog/?filter_category=reduction-strategies" class="btn btn-sm btn-primary btn-lg" target="_blank">View Strategies</a>
              </div>
              <h5 class="text-muted fw-normal mt-0 mb-0 fs-5" title="Emission Categories">Reduction Plans</h5>
            </div>
            <div class="list-group list-group-flush">
              <div class="list-group-item d-flex justify-content-between align-items-center" aria-current="true">
                <div class="d-flex">
                  <span class="widget-icon widget-icon-yellow rounded-circle">
                    <i class="fa-light fa-bolt"></i>
                  </span>
                  <span class="ms-2 my-auto">Optimize Electricity Usage</span>
                </div>
                <div class="btn-group" role="group" aria-label="Actions">
                  <a href="https://carbon-suite.com/optimize-electricity-usage/" target="_blank" class="btn btn-sm btn-link"><i class="fa-light fa-chevron-right"></i></a>
                </div>
              </div>
              <div class="list-group-item d-flex justify-content-between align-items-center" aria-current="true">
                <div class="d-flex">
                  <span class="widget-icon widget-icon-success rounded-circle">
                    <i class="fa-light fa-truck-bolt"></i>
                  </span>
                  <span class="ms-2 my-auto">Provide Transit Benefit </span>
                </div>
                <div class="btn-group" role="group" aria-label="Actions">
                  <a href="https://carbon-suite.com/provide-transit-benefits/" target="_blank" class="btn btn-sm btn-link"><i class="fa-light fa-chevron-right"></i></a>
                </div>
              </div>
              <div class="list-group-item d-flex justify-content-between align-items-center" aria-current="true">
                <div class="d-flex">
                  <span class="widget-icon widget-icon-primary rounded-circle">
                    <i class="fa-light fa-arrow-down-to-arc"></i>
                  </span>
                  <span class="ms-2 my-auto">Purchase Offsets</span>
                </div>
                <div class="btn-group" role="group" aria-label="Actions">
                  <a href="https://carbon-suite.com/purchase-offsets/" target="_blank" class="btn btn-sm btn-link"><i class="fa-light fa-chevron-right"></i></a>
                </div>
              </div>
              <div class="list-group-item d-flex justify-content-between align-items-center" aria-current="true">
                <div class="d-flex">
                  <span class="widget-icon widget-icon-warning rounded-circle">
                    <i class="fa-light fa-recycle"></i>
                  </span>
                  <span class="ms-2 my-auto">Implement Recycling Program</span>
                </div>
                <div class="btn-group" role="group" aria-label="Actions">
                  <a href="https://carbon-suite.com/implement-recycling-program/" target="_blank" class="btn btn-sm btn-link"><i class="fa-light fa-chevron-right"></i></a>
                </div>
              </div>
              <div class="list-group-item d-flex justify-content-between align-items-center" aria-current="true">
                <div class="d-flex">
                  <span class="widget-icon widget-icon-danger rounded-circle">
                    <i class="fa-light fa-handshake"></i>
                  </span>
                  <span class="ms-2 my-auto">Develop Partnerships</span>
                </div>
                <div class="btn-group" role="group" aria-label="Actions">
                  <a href="https://carbon-suite.com/develop-partnerships/" target="_blank" class="btn btn-sm btn-link"><i class="fa-light fa-chevron-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-7">
          <div class="card border-0 shadow light-widget rounded-3">
            <loading-indicator v-if="loading"></loading-indicator>
            <div class="card-body background-blur" v-else>
              <div class="float-end mb-2">
                <router-link :to="{name: 'emission_sources'}" class="btn btn-sm btn-primary btn-lg">View Sources</router-link>
              </div>
              <h5 class="text-muted fw-normal mt-0 mb-0 fs-5" title="Emission Categories">Emission Categories</h5>
              <table class="table table-borderless pt-2">
                <thead>
                <tr>
                  <th scope="col"><span class="fw-bold text-muted">Name</span></th>
                  <th scope="col"><span class="fw-bold text-muted">Emissions</span></th>
                  <th scope="col"><span class="fw-bold text-muted">Offsets</span></th>
                  <th scope="col"><span class="fw-bold text-muted">Net Emissions</span></th>
                  <th scope="col"><span class="fw-bold text-muted">Target</span></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(emcat, index) in emission_categories" :key="index">
                  <td>
                    <span class="ms-2 badge text-white" :class="{'text-bg-warning': emcat.scope === 'Scope 1', 'text-bg-success': emcat.scope === 'Scope 2', 'text-bg-danger': emcat.scope === 'Scope 3'}">
                      {{ emcat.scope }}
                    </span>
                    <span class="ms-1">{{ emcat.name }}</span>
                  </td>
                  <td>{{ parseFloat(emcat.emissions).toFixed(3) }}</td>
                  <td>{{ emcat.offsets }}</td>
                  <td>{{ parseFloat(emcat.net_emissions).toFixed(3) }}</td>
                  <td>{{ emcat.target }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useAuthStore} from "@/stores/auth";
import {useRestletStore} from "@/stores/restlets";
import LoadingIndicator from "@/components/global/LoadingIndicator";

import {onMounted, ref, watch} from "vue";
import {storeToRefs} from "pinia/dist/pinia";
import axios from 'axios';
import chart_background_colors from "@/utils/charts/chart_background_colors";
import Chart from 'chart.js/auto';
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export default {
  components: {
    LoadingIndicator,
  },

  setup()
  {
    const auth_store = useAuthStore();
    const {global_unit} = storeToRefs(auth_store);
    const {enabled_features} = storeToRefs(auth_store);
    const restlet_store = useRestletStore();
    const {emission_dashboard_restlet_url} = storeToRefs(restlet_store);
    const background_url = process.env.BASE_URL + 'images/unsplash-clouds.jpg';
    const background_styles = 'background: fixed; background: url(' + background_url +'); background-size: cover;';
    const loading = ref(true);
    const total_emissions = ref(0);
    const total_scope_1 = ref(0);
    const total_scope_2 = ref(0);
    const total_scope_3 = ref(0);
    const emissions_overview = ref([]);
    const emission_categories = ref([]);
    const chart_labels = ref([]);
    const chart_numbers = ref([]);
    const chart_colors = ref([]);
    const chart_config = ref({
      type: 'doughnut',
      data: {},
      options: {
        responsive: true,
        aspectRatio: 1.5,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
            text: 'Emission Breakdown',
          }
        }
      },
    });
    const chart = ref(null);
    const chart_id = ref(null);
    const dashboard_timeframe = ref('ytd');
    const overview_group = ref('category');
    const is_disabled_overview_group = ref(false);
    const start_date = ref(null);
    const end_date = ref(null);

    function renderChart()
    {
      let chart_node = document.getElementById(chart_id.value);

      if(chart.value)
      {
        chart.value.destroy();
      }
  
      chart.value = new Chart(chart_node, chart_config.value);

      setTimeout(() => {
        is_disabled_overview_group.value=false;
      }, 2000);
    }

    function getYtd()
    {
      start_date.value = dayjs.utc().startOf('year').format('MM-DD-YYYY');
      end_date.value = dayjs.utc().startOf('day').format('MM-DD-YYYY');
      dashboard_timeframe.value = 'ytd';
      getDashboardData();
    }

    function getLast3Months()
    {
      start_date.value = dayjs.utc().subtract(3, 'month').format('MM-DD-YYYY');
      end_date.value = dayjs.utc().startOf('day').format('MM-DD-YYYY');
      dashboard_timeframe.value = 'last_3_months';
      getDashboardData();
    }

    function getLastMonth()
    {
      start_date.value = dayjs.utc().subtract(1, 'month').format('MM-DD-YYYY');
      end_date.value = dayjs.utc().startOf('day').format('MM-DD-YYYY');
      dashboard_timeframe.value = 'last_month';
      getDashboardData();
    }

    function getGroup(group)
    {
      overview_group.value = group;
      getDashboardData();
    }

    function getDashboardData()
    {

      loading.value = true;
      is_disabled_overview_group.value=true;
      let source_params = {
        group: overview_group.value,
        start_date: start_date.value,
        end_date: end_date.value
      };
  
      let query = new URLSearchParams(source_params).toString();
      let endpoint = emission_dashboard_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            total_emissions.value = Number(response.data.total_emissions);
            total_scope_1.value = Number(response.data.total_scope_1);
            total_scope_2.value = Number(response.data.total_scope_2);
            total_scope_3.value = Number(response.data.total_scope_3);
            emissions_overview.value = response.data.emissions_overview;

            chart_labels.value = [];
            chart_numbers.value = [];
            emissions_overview.value.sort((a, b) => (a.total < b.total) ? 1: -1).forEach(em => {
              chart_labels.value.push(em['name']);
              chart_numbers.value.push(em['total']);
            });

            chart_config.value.data = {
              labels: chart_labels.value,
              datasets: [
                {
                  label: 'Dataset 1',
                  data: chart_numbers.value,
                  backgroundColor: chart_colors.value,
                }
              ]
            }

            console.log("==========", chart_config)

            emission_categories.value = response.data.emission_categories;
            loading.value = false;
            renderChart();
          });
    }

    function prepareComponent()
    {
      dayjs.extend(utc);
      start_date.value = dayjs.utc().startOf('year').format('MM-DD-YYYY');
      end_date.value = dayjs.utc().startOf('day').format('MM-DD-YYYY');
      chart_id.value = 'chart_' + Math.floor(Math.random() * 99999);
    }

    onMounted(() => {
      chart_colors.value = chart_background_colors;
      getDashboardData();
    });

    prepareComponent();

    return {
      global_unit,
      background_url,
      background_styles,
      loading,
      total_emissions,
      total_scope_1,
      total_scope_2,
      total_scope_3,
      emissions_overview,
      emission_categories,
      chart_config,
      chart,
      chart_id,
      dashboard_timeframe,
      overview_group,
      start_date,
      end_date,
      getYtd,
      getLast3Months,
      getLastMonth,
      getGroup,
      is_disabled_overview_group,
      enabled_features,
    }
  }
}
</script>
