<template>
  <div class="responsive-table">
    <table class="table table-striped w-auto">
        <thead>
            <tr>
            <th scope="col">ID</th>
            <th scope="col">GHG Period</th>
            <th scope="col">Amount</th>
            <th scope="col">Unit</th>
            <th scope="col">Emission Source</th>
            <th scope="col">Emission Category</th>
            <th scope="col">Activity Type</th>
            <th scope="col" v-if="activetab != 2">Vendor</th>
            <th scope="col" v-if="activetab == 2">Employee</th>
            <th scope="col">Location</th>
            <th scope="col" v-if="activetab != 2">Item</th>
            <th scope="col" v-if="activetab == 2">Expense Category</th>
            <th scope="col">Action</th> 
            </tr>
        </thead>
        <tbody>
            <tr v-for="workbench in workbenchTableLists" :class="{'bg-success': updated_transaction_id == workbench.transaction_id}">
            <td>{{ workbench.transaction_number }}</td>
            <td>{{ workbench.ghg_period_name }}</td>
            <td :class="{'bg-yellow': (workbench.amount == 0 && filter_status == 'incomplete')}">{{ parseFloat(workbench.amount).toFixed(2) }}</td>
            <td>{{ workbench.ghg_unit_name }}</td>
            <td>{{ workbench.emission_source }}</td>
            <td>{{ workbench.emission_category_name || '' }}</td>
            <td>{{ workbench.activity_type_name }}</td>
            <td v-if="activetab != 2">{{ workbench.vendor_name }}</td>
            <td v-if="activetab == 2">{{ workbench.employee_name }}</td>
            <td>{{ workbench.location_name }}</td>
            <td v-if="activetab != 2">{{ workbench.item_name }}</td>
            <td v-if="activetab == 2">{{ workbench.expense_category_name || '' }}</td>
            <td><button type="button" :disabled="isLoadedSourceData" @click="$emit('getTransactionById', workbench.transaction_id)" class="btn btn-link edit-btn">Edit</button></td>
            </tr>
        </tbody>
    </table>

    <div class="d-flex fw-bold" v-if="filter_status == 'incomplete'">
      <div class="p-3 mb-2 bg-yellow w-10"></div>
      <span class="ms-3 mt-2">No Amount found</span>
    </div>
  </div>
</template>

<script>
export default {
    name: "WorkbenchTable",
    emits: ['getTransactionById'],

    props: {
        activetab: Number,
        filter_status: String,
        workbenchTableLists:Array,
        updated_transaction_id:[String, Number],
        isLoadedSourceData: Boolean
    },

    data(){
        return {

        }
    }
}
</script>

<style scoped>
.report-tab-content{
 padding: 1.95rem;
 background: #fff;
 margin-top: -3px;
 z-index: 1;
 position: relative;
}
.report-tab-content .btn-primary{
font-size: 1rem;
padding-left: 1.5rem;
 padding-right: 1.5rem;
}
.report-tab-content .btn-primary.active{
background: #3D485A;
}

.report-tab > li > a{
cursor: pointer;
padding-left: 1.95rem;
 padding-right: 1.95rem;
}
a.nav-link.custom-nav {
 border-radius: 6px;
 border: 0;
 font-size: 1rem;
 font-weight: 700;
 color:#24385B;
}
a.nav-link.custom-nav.active{
color:#2D3648;
}

.table-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem; 
  font-size: 1rem;
}
.table-actions h4 {
  font-size: 1.2rem !important;
}
.table-actions .fa-chevron-left{
margin-left: 0.5rem;
 margin-right: 0.8rem;
}
table{
table-layout: fixed;
border-right:1px solid #d9e9e8;
border-top:1px solid #d9e9e8;
min-width: 100%;
}
thead{
background: #EEEEEE;
}
tr > th,
tr > td{
padding:1.5rem 0.5rem;
/* width: 135px; */
box-shadow: inset 1px 0px rgb(217 233 232);
font-size: 14px !important;
}

tr > th{
  font-weight: bold !important;
}

/* th:first-child,
td:first-child{
vertical-align: middle;
width: 38px;
} */
 
/* th:nth-child(1){
  width: 100px;
}
th:nth-child(2){
  width: 128px;
}
th:nth-child(4){
  width: 100px;
} */
th:nth-child(3), td:nth-child(3) {
  text-align: right;
}
th:last-child,
td:last-child{
  position: sticky;
  right: 0;
  /* width: 70px; */
}
th:last-child{
  background: #eee;
  top: 0;
  z-index: 1;
}
td:last-child{
  background: #fff;
}

td > input{
margin-left: 0.5rem;
}

.edit-btn{
color:#24385B;
font-weight: bold;
}
.responsive-table {
    overflow: auto;
    /* width: calc(100vw - 392px); */
}
.mr-10 {
    margin-right: 10px;
  }
.cursor-pointer {
  cursor: pointer;
}
.font-size-14 {
  font-size: 14px !important;
}

tr > td, tr > th {
  padding: 0.5rem 0.5rem;
  vertical-align: middle;
}
.results-heading{
  margin-top: 5px;
  margin-bottom: 8px;
}

.bg-yellow{
  background-color: yellow;
}


.w-10{
  width: 10px;
}
</style>