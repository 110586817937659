<template>
  <div class="container-fluid">
     <h4 class="page-title">Emission Workbench</h4>
     <div className="d-flex justify-content-between align-items-start">
       <ul class="nav nav-tabs report-tab d-md-flex justify-content-start my-0 px-0" style="list-style-type: none;">
         <li class ="nav-item"> <a @click="handleTabClick($event)" class="nav-link custom-nav" :class="activetab === 1 ? 'active' : ''" aria-current="page">Vendor Bills</a></li>
         <li class ="nav-item"> <a @click="handleTabClick($event)" class="nav-link custom-nav" :class="activetab === 2 ? 'active' : ''" aria-current="page">Expense Reports</a></li>
         <li class ="nav-item"> <a @click="handleTabClick($event)" class="nav-link custom-nav" :class="activetab === 3 ? 'active' : ''" aria-current="page">Credit Card Transactions</a></li>
     </ul>
     <router-link :to="{name: 'show_job_status'}" class="btn btn-primary job-btn"><i class="fa-light fa-briefcase"></i>Job Status</router-link>
    </div>
    <div class="report-tab-content">
       <div class="tab-content" v-if="activetab === 1">
         <div class="tab-content-header">
           <div class="btn-group" role="group" aria-label="Basic example">
           <button type="button" class="btn btn-primary" :disabled="isWorkbenchLoaded" :class="filter_status === 'failed' ? 'active' : ''" @click="handleFilterStatus('failed')">Failed</button>
           <button type="button" class="btn btn-primary" :disabled="isWorkbenchLoaded" :class="filter_status === 'success' ? 'active' : ''" @click="handleFilterStatus('success')">Success</button>
         </div>
         <div class="input-group date-input">
          <!-- <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-calendar-days"></i></span> -->
          <date-range-picker v-model="dateRange" :showDropdowns="true" @update="updateDateRange($event)" >
            <template style="min-width: 350px;">
            {{ dateRange.startDate }} - {{ dateRange.endDate }}
            </template>
          </date-range-picker>
        </div>
        
         <button type="button" class="btn btn-primary" :disabled="isLoadedSourceData" @click="toggleReportModal()"><i class="me-2" :class="isLoadedSourceData ? 'fa-light fa-rotate fa-2xl fa-spin' : 'fa-solid fa-sliders'"></i>Filters</button>
         <button type="button" class="btn btn-link clear-btn" @click="resetFilters()">Clear Filters</button>
         </div>
         <hr />
         <div class="d-flex flex-wrap">
          <template v-for="(emission_source, index) in selectedEmissions" :key="emission_source.id">
            <div class="filter-btn-label">
              <span>{{emission_source.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'emission', 'vendor_bills')"></i>
            </div>
          </template>
          <template v-for="(emission_cat, index) in selectedEmissionCat" :key="emission_cat.id">
            <div class="filter-btn-label">
              <span>{{emission_cat.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'emission_category', 'vendor_bills')"></i>
            </div>
          </template>
          <template v-for="(ghg_period, index) in selectedGhgPeriods" :key="ghg_period.id">
            <div class="filter-btn-label">
              <span>{{ghg_period.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'ghg_period', 'vendor_bills')"></i>
            </div>
          </template>
          <template v-for="(vendor, index) in selectedVendors" :key="vendor.id">
            <div class="filter-btn-label">
              <span>{{vendor.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'vendors', 'vendor_bills')"></i>
            </div>
          </template>
          <template v-for="(location, index) in selectedLocations" :key="location.id">
            <div class="filter-btn-label">
              <span>{{location.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'locations', 'vendor_bills')"></i>
            </div>
          </template>
          <template v-for="(employee, index) in selectedEmployees" :key="employee.id">
            <div class="filter-btn-label">
              <span>{{employee.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'employees', 'vendor_bills')"></i>
            </div>
          </template>
         </div>
         <div class="toast align-items-center bg-success border-0 show d-none" role="alert" aria-live="assertive" aria-atomic="true">
           <div class="d-flex">
             <div class="toast-body">
               <i class="fa-solid fa-check"></i>Record saved to <button type="button" class="btn btn-link">success</button> tab
             </div>
             <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
           </div>
         </div>

         <loading-indicator v-if="loading"></loading-indicator>
         <div v-else>
          <div v-if="(workbenches.length) > 0">
          <div class="table-actions">
            <h4 class="results-heading">Emissions</h4>
            <div class="arrow-pagination">{{ ((this.currentPage * this.perPage) -this.perPage) + 1 }}-{{ (this.currentPage * this.perPage) < this.totalItems?(this.currentPage * this.perPage):this.totalItems}} of {{ totalItems }} <i class="fa-solid fa-chevron-left cursor-pointer" @click="onClickPaginations(currentPage - 1)" disabled></i> <i class="fa-solid fa-chevron-right cursor-pointer" @click="onClickPaginations(currentPage + 1)"></i></div>
          </div>

           <workbench-table :activetab="activetab" :filter_status="filter_status" :isLoadedSourceData="isLoadedSourceData" :updated_transaction_id="updated_transaction_id" :workbenchTableLists="workbenches" @getTransactionById="getTransactionById"></workbench-table>
           
            <vue-awesome-paginate class="d-flex justify-content-center" v-if="totalPages > 1" :total-items="totalItems" :items-per-page="perPage" :max-pages-shown="3" :on-click="onClickPaginations" v-model="currentPage" />
            </div>
            <div class="not-found-box flex-with-column" v-else>
              <p class="record-not-found">This page shows the list of Emission records that have been created via the Emission Workbench script. There are not currently any Emission records to display within the range selected. To create new Emissions, you can run a new Job on the Job Status page.</p>
              <router-link :to="{name: 'show_job_status'}" class="btn btn-primary job-btn mt-4">Go to job status</router-link>
            </div>
        </div>
       </div>
       <div class="tab-content" v-if="activetab === 2">
         <!-- <div class="toast align-items-center bg-danger border-0 show" role="alert" aria-live="assertive" aria-atomic="true">
           <div class="d-flex">
             <div class="toast-body">
               <i class="fa-regular fa-hexagon-exclamation"></i> Error on record, please retry
             </div>
             <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
           </div>
         </div> -->

         <div class="tab-content-header">
           <div class="btn-group" role="group" aria-label="Basic example">
           <button type="button" class="btn btn-primary" :disabled="isWorkbenchLoaded" :class="filter_status_report === 'failed' ? 'active' : ''" @click="handleFilterStatus('failed')">Failed</button>
           <button type="button" class="btn btn-primary" :disabled="isWorkbenchLoaded" :class="filter_status_report === 'success' ? 'active' : ''" @click="handleFilterStatus('success')">Success</button>
         </div>
         <div class="input-group date-input">
          <!-- <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-calendar-days"></i></span> -->
          <date-range-picker v-model="dateRangeReport" :showDropdowns="true" @update="updateDateRange($event)" >
            <template style="min-width: 350px;">
            {{ dateRangeReport.startDate }} - {{ dateRangeReport.endDate }}
            </template>
          </date-range-picker>
        </div>
        
         <button type="button" class="btn btn-primary" :disabled="isLoadedSourceData" @click="toggleReportModal()"><i class="me-2" :class="isLoadedSourceData ? 'fa-light fa-rotate fa-2xl fa-spin' : 'fa-solid fa-sliders'"></i>Filters</button>
         <button type="button" class="btn btn-link clear-btn" @click="resetFilters()">Clear Filters</button>
         </div>
         <hr />
         <div class="d-flex flex-wrap">
          <template v-for="(emission_source, index) in selectedEmissionsReport" :key="emission_source.id">
            <div class="filter-btn-label">
              <span>{{emission_source.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'emission', 'expense_reports')"></i>
            </div>
          </template>
          <template v-for="(emission_cat, index) in selectedEmissionCatReport" :key="emission_cat.id">
            <div class="filter-btn-label">
              <span>{{emission_cat.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'emission_category', 'expense_reports')"></i>
            </div>
          </template>
          <template v-for="(ghg_period, index) in selectedGhgPeriodsReport" :key="ghg_period.id">
            <div class="filter-btn-label">
              <span>{{ghg_period.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'ghg_period', 'expense_reports')"></i>
            </div>
          </template>
          <template v-for="(vendor, index) in selectedVendorsReport" :key="vendor.id">
            <div class="filter-btn-label">
              <span>{{vendor.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'vendors', 'expense_reports')"></i>
            </div>
          </template>
          <template v-for="(location, index) in selectedLocationsReport" :key="location.id">
            <div class="filter-btn-label">
              <span>{{location.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'locations', 'expense_reports')"></i>
            </div>
          </template>
          <template v-for="(employee, index) in selectedEmployeesReport" :key="employee.id">
            <div class="filter-btn-label">
              <span>{{employee.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'employees', 'expense_reports')"></i>
            </div>
          </template>
         </div>
         <div class="toast align-items-center bg-success border-0 show d-none" role="alert" aria-live="assertive" aria-atomic="true">
           <div class="d-flex">
             <div class="toast-body">
               <i class="fa-solid fa-check"></i>Record saved to <button type="button" class="btn btn-link">success</button> tab
             </div>
             <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
           </div>
         </div>

         <loading-indicator v-if="loading_report"></loading-indicator>
         <div v-else>
          <div v-if="(expenseReports.length) > 0">
          <div class="table-actions">
            <h4 class="results-heading">Emissions</h4>
            <div class="arrow-pagination">{{ ((currentPageReport * perPageReport) - perPageReport) + 1 }}-{{ (currentPageReport * perPageReport) < totalItemsReport?(currentPageReport * perPageReport):totalItemsReport}} of {{ totalItemsReport }} <i class="fa-solid fa-chevron-left cursor-pointer" @click="onClickReportPaginations(currentPageReport - 1)" disabled></i> <i class="fa-solid fa-chevron-right cursor-pointer" @click="onClickReportPaginations(currentPageReport + 1)"></i></div>
          </div>
          
          <workbench-table :activetab="activetab" :filter_status="filter_status_report" :isLoadedSourceData="isLoadedSourceData" :updated_transaction_id="updated_transaction_id" :workbenchTableLists="expenseReports" @getTransactionById="getTransactionById"></workbench-table>

            <vue-awesome-paginate class="d-flex justify-content-center" v-if="totalPagesReport > 1" :total-items="totalItemsReport" :items-per-page="perPageReport" :max-pages-shown="3" :on-click="onClickReportPaginations" v-model="currentPageReport" />
            </div>
            <div class="not-found-box flex-with-column" v-else>
              <p class="record-not-found">This page shows the list of Emission records that have been created via the Emission Workbench script. There are not currently any Emission records to display within the range selected. To create new Emissions, you can run a new Job on the Job Status page.</p>
              <router-link :to="{name: 'show_job_status'}" class="btn btn-primary job-btn mt-4">Go to job status</router-link>
            </div>
        </div>
       </div>
       <div class="tab-content" v-if="activetab === 3">
        <div class="tab-content-header">
           <div class="btn-group" role="group" aria-label="Basic example">
           <button type="button" class="btn btn-primary" :disabled="isWorkbenchLoaded" :class="filter_status_transaction === 'failed' ? 'active' : ''" @click="handleFilterStatus('failed')">Failed</button>
           <button type="button" class="btn btn-primary" :disabled="isWorkbenchLoaded" :class="filter_status_transaction === 'success' ? 'active' : ''" @click="handleFilterStatus('success')">Success</button>
         </div>
         <div class="input-group date-input">
          <!-- <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-calendar-days"></i></span> -->
          <date-range-picker v-model="dateRangeTransaction" :showDropdowns="true" @update="updateDateRange($event)" >
            <template style="min-width: 350px;">
            {{ dateRangeTransaction.startDate }} - {{ dateRangeTransaction.endDate }}
            </template>
          </date-range-picker>
        </div>
        
         <button type="button" class="btn btn-primary" :disabled="isLoadedSourceData" @click="toggleReportModal()"><i class="me-2" :class="isLoadedSourceData ? 'fa-light fa-rotate fa-2xl fa-spin' : 'fa-solid fa-sliders'"></i>Filters</button>
         <button type="button" class="btn btn-link clear-btn" @click="resetFilters()">Clear Filters</button>
         </div>
         <hr />
         <div class="d-flex flex-wrap">
          <template v-for="(emission_source, index) in selectedEmissionsTransaction" :key="emission_source.id">
            <div class="filter-btn-label">
              <span>{{emission_source.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'emission', 'credit_card_transactions')"></i>
            </div>
          </template>
          <template v-for="(emission_cat, index) in selectedEmissionCatTransaction" :key="emission_cat.id">
            <div class="filter-btn-label">
              <span>{{emission_cat.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'emission_category', 'credit_card_transactions')"></i>
            </div>
          </template>
          <template v-for="(ghg_period, index) in selectedGhgPeriodsTransaction" :key="ghg_period.id">
            <div class="filter-btn-label">
              <span>{{ghg_period.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'ghg_period', 'credit_card_transactions')"></i>
            </div>
          </template>
          <template v-for="(vendor, index) in selectedVendorsTransaction" :key="vendor.id">
            <div class="filter-btn-label">
              <span>{{vendor.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'vendors', 'credit_card_transactions')"></i>
            </div>
          </template>
          <template v-for="(location, index) in selectedLocationsTransaction" :key="location.id">
            <div class="filter-btn-label">
              <span>{{location.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'locations', 'credit_card_transactions')"></i>
            </div>
          </template>
          <template v-for="(employee, index) in selectedEmployeesTransaction" :key="employee.id">
            <div class="filter-btn-label">
              <span>{{employee.name}}</span>
              <i class="fa-regular fa-xmark" @click="removeFilters(index, 'employees', 'credit_card_transactions')"></i>
            </div>
          </template>
         </div>
         <div class="toast align-items-center bg-success border-0 show d-none" role="alert" aria-live="assertive" aria-atomic="true">
           <div class="d-flex">
             <div class="toast-body">
               <i class="fa-solid fa-check"></i>Record saved to <button type="button" class="btn btn-link">success</button> tab
             </div>
             <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
           </div>
         </div>

         <loading-indicator v-if="loading_transaction"></loading-indicator>
         <div v-else>
          <div v-if="(cardTransactions.length) > 0">
          <div class="table-actions">
            <h4 class="results-heading">Emissions</h4>
            <div class="arrow-pagination">{{ ((currentPageTransaction * perPageTransaction) - perPageTransaction) + 1 }}-{{ (currentPageTransaction * perPageTransaction) < totalItemsTransaction?(currentPageTransaction * perPageTransaction):totalItemsTransaction}} of {{ totalItemsTransaction }} <i class="fa-solid fa-chevron-left cursor-pointer" @click="onClickTransactionPaginations(currentPageTransaction - 1)" disabled></i> <i class="fa-solid fa-chevron-right cursor-pointer" @click="onClickTransactionPaginations(currentPageTransaction + 1)"></i></div>
          </div>

          <workbench-table :activetab="activetab" :filter_status="filter_status_transaction" :isLoadedSourceData="isLoadedSourceData" :updated_transaction_id="updated_transaction_id" :workbenchTableLists="cardTransactions" @getTransactionById="getTransactionById"></workbench-table>

            <vue-awesome-paginate class="d-flex justify-content-center" v-if="totalPagesTransaction > 1" :total-items="totalItemsTransaction" :items-per-page="perPageTransaction" :max-pages-shown="3" :on-click="onClickTransactionPaginations" v-model="currentPageTransaction" />
            </div>
            <div class="not-found-box flex-with-column" v-else>
              <p class="record-not-found">This page shows the list of Emission records that have been created via the Emission Workbench script. There are not currently any Emission records to display within the range selected. To create new Emissions, you can run a new Job on the Job Status page.</p>
              <router-link :to="{name: 'show_job_status'}" class="btn btn-primary job-btn mt-4">Go to job status</router-link>
            </div>
        </div>
       </div>

    </div>
  </div>

<!--Filter Model Vendor Bills-->
<teleport to="#modal-area">
   <div class="modal fade" tabindex="-1" aria-labelledby="filterReportModal" id="report-modal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
     <div class="modal-dialog modal-md filter-model">
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title modal-heading" id="createSourceModal">Filter options</h5>
           <button type="button" class="btn-close" aria-label="Close" @click="toggleReportModal()"></button>
         </div>
         <div class="modal-body">
          <div class="select-box mb-3">
            <label class="modal-label">Emission Source</label>
            <vue-multiselect v-model="selectedEmissions" placeholder="Search and select emission sources" label="name" :options="emission_sources" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>
          
          <div class="select-box mb-3">
            <label class="modal-label">Emission Category</label>
            <vue-multiselect v-model="selectedEmissionCat" placeholder="Search and select emission category" label="name" :options="emission_categories" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>

          <div class="select-box mb-3">
            <label class="modal-label">GHG Period</label>
            <vue-multiselect v-model="selectedGhgPeriods" placeholder="Search and select GHG Period" label="name" :options="ghg_periods" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>
          <div class="select-box mb-3">
            <label class="modal-label">Vendor</label>
            <vue-multiselect v-model="selectedVendors" id="ajax" label="name" track-by="id" placeholder="Type to search vendor" open-direction="bottom" :options="vendors" :multiple="true" :searchable="true" :loading="isVendorLoading" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="5" :max-height="600" :show-no-results="false" :hide-selected="false" @search-change="asyncFind($event, 'vendors')">
            <template #noResult>Oops! No elements found. Consider changing the search query.</template>
            </vue-multiselect>
          </div>
          <div class="select-box mb-3" v-if="enabled_features.locations">
            <label class="modal-label">Location</label>
            <vue-multiselect v-model="selectedLocations" placeholder="Select Location" label="name" :options="locations" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>
          <div class="select-box">
            <label class="modal-label">Employee</label>
            <vue-multiselect v-model="selectedEmployees" id="ajax" label="name" track-by="id" placeholder="Type to search employee" open-direction="bottom" :options="employees" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="5" :max-height="600" :show-no-results="false" :hide-selected="false" @search-change="asyncFind($event, 'employees')">
            <template #noResult>Oops! No elements found. Consider changing the search query.</template>
          </vue-multiselect>
          </div>
         </div>
         <div class="modal-footer d-flex justify-content-end">
           <div class="d-flex justify-content-end">
             <button type="button" class="btn btn-light mr-10" @click="toggleReportModal()">
               <span class="me-2"><i class="fa-light fa-arrow-circle-left"></i></span>
               <span>Cancel</span>
             </button>
             <button type="button" class="btn btn-primary" @click="getWorkbenches(true, true)">
               <span class="me-2"><i class="fa-light fa-save"></i></span>
               <span>Submit</span>
             </button>
           </div>
         </div>
       </div>
     </div>
   </div>
 </teleport>

<!--Filter Model Expense Reports-->
<teleport to="#modal-area">
   <div class="modal fade" tabindex="-1" aria-labelledby="filterReportModal" id="expense-reports-modal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
     <div class="modal-dialog modal-md filter-model">
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title modal-heading" id="expenseReportModal">Filter options</h5>
           <button type="button" class="btn-close" aria-label="Close" @click="toggleReportModal()"></button>
         </div>
         <div class="modal-body">
          <div class="select-box mb-3">
            <label class="modal-label">Emission Source</label>
            <vue-multiselect v-model="selectedEmissionsReport" placeholder="Search and select emission sources" label="name" :options="emission_sources" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>
          
          <div class="select-box mb-3">
            <label class="modal-label">Emission Category</label>
            <vue-multiselect v-model="selectedEmissionCatReport" placeholder="Search and select emission category" label="name" :options="emission_categories" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>

          <div class="select-box mb-3">
            <label class="modal-label">GHG Period</label>
            <vue-multiselect v-model="selectedGhgPeriodsReport" placeholder="Search and select GHG Period" label="name" :options="ghg_periods" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>
          <div class="select-box mb-3">
            <label class="modal-label">Vendor</label>
            <vue-multiselect v-model="selectedVendorsReport" id="ajax" label="name" track-by="id" placeholder="Type to search vendor" open-direction="bottom" :options="vendors" :multiple="true" :searchable="true" :loading="isVendorLoading" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="5" :max-height="600" :show-no-results="false" :hide-selected="false" @search-change="asyncFind($event, 'vendors')">
            <template #noResult>Oops! No elements found. Consider changing the search query.</template>
            </vue-multiselect>
          </div>
          <div class="select-box mb-3" v-if="enabled_features.locations">
            <label class="modal-label">Location</label>
            <vue-multiselect v-model="selectedLocationsReport" placeholder="Select Location" label="name" :options="locations" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>
          <div class="select-box">
            <label class="modal-label">Employee</label>
            <vue-multiselect v-model="selectedEmployeesReport" id="ajax" label="name" track-by="id" placeholder="Type to search employee" open-direction="bottom" :options="employees" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="5" :max-height="600" :show-no-results="false" :hide-selected="false" @search-change="asyncFind($event, 'employees')">
            <template #noResult>Oops! No elements found. Consider changing the search query.</template>
          </vue-multiselect>
          </div>
         </div>
         <div class="modal-footer d-flex justify-content-end">
           <div class="d-flex justify-content-end">
             <button type="button" class="btn btn-light mr-10" @click="toggleReportModal()">
               <span class="me-2"><i class="fa-light fa-arrow-circle-left"></i></span>
               <span>Cancel</span>
             </button>
             <button type="button" class="btn btn-primary" @click="getWorkbenches(true, true)">
               <span class="me-2"><i class="fa-light fa-save"></i></span>
               <span>Submit</span>
             </button>
           </div>
         </div>
       </div>
     </div>
   </div>
 </teleport>

 <!--Filter Model Credit Card Transactions-->
<teleport to="#modal-area">
   <div class="modal fade" tabindex="-1" aria-labelledby="filterReportModal" id="card-transaction-modal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
     <div class="modal-dialog modal-md filter-model">
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title modal-heading" id="cardTransactionModal">Filter options</h5>
           <button type="button" class="btn-close" aria-label="Close" @click="toggleReportModal()"></button>
         </div>
         <div class="modal-body">
          <div class="select-box mb-3">
            <label class="modal-label">Emission Source</label>
            <vue-multiselect v-model="selectedEmissionsTransaction" placeholder="Search and select emission sources" label="name" :options="emission_sources" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>
          
          <div class="select-box mb-3">
            <label class="modal-label">Emission Category</label>
            <vue-multiselect v-model="selectedEmissionCatTransaction" placeholder="Search and select emission category" label="name" :options="emission_categories" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>

          <div class="select-box mb-3">
            <label class="modal-label">GHG Period</label>
            <vue-multiselect v-model="selectedGhgPeriodsTransaction" placeholder="Search and select GHG Period" label="name" :options="ghg_periods" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>
          <div class="select-box mb-3">
            <label class="modal-label">Vendor</label>
            <vue-multiselect v-model="selectedVendorsTransaction" id="ajax" label="name" track-by="id" placeholder="Type to search vendor" open-direction="bottom" :options="vendors" :multiple="true" :searchable="true" :loading="isVendorLoading" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="5" :max-height="600" :show-no-results="false" :hide-selected="false" @search-change="asyncFind($event, 'vendors')">
            <template #noResult>Oops! No elements found. Consider changing the search query.</template>
            </vue-multiselect>
          </div>
          <div class="select-box mb-3" v-if="enabled_features.locations">
            <label class="modal-label">Location</label>
            <vue-multiselect v-model="selectedLocationsTransaction" placeholder="Select Location" label="name" :options="locations" :multiple="true" :taggable="true" track-by="id"></vue-multiselect>
          </div>
          <div class="select-box">
            <label class="modal-label">Employee</label>
            <vue-multiselect v-model="selectedEmployeesTransaction" id="ajax" label="name" track-by="id" placeholder="Type to search employee" open-direction="bottom" :options="employees" :multiple="true" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="true" :options-limit="300" :limit="5" :max-height="600" :show-no-results="false" :hide-selected="false" @search-change="asyncFind($event, 'employees')">
            <template #noResult>Oops! No elements found. Consider changing the search query.</template>
          </vue-multiselect>
          </div>
         </div>
         <div class="modal-footer d-flex justify-content-end">
           <div class="d-flex justify-content-end">
             <button type="button" class="btn btn-light mr-10" @click="toggleReportModal()">
               <span class="me-2"><i class="fa-light fa-arrow-circle-left"></i></span>
               <span>Cancel</span>
             </button>
             <button type="button" class="btn btn-primary" @click="getWorkbenches(true, true)">
               <span class="me-2"><i class="fa-light fa-save"></i></span>
               <span>Submit</span>
             </button>
           </div>
         </div>
       </div>
     </div>
   </div>
 </teleport>

  <!--Edit Modal-->
  <teleport to="#modal-area">
   <edit-emission-model :toggleEditEmissionModel="toggleEditEmissionModel" :editForm="editForm" :enable_edit="enable_edit" :emission_sources="emission_sources" :periods="ghg_periods" :scopes="scopes" :emission_categories="emission_categories" :source_types="source_types" :subsidiaries="subsidiaries" :departments="departments" :classes="classes" :calculation_approaches="calculation_approaches" :activity_types="activity_types" :fuel_types="fuel_types" :units="units" :custom_emission_factors="custom_emission_factors" :refrigerant_sources="refrigerant_sources"  :emission_source="emission_source" :emission_databases="emission_databases" :locations="locations" :getWorkbenches="getWorkbenches" @updatedTransactionId="updateTransactionId($event)" />
  </teleport>
</template>

<script>
import {useAuthStore} from "@/stores/auth";
import {useRestletStore} from "@/stores/restlets";
import {storeToRefs} from "pinia/dist/pinia";
import { nextTick } from 'vue'
import LoadingIndicator from "@/components/global/LoadingIndicator";
import DateRangePicker from "daterange-picker-vue3";
import "daterange-picker-vue3/dist/daterange-picker-vue3.css";
import axios from "axios";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import VueMultiselect from 'vue-multiselect'
import Filter from "@/helpers/filters";
import EditEmissionModel from "@/components/models/EditEmissionModel";
import WorkbenchTable from "@/views/Workbench/WorkbenchTable"

export default {
  name: "Workbench",
  
 components: {
   LoadingIndicator,
   DateRangePicker,
   VueMultiselect,
   EditEmissionModel,
   WorkbenchTable,
 },

 data()
 { 
  const auth_store = useAuthStore();
  const {enabled_features} = storeToRefs(auth_store);
  const restlet_store = useRestletStore();
  const {scope_restlet_url,custom_emission_factor_restlet_url,emission_sources_restlet_url, entity_search_restlet_url, period_restlet_url, workbench_restlet_url, ghg_transactions_restlet_url} = storeToRefs(restlet_store);
   
   return {
     scope_restlet_url: scope_restlet_url,
     custom_emission_factor_restlet_url: custom_emission_factor_restlet_url,
     emission_sources_restlet_url:emission_sources_restlet_url,
     entity_search_restlet_url: entity_search_restlet_url,
     period_restlet_url: period_restlet_url,
     workbench_restlet_url: workbench_restlet_url,
     ghg_transactions_restlet_url: ghg_transactions_restlet_url,
     enabled_features:enabled_features,
     isWorkbenchLoaded:false,
     loading:false,
     isVendorLoading: false,
     isLoading: false,
     enable_edit:true,
     isLoadedSourceData: false,
     reportModal:"",
     activetab:1,
     filter_status:"failed",
     currentPage:1,
     totalPages:0,
     perPage:10,
     totalItems:0,
     dateRange: { 
      startDate:new Date('2022-01-01'), 
      endDate: new Date()
    },
    workbenches : [],
    ghg_periods: [],
    employees: [],
    vendors: [],
    emission_sources: [],
    emission_source:{},
    emission_categories : [],
    locations: [],
    source_types: [],
    scopes: [],
    custom_emission_factors: [],
    departments: [],
    classes: [],
    calculation_approaches: [],
    activity_types: [],
    fuel_types: [],
    units: [],
    subsidiaries: [],
    refrigerant_sources:[],
    emission_databases:[],
    selectedEmissions:[],
    selectedEmissionCat:[],
    selectedLocations:[],
    selectedGhgPeriods:[],
    selectedEmployees:[],
    selectedVendors:[],
    editForm:{},
    editEmissionModel:"",
    updated_transaction_id:0,

    filter_status_report:"failed", //start filter_status_transaction
    exponseReportModal:"",
    loading_report: false,
    currentPageReport:1,
    totalPagesReport:0,
    perPageReport:10,
    totalItemsReport:0,
    dateRangeReport: { 
    startDate:new Date('2022-01-01'), 
    endDate: new Date()
    },
    selectedEmissionsReport:[],
    selectedEmissionCatReport:[],
    selectedLocationsReport:[],
    selectedGhgPeriodsReport:[],
    selectedEmployeesReport:[],
    selectedVendorsReport:[],
    expenseReports:[],

    filter_status_transaction:"failed", //start Credit Card Transactions
    exponseTransactionModal:"",
    loading_transaction: false,
    currentPageTransaction:1,
    totalPagesTransaction:0,
    perPageTransaction:10,
    totalItemsTransaction:0,
    dateRangeTransaction: { 
    startDate:new Date('2022-01-01'), 
    endDate: new Date()
    },
    selectedEmissionsTransaction:[],
    selectedEmissionCatTransaction:[],
    selectedLocationsTransaction:[],
    selectedGhgPeriodsTransaction:[],
    selectedEmployeesTransaction:[],
    selectedVendorsTransaction:[],
    cardTransactions:[],
   }
 },

 mounted(){
  nextTick(() => {
        this.reportModal = new bootstrap.Modal(document.getElementById('report-modal'));
        this.exponseReportModal = new bootstrap.Modal(document.getElementById('expense-reports-modal'));
        this.exponseTransactionModal = new bootstrap.Modal(document.getElementById('card-transaction-modal'));
        this.editEmissionModel = new bootstrap.Modal(document.getElementById('edit_emission_modal'));
        this.getWorkbenches();
        this.getSources('sources_with_filters', true);
        this.getGhgPeriods();
        this.getScopes();
        this.getCustomEmissionFactors();
      });
 },

 methods: {
   handleTabClick(event){
    const currentElm = event.target.parentElement;
    const getIndex = Array.from(currentElm.parentElement.children).indexOf(currentElm) + 1
    this.activetab = getIndex;
    
    var is_called_api = false;
    if(getIndex == 2 && this.expenseReports.length == 0){
      is_called_api = true;
    } else if(getIndex == 3 && this.cardTransactions.length == 0){
      is_called_api = true;
    }

    if(is_called_api){
      //if tab list api is already called then don't call again api when clicked on tab
      this.getWorkbenches();
    }
   },

   handleFilterStatus(status){
    if(this.activetab == 2)
	{
      this.filter_status_report = status;
    }
	
	else if(this.activetab == 3)
	{
      this.filter_status_transaction = status;
    }
	
	else {
      this.filter_status = status;
    }
    this.getWorkbenches(true);
   },

   toggleReportModal() {
      if(this.activetab == 2){
        this.exponseReportModal.toggle();
      } else if(this.activetab == 3){
        this.exponseTransactionModal.toggle();
      } else {
        this.reportModal.toggle();
      }
   },

   toggleEditEmissionModel(){
    this.editEmissionModel.toggle();
   },

   updateDateRange(event){
    if(this.activetab == 2){
      this.dateRangeReport.startDate= event.startDate;
      this.dateRangeReport.endDate= event.endDate;
    } else if(this.activetab == 3){
      this.dateRangeTransaction.startDate= event.startDate;
      this.dateRangeTransaction.endDate= event.endDate;
    } else {
      this.dateRange.startDate= event.startDate;
      this.dateRange.endDate= event.endDate;
    }
    this.getWorkbenches(true);
   },
  
   getScopes(){
        let source_params = {};
  
        let query = new URLSearchParams(source_params).toString();
        let endpoint = this.scope_restlet_url + '&' + query;
  
        axios.get(endpoint)
            .then(response => {
              this.scopes = response.data.scopes;
            })
            .catch(err => {
              console.log(err);
            });
  },
  
  getCustomEmissionFactors()
    {
      let params = {};
  
      let query = new URLSearchParams(params).toString();
      let endpoint = this.custom_emission_factor_restlet_url + '&' + query;
  
      axios.get(endpoint)
          .then(response => {
            this.custom_emission_factors = response.data.custom_emission_factors;
          })
          .catch(err => {
            console.log(err);
          });
  },

   getSources(query_type = 'sources')
    {
      this.loading = true;
      this.isLoadedSourceData = true;

      let source_params = {};

      source_params['query_type'] = query_type;

      let query = new URLSearchParams(source_params).toString();
      let endpoint = this.emission_sources_restlet_url + '&' + query;

      axios.get(endpoint)
          .then(response => {

              this.emission_sources = response.data.emission_sources;

              if(response.data.emission_categories)
              {
                this.emission_categories = [...new Set(response.data.emission_categories)];
              }

              if(response.data.locations)
              {
                this.locations = response.data.locations;
              }

              if(response.data['classes'])
              {
                this.classes = response.data['classes'];
              }

              if(response.data.departments)
              {
                this.departments = response.data.departments;
              }

              if(response.data.source_types)
              {
                this.source_types = response.data.source_types;
              }

              if(response.data.calculation_approaches)
              {
                this.calculation_approaches = response.data.calculation_approaches;
              }

              if(response.data.activity_types)
              {
                this.activity_types = response.data.activity_types;
              }

              if(response.data.fuel_types)
              {
                this.fuel_types = response.data.fuel_types;
              }

              if(response.data.units)
              {
                this.units = response.data.units;
              }

              if(response.data.emission_databases)
              {
                this.emission_databases = response.data.emission_databases;
              }

              if(response.data.gases)
              {
                this.refrigerant_sources = response.data.gases;
              }

              if(response.data.subsidiaries)
              {
                this.subsidiaries = response.data.subsidiaries;
              }

              this.isLoadedSourceData=false;
          })
          .catch(err => {
            console.log(err);
          });
    },

   getGhgPeriods() {
    let params = {query_type: 'child_periods'};

    let query = new URLSearchParams(params).toString();
    let endpoint = this.period_restlet_url + '&' + query;

    axios.get(endpoint)
        .then(response => {
          this.ghg_periods = response.data['ghg_periods'];
        })
        .catch(err => {
          console.log(err);
        });
    },

   getWorkbenches(is_filter=false, is_model_submit=false)
    {
      this.isWorkbenchLoaded = true;

      if(this.activetab == 2) {
        this.loading_report = true;
        var type = 'expense_reports';

        if(is_filter){
          this.currentPageReport = 1;
        }

        var status = this.filter_status_report;
        var s_date = dayjs.utc(this.dateRangeReport.startDate).format('MM-DD-YYYY');
        var e_date = dayjs.utc(this.dateRangeReport.endDate).format('MM-DD-YYYY');
        var emission_sources = Filter.fetchAllIdValues(this.selectedEmissionsReport, 'id');
        var emission_categories = Filter.fetchAllIdValues(this.selectedEmissionCatReport, 'id');
        var ghg_periods = Filter.fetchAllIdValues(this.selectedGhgPeriodsReport, 'id');
        var vendors = Filter.fetchAllIdValues(this.selectedVendorsReport, 'id');
        var locations = Filter.fetchAllIdValues(this.selectedLocationsReport, 'id');
        var employees = Filter.fetchAllIdValues(this.selectedEmployeesReport, 'id');
        var page = this.currentPageReport;
      } else if(this.activetab == 3){
        this.loading_transaction = true;
        var type = 'credit_card_transactions';

        if(is_filter){
          this.currentPageTransaction = 1;
        }
        var status = this.filter_status_transaction;
        var s_date = dayjs.utc(this.dateRangeTransaction.startDate).format('MM-DD-YYYY');
        var e_date = dayjs.utc(this.dateRangeTransaction.endDate).format('MM-DD-YYYY');
        var emission_sources = Filter.fetchAllIdValues(this.selectedEmissionsTransaction, 'id');
        var emission_categories = Filter.fetchAllIdValues(this.selectedEmissionCatTransaction, 'id');
        var ghg_periods = Filter.fetchAllIdValues(this.selectedGhgPeriodsTransaction, 'id');
        var vendors = Filter.fetchAllIdValues(this.selectedVendorsTransaction, 'id');
        var locations = Filter.fetchAllIdValues(this.selectedLocationsTransaction, 'id');
        var employees = Filter.fetchAllIdValues(this.selectedEmployeesTransaction, 'id');
        var page = this.currentPageTransaction;
      } else{
        this.loading = true;
        var type = 'vendor_bills';

        if(is_filter){
          this.currentPage = 1;
        }
        var status = this.filter_status;
        var s_date = dayjs.utc(this.dateRange.startDate).format('MM-DD-YYYY');
        var e_date = dayjs.utc(this.dateRange.endDate).format('MM-DD-YYYY');
        var emission_sources = Filter.fetchAllIdValues(this.selectedEmissions, 'id');
        var emission_categories = Filter.fetchAllIdValues(this.selectedEmissionCat, 'id');
        var ghg_periods = Filter.fetchAllIdValues(this.selectedGhgPeriods, 'id');
        var vendors = Filter.fetchAllIdValues(this.selectedVendors, 'id');
        var locations = Filter.fetchAllIdValues(this.selectedLocations, 'id');
        var employees = Filter.fetchAllIdValues(this.selectedEmployees, 'id');
        var page = this.currentPage;
      }

      if(is_model_submit){
        this.toggleReportModal();
      }
    
      let source_params = {
        type: type,// vendor_bills | expense_reports | credit_card_transactions
        status: status,// failed | success
        date_from: s_date,
        date_to: e_date,
        emission_sources: emission_sources,
        emission_categories: emission_categories,
        ghg_periods: ghg_periods,
        vendors: vendors,
        locations: locations,
        employees: employees,
        page: page,
        sort_order: "desc", //asc,desc
        sort_by: "transaction_id",
      };

      let query = new URLSearchParams(source_params).toString();
      let endpoint = this.workbench_restlet_url + '&' + query;

      axios.get(endpoint)
          .then(response => { 
            if(type === 'expense_reports'){
              this.expenseReports = response.data.results;
              this.currentPageReport = Number(response.data.current_page);
              this.perPageReport     = 10;
              this.totalPagesReport  = response.data.total_pages;
              this.totalItemsReport  = response.data.total_items;
              this.loading_report = false;
            } else if(type === 'credit_card_transactions'){
              this.cardTransactions = response.data.results;
              this.currentPageTransaction = Number(response.data.current_page);
              this.perPageTransaction     = 10;
              this.totalPagesTransaction  = response.data.total_pages;
              this.totalItemsTransaction  = response.data.total_items;
              this.loading_transaction = false;
            } else{
              this.workbenches = response.data.results;
              this.currentPage = Number(response.data.current_page);
              this.perPage     = 10;
              this.totalPages  = response.data.total_pages;
              this.totalItems  = response.data.total_items;
              this.loading = false;
            }
            
            this.isWorkbenchLoaded = false;
          })
          .catch(err => {
            console.log(err);
          });
    },

    onClickPaginations(page){
      if(page < 1){
        return false;
      }else if(page > this.totalPages){
        return false;
      }
      
      this.currentPage = page;
      this.getWorkbenches();
    },

    onClickReportPaginations(page){
      if(page < 1){
        return false;
      }else if(page > this.totalPagesReport){
        return false;
      }

      this.currentPageReport = page;
      this.getWorkbenches();
    },

    onClickTransactionPaginations(page){
      if(page < 1){
        return false;
      }else if(page > this.totalPagesTransaction){
        return false;
      }

      this.currentPageTransaction = page;
      this.getWorkbenches();
    },

    getTransactionById(txnId) {
      let source_params = {
        query_type: "single_transaction",
        transaction_id: txnId
      };
      let query = new URLSearchParams(source_params).toString();
      let endpoint = this.ghg_transactions_restlet_url + '&' + query;
      axios.get(endpoint)
      .then(response => {
          this.editForm = response.data.emission_transaction;
          this.editForm.date = dayjs.utc(this.editForm.date).format('YYYY-MM-DD');

          this.getSummary(this.editForm.id);
          this.getSource(this.editForm.source_id);

          this.toggleEditEmissionModel();
      })
      .catch(err => {
        console.log(err);
      });
    },
    
    getSummary(transaction_id)
    {
      let source_params = {query_type: 'transaction_summary', transaction_id: transaction_id};

      let query = new URLSearchParams(source_params).toString();
      let endpoint = this.ghg_transactions_restlet_url + '&' + query;

      axios.get(endpoint)
          .then(response => {
            this.editForm.summary = response.data['transaction_summary'];
          })
          .catch(err => {
            this.editForm.summary = [];
          });
    },

    getSource(source_id){
      let source_params = {query_type: 'single_source', source_id: source_id};

      let query = new URLSearchParams(source_params).toString();
      let endpoint = this.emission_sources_restlet_url + '&' + query;

      axios.get(endpoint)
          .then(response => {
            this.emission_source = response.data.emission_source;
          })
          .catch(err => {
            console.log(err);
          });
    },

    //Search Employee & Vendor
    asyncFind (search_value, search_type) {

      if(search_type == 'employees')
        this.isLoading = true;
      else if(search_type == 'vendors')
        this.isVendorLoading = true;
      
      let search_params = {search_term: search_value, entity_type: search_type};

      let query = new URLSearchParams(search_params).toString();
      let endpoint = this.entity_search_restlet_url + '&' + query;

      axios.get(endpoint)
        .then(response => {
          if(search_type == 'employees')
          this.employees = response.data['employees'];
          else if(search_type == 'vendors')
          this.vendors = response.data['vendors'];
          this.isLoading = false;
          this.isVendorLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.isLoading = false;
          this.isVendorLoading = false;
        });
    },

    removeFilters(index, filter_type, tab_type=''){
      if(filter_type == 'emission'){
        if(tab_type === 'expense_reports')
        (this.selectedEmissionsReport).splice(index , 1);
        else if(tab_type === 'credit_card_transactions')
        (this.selectedEmissionsTransaction).splice(index , 1);
        else
        (this.selectedEmissions).splice(index , 1);
      } else if(filter_type == 'emission_category'){
        if(tab_type === 'expense_reports')
        (this.selectedEmissionCatReport).splice(index , 1);
        else if(tab_type === 'credit_card_transactions')
        (this.selectedEmissionCatTransaction).splice(index , 1);
        else
        (this.selectedEmissionCat).splice(index , 1);
      } else if(filter_type == 'ghg_period'){
        if(tab_type === 'expense_reports')
        (this.selectedGhgPeriodsReport).splice(index , 1);
        else if(tab_type === 'credit_card_transactions')
        (this.selectedGhgPeriodsTransaction).splice(index , 1);
        else
        (this.selectedGhgPeriods).splice(index , 1);
      } else if(filter_type == 'locations'){
        if(tab_type === 'expense_reports')
        (this.selectedLocationsReport).splice(index , 1);
        else if(tab_type === 'credit_card_transactions')
        (this.selectedLocationsTransaction).splice(index , 1);
        else
        (this.selectedLocations).splice(index , 1);
      } else if(filter_type == 'vendors'){
        if(tab_type === 'expense_reports')
        (this.selectedVendorsReport).splice(index , 1);
        else if(tab_type === 'credit_card_transactions')
        (this.selectedVendorsTransaction).splice(index , 1);
        else
        (this.selectedVendors).splice(index , 1);
      } else if(filter_type == 'employees'){
        if(tab_type === 'expense_reports')
        (this.selectedEmployeesReport).splice(index , 1);
        else if(tab_type === 'credit_card_transactions')
        (this.selectedEmployeesTransaction).splice(index , 1);
        else
        (this.selectedEmployees).splice(index , 1);
      }

      this.getWorkbenches(true);
    },

    resetFilters(){
      if (this.activetab == 2){
        this.currentPageReport = 1;
        this.filter_status_report = 'failed';
        this.dateRangeReport.startDate = new Date('2022-01-01');
        this.dateRangeReport.endDate = new Date();
        this.selectedEmissionsReport = [];
        this.selectedEmissionCatReport = [];
        this.selectedGhgPeriodsReport = [];
        this.selectedVendorsReport = [];
        this.selectedLocationsReport = [];
        this.selectedEmployeesReport = [];
      } else if (this.activetab == 3){
        this.currentPageTransaction = 1;
        this.filter_status_transaction = 'failed';
        this.dateRangeTransaction.startDate = new Date('2022-01-01');
        this.dateRangeTransaction.endDate = new Date();
        this.selectedEmissionsTransaction = [];
        this.selectedEmissionCatTransaction = [];
        this.selectedGhgPeriodsTransaction = [];
        this.selectedVendorsTransaction = [];
        this.selectedLocationsTransaction = [];
        this.selectedEmployeesTransaction = [];
      } else {
        this.currentPage = 1;
        this.filter_status = 'failed';
        this.dateRange.startDate = new Date('2022-01-01');
        this.dateRange.endDate = new Date();
        this.selectedEmissions = [];
        this.selectedEmissionCat = [];
        this.selectedGhgPeriods = [];
        this.selectedVendors = [];
        this.selectedLocations = [];
        this.selectedEmployees = [];
      }

      this.getWorkbenches();
    },
    
    updateTransactionId(updated_transaction_id){
      this.updated_transaction_id = updated_transaction_id;
    },

 },

}

dayjs.extend(utc);
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.page-title{
 font-size: 1.32rem;
 padding-top: 1.94rem;
 padding-bottom: 1.4rem;
 font-weight: 700;
 color: #2D3648;
 margin:0;
}
.report-tab-content{
 padding: 1.95rem;
 background: #fff;
 margin-top: -3px;
 z-index: 1;
 position: relative;
}
.report-tab-content .btn-primary{
font-size: 1rem;
padding-left: 1.5rem;
 padding-right: 1.5rem;
}
.report-tab-content .btn-primary.active{
background: #3D485A;
}

.report-tab > li > a{
cursor: pointer;
padding-left: 1.95rem;
 padding-right: 1.95rem;
}
a.nav-link.custom-nav {
 border-radius: 6px;
 border: 0;
 font-size: 1rem;
 font-weight: 700;
 color:#24385B;
}
a.nav-link.custom-nav.active{
color:#2D3648;
}
.job-btn{
font-size: 20px;
 min-width: 158px;
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: -1.3rem;
}
.job-btn > .fa-briefcase{
margin-right: 10px;
}
.tab-content-header{
display:flex;
align-items: center;
margin-bottom: 2rem;
}
.input-group-text{
background-color: #607799;
color:#fff;
}

.date-input{
margin: 0 1rem;
max-width: 210px;
}

.table-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem; 
  font-size: 1rem;
}
.table-actions h4 {
  font-size: 1.2rem !important;
}
.table-actions .fa-chevron-left{
margin-left: 0.5rem;
 margin-right: 0.8rem;
}
table{
table-layout: fixed;
border-right:1px solid #d9e9e8;
border-top:1px solid #d9e9e8;
}
thead{
background: #EEEEEE;
}
tr > th,
tr > td{
padding:1.5rem 0.5rem;
width: 117px;
box-shadow: inset 1px 0px rgb(217 233 232);
font-size: 14px !important;
}

tr > th{
  font-weight: bold !important;
}

th:first-child,
td:first-child{
vertical-align: middle;
width: 38px;
}
th:nth-child(3){
  width: 255px;
}
th:nth-child(4){
  width: 200px;
}
th:nth-child(7){
  width: 146px;
}
th:last-child,
td:last-child{
  position: sticky;
  right: 0;
}
th:last-child{
  background: #eee;
  top: 0;
  z-index: 1;
}
td:last-child{
  background: #fff;
}

td > input{
margin-left: 0.5rem;
}

.edit-btn{
color:#24385B;
font-weight: bold;
}
.toast{
 min-width: 700px;
 border-radius: 0;
 font-size: 1.35rem;
 padding: 0.9rem;
 padding-left: 1.8rem;
}
.toast-body{
padding:0;
display: flex;
align-items: center;
}
.toast-body i {
font-size: 2rem;
margin-right:10px;
}
.btn-close {
 width: 0.2em;
 height: 0.2em;
 opacity: 1;
}
.toast.bg-success {
background: #5BB240 !important;
 color: #fff !important;
} 

.toast.bg-danger {
background-color: #B24040 !important;
color:#fff !important;
}
.toast-body button.btn.btn-link {
 color: #fff;
 font-weight: bold;
 padding: 0;
 margin: 0;
 padding-left: 4px;
 padding-right: 4px;
 text-transform: lowercase;
 font-size: 1.2rem;
}
.flex-with-column {
 display: flex;
 align-items: center;
 flex-flow: column;
 margin-top:1rem;
}
.flex-with-column > button{
margin-top:1rem;
}
.filter-btn-label{
border:1px solid #3D485A;
border-radius:5px;
padding:6px 10px;
min-width: 133px;
font-size: 1rem;
margin-bottom: 15px;
color: #3D485A;
}
.filter-btn-label:not(:last-child){
margin-right: 15px;
}
.filter-btn-label > .fa-regular{
margin-left:10px;
cursor: pointer;
}
.filter-btn-label.bg-primary{
  margin-top :10px;
  margin-bottom :5px;
  padding: 6px 10px;
}
.filter-btn-label.bg-primary > .fa-regular{
  margin-left:10px;
  margin-right:0;
}
.filter-model{
  max-width: 600px;
}
.clear-btn{
  color: #24385B;
  font-weight: 700;
  text-decoration: none;
  font-size: 1rem;

}
.responsive-table {
    overflow: auto;
    width: calc(100vw - 392px);
}
.mr-10 {
    margin-right: 10px;
  }
.pagination-container {
  padding-top: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.not-found-box {
  margin-top: 65px;
  margin-bottom: 50px;
}
.font-size-14 {
  font-size: 14px !important;
}

.record-not-found {
  text-align: center;
  font-size: 16px !important;
  width: 80%;
}
.modal-heading {
  font-size: 1.5rem;
  margin: 0;
}
.modal-label {
  font-size: 14px;
}

tr > td, tr > th {
  padding: 0.5rem 0.5rem;
  vertical-align: middle;
}
.arrow-pagination{
  margin-right: 10px;
}
.results-heading{
  margin-top: 5px;
  margin-bottom: 8px;
}
</style>

<style>
.multiselect__option--highlight {
  background-color: #607799 !important;
}
.multiselect__tag {
  background: #607799 !important;
}

.multiselect__tag-icon::after {
  color: white !important;
}
.multiselect__content{
  padding-left: 0 !important;
  margin: 0 !important;
}

button i.fa-2xl{
  font-size: 1.5em !important;
}
</style>
