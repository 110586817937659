<template>
  <div class="container-fluid px-5 my-5">
    <div class="row justify-content-center">
      <div class="card border-0 rounded-3 shadow-lg overflow-hidden">
        <div class="card-body p-0">
          <div class="row g-0">
            <div class="col-6 p-4">
              <div class="text-center">
                <span class="mb-3 fs-1 text-primary"><i class="fa-light fa-download"></i></span>
                <div class="h3 fw-light">Step 1</div>
                <a class="mb-4 text-muted">Download CSV Template</a>
              </div>
            </div>
            <div class="col-6 p-4">
              <div class="text-center">
                <span class="mb-3 fs-1 text-primary"><i class="fa-light fa-upload"></i></span>
                <div class="h3 fw-light">Step 2</div>
                <a class="mb-4 text-muted">Initiate Import Process</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportSources"
}
</script>

<style scoped>

</style>