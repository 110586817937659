<template>
  <div class="col-3 p-3 bg-white min-vh-100" style="width: 280px;">
    <router-link :to="{name: 'home'}" class="d-flex align-items-center justify-content-between pb-3 mb-3 link-dark text-decoration-none">
      <span class="">
          <img :src="publicUrl + 'brand/logo.webp'" alt="" height="100">
      </span>
      <span></span>
      <span></span>
    </router-link>
    <div class="ps-0">

      <div class="mb-1">
        <router-link class="nav-link d-inline-flex align-items-center rounded border-0 collapsed py-1" exact-active-class="text-primary fw-bold" to="/">
          <span class="me-3">
            <i style="width: 18px; height: 18px;" class="fa-light fa-home"></i>
          </span>
          <span class="py-1">Dashboard</span>
        </router-link>
      </div>

      <div class="mb-1">
        <a class="nav-link d-inline-flex align-items-center rounded border-0 collapsed py-1" data-bs-toggle="collapse" href="#modulerecordghg" aria-expanded="false" aria-controls="modulerecordghg" :class="{'text-primary fw-bold': ['emission_workbench', 'emission_source', 'import_sources', 'allocate_assets'].includes(route.name)}">
          <span class="me-3">
            <i style="width: 18px; height: 18px;" class="fa-light fa-forklift"></i>
          </span>
          <span class="py-1">Record</span>
        </a>
        <div class="collapse" id="modulerecordghg" style="">
          <div class="">
            <router-link class="nav-link py-1" exact-active-class="text-primary fw-bold" active-class="text-primary fw-bold" :to="{name: 'emission_sources'}" :class="{'text-primary fw-bold': ['coming_soon', 'emission_source', 'import_sources', 'allocate_assets'].includes(route.name)}">
              <span class="me-3 text-white"><i style="width: 18px; height: 18px;" class="fa-light fa-link"></i></span>
              <span>Emission Sources</span>
            </router-link>

            <router-link class="nav-link py-1" exact-active-class="text-primary fw-bold" active-class="text-primary fw-bold" :to="{name: 'workbench'}" :class="{'text-primary fw-bold': ['workbench'].includes(route.name)}">
              <span class="me-3 text-white"><i style="width: 18px; height: 18px;" class="fa-light fa-screwdriver-wrench"></i></span>
              <span>Emission Workbench</span>
            </router-link>

            <a class="nav-link py-1" :href="link.link" target="_blank" v-for="(link, link_index) in record_links">
              <span class="me-3 text-white"><i style="width: 18px; height: 18px;" class="fa-light fa-link"></i></span>
              <span>{{ link.label }}</span>
            </a>
            
          </div>
        </div>
      </div>
      <div class="mb-1">
        <a class="nav-link  d-inline-flex align-items-center rounded border-0 collapsed py-1" data-bs-toggle="collapse" href="#modulereports" aria-expanded="false" aria-controls="modulereports">
          <span class="me-3">
            <i style="width: 18px; height: 18px;" class="fa-light fa-chart-pie"></i>
          </span>
          <span class="py-1">Report</span>
        </a>
        <div class="collapse" id="modulereports">
          <div class="">
            <router-link class="nav-link py-1" :to="{name: 'emission_report'}" active-class="text-primary fw-bold">
              <span class="me-3 text-white"><i style="width: 18px; height: 18px;" class="fa-light fa-link"></i></span>
              <span>Publish GHG Report</span>
            </router-link>
            <a class="nav-link py-1" :href="link.link" target="_blank" v-for="(link, link_index) in list_links">
              <span class="me-3 text-white"><i style="width: 18px; height: 18px;" class="fa-light fa-link"></i></span>
              <span>{{ link.label }}</span>
              <span class="float-end"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
            </a>
          </div>
        </div>
      </div>
      <div class="mb-1">
        <a class="nav-link d-inline-flex align-items-center rounded border-0 collapsed py-1" data-bs-toggle="collapse" href="#modulereduceghg" aria-expanded="false" aria-controls="modulereduceghg" :class="{'text-primary fw-bold': ['reductions_workbench'].includes(route.name)}">
          <span class="me-3">
            <i style="width: 18px; height: 18px;" class="fa-light fa-hand-holding-seedling"></i>
          </span>
          <span class="py-1">Reduce</span>
        </a>
        <div class="collapse" id="modulereduceghg" style="">
          <div class="">
            <!-- <router-link class="nav-link py-1" exact-active-class="text-primary fw-bold" active-class="text-primary fw-bold" :to="{name: 'reductions_workbench'}" :class="{'text-primary fw-bold': ['reductions_workbench'].includes(route.name)}">
              <span class="me-3 text-white"><i style="width: 18px; height: 18px;" class="fa-light fa-link"></i></span>
              <span>Reduction Workbench</span>
            </router-link> -->
            <a class="nav-link py-1" href="https://netzero.salesforce.com/s/" target="_blank">
              <span class="me-3 text-white"><i style="width: 18px; height: 18px;" class="fa-light fa-link"></i></span>
              <span>NetZero Marketplace</span>
              <span class="float-end"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
            </a>
          </div>
        </div>
      </div>
      <div class="mb-1">
        <a class="nav-link d-inline-flex align-items-center rounded border-0 collapsed py-1" data-bs-toggle="collapse" href="#setup" aria-expanded="false" aria-controls="setup">
          <span class="me-3">
            <i style="width: 18px; height: 18px;" class="fa-light fa-cogs"></i>
          </span>
          <span class="py-1">Setup</span>
        </a>
        <div class="collapse" id="setup" style="">
          <div class="">
            <router-link class="nav-link py-1" exact-active-class="text-primary fw-bold" active-class="text-primary fw-bold" :to="{name: 'accounting_period_list'}">
              <span class="me-3 text-white"><i style="width: 18px; height: 18px;" class="fa-light fa-link"></i></span>
              <span>GHG Periods</span>
            </router-link>
            <a class="nav-link py-1" :href="link.link" target="_blank" v-for="(link, link_index) in setup_links">
              <span class="me-3 text-white"><i style="width: 18px; height: 18px;" class="fa-light fa-link"></i></span>
              <span>{{ link.label }}</span>
              <span class="float-end"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
            </a>
            <!-- <a class="nav-link py-1" href="#">
              <span class="me-3 text-white"><i style="width: 18px; height: 18px;" class="fa-light fa-link"></i></span>
              <span>Roles / Permissions</span>
            </a>
            <a class="nav-link py-1" href="#">
              <span class="me-3 text-white"><i style="width: 18px; height: 18px;" class="fa-light fa-link"></i></span>
              <span>Assign Segments</span>
            </a> -->
          </div>
        </div>
      </div>
      <div class="mt-4 mb-2">
        <span class="my-3 menu-label">Knowledge Base</span>
      </div>
      <div class="mb-1">
        <a class="nav-link align-items-center rounded border-0 collapsed py-1" href="https://www.carbon-suite.com/post/carbon-accounting-101" target="_blank">
          <span class="me-3">
            <i style="width: 18px; height: 18px;" class="fa-light fa-square-question"></i>
          </span>
          <span class="py-1">Carbon Accounting 101</span>
          <span class="float-end"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
        </a>
      </div>
      <div class="mb-1">
        <a class="nav-link align-items-center rounded border-0 collapsed py-1" href="https://www.carbon-suite.com/post/glossary-of-terms" target="_blank">
          <span class="me-3">
            <i style="width: 18px; height: 18px;" class="fa-light fa-book-bookmark"></i>
          </span>
          <span class="py-1">Glossary of Terms</span>
          <span class="float-end"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
        </a>
      </div>
      <div class="mb-1">
        <a class="nav-link align-items-center rounded border-0 collapsed py-1" href="https://carbon-suite.com/blog/?filter_category=reduction-strategies" target="_blank">
          <span class="me-3">
            <i style="width: 18px; height: 18px;" class="fa-light fa-chart-line-down"></i>
          </span>
          <span class="py-1">Reduction Strategies</span>
          <span class="float-end"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
        </a>
      </div>
      <!-- <div class="mb-1">
        <a class="nav-link align-items-center rounded border-0 collapsed py-1" href="https://www.carbon-suite.com/post/frequently-asked-questions" target="_blank">
          <span class="me-3">
            <i style="width: 18px; height: 18px;" class="fa-light fa-square-question"></i>
          </span>
          <span class="py-1">FAQs</span>
          <span class="float-end"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
        </a>
      </div> -->
    </div>
  </div>
</template>

<script>
import {ref, computed} from 'vue';
import {storeToRefs} from "pinia";
import {useRoute} from "vue-router";
import {useLinkStore} from "@/stores/links";

export default {
  setup()
  {
    const route = useRoute();
    const publicUrl = ref(process.env.BASE_URL);
    const link_store = useLinkStore();

    const {links} = storeToRefs(link_store);

    const record_links = computed(() => {
      return links.value.filter(l => {
        return l.location === 'sidebar_record';
      });
    });
   
    var removed_label = ['GHG Transactions', 'Total Emissions'];
    const list_links = computed(() => {
      return links.value.filter(l => {
        return l.location === 'sidebar_lists' && !removed_label.includes(l.label);
      });
    });

    const setup_links = computed(() => {
      return links.value.filter(l => {
        return l.location === 'sidebar_setup';
      });
    });

    return {
      route,
      publicUrl,
      links,
      record_links,
      list_links,
      setup_links,
    }
  }
}
</script>