<template>
  <div class="container-fluid">
    <div class="pt-2">
      <nav class="navbar bg-light">
        <div class="d-flex flex-column mb-2">
          <h4 class="navbar-brand fs-4 my-0">Emission Sources</h4>
        </div>
        <div class="d-flex justify-content-between w-100">
          <div class="d-flex w-90">
            <input class="form-control" type="search" placeholder="Search" aria-label="Search" v-model="search_filter" style="width: 120px;">
            <select class="form-select form-select-sm ms-2" aria-label="Category Filter" v-model="category_filter">
              <option value="" selected>--Emission Category--</option>
              <option v-for="(category, cat_index) in emission_categories" :value="category.id" :key="cat_index">{{ category.name }}</option>
            </select>
            <select class="form-select form-select-sm ms-2" aria-label="Subsidiary Filter" v-model="subsidiary_filter" v-if="enabled_features.subsidiaries">
              <option value="" selected>--Subsidiary--</option>
              <option v-for="(subsidiary, sub_index) in subsidiaries" :value="subsidiary.id" :key="sub_index">{{ subsidiary.name }}</option>
            </select>
            <select class="form-select form-select-sm ms-2" aria-label="Location filter" v-model="location_filter" v-if="enabled_features.locations">
              <option value="" selected>--Location--</option>
              <option v-for="(location, loc_index) in locations" :value="location.id" :key="loc_index">{{ location.name }}</option>
            </select>
            <div class="input-search-filter ms-2 w-120">
             <entity-selection entity_type="vendors" @entity-selected="selectFilterEntity('vendor', $event)" ref="vendor_filter_selector" :initial_value="vendor_filter_value" :placeholder="'Vendor'"></entity-selection>
            </div>
            <div class="input-search-filter ms-2 w-120">
              <list-record-selection record_type="items" @record-selected="selectFilterEntity('item', $event)" ref="item_filter_selector" :initial_value="item_filter_value" :placeholder="'Item'"></list-record-selection>
            </div>
            <div class="input-search-filter ms-2 w-200">
              <list-record-selection record_type="expense_categories" @record-selected="selectFilterEntity('expense_category', $event)" ref="expense_category_filter_selector" :initial_value="expense_category_filter_value" :placeholder="'Expense Category'"></list-record-selection>
            </div>
            <!-- <select class="form-select form-select-sm ms-2" aria-label="Class Filter" v-model="class_filter" v-if="enabled_features.classes">
              <option value="" selected>--Class--</option>
              <option v-for="(ns_class, class_index) in classes" :value="ns_class.id" :key="class_index">{{ ns_class.name }}</option>
            </select>
            <select class="form-select form-select-sm ms-2" aria-label="Department filter" v-model="department_filter" v-if="enabled_features.departments">
              <option value="" selected>--Department--</option>
              <option v-for="(department, dep_index) in departments" :value="department.id" :key="dep_index">{{ department.name }}</option>
            </select> -->
          </div>
          <div class="d-flex">
            <div class="dropdown">
              <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="me-2"><i class="fa-light fa-plus-circle"></i></span>
                <span class="pe-2">Add</span>
              </button>
              <div class="dropdown-menu dropdown-menu-dark dropdown-menu-end">
                <button type="button" class="dropdown-item" @click="toggleCreateModal">New</button>
                <button type="button" class="dropdown-item" @click="openImport">
                  <span>Import</span>
                  <span class="float-end"><i class="fa-regular fa-arrow-up-right-from-square"></i></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <loading-indicator v-if="loading"></loading-indicator>
    <div v-else>

      <div class="accordion accordion-flush" id="categoriesAccordion">
        <div class="accordion-item" v-for="(category, cat_index) in emission_categories" :key="cat_index" :class="{'d-none': !shouldShowCategory(category.id)}">
          <h2 class="accordion-header my-0" :id="'categoryHeading' + category.id">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#categoryCollapse' + category.id" aria-expanded="true" :aria-controls="'categoryCollapse' + category.id">
              <span class="me-2 badge text-white" :class="{'text-bg-warning': category.scope_name === 'Scope 1', 'text-bg-success': category.scope_name === 'Scope 2', 'text-bg-danger': category.scope_name === 'Scope 3'}">
                {{ category.scope_name }}
              </span>
              <span>{{ category.name + ' ' + '(' + getCategorySourceCount(category.id) + ')' }}</span>
            </button>
          </h2>
          <div :id="'categoryCollapse' + category.id" class="accordion-collapse collapse" aria-labelledby="'categoryHeading' + category.id" data-bs-parent="#categoriesAccordion">
            <div class="accordion-body">
              <div class="list-group">
                <div class="list-group-item d-flex justify-content-between align-items-center" :class="{'bg-success': updated_source_id && source.id == updated_source_id}" aria-current="true" v-for="(source, source_index) in getCategorySources(category.id)" :key="source_index">
                  <div class="d-flex">
                    <div style="width: 100px;"><span>{{ source?.number }}</span></div>
                    <div><span>{{ source.name }}</span></div>
                  </div>
                  <div class="btn-group" role="group" aria-label="Actions">
                    <button type="button" class="btn btn-sm btn-link" @click="editSource(source)"><i class="fa-light fa-edit"></i></button>
                    <router-link :to="{name: 'emission_source', params: {source: source.id}}" class="btn btn-sm btn-link"><i class="fa-light fa-eye"></i></router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!--Create Modal-->
  <teleport to="#modal-area">
    <div class="modal fade" tabindex="-1" aria-labelledby="createSourceModal" id="create_modal" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fs-5 my-0" id="createSourceModal">New Emission Source</h5>
            <button type="button" class="btn-close" @click="cancel('create')" aria-label="Close"></button>
          </div>

          <div class="modal-body">
            <h5 class="mt-2 mb-2 fs-5">Main</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Name</label>
                  <input class="form-control form-control-sm" aria-label="Name" :class="{'is-invalid': createForm.errors.name}" type="text" name="name" v-model="createForm.name" />
                  <div class="invalid-feedback" v-if="createForm.errors.name">
                    {{ createForm.errors.name.toString() }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Emission Category</label>
                  <select class="form-select form-select-sm" aria-label="Emission Category" v-model="createForm.category_id" :class="{'is-invalid': createForm.errors.category_id}" @change="selectScope('create')">
                    <option value="" selected>--</option>
                    <option v-for="(category, cat_index) in emission_categories" :value="category.id" :key="cat_index">{{ category.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.category_id">
                    {{ createForm.errors.category_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span>Scope</label>
                  <select class="form-select form-select-sm" aria-label="Scope" v-model="createForm.scope_id" :class="{'is-invalid': createForm.errors.scope_id}" disabled :style="disabledInputCss(true)">
                    <option value="" selected>--</option>
                    <option v-for="(scope, index) in scopes" :value="scope.id" :key="index">{{ scope.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.scope_id">
                    {{ createForm.errors.scope_id.toString() }}
                  </div>
                </div>
              </div>
            </div>

            <h5 class="mt-4 mb-2 fs-5">Transaction Search Criteria</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2" v-if="enabled_features.subsidiaries">
                  <label class="form-label">Subsidiary</label>
                  <select class="form-select form-select-sm" aria-label="Subsidiary" v-model="createForm.subsidiary_id">
                    <option value="" selected>--</option>
                    <option v-for="(subsidiary, sub_index) in subsidiaries" :value="subsidiary.id" :key="sub_index">{{ subsidiary.name }}</option>
                  </select>
                </div>
                <div class="mt-2">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Item</span></label>
                  <list-record-selection record_type="items" @record-selected="selectListRecord('create', 'item_id', $event)" ref="item_create_selector"></list-record-selection>
                  <div class="text-danger" v-if="createForm.errors.item_id">
                    {{ createForm.errors.item_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                    <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Account</span></label>
                    <list-record-selection record_type="accounts" @record-selected="selectListRecord('create', 'account_id', $event)" ref="account_create_selector"></list-record-selection>
                    <div class="text-danger" v-if="createForm.errors.account_id">
                        {{ createForm.errors.account_id.toString() }}
                    </div>
                </div>
                
                <!-- <div class="mt-2">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Customer</span></label>
                  <entity-selection entity_type="customers" @entity-selected="selectEntity('create', 'customer_id', $event)" ref="customer_create_selector"></entity-selection>
                  <div class="text-danger" v-if="createForm.errors.customer_id">
                    {{ createForm.errors.customer_id.toString() }}
                  </div>
                </div> -->
                <!-- <div class="mt-2">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Employee</span></label>
                  <entity-selection entity_type="employees" @entity-selected="selectEntity('create', 'employee_id', $event)" ref="employee_create_selector"></entity-selection>
                  <div class="text-danger" v-if="createForm.errors.employee_id">
                    {{ createForm.errors.employee_id.toString() }}
                  </div>
                </div> -->
              </div>
              <div class="col">
                <div class="mt-2" v-if="enabled_features.locations">
                  <label class="form-label">Location</label>
                  <select class="form-select form-select-sm" aria-label="Location" v-model="createForm.location_id" :class="{'is-invalid': createForm.errors.location_id}" @change="updateLocationParams('create')">
                    <option value="" selected>--</option>
                    <option v-for="(location, index) in locations" :value="location.id" :key="index">{{ location.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.location_id">
                    {{ createForm.errors.location_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Expense Category</span></label>
                  <list-record-selection record_type="expense_categories" @record-selected="selectListRecord('create', 'expense_category_id', $event)" ref="expense_category_create_selector"></list-record-selection>
                  <div class="text-danger" v-if="createForm.errors.expense_category_id">
                    {{ createForm.errors.expense_category_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Vendor</span></label>
                  <entity-selection entity_type="vendors" @entity-selected="selectEntity('create', 'vendor_id', $event)" ref="vendor_create_selector"></entity-selection>
                  <div class="text-danger" v-if="createForm.errors.vendor_id">
                    {{ createForm.errors.vendor_id.toString() }}
                  </div>
                </div>
               
              </div>
            </div>

            <h5 class="mt-4 mb-2 fs-5">Activity Data</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2">
                  <label class="form-label">Calculation Approach</label>
                  <select class="form-select form-select-sm" aria-label="Calculation Approach" v-model="createForm.calculation_approach_id" :class="{'is-invalid': createForm.errors.calculation_approach_id}">
                    <option value="" selected>--</option>
                    <option v-for="(approach, index) in calculation_approaches" :value="approach.id" :key="index">{{ approach.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.calculation_approach_id">
                    {{ createForm.errors.calculation_approach_id.toString() }}
                  </div>
                </div>

                <div class="mt-2">
                  <label class="form-label">Emission Factor Database</label>
                  <select class="form-select form-select-sm" aria-label="Emission Factor Database" v-model="createForm.emission_database_id" :class="{'is-invalid': createForm.errors.emission_database_id}" @change="resetSourchFuelValue('create')">
                    <option value="" selected>--</option>
                    <option v-for="(emission_database, index) in emission_databases" :value="emission_database.id" :key="index">{{ emission_database.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.emission_database_id">
                    {{ createForm.errors.emission_database_id.toString() }}
                  </div>
                </div>
                
                <div class="mt-2">
                  <label class="form-label">Activity Type</label>
                  <select class="form-select form-select-sm" aria-label="Activity Type" v-model="createForm.activity_type_id" :class="{'is-invalid': createForm.errors.activity_type_id}" :disabled="!createForm.category_id" @change="resetSourchFuelValue('create')">
                    <option value="" selected>--</option>
                    <option v-for="(activity_type, index) in filteredActivityTypes('create')" :value="activity_type.id" :key="index">{{ activity_type.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.activity_type_id">
                    {{ createForm.errors.activity_type_id.toString() }}
                  </div>
                </div>
                
                <div class="mt-2">
                  <label class="form-label">Source Type</label>
                  <select class="form-select form-select-sm" aria-label="Source Type" v-model="createForm.source_type_id" :class="{'is-invalid': createForm.errors.source_type_id}" :disabled="!createForm.activity_type_id && !createForm.emission_database_id">
                    <option value="" selected>--</option>
                    <option v-for="(source_type, index) in filteredSourceTypes('create')" :value="source_type.id" :key="index">{{ source_type.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.source_type_id">
                    {{ createForm.errors.source_type_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Fuel Type</label>
                  <select class="form-select form-select-sm" aria-label="Fuel Type" v-model="createForm.fuel_id" :class="{'is-invalid': createForm.errors.fuel_id}" :disabled="!createForm.activity_type_id && !createForm.emission_database_id">
                    <option value="" selected>--</option>
                    <option v-for="(fuel_type, index) in filteredFuelTypes('create')" :value="fuel_type.id" :key="index">{{ fuel_type.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.fuel_id">
                    {{ createForm.errors.fuel_id.toString() }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="mt-2" v-if="requiresCustomEmissionFactor()">
                  <label class="form-label"><span class="text-danger me-1">*</span>Custom Emission Factor</label>
                  <select class="form-select form-select-sm" aria-label="Custom Emission Factor" v-model="createForm.custom_emission_factor_id" :class="{'is-invalid': createForm.errors.custom_emission_factor_id}">
                    <option value="" selected>--</option>
                    <option v-for="(custom_emission_factor, index) in custom_emission_factors" :value="custom_emission_factor.id" :key="index">{{ custom_emission_factor.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.custom_emission_factor_id">
                    {{ createForm.errors.custom_emission_factor_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Gas</label>
                  <select class="form-select form-select-sm" aria-label="Gas" v-model="createForm.gas_id" :class="{'is-invalid': createForm.errors.gas_id}">
                    <option value="" selected>--</option>
                    <option v-for="(gas, index) in gases" :value="gas.id" :key="index">{{ gas.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.gas_id">
                    {{ createForm.errors.gas_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Unit</label>
                  <select class="form-select form-select-sm" aria-label="Unit" v-model="createForm.unit_id" :class="{'is-invalid': createForm.errors.unit_id}">
                    <option value="" selected>--</option>
                    <option v-for="(unit, index) in units" :value="unit.id" :key="index">{{ unit.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.unit_id">
                    {{ createForm.errors.unit_id.toString() }}
                  </div>
                </div>
                <div class="mt-2" v-if="requiresYearOfManufacture('create')">
                  <label class="form-label"><span class="text-danger me-1">*</span>Year of Manufacture</label>
                  <input class="form-control form-control-sm" aria-label="Year" :class="{'is-invalid': createForm.errors.year}" type="number" name="year" step="1" v-model="createForm.year" />
                  <div class="invalid-feedback" v-if="createForm.errors.year">
                    {{ createForm.errors.year.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Fuel Efficiency</label>
                  <input class="form-control form-control-sm" aria-label="Fuel Efficiency" :class="{'is-invalid': createForm.errors.fuel_efficiency}" type="number" name="fuel_efficiency" step="1" v-model="createForm.fuel_efficiency" />
                  <div class="invalid-feedback" v-if="createForm.errors.fuel_eficiency">
                    {{ createForm.errors.fuel_eficiency.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Fuel Efficiency Unit</label>
                  <select class="form-select form-select-sm" aria-label="Fuel Efficiency Unit" v-model="createForm.fuel_efficiency_unit_id" :class="{'is-invalid': createForm.errors.fuel_efficiency_unit_id}">
                    <option value="" selected>--</option>
                    <option v-for="(unit, index) in units" :value="unit.id" :key="index">{{ unit.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.fuel_efficiency_unit_id">
                    {{ createForm.errors.fuel_efficiency_unit_id.toString() }}
                  </div>
                </div>
                <!-- Extra fields -->
                <!-- <div class="mt-2">
                  <label class="form-label">Department</label>
                  <select class="form-select form-select-sm" aria-label="Department" v-model="createForm.department_id" :class="{'is-invalid': createForm.errors.department_id}">
                    <option value="" selected>--</option>
                    <option v-for="(department, index) in departments" :value="department.id" :key="index">{{ department.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.department_id">
                    {{ createForm.errors.department_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Class</label>
                  <select class="form-select form-select-sm" aria-label="Class" v-model="createForm.class_id" :class="{'is-invalid': createForm.errors.class_id}">
                    <option value="" selected>--</option>
                    <option v-for="(classification, index) in classes" :value="classification.id" :key="index">{{ classification.name }}</option>
                  </select>
                  <div class="invalid-feedback" v-if="createForm.errors.class_id">
                    {{ createForm.errors.class_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Asset</span></label>
                  <segment-selection segment_type="fixed_assets" @segment-selected="selectSegment('create', 'fixed_asset_id', $event)" ref="fixed_asset_create_selector"></segment-selection>
                  <div class="text-danger" v-if="createForm.errors.fixed_asset_id">
                    {{ createForm.errors.fixed_asset_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label">Assignee</label>
                  <entity-selection entity_type="employees" @entity-selected="selectEntity('create', 'assignee_id', $event)" ref="assignee_create_selector"></entity-selection>
                  <div class="invalid-feedback" v-if="createForm.errors.assignee_id">
                    {{ createForm.errors.assignee_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Partner</span></label>
                  <entity-selection entity_type="partners" @entity-selected="selectEntity('create', 'partner_id', $event)" ref="partner_create_selector"></entity-selection>
                  <div class="text-danger" v-if="createForm.errors.partner_id">
                    {{ createForm.errors.partner_id.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><i class="fa-light fa-search"></i><span class="ms-2">Project</span></label>
                  <entity-selection entity_type="projects" @entity-selected="selectEntity('create', 'project_id', $event)" ref="project_create_selector"></entity-selection>
                  <div class="text-danger" v-if="createForm.errors.project_id">
                    {{ createForm.errors.project_id.toString() }}
                  </div>
                </div> -->
                <!-- END -->
              </div>
            </div>
            
            <h5 class="mt-4 mb-2 fs-5">Region</h5>
            <div class="row">
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span><i class="fa-light fa-search"></i><span class="ms-2">Country</span></label>
                  <country-selection @country-selected="selectCountry('create', 'actual_country', $event)" ref="actual_country_create_selector" :initial_value="createForm.actual_country"></country-selection>
                  <div class="text-danger" v-if="createForm.errors.actual_country">
                    {{ createForm.errors.actual_country.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(createForm.category_id)">*</span><i class="fa-light fa-search"></i><span class="ms-2">State / Region / Province</span></label>
                  <region-selection :disabled="!createForm.actual_country" :country="createForm.actual_country" @region-selected="selectRegion('create', 'actual_state', $event)" ref="actual_region_create_selector" :initial_value="createForm.actual_state"></region-selection>
                  <div class="text-danger" v-if="createForm.errors.actual_state">
                    {{ createForm.errors.actual_state.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(createForm.category_id)">*</span>Zip / Postal Code</label>
                  <input class="form-control form-control-sm" aria-label="Name" :class="{'is-invalid': createForm.errors.zip_code}" type="text" name="zip_code" v-model="createForm.zip_code" />
                  <div class="text-danger" v-if="createForm.errors.zip_code">
                    {{ createForm.errors.zip_code.toString() }}
                  </div>
                </div>

              </div>
              <div class="col">
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1">*</span><i class="fa-light fa-search"></i><span class="ms-2">Emission Factor Country</span></label>
                  <country-selection @country-selected="selectCountry('create', 'country', $event)" ref="country_create_selector" :initial_value="createForm.country"></country-selection>
                  <div class="text-danger" v-if="createForm.errors.country">
                    {{ createForm.errors.country.toString() }}
                  </div>
                </div>
                <div class="mt-2">
                  <label class="form-label"><span class="text-danger me-1" v-if="categoryRequiresRegion(createForm.category_id)">*</span><i class="fa-light fa-search"></i><span class="ms-2">Emission Factor State / Region / Province</span></label>
                  <region-selection :disabled="!createForm.country" :country="createForm.country" @region-selected="selectRegion('create', 'region', $event)" ref="region_create_selector" :initial_value="createForm.region"></region-selection>
                  <div class="text-danger" v-if="createForm.errors.region">
                    {{ createForm.errors.region.toString() }}
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer d-flex justify-content-between">
            <div class="d-flex justify-content-start">

            </div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-light" @click="cancel('create')" :disabled="modal_loading">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-arrow-circle-left"></i></span>
                <span>Cancel</span>
              </button>
              <button type="button" class="btn btn-primary ms-2" :disabled="modal_loading" @click="saveSource">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-save"></i></span>
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <!--Edit Modal-->
  <teleport to="#modal-area">
    <edit-emission-source-model :toggleEditModal="toggleEditModal" @getSources="getSources" :editing_source="editing_source" :editForm="editForm" :subsidiaries="subsidiaries" :emission_categories="emission_categories" :scopes="scopes" :departments="departments" :classes="classes" :calculation_approaches="calculation_approaches" :activity_types="activity_types" :source_types="source_types" :fuel_types="fuel_types" :units="units" :gases="gases" :custom_emission_factors="custom_emission_factors" :emission_databases="emission_databases" :locations="locations" @updatedSourceId="updateSourceId($event)"></edit-emission-source-model>
  </teleport>
</template>

<script>
import {useAuthStore} from "@/stores/auth";
import {useRestletStore} from "@/stores/restlets";
import LoadingIndicator from "@/components/global/LoadingIndicator";
import {ref, computed, onMounted} from "vue";
import {storeToRefs} from "pinia/dist/pinia";
import axios from "axios";
import Fuse from 'fuse.js';
import {useRouter} from "vue-router";

import EntitySelection from "@/components/forms/EntitySelection";
import SegmentSelection from "@/components/forms/SegmentSelection";
import CountrySelection from "@/components/forms/CountrySelection";
import RegionSelection from "@/components/forms/RegionSelection";
import ListRecordSelection from "@/components/forms/ListRecordSelection";
import EditEmissionSourceModel from "@/components/models/EditEmissionSourceModel";
import DisabledLabelCss from "@/helpers/disabledLabelCss";

export default {
  components: {
    LoadingIndicator,
    EntitySelection,
    SegmentSelection,
    CountrySelection,
    RegionSelection,
    ListRecordSelection,
    EditEmissionSourceModel,
  },
  
  setup()
  {
    const auth_store = useAuthStore();
    const {enabled_features} = storeToRefs(auth_store);
    const region_create_selector = ref(null);
    const region_edit_selector = ref(null);
    const router = useRouter();

    const restlet_store = useRestletStore();
    const {emission_sources_restlet_url, scope_restlet_url, custom_emission_factor_restlet_url} = storeToRefs(restlet_store);
    const emission_categories = ref([]);
    const emission_sources = ref([]);
    const subsidiaries = ref([]);
    const locations = ref([]);

    const location_country = ref('');
    const location_region = ref('');

    const scopes = ref([]);
    const classes = ref([]);
    const departments = ref([]);
    const gases = ref([]);
    const calculation_approaches = ref([]);
    const activity_types = ref([]);
    const fuel_types = ref([]);
    const units = ref([]);
    const source_types = ref([]);
    const emission_databases = ref([]);
    const customers = ref([]);
    const employees = ref([]);
    const projects = ref([]);
    const partners = ref([]);
    const fixed_assets = ref([]);
    const custom_emission_factors = ref([]);
    const createForm = ref({
      subsidiary_id:"",
      errors: [],
    });
    const editForm = ref({
      errors: [],
    });
    const editing_source = ref({});
    const updated_source_id = ref(null);

    const loading = ref(true);
    const modal_loading = ref(false);
    const create_modal = ref(null);
    const edit_modal = ref(null);

    const search_filter = ref('');
    const category_filter = ref('');
    const subsidiary_filter = ref('');
    const location_filter = ref('');
    // const class_filter = ref('');
    // const department_filter = ref('');
    const vendor_filter = ref('');
    const vendor_filter_value = ref('');
    const item_filter = ref('');
    const item_filter_value = ref('');
    const expense_category_filter = ref('');
    const expense_category_filter_value = ref('');

    const visible_sources = computed(() => {

      let filtered_sources = emission_sources.value;

      if(search_filter.value.length)
      {
        let options = {
          threshold: 0.1,
          ignoreLocation: true,
          keys: ['name', 'number'],
        }

        let fuse = new Fuse(filtered_sources, options);

        let results = fuse.search(search_filter.value);

        filtered_sources = results.map(result => {
          return result.item;
        });
      }

      return filtered_sources
          .filter((fs) => {
            if(category_filter.value.length > 0)
            {
              return fs['category_id'] === category_filter.value;
            }

            else {
              return true;
            }
          })
          .filter((fs) => {
            if(subsidiary_filter.value.length > 0)
            {
              return fs['subsidiary_id'] === subsidiary_filter.value;
            }

            else {
              return true;
            }
          })
          .filter((fs) => {
            if(location_filter.value.length > 0)
            {
              return fs['location_id'] === location_filter.value;
            }

            else {
              return true;
            }
          })
          .filter((fs) => {
            if(vendor_filter.value.length > 0) {
              return fs['vendor_id'] === vendor_filter.value;
            } else {
              return true;
            }
          })
          .filter((fs) => {
            if(item_filter.value.length > 0) {
              return fs['item_id'] === item_filter.value;
            } else {
              return true;
            }
          })
          .filter((fs) => {
            if(expense_category_filter.value.length > 0) {
              return fs['expense_category_id'] === expense_category_filter.value;
            }else {
              return true;
            }
          });
          // .filter((fs) => {
          //   if(class_filter.value.length > 0)
          //   {
          //     return fs['class_id'] === class_filter.value;
          //   }

          //   else {
          //     return true;
          //   }
          // })
          // .filter((fs) => {
          //   if(department_filter.value.length > 0)
          //   {
          //     return fs['department_id'] === department_filter.value;
          //   }

          //   else {
          //     return true;
          //   }
          // });
    });

    function getScopes()
    {
      let source_params = {};

      let query = new URLSearchParams(source_params).toString();
      let endpoint = scope_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            scopes.value = response.data.scopes;
          })
          .catch(err => {
            console.log(err);
          });
    }

    function getCustomEmissionFactors()
    {
      let params = {};

      let query = new URLSearchParams(params).toString();
      let endpoint = custom_emission_factor_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            custom_emission_factors.value = response.data.custom_emission_factors;
          })
          .catch(err => {
            console.log(err);
          });
    }

    function getSources(query_type = 'sources') 
    {
      loading.value = true;

      let source_params = {};

      if(query_type === 'sources_with_filters')
      {
        source_params['query_type'] = 'sources_with_filters';
      }

      else {
        source_params['query_type'] = 'sources';
      }

      let query = new URLSearchParams(source_params).toString();
      let endpoint = emission_sources_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
              emission_sources.value = response.data.emission_sources.sort((a, b) => {
				return b.id - a.id;
			  });

              if(response.data.emission_categories)
              {
                emission_categories.value = [...new Set(response.data.emission_categories)];
              }

              if(response.data.subsidiaries)
              {
                subsidiaries.value = response.data.subsidiaries;
              }

              if(response.data.locations)
              {
                locations.value = response.data.locations;
              }

              if(response.data['classes'])
              {
                classes.value = response.data['classes'];
              }

              if(response.data.departments)
              {
                departments.value = response.data.departments;
              }

              if(response.data.source_types)
              {
                source_types.value = response.data.source_types;
              }

              if(response.data.fixed_assets)
              {
                fixed_assets.value = response.data.fixed_assets;
              }

              if(response.data.gases)
              {
                gases.value = response.data.gases;
              }

              if(response.data.calculation_approaches)
              {
                calculation_approaches.value = response.data.calculation_approaches;
              }

              if(response.data.activity_types)
              {
                activity_types.value = response.data.activity_types;
              }

              if(response.data.fuel_types)
              {
                fuel_types.value = response.data.fuel_types;
              }

              if(response.data.units)
              {
                units.value = response.data.units;
              }

              if(response.data.emission_databases)
              {
                emission_databases.value = response.data.emission_databases;
              }

              loading.value = false;
          })
          .catch(err => {
            console.log(err);
          });
    }

    function getCategorySources(category_id)
    {
      return visible_sources.value.filter(es => {
          return es.category_id == category_id;
      });
    }

    function getCategorySourceCount(category_id)
    {
      let sources = getCategorySources(category_id);
      return sources.length;
    }

    function shouldShowCategory(category_id)
    {
      let filter_chars = search_filter.value.length + category_filter.value.length + subsidiary_filter.value.length + location_filter.value.length + vendor_filter.value.length + item_filter.value.length + expense_category_filter.value.length; // + class_filter.value.length + department_filter.value.length;
      let result = filter_chars > 0 ? getCategorySourceCount(category_id) > 0 : true;
      return result;
    }

    function toggleCreateModal()
    {
      create_modal.value.toggle();
    }

    function openImport()
    {
      window.open(
        '/app/setup/assistants/nsimport/importassistant.nl?new=T',
        '_blank'
      );
    }

    function toggleEditModal()
    {
      edit_modal.value.toggle();
    }

    function editSource(source)
    {
      editing_source.value = source;
      editForm.value.id = source.id;
      editForm.value.number = source.number;
      editForm.value.name = source.name;
      editForm.value.subsidiary_id = source.subsidiary_id;
      editForm.value.source_type_id = source.source_type_id;
      editForm.value.scope_id = source.scope_id;
      editForm.value.year = source.year;
      editForm.value.department_id = source.department_id;
      editForm.value.class_id = source.class_id;
      editForm.value.location_id = source.location_id;
      editForm.value.fixed_asset_id = source.fixed_asset_id;
      editForm.value.customer_id = source.customer_id || '';
      editForm.value.vendor_id = source.vendor_id;
      editForm.value.employee_id = source.employee_id || '';
      editForm.value.assignee_id = source.assignee_id;
      editForm.value.partner_id = source.partner_id;
      editForm.value.project_id = source.project_id;
      editForm.value.category_id = source.category_id;
      editForm.value.country = source.country;
      editForm.value.region = source.region;
      editForm.value.actual_country = source.actual_country;
      editForm.value.actual_state = source.actual_state;
      editForm.value.zip_code = source.zip_code;
      editForm.value.gas_id = source.gas_id;
      editForm.value.custom_emission_factor_id = source.custom_emission_factor_id;
      editForm.value.account_id = source.account_id;
      editForm.value.calculation_approach_id = source.calculation_approach_id;
      editForm.value.activity_type_id = source.activity_type_id;
      editForm.value.fuel_id = source.fuel_id;
      editForm.value.unit_id = source.unit_id;
      editForm.value.fuel_efficiency = source.fuel_efficiency;
      editForm.value.fuel_efficiency_unit_id = source.fuel_efficiency_unit_id;
      editForm.value.emission_database_id = source.emission_database_id;
      editForm.value.item_id = source.item_id;
      editForm.value.expense_category_id = source.expense_category_id;
      editForm.value.errors = [];

      toggleEditModal();
    }

    function selectEntity(mode, field, entity)
    {
      let entity_form = mode === 'create' ? createForm.value : editForm.value;
      entity_form[field] = !entity ? null : entity.id;
    }
    
    function selectFilterEntity(filter_type, entity)
    { 
      var entity_id = !entity ? "" : entity.id;
      if(filter_type === 'vendor') {
        vendor_filter.value = entity_id;
      } else if(filter_type === 'item') {
        item_filter.value = entity_id;
      } else {
        expense_category_filter.value = entity_id;
      }
    }

    function selectListRecord(mode, field, record)
    {
      let record_form = mode === 'create' ? createForm.value : editForm.value;
      record_form[field] = !record ? null : record.id;
    }

    function selectCountry(mode, field, country)
    {
      let country_form = mode === 'create' ? createForm.value : editForm.value;
      
      if(country)
      {
        country_form[field] = country.code;
      }else {
        country_form[field] = '';
      }

      if(field === 'actual_country'){
        country_form['actual_state'] = '';
        country_form['zip_code'] = '';
      } else{
        country_form['region'] = '';
      }
      
    }

    function selectRegion(mode, field, region)
    {
      let region_form = mode === 'create' ? createForm.value : editForm.value;
      region_form[field] = !region ? null : region.abbreviation;
    }

    function selectSegment(mode, field, segment)
    {
      let segment_form = mode === 'create' ? createForm.value : editForm.value;
      segment_form[field] = !segment ? null : segment.id;
    }

    function requiresCustomEmissionFactor()
    {
      let wanted_calculation_approach_scriptid = 'VAL_61496_T2641309_243';

      let calculation_approach = calculation_approaches.value.find(ca => {
        return ca.id === createForm.value.calculation_approach_id;
      });
  
      if(calculation_approach)
      {
        return wanted_calculation_approach_scriptid === calculation_approach['scriptid'];
      } else {
        return false;
      }
    }

    function validateForm(mode)
    {
      let errors = 0;
      let form = mode === 'create' ? createForm.value : editForm.value;

      //Making source type optional - GHGAC-690
      /*if(!form.source_type_id)
      {
        form.errors['source_type_id'] = 'The source type is mandatory';
        errors++;
      }*/

      if(!form.name)
      {
        form.errors['name'] = 'The name is mandatory';
        errors++;
      }

      if(!form.scope_id)
      {
        form.errors['scope_id'] = 'The scope is mandatory';
        errors++;
      }

      if((requiresYearOfManufacture(mode) && !form.year) || form.year?.length > 0 && (Number(form.year) < 1950 || Number(form.year) > 2050))
      {
        form.errors['year'] = 'Invalid year';
        errors++;
      }

      if(!form.country)
      {
        form.errors['country'] = 'The emission factor country is required';
        errors++;
      }

      if(categoryRequiresRegion(form.category_id) && !form.region)
      {
        form.errors['region'] = 'The emission factor state / region / province is required';
        errors++;
      }

      if(!form.actual_country)
      {
        form.errors['actual_country'] = 'The country is required';
        errors++;
      }

      if(categoryRequiresRegion(form.category_id) && !form.actual_state)
      {
        form.errors['actual_state'] = 'The state / region / province is required';
        errors++;
      }

      if(categoryRequiresRegion(form.category_id) && !form.zip_code)
      {
        form.errors['zip_code'] = 'The zip code is required';
        errors++;
      }

      if(requiresCustomEmissionFactor() && !form.custom_emission_factor_id)
      {
        form.errors['custom_emission_factor_id'] = 'The custom emission factor is required with this calculation approach';
        errors++;
      }

      return errors;
    }

    function saveSource()
    {
      createForm.value.errors = [];
      let errors = validateForm('create');

      if(errors === 0)
      {
        modal_loading.value = true;
        let source_params = {};
        let form = createForm.value;

        let query = new URLSearchParams(source_params).toString();
        let endpoint = emission_sources_restlet_url.value + '&' + query;

        axios.post(endpoint, form)
            .then(response => {

              if(response.data['status'] === 'success')
              {
                createForm.value = {errors: []};
                getSources('sources');
                toggleCreateModal();
                modal_loading.value = false;
                updated_source_id.value = response.data['emission_source_id'] || null;
                if(updated_source_id.value)
                {
                    router.push({name: 'emission_source', params: {source: updated_source_id.value}});
                }
              }
            });
      }
    }

    //** PLACE TO FIX THE MULTIPLE CATEGORY IDS */
    function filteredActivityTypes(mode)
    {
      let form = mode === 'create' ? createForm.value : editForm.value;
      let category_id = form.category_id;

      return activity_types.value.filter(at => {
        let categories = at.category_ids?.length > 0 && category_id ? at.category_ids?.split(',') : [];
        return categories.includes(category_id);
      });
    }
    
    function filteredSourceTypes(mode)
    {
      let form = mode === 'create' ? createForm.value : editForm.value;
      let activity_type_id = form.activity_type_id;
      let emission_database_id = form.emission_database_id;

      return source_types.value.filter(st => {
        let activity_type_ids = st.activity_type_id?.length > 0 && activity_type_id ? st.activity_type_id?.split(',') : [];
        let emission_database_ids = st.emission_database_id?.length > 0 && emission_database_id ? st.emission_database_id?.split(',') : [];
        
        return emission_database_ids.includes(emission_database_id) && activity_type_ids.includes(activity_type_id);
      });
    }

    function filteredFuelTypes(mode)
    {
      let form = mode === 'create' ? createForm.value : editForm.value;
      let activity_type_id = form.activity_type_id;
      let emission_database_id = form.emission_database_id;

      return fuel_types.value.filter(ft => {
        let activity_type_ids = ft.activity_type_id?.length > 0 && activity_type_id ? ft.activity_type_id?.split(',') : [];
        let emission_database_ids = ft.emission_database_id?.length > 0 && emission_database_id ? ft.emission_database_id?.split(',') : [];
        
        return emission_database_ids.includes(emission_database_id) && activity_type_ids.includes(activity_type_id);
      });
    }

    function selectScope(mode)
    {
      let form = mode === 'create' ? createForm.value : editForm.value;
      form.activity_type_id = '';
      form.source_type_id = '';
      form.fuel_id = '';
      let category_id = form.category_id;

      if(category_id)
      {
        let category = emission_categories.value.find(emcat => {
          return emcat.id == category_id;
        });

        if(category)
        {
          form.scope_id = category.scope_id;
        }
      } else {
        form.scope_id = '';
      }
    }

    function resetSourchFuelValue(mode){
      let form = mode === 'create' ? createForm.value : editForm.value;
      form.source_type_id= '';
      form.fuel_id= '';

      let emission_database_id = form.emission_database_id;
      let emission_factor_db = emission_databases.value.find(efd => {
        return efd.id === emission_database_id;
      });

      if(emission_factor_db){
        form.country = emission_factor_db.country;
        form.region = emission_factor_db.region;
      }
    }

    function cancel(mode)
    {
      if(mode === 'create')
      {
        createForm.value = {
          errors: [],
        };
        toggleCreateModal();
      }

      else if(mode === 'edit')
      {
        editForm.value['errors'] = [];
        toggleEditModal();
      }
    }

    function categoryRequiresRegion(category_id)
    {
      let categories_that_require_region = ['VAL_60170_T2641309_514'];

      let category = emission_categories.value.find(c => {
        return c.id === category_id;
      });

      if(category)
      {
        return categories_that_require_region.includes(category['scriptid']);
      }

      else {
        return false;
      }
    }

    function updateLocationParams(mode)
    {
      let form = mode === 'create' ? createForm.value : editForm.value;

      let location = locations.value.find(l => {
        return l.id === form.location_id;
      });

      if(location)
      {
        form.actual_country = location.country;
        form.actual_state = location.region;
        form.zip_code = location.zip_code;
        // form.country = location.country;
        // form.region = location.region;

        // location_country.value = location.country;
        // location_region.value = location.region;
      }
    }

    function requiresYearOfManufacture(mode)
    {
      let form = mode === 'create' ? createForm.value : editForm.value;

      let enabled_activity_types = [
        'VAL_60366_T2641309_196',
      ];

      let activity_type = activity_types.value.find(at => {
        return at.id === form.activity_type_id;
      });

      if(activity_type)
      {
        return enabled_activity_types.includes(activity_type['scriptid']);
      }

      else {
        return false;
      }
    }

    function disabledInputCss(value){
      return DisabledLabelCss.disabledLabel(value);
    }

    function updateSourceId(updated_sources_id){
      updated_source_id.value = updated_sources_id;
    }

    onMounted(() => {
      getScopes();
      getCustomEmissionFactors();
      getSources('sources_with_filters');
      create_modal.value = new bootstrap.Modal(document.getElementById('create_modal'));
      edit_modal.value = new bootstrap.Modal(document.getElementById('edit_modal'));
    });

    return {
      loading,
      modal_loading,
      create_modal,
      edit_modal,
      emission_categories,
      emission_sources,
      visible_sources,
      search_filter,
      category_filter,
      subsidiary_filter,
      location_filter,
      vendor_filter,
      vendor_filter_value,
      item_filter,
      item_filter_value,
      expense_category_filter,
      expense_category_filter_value,
      // class_filter,
      // department_filter,
      subsidiaries,
      locations,
      location_country,
      location_region,
      createForm,
      editForm,
      editing_source,
      updated_source_id,
      classes,
      departments,
      gases,
      calculation_approaches,
      activity_types,
      fuel_types,
      units,
      source_types,
      emission_databases,
      scopes,
      customers,
      employees,
      projects,
      partners,
      fixed_assets,
      custom_emission_factors,
      getScopes,
      getSources,
      getCategorySources,
      getCategorySourceCount,
      shouldShowCategory,
      toggleCreateModal,
      openImport,
      toggleEditModal,
      editSource,
      selectEntity,
      selectFilterEntity,
      selectListRecord,
      selectCountry,
      selectRegion,
      selectSegment,
      requiresCustomEmissionFactor,
      validateForm,
      saveSource,
      filteredSourceTypes,
      filteredFuelTypes,
      filteredActivityTypes,
      selectScope,
      cancel,
      categoryRequiresRegion,
      updateLocationParams,
      requiresYearOfManufacture,
      disabledInputCss,
      resetSourchFuelValue,
      updateSourceId,
      enabled_features,
    }
  }
}
</script>

<style>
.input-search-filter .form-control-sm{
  padding: 0.375rem 0.75rem !important;
  font-size: 0.9rem !important;
  border-radius: 0.375rem !important;
  width: 100px;
}
.w-90 {
  width: 90% !important;
}
.w-120 input {
  width: 120px !important;
}
.w-200 input {
  width: 200px !important;
}
</style>