<template>
  <table>
    <tr class="table-header">
      <th :style="{ 'width': '10%' }">Section</th>
      <th :style="{ 'width': '30%' }">Description</th>
      <th :style="{ 'width': '60%' }">Details</th>
    </tr>

    <template v-for="(data, ecIndex) in EmissionCountry" :key="ecIndex">
      <tr>
        <th>{{ data.section }}</th>
        <td>{{ data.descriptions }}</td>
        <td class="padd-0">
          <table class="no-border">
          <template
            v-for="(value, detailIndex) in data.details"
            :key="detailIndex"
          >
              <tr v-if="detailIndex === 0">
                <th class="bg-color">{{ value.emissions }}</th>
                <th class="bg-color">{{ value['country 1'] }}</th>
                <th class="bg-color">{{ value['country 2'] }}</th>
                <th class="bg-color">{{ value['country 3'] }}</th>
                <th class="bg-color">{{ value['country N'] }}</th>
              </tr>
              <tr v-else>
                <td  className="bg-color">{{ value.emissions }}</td>
                <td>{{ value['country 1'] }}</td>
                <td>{{ value['country 2'] }}</td>
                <td>{{ value['country 3'] }}</td>
                <td>{{ value['country N'] }}</td>
              </tr>
          </template>
        </table>
        </td>
      </tr>
    </template>
  </table>
</template>
<script>
export default {
  props: {
    EmissionCountry: Object
  }
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #000;
  font-family: "docs-Tahoma", Arial;
  font-size: 10pt;
}
td,
th {
  padding: 6px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}
td:first-child,
th:first-child {
  border-left: 1px solid #000;
}
.bg-color {
  background-color: #46bdc6;
}
.no-border {
  border: 0;
}
.padd-0{
    padding:0;
}
td > .no-border > tr:first-child td,
 td > .no-border > tr:first-child th{
    border-top:0;
    border-left: 0;
 }

 td > .no-border > tr >  td{
    border-left: 0;
 }

 td > .no-border > tr >  td:last-child,
 td > .no-border > tr >  th:last-child{
    border-right: 0;
 }
 .table-header{
  background-color: #9fc5e8;
 }

 .table-header  > th{
  font-size: 14px;
 }

th {
  font-weight: bold;
}

td {
  word-wrap: break-word;
}
</style>
