<template>

  <div class="container-fluid">
    <loading-indicator v-if="loading_periods"></loading-indicator>

    <div class="row pt-2" v-else>
      <div class="col-xxl-8 col-md-8">
        <!-- start page title -->
        <div class="d-md-flex justify-content-between">
          <h4 class="fs-4 my-0">GHG Periods</h4>
          <div class="d-md-flex justify-content-end">
            <button type="button" class="btn btn-primary" @click="toggleCreateMonthModal">
              <span class="me-2"><i class="fa-duotone fa-plus-circle"></i></span>
              <span>Add GHG Period</span>
            </button>
            <button type="button" class="btn btn-light bg-white ms-2" @click="toggleCreateYearModal">
              <span class="me-2"><i class="fa-duotone fa-plus-circle"></i></span>
              <span>Add GHG Year</span>
            </button>
          </div>
        </div>
        <!-- end page title -->

        <!-- tasks panel -->
         <div class="data-wrapper">
          <div class="mt-2" v-for="(accounting_period, period_index) in accounting_periods" :key="period_index">
            <router-link :to="{name: 'accounting_period_view_parent', params: {parent_period: accounting_period.id}}">
              <h5 class="m-0 pb-2">
                <span class="text-dark">
                  {{ accounting_period.name }} <span class="text-muted">{{ '(' + accounting_period.child_periods.length + ')' }}</span>
                </span>
              </h5>
            </router-link>

          <div class="list-group">
            <router-link :to="{name: 'accounting_period_view_child', params: {parent_period: accounting_period.id, child_period: child_period.id}}"
                class="list-group-item list-group-item-action d-md-flex justify-content-between fs-6"
                :class="{'bg-success': updated_period_id == child_period.id}"
                aria-current="true"
                v-for="(child_period, child_period_index) in accounting_period.child_periods" :key="child_period_index">
                <div class="d-flex align-items-center">
                  <span v-if="child_period.closed" class="me-2 text-success"><i class="fa-duotone fa-circle-check"></i></span>
                  <span v-else-if="child_period.locked" class="me-2 text-danger"><i class="fa-duotone fa-lock"></i></span>
                  <span v-else class="me-2 text-warning"><i class="fa-duotone fa-lock-open"></i></span>
                  <span v-if="child_period.closed" class="me-2 badge text-white text-bg-success">
                    Closed
                  </span>
                  <span v-else-if="child_period.locked" class="me-2 badge text-white text-bg-danger">
                    Locked
                  </span>
                  <span v-else class="me-2 badge text-white text-bg-warning">
                    Open
                  </span>
                  <span class="ms-2">{{ child_period.name }}</span>
                </div>
                <div class="d-flex justify-content-between" style="min-width: 220px;">
                  <span class="ms-2"><i class='fa-duotone fa-calendar-circle-plus'></i> {{ child_period.start_date }}</span>
                  <span class="ms-2"><i class='fa-duotone fa-chevrons-right'></i> {{ child_period.end_date }}</span>
                </div>
            </router-link>
          </div>
        </div>
         </div>
      </div>

      <!-- task details -->
      <div class="col-xxl-4 col-md-4">
        <div v-if="route.name === 'accounting_period_view_parent'"><router-view :key="route.fullPath" @periods-updated="getPeriods()" :ns_periods="ns_periods" :accounting_periods="accounting_periods"></router-view></div>
        <div v-else-if="route.name === 'accounting_period_view_child'"><router-view :key="route.fullPath" @periods-updated="getPeriods()" :ns_periods="ns_periods" :accounting_periods="accounting_periods"></router-view></div>
        <div v-else></div>
      </div> <!-- end col -->
    </div>
    <!-- end row-->
  </div>

  <!--Create Modal-->
  <teleport to="#modal-area">
    <div class="modal fade" tabindex="-1" aria-labelledby="createSourceModal" id="create_month_modal" aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title my-0" id="createSourceModal">New Month</h5>
            <button type="button" class="btn-close" @click="cancel('month')" aria-label="Close"></button>
          </div>

          <div class="modal-body">

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Netsuite Accounting Period</label>
              <select class="form-select form-select-sm" aria-label="Netsuite Accounting Period" v-model="createMonthForm.ns_period" :class="{'is-invalid': createMonthForm.errors.ns_period}" @change="preselectMonthDates">
                <option value="" selected>--</option>
                <option v-for="(ns_period, index) in ns_periods" :value="ns_period.id" :key="index">{{ ns_period.name }}</option>
              </select>
              <div class="invalid-feedback" v-if="createMonthForm.errors.ns_period">
                {{ createMonthForm.errors.ns_period.toString() }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Parent Period</label>
              <select class="form-select form-select-sm" aria-label="Parent Period" v-model="createMonthForm.parent" :class="{'is-invalid': createMonthForm.errors.parent}">
                <option value="" selected>--</option>
                <option v-for="(accounting_period, index) in accounting_periods" :value="accounting_period.id" :key="index">{{ accounting_period.name }}</option>
              </select>
              <div class="invalid-feedback" v-if="createMonthForm.errors.parent">
                {{ createMonthForm.errors.parent.toString() }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Name</label>
              <input class="form-control form-control-sm" aria-label="Name" :class="{'is-invalid': createMonthForm.errors.name}" type="text" name="name" v-model="createMonthForm.name" />
              <div class="invalid-feedback" v-if="createMonthForm.errors.name">
                {{ createMonthForm.errors.name.toString() }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Start Date</label>
              <input class="form-control form-control-sm" aria-label="Start Date" :class="{'is-invalid': createMonthForm.errors.start_date}" type="date" name="start_date" v-model="createMonthForm.start_date" />
              <div class="invalid-feedback" v-if="createMonthForm.errors.start_date">
                {{ createMonthForm.errors.start_date.toString() }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>End Date</label>
              <input class="form-control form-control-sm" aria-label="End Date" :class="{'is-invalid': createMonthForm.errors.end_date}" type="date" name="end_date" v-model="createMonthForm.end_date" />
              <div class="invalid-feedback" v-if="createMonthForm.errors.end_date">
                {{ createMonthForm.errors.end_date.toString() }}
              </div>
            </div>


          </div>
          <div class="modal-footer d-flex justify-content-between">
            <div class="d-flex justify-content-start">

            </div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-light" @click="cancel('month')" :disabled="modal_loading">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-arrow-circle-left"></i></span>
                <span>Cancel</span>
              </button>
              <button type="button" class="btn btn-primary" :disabled="modal_loading" @click="storeMonth">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-save"></i></span>
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>

  <teleport to="#modal-area">
    <div class="modal fade" tabindex="-1" aria-labelledby="createSourceModal" id="create_year_modal" aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title my-0" id="createSourceModal">New Year</h5>
            <button type="button" class="btn-close" @click="cancel('year')" aria-label="Close"></button>
          </div>

          <div class="modal-body">

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Name</label>
              <input class="form-control form-control-sm" aria-label="Name" :class="{'is-invalid': createYearForm.errors.name}" type="text" name="name" v-model="createYearForm.name" />
              <div class="invalid-feedback" v-if="createYearForm.errors.name">
                {{ createYearForm.errors.name.toString() }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>Start Date</label>
              <input class="form-control form-control-sm" aria-label="Start Date" :class="{'is-invalid': createYearForm.errors.start_date}" type="date" name="start_date" v-model="createYearForm.start_date" />
              <div class="invalid-feedback" v-if="createYearForm.errors.start_date">
                {{ createYearForm.errors.start_date.toString() }}
              </div>
            </div>

            <div class="mt-2">
              <label class="form-label"><span class="text-danger me-1">*</span>End Date</label>
              <input class="form-control form-control-sm" aria-label="End Date" :class="{'is-invalid': createYearForm.errors.end_date}" type="date" name="end_date" v-model="createYearForm.end_date" />
              <div class="invalid-feedback" v-if="createYearForm.errors.end_date">
                {{ createYearForm.errors.end_date.toString() }}
              </div>
            </div>

            <div class="form-check mt-2">
              <input class="form-check-input" type="checkbox" value="" id="create_base_year" v-model="createYearForm.base_year">
              <label class="form-check-label" for="create_base_year">
                Is base year?
              </label>
              <div class="text-danger" v-if="createYearForm.errors.base_year">
                {{ createYearForm.errors.base_year.toString() }}
              </div>
            </div>

          </div>
          <div class="modal-footer d-flex justify-content-between">
            <div class="d-flex justify-content-start">

            </div>

            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-light" @click="cancel('year')" :disabled="modal_loading">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-arrow-circle-left"></i></span>
                <span>Cancel</span>
              </button>
              <button type="button" class="btn btn-primary" :disabled="modal_loading" @click="storeYear">
                <span class="me-2" v-if="modal_loading"><i class="fa-light fa-compact-disc fa-spin"></i></span>
                <span class="me-2" v-else><i class="fa-light fa-save"></i></span>
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>

</template>

<script>
import {onMounted, ref} from "vue";
import {useRouter, useRoute} from "vue-router";
import axios from "axios";
import {useRestletStore} from "@/stores/restlets";
import {storeToRefs} from "pinia";
import LoadingIndicator from "@/components/global/LoadingIndicator.vue";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export default {

  components: {
    LoadingIndicator,
  },

  setup()
  {
    const router = useRouter();
    const route = useRoute();
    const restlet_store = useRestletStore();
    const {period_restlet_url} = storeToRefs(restlet_store);

    const loading_periods = ref(false);
    const modal_loading = ref(false);
    const accounting_periods = ref([]);
    const ns_periods = ref([]);
    const updated_period_id = ref('');

    const create_month_modal = ref(null);
    const create_year_modal = ref(null);

    const createMonthForm = ref({
      errors: [],
    });
    const createYearForm = ref({
      errors: [],
    });

    function getPeriods()
    {
      loading_periods.value = true;
      let params = {query_type: 'period_hierarchy'};

      let query = new URLSearchParams(params).toString();
      let endpoint = period_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            loading_periods.value = false;
            accounting_periods.value = response.data['ghg_periods'];
            (accounting_periods.value)
                .map(ap => {
                    ap.start_date = new Date(ap.start_date).toDateString();
                    ap.end_date = new Date(ap.end_date).toDateString();

                    ap.child_periods.forEach(cp => {
                        cp.start_date = new Date(cp.start_date).toDateString();
                        cp.end_date = new Date(cp.end_date).toDateString();
                    });
                })
                .sort((a,b) => {
                  if(a.name < b.name){
                    return 1;
                  } else{
                    return -1;
                  }
                })
          })
          .catch(err => {
            loading_periods.value = false;
          });
    }

    function getNsPeriods()
    {
      let params = {query_type: 'ns_periods'};
      let query = new URLSearchParams(params).toString();
      let endpoint = period_restlet_url.value + '&' + query;

      axios.get(endpoint)
          .then(response => {
            ns_periods.value = response.data['ns_periods'];
          })
          .catch(err => {

          });
    }

    function toggleCreateMonthModal()
    {
      create_month_modal.value.toggle();
    }

    function toggleCreateYearModal()
    {
      create_year_modal.value.toggle();
    }

    function cancel(mode)
    {
      if(mode === 'month')
      {
        createMonthForm.value = {
          errors: [],
        };
        toggleCreateMonthModal();
      }

      else if(mode === 'year')
      {
        createYearForm.value = {
          errors: [],
        };
        toggleCreateYearModal();
      }
    }

    function preselectMonthDates()
    {
      let ns_period = ns_periods.value.find(nsp => {
        return nsp.id === createMonthForm.value.ns_period;
      });

      if(ns_period)
      {
        createMonthForm.value.name = ns_period.name;
        createMonthForm.value.start_date = dayjs.utc(ns_period.start_date).format('YYYY-MM-DD');
        createMonthForm.value.end_date = dayjs.utc(ns_period.end_date).format('YYYY-MM-DD');
      }
    }

    function validateMonthForm() {
      let errors = 0;

      if (!createMonthForm.value.ns_period) {
        createMonthForm.value.errors['ns_period'] = 'The Netsuite period is required';
        errors++;
      }

      if (!createMonthForm.value.parent) {
        createMonthForm.value.errors['parent'] = 'The parent period is required';
        errors++;
      }

      if (!createMonthForm.value.name) {
        createMonthForm.value.errors['name'] = 'The name is required';
        errors++;
      }

      if (!createMonthForm.value.start_date) {
        createMonthForm.value.errors['start_date'] = 'The start date is required';
        errors++;
      }

      if (!createMonthForm.value.end_date) {
        createMonthForm.value.errors['end_date'] = 'The end date is required';
        errors++;
      }

      return errors;
    }

    function validateYearForm() {
      let errors = 0;

      if (!createYearForm.value.name) {
        createYearForm.value.errors['name'] = 'The name is required';
        errors++;
      }

      if (!createYearForm.value.start_date) {
        createYearForm.value.errors['start_date'] = 'The start date is required';
        errors++;
      }

      if (!createYearForm.value.end_date) {
        createYearForm.value.errors['end_date'] = 'The end date is required';
        errors++;
      }

      if(!createYearForm.value.base_year) {
        createYearForm.value.base_year = false;
      }

      if(createYearForm.value.base_year) {
        let existing_base_year = accounting_periods.value.find(ap => {
          return ap.base_year === true;
        });

        if(existing_base_year)
        {
          createYearForm.value.errors['base_year'] = existing_base_year.name + ' ' + 'is already marked as the base year.';
          errors++;
        }
      }

      return errors;
    }

    function storeMonth()
    {
      createMonthForm.value.errors = [];
      let errors = validateMonthForm();

      if(errors === 0)
      {
        modal_loading.value = true;
        let source_params = {};
        let form = createMonthForm.value;
        form.period_type = 'month';

        let query = new URLSearchParams(source_params).toString();
        let endpoint = period_restlet_url.value + '&' + query;

        axios.post(endpoint, form)
            .then(response => {

              if(response.data['status'] === 'success')
              {
                createMonthForm.value = {errors: []};
                getPeriods();
                toggleCreateMonthModal();
                modal_loading.value = false;
                updated_period_id.value = response.data['ghg_period_id'] || null;
              }
            })
            .catch(e => {
              modal_loading.value = false;
              if(e.response.data['message'])
              {
                alert(e.response.data['message']);
              }

              else {
                alert('Error');
                toggleCreateMonthModal();
              }
            });
      }
    }

    function storeYear()
    {
      createYearForm.value.errors = [];
      let errors = validateYearForm();

      if(errors === 0)
      {
        modal_loading.value = true;
        let source_params = {};
        let form = createYearForm.value;
        form.period_type = 'year';

        /**
         * Prepare the months array for the subperiods.
         */
        let months = [];
        const date1 = dayjs.utc(form.start_date);
        const date2 = dayjs.utc(form.end_date);
        let number_of_months = date2.diff(date1.subtract(1, 'day'), 'month');

        for (let i = 0; i < number_of_months; i++) {

          let month = {};

          if(i === 0)
          {
            month.start_date = form.start_date;
            month.end_date = dayjs.utc(form.start_date).endOf('month').format('YYYY-MM-DD');
            month.name = dayjs.utc(form.start_date).format('MMM') + ' ' + dayjs.utc(form.start_date).format('YY');
          }

          else if(i === (number_of_months - 1))
          {
            month.start_date = dayjs.utc(form.end_date).startOf('month').format('YYYY-MM-DD');
            month.end_date = form.end_date;
            month.name = dayjs.utc(form.end_date).format('MMM') + ' ' + dayjs.utc(form.end_date).format('YY');
          }

          else {
            let current_date = date1.add(i, 'month');
            month.start_date = current_date.startOf('month').format('YYYY-MM-DD');
            month.end_date = current_date.endOf('month').format('YYYY-MM-DD');
            month.name = current_date.format('MMM') + ' ' + current_date.format('YY');
          }

          months.push(month);
        }

        form.months = months;

        let query = new URLSearchParams(source_params).toString();
        let endpoint = period_restlet_url.value + '&' + query;

        axios.post(endpoint, form)
            .then(response => {

              if(response.data['status'] === 'success')
              {
                createYearForm.value = {errors: []};
                getPeriods();
                toggleCreateYearModal();
                modal_loading.value = false;
                updated_period_id.value = response.data['ghg_period_id'] || null;
              }
            })
            .catch(e => {
              modal_loading.value = false;
              if(e.response.data['message'])
              {
                alert(e.response.data['message']);
              }

              else {
                alert('Error');
                toggleCreateYearModal();
              }
            });
      }
    }

    dayjs.extend(utc);

    onMounted(() => {
      getPeriods();
      getNsPeriods();
      create_month_modal.value = new bootstrap.Modal(document.getElementById('create_month_modal'));
      create_year_modal.value = new bootstrap.Modal(document.getElementById('create_year_modal'));
    });

    return {
      router,
      route,
      create_month_modal,
      create_year_modal,
      loading_periods,
      modal_loading,
      updated_period_id,
      accounting_periods,
      ns_periods,
      createMonthForm,
      createYearForm,
      getPeriods,
      getNsPeriods,
      toggleCreateMonthModal,
      toggleCreateYearModal,
      cancel,
      preselectMonthDates,
      storeMonth,
      storeYear,

    }
  }
}
</script>
<style scoped>
h5{
  font-size:1.125rem !important
}
.data-wrapper{
  max-height: calc(100vh - 73px);
  overflow-y: auto;
  margin-top:15px;
}
</style>