<template>
    <div class="px-4 py-5 my-5 text-center">
      <img class="d-block mx-auto mb-4" :src="publicUrl + 'brand/logo.webp'" alt="" height="100">
      <h1 class="display-5 fw-bold">Coming Soon</h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">This feature is coming soon</p>
  <!--      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
          <button type="button" class="btn btn-primary btn-lg px-4 gap-3">Primary button</button>
          <button type="button" class="btn btn-outline-secondary btn-lg px-4">Secondary</button>
        </div>-->
      </div>
    </div>
  </template>
  
  <script>
  import {ref} from 'vue';
  
  export default {
    setup()
    {
      const publicUrl = ref(process.env.BASE_URL);
  
      return {
        publicUrl,
      }
    }
  }
  </script>