<template>
  <table>
    <tr class="table-header">
      <th :style="{ 'width': '10%' }">Section</th>
      <th :style="{ 'width': '24.5%' }">Description</th>
      <th :style="{ 'width': '65.5%' }">Details</th>
    </tr>
    <template v-for="(data, egIndex) in EmissionGas" :key="egIndex">
      <tr>
        <th>{{ data.section }}</th>
        
        <td v-if="Object.keys(data.details).length" class="padd-0" colspan="2">
          <table class="no-border">
            <template v-for="(childData, key, detailIndex) in data.details" :key="detailIndex">
              <tr v-if="detailIndex == 0 && key === 'emissions'">                
                <td colspan="3" :rowspan="childData.length + 1">{{ data.descriptions }}</td>
                <template v-if="data.header.length">
                <template v-for="(hName, h_index) in data.header" :key="h_index">
                  <th class="bg-color" v-if="hName === 'GWP by Gas'" colspan="2" rowspan="2"> {{ hName }} </th>
                  <th v-else-if="hName" class="bg-color" :class="{ capitalize: key === 'emissions' }">
                    {{ hName }}
                  </th>
                </template>
              </template>
              </tr>
              <tr v-if="data.optional_header.length && key !== 'emissions'">
                  <td colspan="3" :rowspan="childData.length + 1">{{ data.optional_descriptions??'' }}</td>
                  <template v-for="(ohName, oh_index) in data.optional_header" :key="oh_index">
                      <th v-if="ohName" class="bg-color border-top" :class="{ capitalize: key === 'emissions' }">
                        {{ ohName }}
                      </th>
                  </template>
                  <th class="border-bottom border-top"></th>
                  <th class="border-bottom border-top"></th>
              </tr>  
              <template v-for="(headerData, index) in childData" :key="index">
                 <tr>
                  <template v-for="(column, index) in headerData" :key="index">
                    <td v-if="index !== 'GWP by Gas'">{{ roundNumber(column) }}</td>
                  </template>
                  <td class="border-bottom border-top" v-if="key !== 'emissions'"></td>
                  <td class="border-bottom border-top" v-if="key !== 'emissions'"></td>
                 </tr>
              </template>
            </template>
          </table>
        </td>
      </tr>

    </template>

  </table>
</template>
<script>
export default {
  props: {
    EmissionGas: Object
  },

  setup()
  {
    function roundNumber(number)
    {
      if (typeof number === 'string') {
        return number;
      } else if(Number(number) == 0){
        return number;
      } else if(Number(number) > 99999){
        return Number(number).toExponential();
      } else if(Number(number) < 0.0001){
        return Number(number).toExponential();
      } else{
        return Math.round(Number(number) * 100000) / 100000;
      }
    }

    return {
      roundNumber,
    }
  }
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #000;
  font-family: 'docs-Tahoma', Arial;
  font-size: 10pt;
}

td,
th {
  padding: 6px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}

td:first-child,
th:first-child {
  border-left: 1px solid #000;
}

.column-border {
  border-bottom: 1px solid #000;
}

.bg-color {
  background-color: #46bdc6;
}

.blank-column {
  height: 24px;
}

.no-border {
  border: 0;
  table-layout: fixed;
}
.padd-0 {
  padding: 0;
}
table.no-border > tr > th {
    border-top: 0;
}
table.no-border > tr > th:first-child,
table.no-border > tr > td:first-child{
    border-left: 0;
}

table.no-border > tr > th:last-child,
table.no-border > tr > td:last-child{
    border-right: 0;
}

.table-header {
  background-color: #9fc5e8;
}
.table-header > th {
  font-size: 14px;
}
table > tr:nth-child(2) > td:last-child th {
    height: 13vh;
}
table > tr:nth-child(2) > td:last-child td{
  height: 35px;
}
table > tr:nth-child(3) > td:last-child > table > tr:nth-child(4) > td:last-child,
table > tr:nth-child(3) > td:last-child > table > tr:nth-child(4) > td:nth-last-child(2),
table > tr:nth-child(4) > td:last-child > table > tr:nth-child(4) > td:nth-last-child(2),
table > tr:nth-child(4) > td:last-child > table > tr:nth-child(4) > td:last-child {
    border-bottom: 1px solid #000;
}
table > tr:nth-child(3) > td:last-child > table > tr:nth-child(5) > th:last-child,
table > tr:nth-child(3) > td:last-child > table > tr:nth-child(6) > td:last-child,
table > tr:nth-child(3) > td:last-child > table > tr:nth-child(7) > td:last-child,
table > tr:nth-child(3) > td:last-child > table > tr:nth-child(8) > td:last-child,
table > tr:nth-child(4) > td:last-child > table > tr:nth-child(8) > td:last-child,
table > tr:nth-child(4) > td:last-child > table > tr:nth-child(7) > td:last-child,
table > tr:nth-child(4) > td:last-child > table > tr:nth-child(6) > td:last-child,
table > tr:nth-child(4) > td:last-child > table > tr:nth-child(5) > th:last-child{
    border-right: 1px solid #000;
}
th {
  font-weight: bold;
}
.w-10{
  width:10%;
}
.capitalize {
  text-transform: capitalize;
}

td {
  word-wrap: break-word;
}
</style>
