export default {
    emissionsFacilityData(emission_facility){
        const HEADER_ROW = [
            {
              value: 'Section',
              fontWeight: 'bold',
              backgroundColor:"#9FC5E8",
              height:20,
              alignVertical:"center",
              borderColor: "#000000",
            },
            {
              value: 'Description',
              fontWeight: 'bold',
              backgroundColor:"#9FC5E8",
              height:20,
              alignVertical:"center",
              borderColor: "#000000",
            },
            {
              value: 'Details',
              fontWeight: 'bold',
              backgroundColor:"#9FC5E8",
              height:20,
              span:6,
              alignVertical:"center",
              borderColor: "#000000",
            }
          ];
          
          const columns = [
            { width: 15 },
            { width: 40 }, // in characters
            { width: 10 },
            { width: 10 },
            { width: 10 },
            { width: 10 },
            { width: 10 },
            { width: 10 },
          ];
          
          const data = [
            HEADER_ROW,
          ];
        
        var data_len = data.length;
        var data_index = 0;

        emission_facility.forEach((currentValue)=>{
            var org_details = currentValue.details;
            var org_details_len = org_details.length;

            var row_data = [
                // "Section"
                {
                  value: currentValue.section,
                  wrap: true,
                  fontWeight:"bold",
                  align: "center",
                  alignVertical:"center",
                  borderColor:"#000000",
                  rowSpan:org_details_len+1
                },
                // "Descriptions"
                {
                  value: currentValue.descriptions,
                  wrap: true,
                  align: 'center',
                  alignVertical:"center",
                  borderColor:"#000000",
                  rowSpan:org_details_len+1
                },
              ];

              data[data_index+data_len]= row_data;
              data_index++;

              if(org_details_len > 0){

                row_data.push({
                  value: "Emissions",
                  align: 'center',
                  alignVertical:"center",
                  backgroundColor:"#46BDC6",
                  height: 40,
                  wrap:true,
                  borderColor:"#000000"
                  });
                  
                var location_names = org_details[0]['locations'].map(l => {
                  return l.location_name;
                });
                
                location_names.forEach(location_name => {
                  row_data.push({
                      value: location_name,
                      align: 'center',
                      alignVertical:"center",
                      backgroundColor:"#46BDC6",
                      height: 40,
                      wrap:true,
                      borderColor:"#000000"
                    })
                });
  
                // data[data_index+data_len]= row_data;
                // data_index++;
                
                for (let i = 0; i < org_details_len; i++) {

                    let new_row_data = [
                      null,
                      null,
                      {
                        value: org_details[i]['scope'],
                        align: 'center',
                        alignVertical:"center",
                        backgroundColor:"#46BDC6",
                        height: 30,
                        borderColor:"#000000",
                        wrap: true,
                      },
                    ];

                    org_details[i]['locations'].forEach((location) => {
                      new_row_data.push({
                        value: this.roundNumber(location.location_amount),
                        align: 'center',
                        alignVertical:"center",
                        borderColor:"#000000",
                        wrap: true,
                      });
                    });

                    data[data_index+data_len] =  new_row_data;
                    data_index++;
                  }
              }
        });

        return {
            data,
            columns
        }
    },

    roundNumber(number)
    {
      if(typeof number === 'undefined'){
        return '';
      } else if (typeof number === 'string') {
        return number;
      } else if(Number(number) == 0){
        return number;
      } else if(Number(number) > 99999){
        return Number(number).toExponential();
      } else if(Number(number) < 0.0001){
        return Number(number).toExponential();
      } else{
        return Math.round(Number(number) * 100000) / 100000;
      }
    }
}