<template>
  <table>
    <tr class="table-header">
      <th :style="{ 'width': '10%' }">Section</th>
      <th :style="{ 'width': '30%' }">Description</th>
      <th :style="{ 'width': '35.8%' }" colspan="3">Details</th>
      <th :style="{ 'width': '8%' }">Output</th>
      <th :style="{ 'width': '8%' }">Output</th>
      <th :style="{ 'width': '9%' }">Output</th>
    </tr>
    <template v-for="(data, ecIndex) in EmissionCategory" :key="ecIndex">
      <tr v-if="data.header.length">
        <th></th>
        <th></th>
          <template v-for="(header, hIndex) in data.header" :key="hIndex">
            <th class="bg-color text-center">{{ header }}</th>
          </template>
      </tr>
      <template v-if="data.details.length">
      <template v-for="(childData, detailsIndex) in data.details" :key="detailsIndex">
        <template v-if="childData.category.length">
          <template v-for="(subChild, index) in childData.category" :key="index">
            <tr>
            <th v-if="index === 0 && detailsIndex == 0" :rowspan="calculateRows(data.details)">{{ data.section }}</th>
            <td v-if="index === 0 && detailsIndex == 0" :rowspan="calculateRows(data.details)">{{ data.descriptions }}</td>
            <td v-if="index === 0" :rowspan="childData.category.length" class="bg-color">{{ childData.scope }}</td>
            <td class="bg-color">{{ subChild.name }}</td>
            <td>{{ roundNumber(subChild.total_emissions) }}</td>
            <td>{{ roundNumber(subChild.biogenic_emissions) }}</td>
            <td>{{ roundNumber(subChild.base_year_total_emissions) }}</td>
            <td>{{ roundNumber(subChild.base_year_biogenic_emissions) }}</td>
          </tr>
        </template>
        </template>
        <template v-else>
          <tr>
            <th v-if="ecIndex === 0 && detailsIndex == 0" :rowspan="calculateRows(data.details)">{{ data.section }}</th>
            <td v-if="ecIndex === 0 && detailsIndex == 0" :rowspan="calculateRows(data.details)">{{ data.descriptions }}</td>
            <td class="bg-color">{{ childData.scope }}</td>
            <td class="bg-color"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </template>
      </template>
    </template>
    <template v-else>
      <tr>
        <th>{{ data.section }}</th>
        <td>{{ data.descriptions }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </template>
    </template>
  </table>
</template>
<script>
export default {
  props: {
    EmissionCategory: Object
  },

  setup()
  {
    function roundNumber(number)
    {
      if (typeof number === 'string') {
        return number;
      } else if(Number(number) == 0){
        return number;
      } else if(Number(number) > 99999){
        return Number(number).toExponential();
      } else if(Number(number) < 0.0001){
        return Number(number).toExponential();
      } else{
        return Math.round(Number(number) * 100000) / 100000;
      }
    }
    
    function calculateRows(data){
      var total_len = 1;
      data.forEach((currentVal, index) => {
        total_len += (currentVal.category.length + index);
      });
      return total_len;
    }

    return {
      roundNumber,
      calculateRows,
    }
  }
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #000;
  font-family: "docs-Tahoma", Arial;
  font-size: 10pt;
}

td,
th {
  padding: 6px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}

td:first-child,
th:first-child {
  border-left: 1px solid #000;
}

.bg-color {
  background-color: #46bdc6;
}

.no-border {
  border: 0;
}

.padd-0 {
  padding: 0;
}

td>.no-border>tr:first-child>td,
td>.no-border>tr:first-child>th {
  border-top: 0;
  border-left: 0;
}

td>.no-border>tr>td {
  border-left: 0;
}

td>.no-border>tr>td:last-child,
td>.no-border>tr>th:last-child {
  border-right: 0;
}

.table-header {
  background-color: #9fc5e8;
}
.table-header > th {
  font-size: 14px;
}

th {
  font-weight: bold;
}

td {
  word-wrap: break-word;
}
</style>
