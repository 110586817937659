<template>
  <div>
    <div v-if="route.name === 'reports'">
      <h1>Reports</h1>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import {useRoute} from "vue-router";

export default {
  setup()
  {
    const route = useRoute();

    return {
      route,
    }
  }
}
</script>