<template>
  <table>
    <tr class="table-header">
      <th>Section</th>
      <th>Reporting Framework Tags</th>
      <th>Description (Questionnaire - "Question" field)</th>
      <th>Details</th>
    </tr>
    <template v-for="(data, iiIndex) in InventoryInformation" :key="iiIndex">
      <tr>
        <th>{{ data.section }}</th>
        <td v-if="data.informations.length" colspan="3" class="padd-0">
          <table class="no-border">
            <template v-for="(columnData, diIndex) in data.informations" :key="diIndex">
              <tr>
                <td>{{ columnData.reporting_framework_tag }}</td>
                <td>{{ columnData.question }}</td>
                <td v-if="columnData.details.length" class="padd-0">
                  <table class="no-border">
                    <template
                      v-for="(childData, detailIndex) in columnData.details"
                    >
                      <template v-for="(subChild, index) in childData.category" :key="index">
                        <tr v-if="detailIndex === 0 && index === 0">
                          <template
                            v-for="(
                              childHeaderName, childHeadIndex
                            ) in Object.keys(subChild)"
                            :key="childHeadIndex"
                          >
                            <th
                              v-if="childHeaderName === 'name'"
                              colspan="2"
                              class="bg-color text-center"
                              :key="childHeadIndex"
                            >
                            {{ columnData.header[childHeaderName]}}
                            </th>
                            <th v-else class="bg-color">
                              {{ columnData.header[childHeaderName]}}
                            </th>
                          </template>
                        </tr>
                        <tr>
                          <td v-if="index === 0"  class="bg-color" :rowspan="childData.category.length">{{ childData.scope }}</td>
                          <td>{{ subChild.name }}</td>
                          <td>{{ subChild.description_type }}</td>
                          <td>{{ subChild.description_date }}</td>
                          <td>{{ subChild.description_methodologies }}</td>
                          <td>{{ subChild.other_value }}</td>
                        </tr>
                      </template>
                    </template>
                  </table>
                </td>
                <td v-else>{{ columnData.details }}</td>
              </tr>
            </template>
          </table>
        </td>
        <td v-else>{{ data.informations }}</td>
      </tr>
    </template>
  </table>
</template>
<script>
export default {
  props: {
    InventoryInformation: Object
  }
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #000;
  font-family: "docs-Tahoma", Arial;
  font-size: 10pt;
  word-break: break-word;
  table-layout: fixed;
}
td,
th {
  padding: 6px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  width:15%;
}

table > tr > td:first-child,
table > tr > th:first-child {
  width:10%;
}

.no-border > tr > td:first-child,
.no-border > tr > th:first-child {
  width:15%;
}
tr > th:last-child,
tr > td:last-child {
  width: 60%;
}

td:first-child,
th:first-child {
  border-left: 1px solid #000;
}
.column-border {
  border-bottom: 1px solid #000;
}
.bg-color {
  background-color: #46bdc6;
}
.descp {
  width: 35%;
}
.no-border {
  border: 0;
}
.padd-0 {
  padding: 0;
}
td > .no-border > tr:first-child > td,
td > .no-border > tr:first-child > th {
  border-top: 0;
  border-left: 0;
}

td > .no-border > tr > td {
  border-left: 0;
}

td > .no-border > tr > td:last-child,
td > .no-border > tr > th:last-child {
  border-right: 0;
}
.table-header {
  background-color: #9fc5e8;
}

.table-header > th {
  font-size: 14px;
}
td > .no-border > tr > td > table td,
td > .no-border > tr > td > table th{
    width:15%
}
td > .no-border > tr > td > table td:first-child,
td > .no-border > tr > td > table th:first-child{
    width:30%;
}

th {
  font-weight: bold;
}

td {
  word-wrap: break-word;
}
</style>
