import {defineStore} from "pinia";

export const useRestletStore = defineStore('restlet', {
    state: () => {
        return {
            authentication_restlet_url: '',
            emission_categories_restlet_url: '',
            emission_sources_restlet_url: '',
            entity_search_restlet_url: '',
            segment_search_restlet_url: '',
            ghg_transactions_restlet_url: '',
            ghg_reductions_restlet_url: '',
            emission_dashboard_restlet_url: '',
            custom_emission_factor_restlet_url: '',
            link_restlet_url: '',
            period_restlet_url: '',
            scope_restlet_url: '',
            report_restlet_url: '',
            list_record_search_restlet_url: '',
            workbench_restlet_url: '',
        };
    },
    getters: {},
    actions: {
        setRestletUrls(restlet_urls)
        {
            this.authentication_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_auth_controller&deploy=customdeploy_csghg_auth_controller_d1';
            this.emission_categories_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_emcat_controller&deploy=customdeploy_csghg_emcat_controller_d1';
            this.emission_sources_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_es_controller&deploy=customdeploy_csghg_es_controller_d1';
            this.entity_search_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_ent_search_controller&deploy=customdeploy_csghg_ent_search_cont_d1';
            this.segment_search_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_seg_search_controller&deploy=customdeploy_csghg_seg_search_cont_d1';
            this.ghg_transactions_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_et_controller&deploy=customdeploy_csghg_tran_controller_d1';
            this.ghg_reductions_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_er_controller&deploy=customdeploy_csghg_er_controller_d1';
            this.emission_dashboard_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_dashboard_controller&deploy=customdeploy_csghg_dash_controller_d1';
            this.custom_emission_factor_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_custef_controller&deploy=customdeploy_csghg_custef_controller_d1';
            this.link_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_link_controller&deploy=customdeploy_csghg_link_controller_d1';
            this.period_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_period_controller&deploy=customdeploy_csghg_period_controller_d1';
            this.scope_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_scope_controller&deploy=customdeploy_csghg_scope_controller_d1';
            this.report_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_report_controller&deploy=customdeploy_csghg_report_controller_d1';
            this.list_record_search_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_lre_search_controller&deploy=customdeploy_csghg_lre_search_control_d1';
            this.workbench_restlet_url = '/app/site/hosting/restlet.nl?script=customscript_csghg_workbench_controller&deploy=customdeploy_csghg_wb_controller_d1';
        }
    }
});