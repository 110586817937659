<template>
  <div>
    <input ref="parent" type="search" class="form-control form-control-sm" :placeholder="(disabled == false) ? placeholder : ''" :disabled="disabled" v-model="search" @input="dropdownSearch($event)" :style="disabledInputCss()">
    <div class="list-group shadow-lg" v-if="menu" style="position: absolute; z-index: 1000;">
      <button type="button" class="list-group-item list-group-item-action" v-for="(segment, index) in segments" @click="selectSegment(segment)" :key="index">
        <span class="text-uppercase">{{ segment.name }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import {useRestletStore} from "@/stores/restlets";
import {storeToRefs} from "pinia/dist/pinia.esm-browser";
import {ref, onMounted, watch} from "vue";
import axios from "axios";

import LoadingIndicator from "@/components/global/LoadingIndicator";
import DisabledLabelCss from "@/helpers/disabledLabelCss";

export default {

  components: {
    LoadingIndicator,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Type to search',
    },
    initial_value: {
      type: String,
      default: '',
    },
    segment_type: {
      type: String,
      default: 'departments', /** subsidiaries,departments,classes,locations,fixed_assets */
    }
  },

  setup(props, {emit, expose})
  {
    const restlet_store = useRestletStore();
    const {segment_search_restlet_url} = storeToRefs(restlet_store);

    const menu = ref(false);
    const loading = ref(false);
    const name =  ref('');
    const segments = ref([]);

    const search = ref('');

    function dropdownSearch(event)
    {
      if(search.value.length === 0)
      {
        segments.value = [];
        menu.value = false;
        emit('segment-selected', null);
      }

      else {
        setTimeout(() => {
          searchSegments(false, true);
        }, 500);
      }
    }

    function searchSegments(run = false, open_menu = false)
    {
      if(search.value.length >= 3 || (run && !search.value))
      {
        let search_params = {search_term: search.value, segment_type: props.segment_type};

        let query = new URLSearchParams(search_params).toString();
        let endpoint = segment_search_restlet_url.value + '&' + query;


        loading.value = true;
        axios.get(endpoint)
            .then(response => {
              segments.value = response.data[props.segment_type];
              loading.value = false;
            })
            .catch(error => {});
      }

      if(open_menu)
      {
        menu.value = true;
      }
    }

    function selectSegment(segment)
    {
      if(segment)
      {
        search.value = segment.name;
        emit('segment-selected', segment);
        menu.value = false;
      }
    }

    function clear()
    {
      search.value = '';
      segments.value = [];
    }

    function setExistingName(name)
    {
      search.value = name;
    }

    function disabledInputCss(){
      let css_val = DisabledLabelCss.disabledLabel(props.disabled);
      return css_val;
    }

    onMounted(() => {
      search.value = props.initial_value || '';
    });

    watch(() => props.initial_value, ( newValue) => {
      search.value = newValue;
    });

    expose({setExistingName});

    return {
      menu,
      loading,
      search,
      name,
      segments,
      dropdownSearch,
      searchSegments,
      selectSegment,
      clear,
      setExistingName,
      disabledInputCss,
    }
  }
}
</script>
